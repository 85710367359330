import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FetchApi from '../React/REST';

const initialState = {
    status: 'idle',
    firstLoading: false,
    dashboard: {},
    error: false
};

export const fetchDashboardParams = createAsyncThunk(
    'dashboardParams/status',
    async () => {
        const fetchApi = new FetchApi('/dashboard?noActionSeconds=' + window.noActionSeconds);
        const response = await fetchApi.get();
        return response;
    }
);

const dashboardParamsSlice = createSlice({
    name: 'dashboardParams',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchDashboardParams.fulfilled]: (state, { payload }) => {
            state.dashboard = payload;
            state.firstLoading = true;
            state.status = 'load';
        },
        [fetchDashboardParams.pending]: (state) => {
            state.status = 'pending';
        },
        [fetchDashboardParams.rejected]: (state, action) => {
            state.status = 'rejected';
        },
    }
});

export default dashboardParamsSlice.reducer;

