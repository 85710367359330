import FetchApi from '../../REST'

export default async (gettext, type = 3) => {
    const defaultValues = await FetchApi('/outlook/card-settings/default').get()
    if (type === 3) {
        defaultValues.name = gettext.getString('Новый тип')
        defaultValues.from = []
        defaultValues.to = []
        defaultValues.subject = []
        defaultValues.copy = []
        defaultValues.token = ''
        defaultValues.type = type
        defaultValues.settingId = ~Math.floor((Math.random() * 1000))
    }
    if (type !== 1) {
        defaultValues.sendMailToSecurity = 0
    }
    return defaultValues
}
