import {LDAP_PROFILES_SERVICE} from '../../const';
import style from './style.modules.css';

class LdapProfileModalController {
    constructor(LdapProfilesService, profileId, moodleIsVisible) {
        this.LdapProfilesService = LdapProfilesService;
        this.profileId = profileId;
        this.moodleIsVisible = moodleIsVisible;
        this.style = style;
        this.profile = null;
        this.changePassword = true;
        this.appCtrl = window.appCtrl;
        this.gettextCatalog = this.appCtrl.gettextCatalog;
        this.loading = false;
        this.errorText = null;
        this.useTimeValue = false;
    }

    setForm = (form) => {
        this.form = form;
    };

    $onInit = () => {
        this.loading = true;
        this.errorText = null;
        if (this.profileId > 0) {
            this.LdapProfilesService.one(this.profileId).then((profile) => {
                this.profile = profile;
                if (profile && profile.timeValue > 0) {
                    this.useTimeValue = true;
                }
                this.changePassword = false;
                this.loading = false;
            }, () => {
                this.loading = false;
                this.errorText = this.gettextCatalog.getString('Ошибка при загрузке ldap-профиля');
            });
        } else {
            this.profile = this.LdapProfilesService.YiiModel.one('ldap-profiles');
            this.profile.timeout = 60;
            this.loading = false;
        }
        this.editable = this.appCtrl && this.appCtrl.hasAccess(10, 'ldap_save');
    };

    save = () => {
        if (this.editable) {
            this.errorText = null;
            this.form.$setSubmitted();
            if (this.form.$valid) {
                this.inProcess = true;
                if (!this.useTimeValue) {
                    this.profile.timeValue = null;
                    this.profile.timePeriod = null;
                }
                this.profile.$save().then((profile) => {
                    if (profile) {
                        this.$close(profile);
                    }
                    this.inProcess = false;
                }).catch(() => {
                    this.errorText = this.gettextCatalog.getString('Ошибка при сохранении ldap-профиля');
                    this.inProcess = false;
                });
            }
        }
    };

    setProfileTimeout = (value) => {
        this.profile.timeout = value;
    }

    setSchema = (schema) => {
        if (this.editable) {
            this.profile.schema = schema || 'ActiveDirectory';
        }
    };

    setEncryption = (encryption) => {
        if (this.editable) {
            this.profile.encryption = encryption || 0;
        }
    };

    enableTimeValue = (enabled) => {
        this.useTimeValue = !!enabled;
        if (this.useTimeValue && !this.profile.timeValue) {
            this.profile.timeValue = 1;
        }
    };
}

LdapProfileModalController.$inject = [
    LDAP_PROFILES_SERVICE,
    'profileId',
    'moodleIsVisible',
];

export {
    LdapProfileModalController,
};
