import React, {useContext} from 'react';
import { GettextContext, InjectorContext } from 'rootReact/context';
import { BossKadrovikProfileModal } from 'root/assets/webpack/src/js/components/bossKadrovikModal';
import { deleteAccount } from 'root/assets/webpack/src/js/toolkit/accountUsers';
import {useSelector, useDispatch} from 'react-redux';
import SettingsHeader from 'rootReact/components/SettingsSectionHeader';
import SettingsTable from 'rootReact/components/SettingsTable';
import { bossKadrovikSchema } from './BossKadrovikSchema.jsx';

function SettingsBossKadrovik({licenseIsValid}) {
    const gettext = useContext(GettextContext)
    const injector = useContext(InjectorContext)
    const bossProfiles = useSelector(state => state.settings.settings.bossKadrovikProfiles)
    const dispatch = useDispatch()

    const getHeaderLink = () => {
        return (
                <div>
                    <a onClick={() => licenseIsValid && openModal()} className={`settings_path-right ${!licenseIsValid ? 'nolink' : ''}`}>{gettext.getString('добавить')}</a>
                </div>
        )
    }

    const openModal = (id = 0) => {
        let modal = injector.instantiate(BossKadrovikProfileModal)
        modal.open(id)
    }

    const handleRow = (id) => {
        openModal(id)
    }

    const iconDelete = (e, id) => {
        e.stopPropagation()
        dispatch(deleteAccount(id))
    }

    return (
            <div>
                <SettingsHeader title={gettext.getString('Профили БОСС-Кадровик')} right>{getHeaderLink()}</SettingsHeader>
                <div className={'settings_item'}>
                    <SettingsTable header={[
                        {name: gettext.getString('Название')},
                        {name: gettext.getString('Адрес')},
                        {name: gettext.getString('Имя базы')},
                        {name: ''}
                    ]}
                                   body={bossProfiles}
                                   assets={{empty: gettext.getString('Нет профилей')}}
                                   cellSchema={
                                       bossKadrovikSchema({
                                           iconHandle:[iconDelete],
                                           handleRow,
                                           disabled: !licenseIsValid
                                       })}
                    ></SettingsTable>
                </div>
            </div>
    )
};

export default SettingsBossKadrovik;
