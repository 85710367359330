import arrowsFilterTemplate from './view.html';

import {ArrowsFilterController} from './controller';

const ArrowsFilterComponent = {
    template: arrowsFilterTemplate,
    controller: ArrowsFilterController,
    controllerAs: '$',
    scope: true,
    bindings: {
        templatesShow: '=',
        clearArrows: '=',
        sources: '=',
        setFieldChange: '=',
        fieldsChange: '=',
        vectors: '=',
        templatesAll: '<',
        arrowsCampaign: '<',
        templateCampaign: '<',
        allArrows: '<',
        isDisabled: '<',
    },
};

export {
    ArrowsFilterComponent,
};
