import style from './style.modules.css';

class DaemonMessageViewController {
    constructor (scope) {
        this.style = style;
        this.scope = scope;
    }

    $onInit () {}
}

DaemonMessageViewController.$inject = [
    '$scope'
];

export {
    DaemonMessageViewController
};
