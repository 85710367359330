import modalTemplate from './view.html';

import {CertsModalController} from './controller';
import { CERT_MODAL } from '../../const';

class CertsModal {
    constructor($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
        this.modalInstance = null;
    }

    open = (certId) => {
        this.modalInstance = this.$uibModal.open({
            template: modalTemplate,
            bindToController: true,
            controller: CERT_MODAL,
            controllerAs: '$',
            size: 'lg',
            resolve: {
                certId: certId,
            },
            backdrop: 'static',
            keyboard: false,
        });

        return this.modalInstance.result;
    };
}

export {
    CertsModalController,
    CertsModal
};
