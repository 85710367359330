import imageModalTemplate from './view.html';

import {ImageModalController} from './controller';

import {IMAGE_MODAL_CONTROLLER} from '../../const';

class ImageModal {
    constructor($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
        this.modalInstance = null;
    }

    open = (image) => {
        this.modalInstance = this.$uibModal.open({
            template: imageModalTemplate,
            bindToController: true,
            controller: IMAGE_MODAL_CONTROLLER,
            controllerAs: '$',
            size: 'md',
            resolve: {
                image: image,
            },
            backdrop: 'static',
            keyboard: false,
        });
        return this.modalInstance.result;
    };
}

export {
    ImageModal,
    ImageModalController,
};
