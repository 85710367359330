const fileToBlob = async (file) => {
    let arrayBuffer = await file.arrayBuffer();
    let blob = new Blob([new Uint8Array(arrayBuffer)], {type: file.type });
    return blob;
};

export const setLocalesFormData = async (params, scheme) => {
    let formData = new FormData();
    for (let i = 0; i < params.length; i++) {
        for (let key in scheme.files) {
            if (params[i][key]) {
                let file = await fileToBlob(params[i][key]);
                formData.append(`locales[${i}][${scheme.files[key]}]`, file, params[i][key].name);
            }
        }
        scheme.data.forEach(item => {
            if (params[i][item] || item === 'isDefault') {
                formData.append(`locales[${i}][${item}]`, params[i][item]);
            }
        });
    }
    return formData;
};