import practiceTemplate from './view.html';

import {PracticeController} from './controller.js';

const PracticeToolbarComponent = {
    template: practiceTemplate,
    controller: PracticeController,
    controllerAs: '$',
    scope: true,
    bindings: {
    },
};

export {
    PracticeToolbarComponent,
};
