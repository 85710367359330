import { LDAP_PROFILES_SERVICE, ROLES_SERVICE, TARGETS_SERVICE } from '../../const';
import angular from 'angular';
import style from './style.modules.css';
import ldapStyle from '../employeesModal/ldap.modules.css';
import { hasAccess } from '../../reselect';
import setOptions from '../../helpers/setOptions';
import ModalRole from '../../React/components/ModalRole';

const ROLE_SYNC_AUTO_MODE = 1;
const ROLE_SYNC_MANUAL_MODE = 2;
const ROLE_SYNC_INTERVAL_DEFAULT = 3600*24;

class RoleModalController {
    constructor (RolesService, roleId, ldapProfilesList, LdapProfilesService, TargetsService, scope, injector, $ngRedux, gettextCatalog) {
        this.RolesService = RolesService;
        this.sections = []; //RolesService.getSections();
        this.rights = []; //RolesService.getRights();
        this.roleId = roleId;
        this.ModalRole = ModalRole;
        this.style = style;
        const extensible = (list) => {
            return list.map((item) => {
                let newObj = {};
                for (let key in item) {
                    newObj[key] = item[key];
                }
                return newObj;
            });
        };
        this.ldapProfilesList = extensible(ldapProfilesList);
        this.ldapStyle = ldapStyle;
        this.inProcess = false;
        this.role = null;
        this.gettextCatalog = gettextCatalog;
        this.TargetsService = TargetsService;
        this.LdapProfilesService = LdapProfilesService;
        this.ldapProfiles = LdapProfilesService.all;
        this.syncParameters = [];
        this.ldapSyncRefs = [];
        this.scope = scope;
        this.injector = injector;
        this.loading = {
            role: false,
            group: false
        };
        this.errors = {
            role: null
        };
        this.errorText = null;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, null)(this);
        this.redux = $ngRedux;
    }

    mapStateToThis = (state) => {
        return {
            editable: hasAccess(state, { sectionId: 102, rightName: 'role_save' }),
            syncEditable: hasAccess(state, { sectionId: 102, rightName: 'role_sync' }),
            userInfo: state.auth.auth
        };
    }

    $onInit = () => {
        this.loading.role = true;
        let headers = {
            'X-Csrf-Token': window.csrfToken,
            'Enable-Session': 1
        };
        fetch(`${window.config.SYSTEM_URL}${window.config.API_URL}/roles/sections`, {
            headers
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(sections => {
            this.sections = sections;
            fetch(`${window.config.SYSTEM_URL}${window.config.API_URL}/roles/rights`, { headers }
            ).then(response => {
                if (response.ok) {
                    return response.json();
                }
            }).then(rights => {
                this.rights = rights;
                this.loadRole();
            });
        });
    };

    setForm = (form) => {
        this.form = form;
    };

    getGroupLoading = (loading) => {
        this.loading.group = loading;
    }

    loadRole = async () => {
        this.errorRole = false;
        if (!this.roleId) {
            this.initNewRole();
            this.loading.role = false;
            this.errorRole = false;
            this.scope.$apply();
            return;
        }
        let headers = {
            'X-Csrf-Token': window.csrfToken,
            'Enable-Session': 1
        };
        try {
            let response = await fetch(window.config.SYSTEM_URL + window.config.API_URL + '/roles/' + this.roleId, {headers});
            if (!response.ok) {
                throw true;
            }
            let role = await response.json();
            if (role.default) {
                this.editable = false;
            }
            this.role = role;
            this.loading.role = false;
            this.scope.$apply();
        } catch (err) {
            console.log(err);
            this.loading.role = false;
            this.errorRole = true;
            this.scope.$apply();
        }
    };

    initNewRole = () => {
        this.role = {};
        this.role.page = 'targets';
        this.role.rights = [];
        if (this.sections) {
            angular.forEach(this.sections, (sectionValue, sectionId) => {
                this.checkSection(sectionId);
                if (sectionId == 0) {
                    this.role.sections[sectionId].access = 0;
                }
            });
        }
        // this.initRole();
    }

    beforeSave = () => {
        if (this.sections) {
            angular.forEach(this.sections, (sectionValue, sectionId) => {
                this.checkSection(sectionId);
            });
        }
        delete this.syncParameters.prettyInterval;
        this.role.ldapSyncRefs = this.ldapSyncRefs;
        if (!this.ldapSyncRefs.length) {
            this.role.syncParameters = {};
        } else {
            this.role.syncParameters = this.syncParameters;
        }
    };

    save = () => {
        if (!this.editable || this.loading.group) return;
        this.errorText = null;
        this.form.$setSubmitted();
        if (this.form.$valid) {
            this.beforeSave();
            this.inProcess = true;
            let options = setOptions();
            options.method = this.role.id ? 'PUT': 'POST';
            options.headers['Content-Type'] = 'application/json;charset=UTF-8';
            options.body = JSON.stringify(this.role);
            let addres = `${window.config.SYSTEM_URL}${window.config.API_URL}/roles`;
            if (this.role.id) {
                addres += `/${this.role.id}`;
            }
            fetch(addres, options)
                .then((role) => {
                    if (role) {
                        this.$close(role);
                    }
                    this.inProcess = false;
                }).catch(() => {
                    this.inProcess = false;
                    this.errorText = this.gettextCatalog.getString('Ошибка при сохранении роли');
                });
        }
    };

    checkSection = (sectionId, addRights) => {
        if (typeof (this.role.sections) == 'undefined') {
            this.role.sections = {};
        }
        if (typeof (this.role.sections[sectionId]) == 'undefined') {
            this.role.sections[sectionId] = {
                type: sectionId,
                access: -1
            };
        }
        if (typeof (this.role.sections[sectionId].rights) == 'undefined') {
            this.role.sections[sectionId].rights = [];
        }
        if (addRights) {
            if (this.rights) {
                angular.forEach(this.rights, (sectionRights, sectionId) => {
                    angular.forEach(sectionRights, (rightName, rightId) => {
                        this.role.sections[sectionId].rights.push(rightId);
                    });
                });
            }
        }
    };

    sectionChanged = (sectionId) => {
        if (this.editable) {
            this.checkSection(sectionId);
            if (this.role.sections[sectionId].access < 0) {
                if (typeof (this.rights[sectionId]) == 'undefined') {
                    this.role.sections[sectionId].access = 1;
                } else {
                    this.role.sections[sectionId].access = 0;
                }
                if (this.sections[sectionId] && this.sections[sectionId].onAdd && this.sections[sectionId].onAdd) {
                    if (this.sections[sectionId].onAdd.sections) {
                        angular.forEach(this.sections[sectionId].onAdd.sections, (selectSectionId) => {
                            this.checkSection(selectSectionId);
                            if (this.role.sections[selectSectionId].access < 0) {
                                if (typeof (this.rights[selectSectionId]) == 'undefined') {
                                    this.role.sections[selectSectionId].access = 1;
                                } else {
                                    this.role.sections[selectSectionId].access = 0;
                                }
                            }
                        });
                    }
                }
            } else {
                this.role.sections[sectionId].access = -1;
                if (this.sections[sectionId] && this.sections[sectionId].onRemove) {
                    if (this.sections[sectionId].onRemove.sections) {
                        angular.forEach(this.sections[sectionId].onRemove.sections, (removeSectionId) => {
                            this.checkSection(removeSectionId);
                            this.role.sections[removeSectionId].access = -1;
                        });
                    }
                    if (this.sections[sectionId].onRemove.rights) {
                        angular.forEach(this.sections[sectionId].onRemove.rights, (removeRights, removeSectionId) => {
                            this.removeSectionRights(removeSectionId, removeRights);
                        });
                    }
                }
            }
        }
    };

    rightChanged = (sectionId, rightId) => {
        if (this.editable) {
            this.checkSection(sectionId);
            let rightIndex = this.role.sections[sectionId].rights.indexOf(rightId);
            if (rightIndex == -1) {
                this.role.sections[sectionId].rights.push(rightId);
                if (this.rights[sectionId] && this.rights[sectionId][rightId] && this.rights[sectionId][rightId].onAdd) {
                    if (this.rights[sectionId][rightId].onAdd.sections) {
                        angular.forEach(this.rights[sectionId][rightId].onAdd.sections, (selectSectionId) => {
                            this.checkSection(selectSectionId);
                            if (this.role.sections[selectSectionId].access < 0) {
                                if (typeof (this.rights[selectSectionId]) == 'undefined') {
                                    this.role.sections[selectSectionId].access = 1;
                                } else {
                                    this.role.sections[selectSectionId].access = 0;
                                }
                            }
                        });
                    }
                    if (this.rights[sectionId][rightId].onAdd.rights) {
                        angular.forEach(this.rights[sectionId][rightId].onAdd.rights, (selectRights, selectSectionId) => {
                            this.selectSectionRights(selectSectionId, selectRights);
                        });
                    }
                }
            } else {
                this.role.sections[sectionId].rights.splice(rightIndex, 1);
                if (this.rights[sectionId] && this.rights[sectionId][rightId] && this.rights[sectionId][rightId].onRemove) {
                    if (this.rights[sectionId][rightId].onRemove.rights) {
                        angular.forEach(this.rights[sectionId][rightId].onRemove.rights, (removeRights, removeSectionId) => {
                            this.removeSectionRights(removeSectionId, removeRights);
                        });
                    }
                }
            }
            if (this.role.sections[sectionId].rights.length > 0) {
                this.role.sections[sectionId].access = 1;
            } else {
                if (typeof (this.rights[sectionId]) == 'undefined') {
                    this.role.sections[sectionId].access = 1;
                } else if (this.role.sections[sectionId].access != -1) {
                    this.role.sections[sectionId].access = 0;
                }
            }
        }
    };

    selectSectionRights = (selectSectionId, selectRights) => {
        if (this.editable) {
            this.checkSection(selectSectionId);
            if (selectRights) {
                angular.forEach(selectRights, (selectRightId) => {
                    let selectRightIndex = this.role.sections[selectSectionId].rights.indexOf(selectRightId);
                    if (selectRightIndex == -1) {
                        this.role.sections[selectSectionId].rights.push(selectRightId);
                    }
                });
            }
        }
    };

    removeSectionRights = (removeSectionId, removeRights) => {
        if (this.editable) {
            this.checkSection(removeSectionId);
            if (removeRights) {
                angular.forEach(removeRights, (removeRightId) => {
                    let removeRightIndex = this.role.sections[removeSectionId].rights.indexOf(removeRightId);
                    if (removeRightIndex > -1) {
                        this.role.sections[removeSectionId].rights.splice(removeRightIndex, 1);
                    }
                });
            }
        }
    };

    setPage = (page) => {
        if (this.editable) {
            this.role.page = page || 'targets';
        }
    };

    getSectionKey = sectionId => {
        let sectionKey = -1;
        if (this.role && this.role.sections) {
            angular.forEach(this.role.sections, (value, key) => {
                if (+value.type == +sectionId) {
                    sectionKey = key;
                }
            });
        }
        return sectionKey;
    };

    isSectionChecked = (sectionId) => {
        sectionId = this.getSectionKey(sectionId);
        if (sectionId > -1 && this.role && this.role.sections && this.role.sections[sectionId]) {
            return this.role.sections[sectionId].access > -1 && (this.role.access < 2 || [101, 102].indexOf(sectionId) == -1);
        }
    };

    isRightChecked = (sectionId, rightId) => {
        sectionId = this.getSectionKey(sectionId);
        if (sectionId > -1 && this.role && this.role.sections && this.role.sections[sectionId] && this.role.sections[sectionId].rights) {
            return this.role.sections[sectionId].rights.indexOf(rightId) > -1 && (this.role.access < 2 || [101, 102].indexOf(sectionId) == -1);
        }
    };

    get syncModeAuto() {
        return ROLE_SYNC_AUTO_MODE;
    }
    get syncModeManual() {
        return ROLE_SYNC_MANUAL_MODE;
    }
    get syncDefaultInterval() {
        return ROLE_SYNC_INTERVAL_DEFAULT;
    }

    buildPrettyInterval(v) {
        let h = 3600;//час
        let d = h*24;//день
        let value = 1;
        let unit = 'hour';
        // если меньше суток
        if (v < d) {
            value = ~~(v/h);
        } else {
            let days = ~~(v/d);
            let hours = ~~((v%d)/3600);
            if (hours > 0) {
                value = (days*24) + hours;
            } else {
                value = days;
                unit = 'day';
            }
        }
        return {
            value: value,
            unit: unit
        };
    }

    getSyncParams = (params) => {
        this.syncParameters = params;
        this.role.syncParameters = params;
    }

    getSyncRef = (refs) => {
        this.ldapSyncRefs = refs;
        this.role.ldapSyncRefs = refs;
    }

    isAnyLoading = () => {
        return this.loading.role;
    };
}

RoleModalController.$inject = [
    ROLES_SERVICE,
    'roleId',
    'ldapProfilesList',
    LDAP_PROFILES_SERVICE,
    TARGETS_SERVICE,
    '$scope',
    '$injector',
    '$ngRedux',
    'gettextCatalog'
];

export {
    RoleModalController
};
