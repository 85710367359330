import React, {useContext, useEffect, useState} from 'react'
import SettingsHeader from 'rootReact/components/SettingsSectionHeader'
import { GettextContext, InjectorContext } from 'rootReact/context'
import SettingsTable from '../SettingsTable'
import {notificationSchema} from './notificationSchema'
import {NotificationModal} from '../../../components/notificationModal'
import {useDispatch, useSelector} from 'react-redux'
import { systemNotificationToggle } from '../../../toolkit/think'
import { targetNotification } from 'root/assets/webpack/src/js/reselect/systemNotification';
import StaticNotification from 'rootReact/components/StaticNotification';
import { usePlural } from 'rootReact/hook';

function SettingsNotifications() {
    const gettext = useContext(GettextContext)
    const injector = useContext(InjectorContext)
    const [disabled, setDisabled] = useState([])
    const dispatch = useDispatch()
    const notifications = useSelector((state) => targetNotification(state))
    const [notificationText, setNotificationText] = useState('')

    useEffect(() => {
        let dNot = notifications.filter(item => !item.status).map(item => item.id)
        checkNotifications(dNot.length)
        setDisabled(dNot)
    }, [notifications])

    const toggle = (e, id) => {
        e.stopPropagation();
        let currentItem = Object.assign({}, notifications.filter(item => item.id === id)[0])
        currentItem.status = +!currentItem.status
        dispatch(systemNotificationToggle({id, params: {body: currentItem}}))
    }

    const edit = (e, id) => {
        e.stopPropagation();
        let modal = injector.instantiate(NotificationModal)
        modal.open(id)
    }

    const checkNotifications = (count) => {
        if (!count) {
            setNotificationText('')
            return
        }
        setNotificationText(`${count} ${gettext.getString(usePlural(count, ['уведомление отключено.', 'уведомления отключены.', 'уведомлений отключены.']))}`)
    }

    return (
        <div id={'notifications-field'}>
            <SettingsHeader title={gettext.getString('Уведомления')}/>
            <SettingsTable
                header={[
                    {name: gettext.getString('Сотрудник:')},
                    {name: ''}
                ]}
                body={notifications}
                assets={{empty: gettext.getString('Нет уведомлений')}}
                cellSchema={
                    notificationSchema({
                        iconHandle:[edit, toggle],
                        handleRow: () => null,
                        disabled
                    })}
            />
            {notificationText && <div className={'notification-alert-wrapper'}>
                <StaticNotification text={notificationText} type={'warning'}/>
            </div>}
        </div>
    )
}

export default SettingsNotifications
