import {createSelector} from 'reselect';


export const wifiAttacks = (state) => {
    return state.wifi.wifi;
};

export const notExtensibleWifiAttack = createSelector([wifiAttacks], wifiAttacks => {
    return wifiAttacks.map((item) => {
        let newObj = {};
        for (let key in item) {
            newObj[key] = item[key];
        }
        return newObj;
    });
});
