import style from './style.modules.css';
import getNextPage from '../../helpers/getNextPage';
import buildSearchString from '../../helpers/buildSearchString';
import { hasAccess } from '../../reselect';
import {setBreadcrumbLink} from '../../toolkit/actions';

class InnerCoursesController {
    constructor ($scope, $ngRedux, $localStorage, gettextCatalog) {
        this.style = style;
        this.scope = $scope;
        this.departments = [];
        this.$localStorage = $localStorage;
        this.targetCount = 0;
        this.gettextCatalog = gettextCatalog;
        this.maxTargetCount = 0;
        this.loading = false;
        this.nextPage = 0;
        this.departmentsCount = 0;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, {setBreadcrumbLink})(this);
        this.strings = {
            eduComplete: gettextCatalog.getString('прошел обучение'),
            eduProcess: gettextCatalog.getString('на обучении'),
            eduMiss: gettextCatalog.getString('не прошел обучение вовремя')
        };
    }

    $onDestroy () {
        this.unsubscribe();
    }

    mapStateToThis (state) {
        return {
            selectedData: state.selectedData.selectedData,
            linkNames: state.breadcrumbLinks.link,
            accessCoursesDepartments: hasAccess(state, { sectionId: 1, rightName: 'view' })
        };
    }

    $onInit = () => {
        if (!this.accessCoursesDepartments) {
            return;
        }
        this.fetchDepartments(0, 1);
        this.scope.$watch(scope => {
            return scope.$.linkNames;
        }, (links, oldLinks) => {
            if (links.length === oldLinks.length) {
                return;
            }
            if (!links.length && !this.courseId) {
                this.parentId = null;
                this.departments = [];
                this.fetchDepartments(false, 1);
                return;
            }
            if (links.length === 1 && links[0] &&
                {}.hasOwnProperty.call(links[0], 'isTitle')) {
                this.parentId = null;
                this.departments = [];
                this.fetchDepartments(false, 1);
                return;
            }
            this.parentId = links[links.length - 1] ? links[links.length - 1].id : null;
            this.departments = [];
            this.fetchDepartments(false, 1);
        }, true);
        this.scope.$watch(scope => {
            return scope.$.selectedData.search;
        }, (search, oldSearch) => {
            let searchString = buildSearchString(search);
            let oldSearchString = buildSearchString(oldSearch);
            if (searchString != oldSearchString) {
                this.departments = [];
                this.fetchDepartments(false, 1);
            }
        });
    };

    onItemClick = (department, search) => {
        if (search) {
            this.setSearch(search);
        }
        this.loadDepartment(department.id);
    };

    loadDepartment = (id) => {
        this.setBreadcrumbLink([
            ...this.linkNames,
            ...this.departments.filter(item => item.id === id)]);
    };

    fetchDepartments = (fullList, page) => {
        this.error = false;
        this.loading = true;
        fetch(this.getAdress(fullList, this.courseId, page), {
            headers: {
                'X-Csrf-Token': window.csrfToken,
                'Enable-Session': 1
            }
        }).then(response => {
            if (response.ok) {
                // eslint-disable-next-line
                let nextLink = response.headers.get('link').split(/\,/g);
                this.departmentsCount = response.headers.get('X-Pagination-Total-Count');
                this.nextPage = getNextPage(nextLink);
            }
            return response.json();
        }).then(json => {
            if (json.message) {
                this.loading = false;
                this.error = json.message;
                this.scope.$apply();
                return;
            }
            if (json && json.length > 0) {
                if (fullList) {
                    this.departments = json;
                } else {
                    this.departments = [...this.departments, ...json];
                }
            }
            this.sortCourses();
        }).catch(() => {
            this.error = true;
            this.loading = false;
            this.scope.$apply();
        });
    };

    loadNextPage = () => {
        this.fetchDepartments(0, this.nextPage);
    };

    loadAll = () => {
        this.fetchDepartments(1, 0);
    };

    sortCourses = () => {
        let targetCount = 0;
        for (let i = 0; i < this.departments.length; i++) {
            targetCount = this.departments[i].targetsCount;
            if (targetCount > this.maxTargetCount) {
                this.maxTargetCount = targetCount;
            }
        }
        this.loading = false;
        this.scope.$apply();
    };

    getAdress = (fullList, page) => {
        let adress = `${window.config.SYSTEM_URL}${window.config.API_URL}/departments?fullList=${+fullList}&page=${page}&per-page=10`;
        adress += this.getSearchStr();
        adress += this.getSortStr();
        if (this.courseId) {
            adress += this.getCoursesStr();
        }
        if (this.parentId) {
            adress += this.getParentIdStr();
        }
        if (this.isVuln) {
            adress += this.getVulnStr();
        }
        if (this.managerId) {
            adress += this.getManagerStr();
        }
        return adress;
    };

    getSortStr = () => {
        return `&sortBy=${this.sortBy}&sortReverse=${this.sortReverse}`;
    };

    getSearchStr = () => {
        let search = this.buildSearchString();
        return `&search=${search.replace('+', '%2b')}`;
    };

    getCoursesStr = () => {
        return `&courses=${this.courseId}`;
    };

    getManagerStr = () => {
        return `&managerId=${this.managerId}&actual=1`;
    };

    getParentIdStr = () => {
        return `&parentId=${this.parentId}`;
    };

    getVulnStr = () => {
        return '&risk=1';
    };

    buildSearchString = () => {
        return buildSearchString(this.selectedData.search);
    };
}

InnerCoursesController.$inject = [
    '$scope',
    '$ngRedux',
    '$localStorage',
    'gettextCatalog'
];

export {
    InnerCoursesController
};
