import style from './style.modules.css';
import angular from 'angular';

class SettingsOutlookController {
    constructor (gettextCatalog, $location, scope, timeout, cookies) {
        this.style = style;
        this.outlookEmail = [];
        this.$location = $location;
        this.prefix = '';
        this.scope = scope;
        this.timeout = timeout;
        this.langCode = cookies.get('langCode');
        this.settingsLoading = false;
        this.settingsIsSaved = false;
        this.isSettingsChange = false;
        this.saveMailBody = 'true';
        this.gettextCatalog = gettextCatalog;
        this.rout = '/v2/outlook/settings';
        this.isSaveDisabled = true;
        this.loading = true;
        this.strings = {
            outlookEmailLabel: gettextCatalog.getString('Отправлять сообщения на эти адреса:')
        };
        this.instructionFileName = {
            ru: 'Антифишинг — инструкция по установке и настройке плагина для Microsoft Outlook.pdf',
            en: 'Antiphish — plugin installation and configuration guide for Microsoft Outlook email client.pdf',
            it: 'Antiphish — Guida per l’installazione e la configurazione per il client di posta elettronica Microsoft.pdf'
        };
    }

    $onInit = () => {
        this.getSettings();
    };

    getOutlookEmail = (data) => {
        let valid = false;
        this.outlookEmail = [...data];
        this.outlookEmail.forEach(item => {
            if (!this.isValidEmail(item)) {
                valid = true;
            }
        });
        this.isSaveDisabled = !valid;
        this.isSettingsChange = true;
    }

    setSaveMailBody = (mode) => {
        if (this.saveMailBody === mode) return;
        this.saveMailBody = mode;
        this.isChange();
    }

    isValidEmail = (email) => {
        let isValid = false;
        if (this.domains && this.domains.length) {
            let regexp = new RegExp('(\\w+@[a-zA-Z_-]+?\\.[a-zA-Z-]{2,6})', 'gim');
            isValid = email && email.match(regexp);
            if (isValid) {
                isValid = false;
                angular.forEach(this.domains, (domain) => {
                    regexp = new RegExp('(\\w+@' + domain.replace('.', '\\.') + ')', 'gim');
                    if (email.match(domain)) {
                        isValid = true;
                    }
                });
            }
        }
        return isValid;
    };

    getInstructionUrl = () => {
        return this.instructionLink ? this.instructionLink : '/document/outlook_' + this.langCode + '.pdf';
    }

    getInstructionFileName = () => {
        return this.instructionCustomFileName ? this.instructionCustomFileName : this.instructionFileName[this.langCode] || this.instructionFileName.en;
    }

    isChange = () => {
        this.isSettingsChange = true;
    }

    cross_download(url, fileName) {
        var req = new XMLHttpRequest();
        req.open('GET', url, true);
        req.responseType = 'blob';
        var __fileName = fileName;
        req.onload = function () {
            var blob = req.response;
            var contentType = req.getResponseHeader('content-type');
            if (window.navigator.msSaveOrOpenBlob) {
                // Internet Explorer
                window.navigator.msSaveOrOpenBlob(new Blob([blob], {type: contentType}), fileName);
            } else {
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.download = __fileName;
                link.href = window.URL.createObjectURL(blob);
                link.click();
                document.body.removeChild(link); //remove the link when done
            }
        };
        req.send();
    }

    getSettings = () => {
        let options = {
            method: 'GET',
            headers: {
                'X-Csrf-Token': window.csrfToken,
                'Enable-Session': 1
            },
        };
        this.goToRout(options).then(response => {
            return response.json();
        }).then(response => {
            this.outlookEmail =response['outlook.email'].split('$;$') || [];
            this.prefix = response['outlook.prefix'] || '';
            this.saveMailBody = response['outlook.saveMailBody'] || true;
            this.loading = false;
            this.scope.$apply();
        });
    }

    save = () => {
        this.settingsLoading = true;
        let options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Csrf-Token': window.csrfToken,
                'Enable-Session': 1
            },
            body: JSON.stringify([
                {'name': 'outlook.email', 'value':this.outlookEmail.join('$;$')},
                {'name': 'outlook.prefix', 'value': this.prefix},
                {'name': 'outlook.saveMailBody', 'value': this.saveMailBody}
            ])
        };

        this.goToRout(options).then(response => {
            return response.json();
        }).then(() => {
            this.settingsLoading = false;
            this.settingsIsSaved = true;
            this.isSettingsChange = false;
            this.scope.$apply();
            this.timeout(() => {
                this.settingsIsSaved = false;
                this.scope.$apply();
            }, 2000);
        });
    }

    goToRout = (options) => {
        return fetch(this.rout, options);
    }
}

SettingsOutlookController.$inject = [
    'gettextCatalog',
    '$location',
    '$scope',
    '$timeout',
    '$cookies'
];

export {
    SettingsOutlookController
};
