import { useContext, useState, forwardRef } from "react";
import { GettextContext } from "rootReact/context";
import { useDispatch, useSelector } from "react-redux";
import { format, subDays } from "date-fns";
import FetchApi from "../../REST";
import DatePicker from "react-datepicker";
import { fetchDaemonsMessage } from "root/assets/webpack/src/js/toolkit/think";

function GeneralReport({ }) {
    const gettext = useContext(GettextContext);
    const dispatch = useDispatch();
    const dataCreateAuth = useSelector(state => state.auth.auth.createdAt)
    const [buttonLoad, setButtonLoad] = useState(false);
    const [reportDeleteAttack, setReportDeleteAttack] = useState(false);
    const [period, setPeriod] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [prevDate, setPrevDate] = useState(subDays(new Date(), 7));
    const lodaingDaemons = useSelector(state => state.daemonsMessage.loading)
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <button className="wifi-attack_date-picker" onClick={onClick} ref={ref}>
            {format(new Date(value), "yyyy-MM-dd")}
        </button>
    ));

    const startSaveFile = async () => {
        setButtonLoad(true)
        try {
            const response = await FetchApi(`/daemon/start`).post({
                body: {
                    type: 2,
                    data: {
                        reportType: "wifi",
                        dateFrom: period ? format(new Date(prevDate), "yyyy-MM-dd") : dataCreateAuth,
                        dateTo: period ? format(new Date(startDate), "yyyy-MM-dd") : format(new Date(), "yyyy-MM-dd"),
                        reportDeleteAttack,
                    },
                },
            });
            if (response && response.isError) {
                throw response;
            }
            dispatch(fetchDaemonsMessage(response));
            setButtonLoad(false)
        } catch (e) {
            console.log(e);
            setButtonLoad(false)
        }
    };

    return (
        <div className={"wifi-attack_general-report"}>
            <div className={"wifi-attack_general-report-content"}>
                <div>
                    <div className={"wifi-attack_general-report-left"}>
                        <span>{gettext.getString("Отчет по Wi-Fi атакам")}</span>
                        {period ? (
                            <>
                                <span
                                    onClick={() => setPeriod(false)}
                                    className={"wifi-attack_general-report-calendar"}
                                    data-testid="report-toggle"
                                >
                                    {gettext.getString("c")}
                                </span>
                                <div className={`wifi-attack_calendar`}>
                                    <DatePicker
                                        positionFixed="true"
                                        popperPlacement="top"
                                        selected={prevDate}
                                        onChange={(date) => setPrevDate(date)}
                                        customInput={<ExampleCustomInput />}
                                        maxDate={new Date()}
                                        minDate={subDays(new Date(), 31)}
                                        data-testid="report-prev"
                                    />
                                </div>
                                <span>{gettext.getString("по")}</span>
                                <div className={`wifi-attack_calendar`}>
                                    <DatePicker
                                        positionFixed="true"
                                        popperPlacement="top"
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        customInput={<ExampleCustomInput />}
                                        maxDate={new Date()}
                                        minDate={subDays(new Date(), 31)}
                                        data-testid="report-start"
                                    />
                                </div>
                            </>
                        ) : (
                            <span data-testid="report-toggle" onClick={() => setPeriod(true)} className={"wifi-attack_general-report-calendar"}>
                                {gettext.getString("за все время")}
                            </span>
                        )}
                    </div>
                    <div className={"wifi-attack_report-checkbox"}>
                        <input
                            checked={reportDeleteAttack}
                            onChange={() => setReportDeleteAttack((prev) => !prev)}
                            className="wifi-attack-checkbox"
                            type="checkbox"
                            data-testid="report-delete-attack"
                        />
                        <label className="wifi-attack-checkbox_label">
                            {gettext.getString("Включить в отчет удаленные атаки")}
                        </label>
                    </div>
                </div>
                <button
                    onClick={() => startSaveFile()}
                    className={"wifi-attack_general-report-btn"}
                    data-testid="report-save"
                    disabled={buttonLoad || lodaingDaemons}
                >
                    <svg className={"wifi-attack_general-report-icon"}>
                        <use xlinkHref={`/image/icoSprite.svg#report-wifi-new`}></use>
                    </svg>
                    {gettext.getString("Скачать")}
                </button>
            </div>
        </div>
    );
}

export default GeneralReport;
