import React from 'react';
import { GettextContext, ConfigContext } from 'rootReact/context';
import SelectLanguage from 'rootReact/components/SelectLanguage';

function FooterPage(props) {
    const gettext = React.useContext(GettextContext);
    const config = React.useContext(ConfigContext);
    const {
        supportEmail,
        supportEmailSubject,
        projectUrl,
        companyName,
        projectFooterName,
        languages,
        langCode,
        setLanguage,
        supportPhone,
        supportPhonePure,
    } = props
    const supportEmailAddress = `mailto:${supportEmail}?subject=${supportEmailSubject}`;

    const getSupport = () => {
        if (!config.SERVICE_URL && (supportEmail || supportPhone)) {
            return (<div className={'class'}>
                <span><span>{gettext.getString('Поддержка')}</span>:</span>
                {config.SUPPORT_PRIORITY !== 'email' && (
                    <a href={'tel:'+ +supportPhonePure}>{supportPhone}</a>
                )}
                {config.SUPPORT_PRIORITY === 'email' && (
                    <a href={supportEmailAddress}>{supportEmail}</a>
                )}
            </div>)
        }
        if (config.SERVICE_URL) {
            return (
                <div>
                    Посмотрите <a href={config.SERVICE_URL} target="_blank">ответы на&nbsp;частые вопросы </a>
                    или&nbsp;обратитесь к&nbsp;вашему сервис-менеджеру
                </div>
            )
        }
    }

    return (
        <footer className={'react-wrapper'}>
            <div className={'footer-page'}>
                <div className={'footer-page_project'}>
                    &#169; {new Date().getFullYear()}&nbsp;
                    <a href={projectUrl}
                        target="_blank">
                     {gettext.getString(projectFooterName)}
                </a>
                    { companyName && (<><span> {gettext.getString('для')} </span>
                    <span>{companyName}</span></>)}
                </div>
                <div className={'footer-page_language languages'}>
                    <SelectLanguage
                        onSelect={setLanguage}
                        viewValue={langCode}
                        listValues={languages}
                    />
                </div>
                <div className={'footer-page_support'}>{getSupport()}</div>
            </div>
        </footer>
    );
};

export default FooterPage;
