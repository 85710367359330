import ldapDepartmentLinkTemplate from './view.html';

import {LdapDepartmentLinkController} from './controller.js';

const LdapDepartmentLinkComponent = {
    template: ldapDepartmentLinkTemplate,
    controller: LdapDepartmentLinkController,
    controllerAs: '$',
    scope: true,
    bindings: {
        ldapDepartmentsCtrl: '=',
        chosenDepartmentName: '<',
    },
};

export {
    LdapDepartmentLinkComponent,
};
