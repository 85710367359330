import modalTemplate from './view.html';

import {EmployeesModalController} from './controller';

import {EmployeesImportLdapController} from './importFromLdapController';

import {EMPLOYEES_MODAL_CONTROLLER} from '../../const';

class EmployeesModal {
    constructor($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
        this.modalInstance = null;
    }

    open = (targetId, departmentId, viewMode = false) => {
        this.modalInstance = this.$uibModal.open({
            template: modalTemplate,
            bindToController: true,
            controller: EMPLOYEES_MODAL_CONTROLLER,
            controllerAs: 'employeesModalCtrl',
            size: 'lg',
            windowClass: 'center-modal',
            resolve: {
                targetId: targetId,
                departmentId: departmentId,
                viewMode: viewMode,
            },
            backdrop: 'static',
            keyboard: false,
        });

        return this.modalInstance.result;
    };
}

export {
    EmployeesImportLdapController,
    EmployeesModalController,
    EmployeesModal,
};
