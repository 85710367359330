import campaignAttachmentTemplate from './view.html';

import {CampaignAttachmentController} from './controller';

const CampaignAttachmentComponent = {
    template: campaignAttachmentTemplate,
    controller: CampaignAttachmentController,
    controllerAs: '$',
    scope: true,
    bindings: {
        attachments: '<',
        attachment: '<',
        attachmentFakeName: '=',
        attachmentActions: '<',
        setAttachmentFakeName: '=',
        setAttachment: '=',
        setAttachmentActions: '=',
        setZipAction: '=',
        sendAttachment: '<',
        state: '<',
        patchers: '<',
        activeFileLink: '<',
        zip: '<',
        toggleAction: '=',
        setSendAttachment: '<',
        attachmentNameError: '<'
    },
};

export {
    CampaignAttachmentComponent,
};
