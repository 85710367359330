import React, {useContext} from 'react'
import moment from 'moment'
import { GettextContext } from 'rootReact/context'
import {useCookies} from 'react-cookie'

function SettingsLicenseTill({validTill, supportTill, isInfinity}) {
    const gettext = useContext(GettextContext)
    const [cookies] = useCookies()

    const getValidTillFormat = () => {
        let date = moment(validTill)
        moment.locale(cookies.langCode)
        let validTillFormat = cookies.langCode !== 'ru' ? date.format('MMMM DD, YYYY') : date.format('DD MMMM YYYY')
        let beforeValidTill = isInfinity ? gettext.getString('лицензия действует до {{date}}', {date: validTillFormat})
                : gettext.getString('действует до {{date}}', {date: validTillFormat})

        return beforeValidTill
    }

    const getSupportTillFormat = () => {
        if (!isInfinity) return
        let date = moment(supportTill)
        moment.locale(cookies.langCode)
        let supportTillFormat = cookies.langCode !== 'ru' ? date.format('MMMM DD, YYYY') : date.format('DD MMMM YYYY')
        let beforeSupportTill = gettext.getString('поддержка действует до {{date}}', {date: supportTillFormat})

        return beforeSupportTill
    }

    return (
            <>
                <div className={'settings_item'}>
                    <span>{getValidTillFormat()}</span>
                </div>
                <div className={'settings_item'}>
                    <span>{getSupportTillFormat()}</span>
                </div>
            </>
    )
}

export default SettingsLicenseTill
