import React, { useContext, useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { GettextContext } from "rootReact/context";
import ReactIcon from "rootReact/components/ReactIcons";
import DropdownSelect from "rootReact/components/DropdownSelect";
import { useValidForm } from "rootReact/hook";
import AttachmentDropdown from "rootReact/components/AttachmentAddIn/AttachmentDropdown/AttachmentDropdown.jsx";

function AttachmentAddIn({
    phishingPage,
    attachments,
    attachment,
    setAttachment,
    phishingPages,
    setAttachmentFakeName,
    attachmentFakeName,
    setAttachmentActions,
    attachmentActions,
    sendAttachment,
    setSendAttachment,
    activeFileLink,
    archiveType,
    setZipAction,
    setPhishing,
    setShowRedirectPage,
    showRedirectPage,
    finalPages,
    finalPage,
    setFinalPage,
    checkLdapPassword,
    setCheckLdapPassword,
    attachmentNameError,
    setAttachmentNameError,
    campaign
}) {
    const gettext = useContext(GettextContext);
    const [attachmentSelect, setAttachmentSelect] = useState(attachment);
    const [actionActive, setActionActive] = useState(attachmentActions ? { action: attachmentActions } : "");
    const [fileType, setFileType] = useState();
    const attachmentSelected = useValidForm(
        {
            attachmentSelect: { value: attachmentSelect, setValue: setAttachmentSelect },
        },
        { attachmentSelect: { required: false } }
    );
    const finalPlaceholder = useSelector((state) => state.auth.auth.license.outInfectedUrl);
    const ldapProfileCount = useSelector((state) => state.auth.auth.targetImport.ldapProfileCount);
    const [changeFakeName, setChangeFakeName] = useState(false)

    useEffect(() => {
        setFileType(attachment?.type);
    }, []);

    useEffect(() => {
        if (sendAttachment && (fileType === 'lnk' || fileType === 'js') && !archiveType) {
            setZipAction(1);
        }
    }, [sendAttachment, attachment]);

    const handleChoose = (item) => {
        attachmentSelected.attachmentSelect.setValue(item);
        handleAction(item?.actions[0]);
        setAttachment(item);
    };

    const isAction = () => {
        return !!attachmentSelected.attachmentSelect.inputAttr?.value?.actions.length;
    };

    const handleAction = (item) => {
        if (!item) {
            setActionActive(null);
            setAttachmentActions(null);
            return;
        }
        setAttachmentActions(item.action);
        setActionActive(item);
    };

    const getActionsList = () => {
        let actions = attachmentSelected.attachmentSelect.inputAttr?.value?.actions;
        return actions.map((item, index, arr) => (
            <div
                className={`react-button_element attachment_button ${arr.length < 2 ? "" : index === 0 ? "react-button_element__left" : "react-button_element__right"
                    } ${actionActive?.action === item.action && "react-button_element__active"}`}
                key={item.id}
                data-testid={`detect-action-${item.name}`}
                onClick={() => handleAction(item)}
            >
                {item.name}
            </div>
        ));
    };

    const handleArchive = (type) => {
        let toggle = archiveType === type;
        if (sendAttachment && toggle && (fileType === "lnk" || fileType === "js")) return;
        if (toggle) {
            setZipAction(null);
            return;
        }
        setZipAction(type);
    };

    const handleFileLink = () => {
        let fileInLink = document.querySelector("#link-attachment-button");
        fileInLink?.parentNode?.parentNode?.click();
    };

    const handleFileAttachment = () => {
        setSendAttachment(!sendAttachment)
    }

    useEffect(() => {
        if (!sendAttachment && !activeFileLink) { 
            setAttachment(null)
            setAttachmentFakeName('')
            setChangeFakeName(false)
        }
    }, [sendAttachment, activeFileLink])

    return (
        <div className={`${campaign.state > 0 && 'react-final_disabled'}`}>
            {attachments.length > 0 && (
                <>
                    <div className={`modal-form`} data-testid={"attachment"}>
                        <div className={"modal-form_label"}>{gettext.getString("Приложить")}</div>
                        <div>
                            <div data-testid={"attachment-file"} className={"modal-form_checkbox-wrapper"}>
                                <div

                                    onClick={handleFileAttachment}
                                    data-testid={"send-file"}
                                    className={`react-checkbox modal-form_checkbox ${sendAttachment && "react-checkbox_active"}`}
                                ></div>
                                <div onClick={handleFileAttachment} data-testid={"send-file-text"}>
                                    {gettext.getString("Файл")}
                                </div>
                            </div>
                            <div data-testid={"attachment-file-link"} className={"modal-form_checkbox-wrapper"}>
                                <div
                                    onClick={handleFileLink}
                                    data-testid={"file-in-link"}
                                    className={`react-checkbox modal-form_checkbox ${activeFileLink && "react-checkbox_active"}`}
                                ></div>
                                <div onClick={handleFileLink} data-testid={"file-in-link-text"}>
                                    {gettext.getString("Ссылку на файл в тело письма")}
                                </div>
                            </div>
                        </div>
                    </div>
                    {(activeFileLink || sendAttachment) && (
                        <div className={"modal-form react-attachment_mb"}>
                            <div className={"modal-form_label"}></div>
                            <div>
                                <AttachmentDropdown
                                    attachment={attachment}
                                    attachments={attachments}
                                    disabled={false}
                                    setZipAction={setZipAction}
                                    sendAttachment={sendAttachment}
                                    archiveType={archiveType}
                                    setAttachment={handleChoose}
                                    setAttachmentFakeName={setAttachmentFakeName}
                                    attachmentFakeName={attachmentFakeName}
                                    fileType={fileType}
                                    setFileType={setFileType}
                                    setAttachmentNameError={setAttachmentNameError}
                                    setChangeFakeName={setChangeFakeName}
                                    changeFakeName={changeFakeName}
                                />
                            </div>
                        </div>
                    )}
                    {(activeFileLink || sendAttachment) && isAction() && (
                        <div className={"modal-form react-attachment_mb"}>
                            <div className={"modal-form_label modal-form_label__center"}>
                                {gettext.getString("Детект внутри файла")}
                            </div>
                            <div className={"react-button"} style={{ marginLeft: "-15px" }}>
                                {getActionsList()}
                            </div>
                        </div>
                    )}
                    {(activeFileLink || sendAttachment) && (
                        <div className={"modal-form react-attachment_mb"}>
                            <div className={"modal-form_label modal-form_label__center"}>
                                {gettext.getString("Архивация")}
                            </div>
                            <div className={"react-button"} style={{ marginLeft: "-15px" }}>
                                <div
                                    className={`${archiveType === 1 && "react-button_element__active"
                                        } attachment_button react-button_element react-button_element__left`}
                                    onClick={() => handleArchive(1)}
                                    data-testid={"zip"}
                                >
                                    Zip
                                </div>
                                <div
                                    className={`${archiveType === 2 && "react-button_element__active"
                                        } attachment_button react-button_element react-button_element__center`}
                                    onClick={() => handleArchive(2)}
                                    data-testid={"zip-password"}
                                >
                                    {gettext.getString("Zip с паролем")}
                                </div>
                                <div
                                    className={`${archiveType === 3 && "react-button_element__active"
                                        } attachment_button react-button_element react-button_element__right`}
                                    onClick={() => handleArchive(3)}
                                    data-testid={"iso"}
                                >
                                    ISO
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
            {(phishingPages.length !== 0 && !((activeFileLink || sendAttachment) && fileType === "lnk")) && (
                <div data-testid={"phishing-page"} className={"modal-form react-attachment_mb align-center"}>
                    <div className={"modal-form_checkbox-wrapper"}>
                        <div
                            className={"modal-form_label"}
                            data-testid={"redirect-page"}
                            style={{ display: "flex" }}
                        >
                            <div
                                onClick={() => setShowRedirectPage()}
                                data-testid={"phishing-page-checkbox"}
                                className={`react-checkbox modal-form_checkbox ${!!showRedirectPage && "react-checkbox_active"}`}
                            ></div>
                            <div onClick={() => setShowRedirectPage()} data-testid={"redirect-page-text"}>
                                {gettext.getString("Фишинговая страница")}
                            </div>
                        </div>
                    </div>
                    {!!showRedirectPage && (
                        <>
                            <div style={{ alignSelf: 'flex-start' }}>
                                <DropdownSelect
                                    idClass={"attachment_chose"}
                                    inputAttr={{ value: phishingPage?.title }}
                                    Style={["react-input_field"]}
                                    optionsName={"title"}
                                    testId={"phishing-page-item"}
                                    testIdList={"phishing-pages-list"}
                                    testIdSearch={"phishing-page-search-field"}
                                    handleClick={(item) => {
                                        setPhishing(item);
                                    }}
                                    placeholder={gettext.getString("Страница")}
                                    list={phishingPages}
                                />
                            </div>

                            <div style={{paddingBottom: '12px'}}>
                                {phishingPage?.id && (
                                    <a
                                        href={`/page/${phishingPage.id}`}
                                        data-testid={"phishing-page-show"}
                                        target="_blank"
                                    >
                                        <ReactIcon icoClass={"world"} />
                                    </a>
                                )}
                            </div>
                            <div></div>
                            <div style={{ display: "flex" }}>
                                {!!ldapProfileCount && phishingPage?.id && (
                                    <>
                                        <div
                                            onClick={() => setCheckLdapPassword()}
                                            data-testid={"check-ldap"}
                                            className={`react-checkbox modal-form_checkbox ${!!checkLdapPassword && "react-checkbox_active"}`}
                                        ></div>
                                        <span
                                            data-testid={"check-ldap-text"}
                                            onClick={() => setCheckLdapPassword()}
                                        >
                                            {gettext.getString("Проверять в LDAP")}
                                        </span>
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </div>
            )}
            <div className={"modal-form react-attachment_mb align-center"} data-testid="final-page">
                <div className={"modal-form_label"}>{gettext.getString("Финальная переадресация")}</div>
                <DropdownSelect
                    idClass={"attachment_chose"}
                    inputAttr={{ value: finalPage?.title }}
                    testId={"final-page-item"}
                    testIdList={"final-pages-list"}
                    testIdSearch={"final-page-search-field"}
                    Style={["react-input_field"]}
                    optionsName={"title"}
                    handleClick={(item) => {
                        setFinalPage(item);
                    }}
                    placeholder={finalPlaceholder}
                    list={finalPages}
                />
                <div>
                    <a
                        href={finalPage?.id && finalPage?.id !== -1 ? `/final/${finalPage?.id}` : finalPlaceholder}
                        data-testid={"final-page-show"}
                        target="_blank"
                    >
                        <ReactIcon icoClass={"world"} />
                    </a>
                </div>
            </div>
        </div>
    );
}

export default AttachmentAddIn;
