import { useContext, useEffect, useState } from "react";
import { GettextContext, InjectorContext } from "rootReact/context";
import WifiAttackModal from "../WifiAttackModal";
import { ReactModal } from "root/assets/webpack/src/js/components/ReactModal";

function WifiPopup({ errorAttacks, setErrorAttacks, setPopupError }) {
    const gettext = useContext(GettextContext);
    const injector = useContext(InjectorContext);

    useEffect(() => {
        if (errorAttacks.length === 0) {
            setPopupError(false)
        }
    }, [errorAttacks])

    const handleEdit = async (id) => {
        const modal = injector.instantiate(ReactModal);
        let wifiModal = await modal.open({
            component: WifiAttackModal,
            props: {
                id,
            },
        });
        setErrorAttacks([...errorAttacks].filter(item => item.id !== id))
    }

    const onClosePopup = () => {
        setPopupError(false)
    };

    return (

        <div className={"wifi-popup"}>
            {
                errorAttacks.length === 1
                    ?
                    <div className="wifi-popup_single">
                        <svg className="wifi-popup_img"> <use xlinkHref={`/image/icoSprite.svg#wifi-start-error`}></use> </svg>
                        <div className="wifi-popup_single-content">
                            {
                                errorAttacks?.map((item, i) => (
                                    <>
                                        <div className="wifi-popup_single-top">
                                            <strong className="wifi-popup_text">{gettext.getString(`Не можем запустить атаку`)}{item.name}</strong>
                                            <span className="wifi-popup_text">{gettext.getString(`${item.error}`)}</span>
                                        </div>
                                        <button onClick={() => handleEdit(item.id)} className="wifi-popup_btn">{gettext.getString(`Заменить`)}</button>
                                    </>
                                ))
                            }
                        </div>
                        <div data-testid="close" onClick={() => onClosePopup()} className="wifi-popup_btn-close">
                            <svg className="wifi-popup_close"> <use xlinkHref={`/image/icoSprite.svg#wifi-close-error`}></use> </svg>
                        </div>
                    </div>
                    :
                    <div className="wifi-popup_wpapper">
                        <div className="wifi-popup_header">
                            <svg className="wifi-popup_img"> <use xlinkHref={`/image/icoSprite.svg#wifi-start-error`}></use> </svg>
                            <strong className="wifi-popup_title">{gettext.getString(`Не можем запустить атаки`)}</strong>
                            <div data-testid="close" onClick={() => onClosePopup()} className="wifi-popup_btn-close">
                                <svg className="wifi-popup_close"> <use xlinkHref={`/image/icoSprite.svg#wifi-close-error`}></use> </svg>
                            </div>
                        </div>
                        <ul className="wifi-popup_list">
                            {
                                errorAttacks?.map((item, i) => (
                                    <li className="wifi-popup_item" key={i}>
                                        <div className="wifi-popup_block" >
                                            <strong className="wifi-popup_text">{gettext.getString(`${item.name}`)}</strong>
                                            <span className="wifi-popup_text">{gettext.getString(`${item.error}`)}</span>
                                        </div>
                                        <button onClick={() => handleEdit(item.id)} className="wifi-popup_btn">{gettext.getString(`Заменить`)}</button>
                                    </li>
                                ))
                            }
                        </ul>

                    </div>
            }

        </div>
    );
}

export default WifiPopup;
