import sendToTrainingTemplate from './view.html';

import {SendToTrainingController} from './controller';

const SendToTrainingComponent = {
    template: sendToTrainingTemplate,
    controller: SendToTrainingController,
    controllerAs: '$',
    scope: true,
    bindings: {
        courseList: '<',
        trainingIsOpen: '=',
        trainingSuccsess: '=',
    },
};

export {
    SendToTrainingComponent,
};
