import ldapProfileModalTemplate from './view.html';

import {LdapProfileModalController} from './controller';

import {LDAP_PROFILE_MODAL_CONTROLLER} from '../../const';

class LdapProfileModal {
    constructor($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
        this.modalInstance = null;
    }

    open = (profileId, moodleIsVisible) => {
        this.modalInstance = this.$uibModal.open({
            template: ldapProfileModalTemplate,
            bindToController: true,
            controller: LDAP_PROFILE_MODAL_CONTROLLER,
            controllerAs: '$',
            size: 'md',
            resolve: {
                profileId: profileId,
                moodleIsVisible: moodleIsVisible,
            },
            backdrop: 'static',
            keyboard: false,
        });

        return this.modalInstance.result;
    };
}

export {
    LdapProfileModal,
    LdapProfileModalController,
};
