import style from './style.modules.css';
import getNextPage from '../../helpers/getNextPage';
import { hasAccess } from '../../reselect';
import {setBreadcrumbLink, setSearchData} from '../../toolkit/actions';

class CourseFunctionController {
    constructor ($localStorage, $scope, gettextCatalog, $sessionStorage, $ngRedux, $filter) {
        this.style = style;
        this.$localStorage = $localStorage;
        this.gettextCatalog = gettextCatalog;
        this.$sessionStorage = $sessionStorage;
        this.filter = $filter;
        this.setCoursesLoading(false);
        this.scope = $scope;
        this.sortReverse = false;
        this.nextPage = 0;
        this.sortBy = '';
        this.courses = [];
        this.courseCount = 0;
        this.departmentChoosed = false;
        this.sortableFields = [
            'name'
        ];
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, {setBreadcrumbLink, setSearchData})(this);
    }

    mapStateToThis (state) {
        return {
            search: state.selectedData.selectedData.search,
            linkNames: state.breadcrumbLinks.link,
            accessCoursesDepartments: hasAccess(state, { sectionId: 1, rightName: 'view' })
        };
    }

    $onInit () {
        this.setBreadcrumbLink([]);
        if (!this.accessCoursesDepartments) {
            return;
        }
        this.loadCourses(0, 1);
        this.scope.$watch(scope => {
            return scope.$.linkNames;
        }, (links, oldLinks) => {
            if (links.length === oldLinks.length || !oldLinks.length) {
                return;
            }
            if (links.length === 1 && links[0] && {}.hasOwnProperty.call(links[0], 'isTitle')) {
                return;
            }
            if (!links.length) {
                this.courses = [];
                this.loadCourses(false, 1);
            }
        }, true);
    }

    loadCourses = (fullList, page) => {
        this.setCoursesLoading(true);
        this.departmentChoosed = false;
        fetch(this.getAdress(fullList, page), {
            headers: {
                'X-Csrf-Token': window.csrfToken,
                'Enable-Session': 1
            }
        }).then(response => {
            if (response.ok) {
                // eslint-disable-next-line
                let nextLink = response.headers.get('link').split(/\,/g);
                this.courseCount = response.headers.get('X-Pagination-Total-Count');
                this.nextPage = getNextPage(nextLink);
            }
            return response.json();
        }).then(json => {
            if (json.message) {
                this.setCoursesLoading(false);
                this.error = json.message;
                this.scope.$apply();
                return;
            }
            if (json && json.length > 0) {
                if (fullList || page == 1) {
                    this.courses = json;
                } else {
                    this.courses = [...this.courses, ...json];
                }
            }
            this.sortCourses();
            this.setCoursesLoading(false);
            this.scope.$apply();
        }).catch(() => {
            this.error = true;
            this.setCoursesLoading(false);
            this.scope.$apply();
        });
    };

    loadDepartments = (id, search) => {
        if (search) {
            this.setSearch(search);
        }
        this.setBreadcrumbLink([
            ...this.linkNames, ...this.courses.filter(item => {
                if (item.id === id) {
                    item.isTitle = true;
                    return true;
                } else {
                    return false;
                }
            })]);
        this.departmentChoosed = id;
        this.$localStorage.openedCourse = id;
    };

    setCoursesLoading = (loading) => {
        this.loading = loading;
        if (typeof (this.setLoading) == 'function') {
            this.setLoading('coursesLoading', loading);
        }
    };

    setSortBy = (str) => {
        if (this.sortBy === str) {
            this.sortReverse = !this.sortReverse;
        } else if (this.sortBy != str) {
            this.sortReverse = false;
        }
        this.sortBy = str;
        if (this.sortableFields.indexOf(this.sortBy) > -1) {
            this.loadCourses(0, this.nextPage > 1 ? this.nextPage - 1 : 1);
        } else {
            this.sortCourses();
        }
    };

    sortCourses = () => {
        let str = this.sortBy;
        if (str && this.sortableFields.indexOf(str) === -1) {
            let sorting = !this.loading;
            if (sorting) {
                this.setCoursesLoading(true);
            }
            this.courses = this.filter('orderBy')(this.courses, str);
            if (this.sortReverse) {
                this.courses.reverse();
            }
            if (sorting) {
                this.setCoursesLoading(false);
            }
        }
    };

    getAdress = (fullList, page) => {
        return `${window.config.SYSTEM_URL}${window.config.API_URL}/courses?layout=targets&fullList=${+fullList}&page=${page}&per-page=10&sortBy=${this.sortBy}&sortReverse=${this.sortReverse}`;
    };

    setSearch = (str) => {
        let search = this.search.filter(() => true);
        if (!(~search.indexOf(str))) {
            search[search.length - 1] = str;
        }
        this.setSearchData(search);
    };

    $onDestroy () {
        this.setBreadcrumbLink([]);
    }
}

CourseFunctionController.$inject = [
    '$localStorage',
    '$scope',
    'gettextCatalog',
    '$sessionStorage',
    '$ngRedux',
    '$filter'
];

export {
    CourseFunctionController
};
