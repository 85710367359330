import modalTemplate from './view.html';

import {UserModalController} from './controller';

import {USER_MODAL_CONTROLLER} from '../../const';

class UserModal {
    constructor($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
        this.modalInstance = null;
    }

    open = (userId, userInfo) => {
        this.modalInstance = this.$uibModal.open({
            template: modalTemplate,
            bindToController: true,
            controller: USER_MODAL_CONTROLLER,
            controllerAs: '$',
            size: 'lg',
            resolve: {
                userId: userId,
                userInfo: userInfo,
            },
            backdrop: 'static',
            keyboard: false,
        });

        return this.modalInstance.result;
    };
}

export {
    UserModalController,
    UserModal,
};
