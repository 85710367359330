const fileToBlob = async (file) => {
    let arrayBuffer = await file.arrayBuffer();
    let blob = new Blob([new Uint8Array(arrayBuffer)], {type: file.type });
    return blob;
};

export const setLocales = async (params, languages, scheme) => {
    let formData = new FormData();
    for (let i = 0; i < params.length; i++) {
        for (let key in scheme.files) {
            if (params[i][key]) {
                let file = await fileToBlob(params[i][key]);
                formData.append(`locales[${i}][${scheme.files[key]}]`, file, params[i][key].name);
            }
        }
        scheme.data.forEach(item => {
            if (params[i][item] || item === 'isDefault') {
                formData.append(`locales[${i}][${item}]`, params[i][item]);
            }
        });
    }
    return formData;
};

export const getLocales = (params, schema, languages) => {
    let locales = [];
    languages.forEach((item, index) => {
        locales[index] = {
            languageId: item.id
        };
    });
    locales = locales.map(item => {
        params.forEach(paramsItem => {
            if (item.languageId === paramsItem.languageId) {
                schema.data.forEach((schemaItem) => {
                    item[schemaItem] = paramsItem[schemaItem];
                });
            }
        });
        return item;
    });
    return locales;
};

export const checkDefaultLanguage = (params, languages) => {
    let isDefault = params.filter(item => item.isDefault)[0];
    if (!isDefault) {
        let cookiesToObj = {};
        document.cookie.split('; ').forEach(item => {
            cookiesToObj[item.split('=')[0]] = item.split('=')[1];
        });
        let defaultLanguage = cookiesToObj.langCode;
        return languages.filter(item => item.isoCode.indexOf(defaultLanguage) !== -1)[0];
    }
    return languages.filter(item => item.id === isDefault.languageId)[0];
};

export const setDefaultLanguage = (defaultLanguage, locales) => {
    locales = locales.map(item => {
        if (item.languageId === defaultLanguage.id) {
            item.isDefault = 1;
        } else {
            item.isDefault = 0;
        }
        return item;
    });
    return locales;
};
