import {AppController} from './app';
import {CampaignsController} from './campaigns';
import {DepartmentsController} from './departments';
import {InnerLayoutController} from './inner';
import {LayoutController} from './layout';
import {SettingsController} from './settings';
import {ReportsController} from './reports';
import {RisksController} from './risks';
import {LoginController} from './login';
import {ErrorController} from './error';
import {PracticePlan} from './practicePlan';
import {DashboardGraph} from './DashboardGraph';

export {
    AppController,
    DepartmentsController,
    CampaignsController,
    InnerLayoutController,
    LayoutController,
    SettingsController,
    ReportsController,
    RisksController,
    LoginController,
    ErrorController,
    PracticePlan,
    DashboardGraph
};
