import {STATUS_SERVICE} from '../../const';

class IcoRoundController {
    constructor(StatusService) {
        this.StatusService = StatusService;
    }

    $onInit() {}

    getStyle = () => {
        let style = '';
        switch (this.targetStatus) {
            case 0:
            case 12:
            case -16:
                style = 'new-target-ico';
                break;
            case 1:
                style = 'succsess-ico';
                break;
            case 2:
                style = 'warning-ico';
                break;
            case 4:
                style = 'learning-ico learning-proccess';
                break;
            case 5:
                style = 'learning-ico complete-learning';
                break;
            case 7:
                style = 'learning-ico miss-learning';
                break;
            case -4:
                style = 'learning-ico no-learning';
                break;
            case 15:
            case 9:
                style += ' open-file';
                break;
        }
        if (this.reportMail) {
            style += ' report-mail-ico';
        }
        if (this.hasVuln) {
            style += ' vuln-ico';
        }
        if (this.targetStatus === 3 || this.targetStatus === 10 || this.targetStatus === 17) {
            style += ' danger-ico';
        }
        if (this.targetStatus === 8 || this.targetStatus === 11) {
            style += ' time-out-ico';
        }
        return style;
    };

    $onDestroy() {}
}

IcoRoundController.$inject = [
    STATUS_SERVICE,
];

export {
    IcoRoundController,
};
