import style from './style.modules.css';
import {
    EMPTY_LDAP_SELECTED_DATA,
    GROUPS_SERVICE,
    LDAP_IMPORTS_SERVICE,
    DEFAULT_PER_PAGE_SIZE,
    TARGETS_SERVICE,
    NODE,
    LIST
} from '../../const';
import angular from 'angular';
import { EmployeesDeleteModal } from '../employeesDeleteModal';
import { Tree } from '../../helpers/selectedLdap';
import {fetchLdapDepartments} from '../../toolkit/think';
import {setDefaultLdap, setChoosenLdapDepartment} from '../../toolkit/actions';

class LdapDepartmentsController {
    get SUCCESS () {return 'success';}
    get LOADING () {return 'loading';}
    get FAILED () {return 'failed';}
    get VM_DEPARTMENTS () {return 'departments';}
    get VM_TARGETS () {return 'targets';}
    constructor ($scope, $timeout, TargetsService, GroupsService, gettextCatalog, $injector, $interval, LdapImportsService, $ngRedux) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$injector = $injector;
        this.$interval = $interval;
        this.TargetsService = TargetsService;
        this.GroupsService = GroupsService;
        this.LdapImportsService = LdapImportsService;
        this.style = style;
        this.chosenDepartmentName = '';
        this.chosenTargets = null;
        this.viewMode = this.VM_DEPARTMENTS;
        this.importResult = null;
        this.errorDepartments = null;
        this.departmentReverse = true;
        this.departmentSort = 'count';
        this.departments = [];
        this.language = null;
        this.DepartmentsService = TargetsService.ldapDepartments;
        this.departmentsCache = { '_': [] };
        this.allTargetsCounts = { '_': 0 };
        this.targets = {};
        this.gettextCatalog = gettextCatalog;
        this.groups = [];
        this.modal = {
            ldap: null,
            delete: null
        };
        this.createdImportText = [
            this.gettextCatalog.getString('{{count}} новый сотрудник добавлен'),
            this.gettextCatalog.getString('{{count}} новых сотрудника добавлены'),
            this.gettextCatalog.getString('{{count}} новых сотрудников добавлены')
        ];
        this.updatedImportText = [
            this.gettextCatalog.getString('{{count}} сотрудник обновлен'),
            this.gettextCatalog.getString('{{count}} сотрудника обновлены'),
            this.gettextCatalog.getString('{{count}} сотрудников обновлены')
        ];
        this.notUpdatedImportText = [
            this.gettextCatalog.getString('{{count}} сотрудник оставлен без изменений'),
            this.gettextCatalog.getString('{{count}} сотрудника оставлены без изменений'),
            this.gettextCatalog.getString('{{count}} сотрудников оставлены без изменений')
        ];
        this.notValidLinkText = [
            this.gettextCatalog.getString('{{count}} сотрудник'),
            this.gettextCatalog.getString('{{count}} сотрудника'),
            this.gettextCatalog.getString('{{count}} сотрудников')
        ];
        this.unknownDomainsLinkText = [
            this.gettextCatalog.getString('{{count}} домен'),
            this.gettextCatalog.getString('{{count}} домена'),
            this.gettextCatalog.getString('{{count}} доменов')
        ];
        this.notValidImportText = [
            this.gettextCatalog.getString('{{employee}} не может быть импортирован в {{profile}}'),
            this.gettextCatalog.getString('{{employees}} не могут быть импортированы в {{profile}}'),
            this.gettextCatalog.getString('{{employees}} не могут быть импортированы в {{profile}}')
        ];
        this.unknownDomainsImportText = [
            this.gettextCatalog.getString('{{domain}} не разрешен в лицензии'),
            this.gettextCatalog.getString('{{domains}} не разрешены в лицензии'),
            this.gettextCatalog.getString('{{domains}} не разрешены в лицензии')
        ];
        this.deleteEmployeesText = {
            preTextCallback: this.gettextCatalog.getString('Если вы загрузили полный список сотрудников, вы можете {{deleteLink}}'),
            textCallback: this.gettextCatalog.getString('удалить сотрудников'),
            postTextCallback: this.gettextCatalog.getString('{{deleteEmployees}} из системы')
        };
        this.unknownImportText = [
            this.gettextCatalog.getString('{{employee}} не выбран при импорте'),
            this.gettextCatalog.getString('{{employees}} не выбраны при импорте'),
            this.gettextCatalog.getString('{{employees}} не выбраны при импорте')
        ];
        this.unknownLinkText = [
            this.gettextCatalog.getString('{{count}} сотрудник из {{profile}}'),
            this.gettextCatalog.getString('{{count}} сотрудника из {{profile}}'),
            this.gettextCatalog.getString('{{count}} сотрудников из {{profile}}')
        ];
        this.defaultText = this.gettextCatalog.getString('Импорт прерван по причине: {error}');
        this.alertMessages = [];
        this.firstLoading = true;
        this.loadDepartmentsTry = 0;
        this.targets = [];
        this.departmentTargets = [];
        this.loadingCount = false;
        this.isAnyChecked = false;
        this.departments = {};
        this.updateImport = null;
        this.languageId = null;
        this.showDepartments = DEFAULT_PER_PAGE_SIZE;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, {fetchLdapDepartments, setDefaultLdap, setChoosenLdapDepartment})(this);
    }

    mapStateToThis = (state) => {
        return {
            languages: state.languages.languages,
            departmentsFields: state.ldapDepartments.departments,
            externalIdEnabled: state.auth.auth.targetImport.ldapExternalIdEnabled,
            status: state.ldapDepartments.status,
            errorText: state.ldapDepartments.errorText,
            selectedData: state.ldapDepartments.selectedData,
            chosenDepartmentName: state.ldapDepartments.selectedData.chosenDepartmentName,
        };
    };

    $onInit () {
        if (this.employeesModalCtrl && this.importFromLdapCtrl) {
            this.$scope.$watch(() => {
                return this.selectedData.search;
            }, (current, old) => {
                if (current !== old && !this.loadingImport) {
                    this.search();
                }
            });
            this.load();
        }
        this.setDepartmentsLoading(true);
        this.$scope.$watch(scope => {
            return scope.$.status;
        }, (value, oldValue) => {
            if (value === oldValue) return;
            if (value === this.FAILED) {
                this.setDepartmentsLoading(false);
                this.departments = {};
                this.errorDepartments = this.errorText ? this.errorText : this.gettextCatalog.getString('Что-то пошло не так');
            }
            if (value === this.SUCCESS) {
                this.selectedLdap = new Tree(this.departmentsFields, {
                    parent: 'fullName',
                    children: [{name: 'departments', type: NODE}, {name: 'users', type: LIST}],
                    nodeCurrentParams: ['name', 'fullName', 'count', 'ownCount'],
                    listCurrentParams: ['id', 'email', 'externalId', 'firstName', 'position', 'secondName', 'thirdName', 'riskGroup']
                }, this.selectedData);
                this.departments = this.selectedLdap.root.children;
                const selectedData = this.setChoosenData();
                this.targetsChoosenCount = selectedData.targetsCount;
                this.isAnyChecked = this.targetsChoosenCount > 0;
                this.setLanguage(this.importFromLdapCtrl.languageId);
                this.getSelectedData(selectedData);
                this.setDepartmentsLoading(false);
            }
        });
    }

    setLanguage = (id) => {
        this.language = this.languages.filter(item => item.id === id)[0];
    }

    onDepartmentClick =  (department, status = null) => {
        let parentsId = null;
        this.selectedLdap.traverseDF((data) => {
            if (data.parent && data.parent.indexOf(department.id) !== -1) {
                data.checked = status ? status === 'checked' : !data.checked;
                data.partial = false;
            }
            if (data.id === department.id) {
                data.checked = status ? status === 'checked' : !data.checked;
                data.partial = false;
                parentsId = department.parent;
            }
        });
        this.checkParent(parentsId);
    }

    checkParent = (parents) => {
        if (parents && parents.length) {
            for (let i = parents.length - 1 ; i !== 0; i--) {
                this.selectedLdap.traverseDF(data => {
                    if (data.id === parents[i]) {
                        let checked = new Set([]);
                        let partial = new Set([]);
                        data.children.forEach(item => {
                            checked.add(item.checked);
                            partial.add(item.partial);
                        });
                        if (partial.size > 1 || partial.has(true) || checked.size > 1) {
                            data.partial = true;
                            data.checked = false;
                        } else {
                            data.checked = checked.has(true);
                            data.partial = false;
                        }
                    }
                });
            }
        }
        const selectedData = this.setChoosenData();
        this.targetsChoosenCount = selectedData.targetsCount;
        this.isAnyChecked = this.targetsChoosenCount > 0;
        this.getSelectedData(selectedData);
    }

    setChoosenData = () => {
        let parentsId = [];
        let departments = [];
        let departmentsUsed = [];
        let targets = [];
        let targetsCount = 0;
        this.selectedLdap.traverseBF((data) => {
            if (data.checked && data.type === NODE) {
                departments.push(data.id);
                parentsId.push(data.id);
            }
            if (data.checked && data.type === LIST) {
                targetsCount += 1;
            }
            if (data.checked && data.type === LIST && !this.isParentChoosen(parentsId, data.parent)) {
                targets.push(data.id);
                parentsId.push(data.id);
            }
            if (data.partial) {
                departmentsUsed.push(data.id);
            }
        });
        return {departments, departmentsUsed, targets, targetsCount};
    }

    isParentChoosen = (choosenList, parentsId) => {
        let choosen = false;
        choosenList.forEach(item => {
            if (parentsId.indexOf(item) !== -1) {
                choosen = true;
            }
        });
        return choosen;
    }

    loadGroups = () => {
        this.GroupsService.simple.query({ layout: 'select' }).$promise.then((groups) => {
            this.groups = groups;
        }, () => {
        });
    };

    sortDepartment = (field) => {
        if (this.departmentSort) {
            this.departmentReverse = !this.departmentReverse;
        }
        this.departmentSort = field;
        this.departments.sort((a,b) => {
            if (a[field] > b[field]) return this.departmentReverse ? -1 : 1;
            if (a[field] < b[field]) return this.departmentReverse ? 1 : -1;
        });
    }

    load = () => {
        this.loadGroups();
        this.alertMessages = [];
        this.importResult = null;
        this.loadDepartments();
    };

    getFilterOptions = () => {
        let filterOptions = {
            'filter-search-query': this.selectedData.search,
            profile: this.importFromLdapCtrl.ldapProfile.id,
            filter: (this.importFromLdapCtrl.groupFilter ? this.importFromLdapCtrl.groupFilter.map(function (item) {
                item = item.text ? item.text : item;
                return item.replace('#', '[hashtag]');
            }).join('|') : null),
            emailField: this.importFromLdapCtrl.emailField,
            departmentField: this.importFromLdapCtrl.departmentField,
            riskGroupField: this.importFromLdapCtrl.riskGroupField,
            positionField: this.importFromLdapCtrl.positionField,
            managerField: this.importFromLdapCtrl.managerField,
            fullNameMode: this.importFromLdapCtrl.fullNameMode,
            firstNameField: this.importFromLdapCtrl.firstNameField,
            secondNameField: this.importFromLdapCtrl.secondNameField,
            // languageId: this.language.id,
            languageId: this.importFromLdapCtrl.languageId || 0,
            thirdNameField: this.importFromLdapCtrl.thirdNameField,
            fullNameField: this.importFromLdapCtrl.fullNameField,
            ldapDepartmentName: this.chosenDepartmentName,
            marks: this.importFromLdapCtrl.marks || [],
            groupRisk: this.importFromLdapCtrl.groupRisk
        };
        if (this.importFromLdapCtrl.bossKadrovikProfile && this.importFromLdapCtrl.bossKadrovikProfile.id) {
            filterOptions.bossKadrovik = this.importFromLdapCtrl.bossKadrovikProfile.id;
        }
        if (this.importFromLdapCtrl.externalIDEnabled) {
            filterOptions.externalID = this.importFromLdapCtrl.externalField;
        }
        return filterOptions;
    };

    onSelect = (id) => {
        this.setLanguageId(id);
    }

    loadDepartments = () => {
        this.alertMessages = [];
        this.importResult = null;
        if (!this.loadingDepartments) {
            this.setParentLoading();
            this.loadDepartmentsTry++;
            this.fetchLdapDepartments({
                groups: this.importFromLdapCtrl.groupFilter || [],
                profileId: this.importFromLdapCtrl.ldapProfile.id,
                filterOptions: this.getFilterOptions()
            });
        }
    };

    search = (searchString) => {
        this.searchString = searchString;
        this.selectedLdap.filterNode(searchString);
        this.getChosen(this.department && this.department.name, this.department && this.department.fullName);
    }

    setViewMode = (viewMode) => {
        this.viewMode = viewMode;
        this.setTargets();
    };

    setTargets = () => {
        this.targets = this.selectedLdap.getNodeByType(LIST, this.department);
    }

    setParentLoading = () => {
        let loading = this.loadingImport || this.loadingTargets || this.loadingDepartments;
        this.employeesModalCtrl.setInProcess(loading);
    };

    toggleAll = (status) => {
        this.departments.forEach(item => {
            this.onDepartmentClick(item,status ? 'checked' : 'unchecked');
        });
    };

    showMore = (count = 0) => {
        if (count) {
            this.showDepartments = count;
        } else {
            this.showDepartments += 10;
        }
    }

    toggleTargetsLdap = (target) => {
        this.selectedLdap.traverseDF((data) => {
            if (data.id === target.id) {
                data.checked = !data.checked;
                this.checkParent(data.parent);
            }
        });
    }

    onDepartmentNameClick = (event, department) => {
        event.stopPropagation();
        if (!department) return;
        if (department.count > department.ownCount) {
            this.getChosen(department.fullName, department);
            this.selectedLdap.traverseDF((data) => {
                if (data.id === department.id) {
                    this.departments = data.children.filter(item => item.show);
                }
            });
            this.showDepartments = DEFAULT_PER_PAGE_SIZE;
        } else {
            department.showTargets = !department.showTargets;
        }
    };

    getChosen = (departmentName, department) => {
        this.setChoosenLdapDepartment({chosenDepartmentName: departmentName});
        if (!this.chosenDepartmentName) {
            this.department = null;
            this.departments = this.selectedLdap.root.children.filter(item => item.show);
        } else {
            this.selectedLdap.traverseDF((data) => {
                if (data.id === (department.id || department)) {
                    this.departments = data.children.filter(item => item.show);
                    this.department = data;
                    this.departmentTargets = data.children.filter(item => item.type === LIST && item.show);
                }
            });
        }
        this.setTargets();
        this.showDepartments = DEFAULT_PER_PAGE_SIZE;
    };

    chekDepartmentLevel = (fullName) => {
        return fullName.split('/').length;
    }

    getDepartmentName = () => {
        return this.chosenDepartmentName;
    };

    selectedCount = () => {
        this.selectedData.selectedCount.targets = this.selectedData.targets.length;
        return this.selectedData.selectedCount;
    };

    checkImport = () => {
        this.$interval.cancel(this.updateImport);
        fetch(`${window.config.SYSTEM_URL}${window.config.API_URL}/ldap-imports/${this.importResult.id}`, {
            method: 'GET',
            headers: {
                'X-Csrf-Token': window.csrfToken,
                'Enable-Session': 1
            }
        }).then(response => {
            return response.json();
        }).then(response => {
            this.importResult = response;
            if (this.importResult.completed < 1) {
                this.updateImport = this.$interval(this.checkImport, 5000);
            } else {
                this.setImportLoading(false);
                this.setParentLoading();
                this.$scope.$apply();
                this.loadDashboard();
                this.LdapImportsService.action(this.importResult.id, 'decline', this, () => {});
            }
        }).catch(() => {
            if (this.importResult && this.importResult.completed < 1) {
                this.updateImport = this.$interval(this.checkImport, 5000);
            } else {
                this.setImportLoading(false);
                this.setParentLoading();
                this.$scope.$apply();
            }
        });
    };

    importSelectedTargets = () => {
        this.setImportLoading(true);
        this.alertMessages = [];
        this.importResult = null;
        this.errorsImportText = null;
        this.setImportError(false);
        this.setParentLoading();
        let selectedData = this.setChoosenData();
        let data = angular.extend({
            selectedData: angular.extend({}, angular.copy(EMPTY_LDAP_SELECTED_DATA), this.selectedData, selectedData)
        }, this.getFilterOptions());
        data.marks = [];
        if (typeof (this.importFromLdapCtrl.marks) != 'undefined' && this.importFromLdapCtrl.marks && this.importFromLdapCtrl.marks.length > 0) {
            this.importFromLdapCtrl.marks.forEach((mark) => {
                data.marks.push(mark.text ? mark.text : mark);
            });
        }
        data.groupRisk = '';
        if (this.importFromLdapCtrl.groupRisk) {
            if (this.importFromLdapCtrl.groupRisk.fullName) {
                data.groupRisk = this.importFromLdapCtrl.groupRisk.fullName;
            } else {
                data.groupRisk = this.importFromLdapCtrl.groupRisk;
            }
        }
        this.TargetsService.ldapImport(data, this, (ctrl, response) => {
            this.importResult = response;
            if (response && response.id) {
                this.checkImport();
            }
        }, (ctrl, response) => {
            ctrl.setImportLoading(false);
            ctrl.setImportError(true);
            if (response.data) {
                if (response.data.error) {
                    ctrl.errorsImportText = response.data.error;
                }
                if (response.data.message) {
                    ctrl.errorsImportText = response.data.message;
                }
                if (response.status && response.status == 504) {
                    ctrl.errorsImportText = this.gettextCatalog.getString('Превышен лимит ожидания импорта сотрудников');
                }
            }
            ctrl.setParentLoading();
        });
    };

    deleteTargets = () => {
        this.modal.delete = this.$injector.instantiate(EmployeesDeleteModal);
        let selectedData = angular.extend({}, EMPTY_LDAP_SELECTED_DATA);
        selectedData.selectedCount.targets = this.importResult.userNotFound;
        selectedData.importId = this.importResult.id;
        this.modal.delete.open(selectedData).then((selectedData) => {
            if (selectedData) {
                if (selectedData) {
                    this.importResult.userDelete = this.importResult.userNotFound;
                }
            }
        }, () => {
        });
    };

    makeAlert = (ctrl, response) => {
        if (ctrl.gettextCatalog) {
            for (let key in response) {
                if ((key === 'created' || key === 'updated' || key === 'notUpdated') && response[key] > 0 && ctrl[key + 'ImportText']) {
                    ctrl.alertMessages.push({
                        type: key,
                        item: response[key],
                        text: ctrl[key + 'ImportText'].map(item => {
                            return ctrl.gettextCatalog.getString(`${item}`, { count: response[key] });
                        }),
                        class: 'success'
                    });
                }
                if ((key === 'notValid' || key === 'unknownDomains') && response[key] > 0 && ctrl[key + 'LinkText'] && ctrl[key + 'ImportText']) {
                    response[key] = response[key] + '';
                    ctrl.alertMessages.push({
                        type: key,
                        item: response[key],
                        textLink: ctrl[key + 'LinkText'].map(item => {
                            return ctrl.gettextCatalog.getString(`${item}`, { count: response[key] });
                        }),
                        isShowLogs: false,
                        text: ctrl[key + 'ImportText'].map(item => {
                            return ctrl.gettextCatalog.getString(`${item}`, {
                                employee: '',
                                employees: '',
                                domain: '',
                                domains: '',
                                profile: ctrl.importFromLdapCtrl.ldapProfile.title
                            });
                        }),
                        class: 'danger',
                        logs: response[key + 'Logs'],
                        showLogs () {
                            this.isShowLogs = !this.isShowLogs;
                        }
                    });
                }
                if (key === 'unknown' && response[key] > 0 && ctrl[key + 'LinkText'] && ctrl[key + 'ImportText']) {
                    response[key] = response[key] + '';
                    ctrl.alertMessages.push({
                        type: key,
                        item: response[key],
                        textLink: ctrl[key + 'LinkText'].map(item => {
                            return ctrl.gettextCatalog.getString(`${item}`, {
                                count: response[key],
                                profile: ctrl.importFromLdapCtrl.ldapProfile.title
                            });
                        }),
                        isShowLogs: false,
                        text: ctrl[key + 'ImportText'].map(item => {
                            return ctrl.gettextCatalog.getString(`${item}`, { employee: '', employees: '' });
                        }),
                        class: 'danger',
                        logs: response[key + 'Logs'],
                        showLogs () {
                            this.isShowLogs = !this.isShowLogs;
                        },
                        callback: () => {
                            ctrl.deleteTargets();
                        },
                        preTextCallback: ctrl.gettextCatalog.getString(ctrl.deleteEmployeesText.preTextCallback, { deleteLink: '' }),
                        textCallback: ctrl.gettextCatalog.getString(ctrl.deleteEmployeesText.textCallback),
                        postTextCallback: ctrl.gettextCatalog.getString(ctrl.deleteEmployeesText.postTextCallback, { deleteEmployees: '' })
                    });
                }
                if (key === 'exception' && response[key]) {
                    response[key] = response[key] + '';
                    ctrl.alertMessages.push({
                        type: key,
                        default: true,
                        text: ctrl.defaultText.map(item => {
                            return ctrl.gettextCatalog.getString(`${item}`, { error: response[key] });
                        })
                    });
                }
            }
        }
    };

    isAnyLoading = () => {
        return this.loadingDepartments ||
            this.loadingTargets ||
            (this.importFromLdapCtrl && this.importFromLdapCtrl.isAnyLoading()) ||
            (this.employeesModalCtrl && this.employeesModalCtrl.inProcess);
    };

    setImportLoading = (value) => {
        this.loadingImport = value;
        if (typeof (this.setLoading) == 'function') {
            this.setLoading('import', value);
        }
    };

    setImportError = (value) => {
        this.errorsImport = value;
        if (typeof (this.setError) == 'function') {
            this.setError('import', value);
        }
    };

    setDepartmentsLoading = (value) => {
        this.loadingDepartments = value;
        if (typeof (this.setLoading) == 'function') {
            this.setLoading('departments', value);
        }
    };

    setDepartmentsError = (value) => {
        this.errorDepartments = value;
        if (typeof (this.setError) == 'function') {
            this.setError('departments', value);
        }
    };
    //
    // setDepartments = (departments) => {
    //     this.departments = Object.values(departments).filter(item => {
    //         return angular.isObject(item) && !angular.isArray(item);
    //     });
    // }

    hasTargets = () => {
        if (!this.department) {
            return this.departments.length;
        }
        return this.department.hasOwnProperty('users');
    }

    $onDestroy = () => {
        this.setDefaultLdap();
        this.unsubscribe();
    }
}

LdapDepartmentsController.$inject = [
    '$scope',
    '$timeout',
    TARGETS_SERVICE,
    GROUPS_SERVICE,
    'gettextCatalog',
    '$injector',
    '$interval',
    LDAP_IMPORTS_SERVICE,
    '$ngRedux'
];

export {
    LdapDepartmentsController
};
