import {useInputStyle} from './useInputStyle';
import {usePlural} from './pluralReact';
import {useValidForm} from './useValidForm.jsx';
import {useAutoWidth} from './useAutoWidth.jsx';
import {useValidModal} from './useValidModal.jsx';

export {
    useInputStyle,
    usePlural,
    useValidForm,
    useAutoWidth,
    useValidModal,
};
