import * as React from 'react'
import { ReactTableHead } from './ReactTableHead.jsx'
import { ReactTableBody } from './ReactTableBody.jsx'

export function ReactTable({header, body, assets, cellSchema}) {
    return (
        <table className={'react-table'}>
            <ReactTableHead header={header}/>
            <ReactTableBody body={body} assets={assets} cellSchema={cellSchema}/>
        </table>
    )
}
