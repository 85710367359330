import React, { useState, useEffect, useContext } from "react";
import { GettextContext } from "../../context";
import { useDispatch, useSelector } from "react-redux";
import { updateCoursesChecked } from "../../../toolkit/actions";
import { usePlural } from 'rootReact/hook'

function SnackBar({ setCheckboxAllCourses, checkedCourses, coursesList, learning }) {
    const gettext = useContext(GettextContext);
    const dispatch = useDispatch();
    const [dropdown, setDropdown] = useState(false)
    const [arrow, setArrow] = useState(false)
    const [roleVisible, setRoleVisible] = useState(true)
    const [roleBtnDisable, setRoleBtnDisable] = useState(false)
    const [allBtnDisable, setAllBtnDisable] = useState(false)
    const [userVisible, setUserVisible] = useState(true)
    const [userBtnDisable, setUserBtnDisable] = useState(false)
    const checkedUser = checkedCourses.map((item) => item.user.name)
    const checkedRole = checkedCourses.map((item) => item.user.roles.map(item => item.name))
    const uniqueRoleList = [...new Set(checkedRole.flat())] //собираем все роли в выбранных курсах и делаем массив уникальных(для дропдауна)
    const uniqueUserList = [...new Set(checkedUser.flat())]
    const roleAll = coursesList.map((item) => item.user.roles.map(item => item.name)).flat()

    const getDropdownRoles = () => {
        return uniqueRoleList.map((item, i) => (
            <li key={i} onClick={() => handleRoleItem(item)} className={"react-courses_snackbar-dropdown-item"}>{item}</li>
        ))
    }

    useEffect(() => {
        if (uniqueRoleList.length > 1) {
            setArrow(true)
        } else {
            setArrow(false)
        }
    }, [uniqueRoleList])

    function countRoles(arr) {
        let countRole = {};
        for (let elem of arr) {
            if (countRole[elem] === undefined) {
                countRole[elem] = 1;
            } else {
                countRole[elem]++;
            }
        }
        return countRole;
    }

    //дизейблит кнопку Роль, если отжаты все чекбоксы с одинаковыми ролями
    useEffect(() => {
        const allRolesCount = countRoles(roleAll)
        const checkedRolesCount = countRoles(checkedRole.flat())
        for (let key in allRolesCount) {
            for (let keyChecked in checkedRolesCount) {
                if (key === keyChecked) {
                    if (allRolesCount[key] === checkedRolesCount[keyChecked] && uniqueRoleList.length === 1) {
                        setRoleBtnDisable(true)
                    } else {
                        setRoleBtnDisable(false)
                    }
                }
            }
        }
    }, [checkedCourses, roleAll, checkedRole])

    //дизейблит кнопку Добавитель, если отжаты все чекбоксы с одинаковыми добавителями
    useEffect(() => {
        const userAll = coursesList.map((item) => item.user.name)
        const allUserCount = countRoles(userAll)
        const checkedUserCount = countRoles(checkedUser)
        for (let key in allUserCount) {
            for (let keyChecked in checkedUserCount) {
                if (key === keyChecked) {
                    if (allUserCount[key] === checkedUserCount[keyChecked]) {
                        setUserBtnDisable(true)
                    } else {
                        setUserBtnDisable(false)
                    }
                }
            }
        }
    }, [coursesList, checkedUser])

    //дизейблит кнопку Все,если отжаты все чекбоксы
    useEffect(() => {
        const allChecked = coursesList.some(item => item.checked === false)
        if (allChecked) {
            setAllBtnDisable(false)
        } else {
            setAllBtnDisable(true)
        }
    }, [coursesList])

    //если добавители и роли не совпадают, убирает обе кнопки
    useEffect(() => {
        let roleCountChecked = Object.values(countRoles(checkedRole.flat()))
        let checkCount = roleCountChecked.some(item => item > 1)
        if ((!checkCount && uniqueUserList.length !== 1) || (checkedCourses.length === coursesList.length) || learning.type !== 2) {
            setUserVisible(false)
            setRoleVisible(false)
        } else {
            setUserVisible(true)
            setRoleVisible(true)
        }
    }, [checkedCourses, coursesList])


    const handleAll = () => {
        coursesList.forEach(item => {
            if (item.checked === false) {
                dispatch(updateCoursesChecked(item.id));
            }
        })
    }

    const handleClose = () => {
        checkedCourses.forEach(item => {
            dispatch(updateCoursesChecked(item.id));
        })
        setCheckboxAllCourses(false)
    }

    const handleRoleItem = (role) => {
        coursesList.forEach(item => {
            if (item.checked === false) {
                item.user.roles.forEach(i => {
                    if (i.name === role.toString()) {
                        dispatch(updateCoursesChecked(item.id));
                    }
                })
            }
        })
    }

    const handleRole = () => {
        if (uniqueRoleList.length > 1) {
            setDropdown(prev => !prev)
        } else if (uniqueRoleList.length === 1) {
            coursesList.forEach(item => {
                if (item.checked === false) {
                    item.user.roles.forEach(i => {
                        if (i.name === uniqueRoleList.toString()) {
                            dispatch(updateCoursesChecked(item.id));
                        }
                    })
                }
            })
        }
    }

    const handleUser = () => {
        coursesList.forEach(i => {
            if (i.user.name === uniqueUserList.toString()) {
                if (i.checked === false) {
                    dispatch(updateCoursesChecked(i.id));
                }
            }
        })
    }

    return (
        <div className={"react-courses_snackbar"}>
            <div className={"react-courses_snackbar-left"}>
                <span>{gettext.getString(usePlural(checkedCourses.length, ['Выбран', 'Выбрано', 'Выбраны']))}</span>
                <span className={"react-courses_snackbar-count"}>{gettext.getString(`${checkedCourses.length}`)}</span>
                <span>{gettext.getString(usePlural(checkedCourses.length, ['курс', 'курса', 'курсов']))}</span>
            </div>
            <div className={"react-courses_snackbar-right"}>
                <span className={"react-courses_snackbar-text"}>{gettext.getString("Выбрать курсы")}</span>
                {userVisible &&
                    <button
                        disabled={userBtnDisable}
                        onClick={handleUser}
                        className={`react-courses_snackbar-btn ${userBtnDisable ? 'react-courses_snackbar-btn-disable' : ''}`}>
                        {gettext.getString("Добавителя")}
                    </button>
                }
                {roleVisible &&
                    <button
                        disabled={roleBtnDisable}
                        onClick={handleRole}
                        className={`react-courses_snackbar-btn-role ${roleBtnDisable ? 'react-courses_snackbar-btn-disable' : ''}`}>
                        {dropdown &&
                            <ul className={"react-courses_snackbar-dropdown"}>
                                {getDropdownRoles()}
                            </ul>
                        }
                        {gettext.getString("Роли")}
                        {arrow &&
                            <svg className={"react-courses_snackbar-arrow"}>
                                <use xlinkHref={`/image/icoSprite.svg#dropdown-arrow`}></use>
                            </svg>
                        }
                    </button>
                }
                <button onClick={handleAll} className={`react-courses_snackbar-btn ${allBtnDisable ? 'react-courses_snackbar-btn-disable' : ''}`}>
                    {gettext.getString("Все")}
                </button>
                <svg onClick={handleClose} className={"react-courses_snackbar-close"}>
                    <use xlinkHref={`/image/icoSprite.svg#courses-snackbar-close`}></use>
                </svg>
            </div>
        </div>
    );
}

export default SnackBar;
