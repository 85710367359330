import dashboardTemplate from './view.html';
import {DashboardPeopleComponent} from './dashboardPeople';
import {DashboardMotivationComponent} from './dashboardMotivation';
import {DashboardEducationComponent} from './dashboardEducation';
import {DashboardAttackComponent} from './dashboardAttack';

import {DashboardController} from './controller';

const DashboardCoveringComponent = {
    template: dashboardTemplate,
    controller: DashboardController,
    controllerAs: '$',
    bindings: {
        educationCount: '<',
        attackCount: '<',
        startCampaign: '=',
        sendToLearning: '=',
        subEducation: '<',
        subAttack: '<',
        dasboardInfo: '<',
        noCourse: '<',
        noCampaign: '<',
        targetsInSystem: '<',
    },
};

export {
    DashboardCoveringComponent,
    DashboardPeopleComponent,
    DashboardMotivationComponent,
    DashboardEducationComponent,
    DashboardAttackComponent
};
