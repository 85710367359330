// import angular from 'angular';
import { hasAccess } from '../../reselect';
import {ImageModal} from '../imageModal';

class SettingsEducationController {
    constructor($ngRedux, gettextCatalog, FileReader, $timeout, $injector, $scope) {
        this.gettextCatalog = gettextCatalog;
        this.FileReader = FileReader;
        this.$timeout = $timeout;
        this.$injector = $injector;
        this.$scope = $scope;
        this.internalUrl = '';
        this.systemLogo = null;
        this.systemLogoExt = '';
        this.systemName = '';
        this.systemDescription = '';
        this.systemUrl = '';
        this.systemPhone = '';
        this.systemLanguage = '';
        this.errors = {};
        this.fields = [
            'internalUrl',
            'systemLogo',
            'systemLogoExt',
            'systemName',
            'systemDescription',
            'systemUrl',
            'systemPhone',
            'systemLanguage'
        ];
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis)(this);
    }

    mapStateToThis = (state) => {
        return {
            educationSave: hasAccess(state, {sectionId: 6, rightName: 'education_save'}),
        };
    };

    $onInit() {
        this.internalUrl = this.learningConfig.internalUrl;
        this.systemLogo = this.learningConfig.systemLogo;
        this.systemLogoExt = this.learningConfig.systemLogoExt;
        this.systemName = this.learningConfig.systemName;
        this.systemDescription = this.learningConfig.systemDescription;
        this.systemUrl = this.learningConfig.systemUrl;
        this.systemPhone = this.learningConfig.systemPhone;
        this.systemLanguage = this.learningConfig.systemLanguage;
    }

    clearLogotype = () => {
        this.errors.logo = null;
        if (this.systemLogo) {
            this.setLearningParams();
        }
        this.systemLogo = '';
        this.systemLogoExt = '';
        this.setLearningParams();
    };

    getLogotype = (file) => {
        this.FileReader.readAsDataUrl(file, this.$scope).then((result) => {
            this.systemLogoExt = file.type.split('/')[1];
            let types = ['jpg', 'jpeg', 'png', 'gif'];
            if (types.indexOf(this.systemLogoExt) == -1) {
                this.errors.logo = this.gettextCatalog.getString('Вы можете загрузить только: ') +
                    types.join(', ');
                this.systemLogo = '';
                this.systemLogoExt = '';
            } else {
                this.errors.logo = null;
                this.systemLogo = result.replace('data:image/' +
                    this.systemLogoExt + ';base64,', '');
                this.setLearningParams();
            }
        });
    };

    setLearningParams = (urlName, fieldName) => {
        if (fieldName === 'internalUrl') {
            this.internalUrl = urlName ? urlName : this.internalUrl;
        }
        if (fieldName === 'systemUrl') {
            this.systemUrl = urlName ? urlName : this.systemUrl;
        }
        this.sendParams();
    }

    checkParams = () => {
        let newKey = false;
        this.fields.forEach(item => {
            if (this.learningConfig[item] !== this[item]) {
                newKey = true;
            }
        });
        return newKey;
    }

    sendParams = () => {
        const {
            internalUrl,
            systemLogo,
            systemLogoExt,
            systemName,
            systemDescription,
            systemUrl,
            systemPhone,
            systemLanguage
        } = this;
        this.getLearningParams({
            internalUrl,
            systemLogo,
            systemLogoExt,
            systemName,
            systemDescription,
            systemUrl,
            systemPhone,
            systemLanguage
        });
    }

    viewLogotype = (image) => {
        this.logoModal = this.$injector.instantiate(ImageModal);
        this.logoModal.open(image).then(() => {
        }, () => {
        });
    };

    selectLogotype = () => {
        this.clearLogotype();
        this.$timeout(function() {
            document.getElementById('systemLogo').click();
        }, 100);
    };

    $onDestroy() {
        this.unsubscribe();
    }
}

SettingsEducationController.$inject = [
    '$ngRedux',
    'gettextCatalog',
    'FileReader',
    '$timeout',
    '$injector',
    '$scope'
];

export {
    SettingsEducationController,
};
