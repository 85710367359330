import sideBarTemplate from './view.html';

import {SideBarController} from './controller';

const SideBarComponent = {
    template: sideBarTemplate,
    controller: SideBarController,
    controllerAs: '$',
    scope: true,
    bindings: {
        menuOpen: '<',
        onBurgerClick: '='
    },
};

export {
    SideBarComponent,
};
