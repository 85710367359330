import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    status: 'idle',
    templates: [],
    error: false
};

export const fetchTemplates = createAsyncThunk(
    'templates/status',
    async () => {}
);

const templatesSlice = createSlice({
    name: 'templates',
    initialState,
    reducers: {
        setTemplates (state, {payload}) {
            state.templates = payload;
        }
    },
    extraReducers: {}
});

export const {setTemplates} = templatesSlice.actions;
export default templatesSlice.reducer;

