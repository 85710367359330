import {AuthService} from './auth';
import {CampaignsService} from './campaigns';
import {DashboardService} from './dashboard';
import {DepartmentsService} from './departments';
import {StatusService} from './status';
import {TargetsService} from './targets';
import {MarksService} from './marks';
import {TemplatesService} from './templates';
import {SettingsService} from './settings';
import {LearningService} from './learning';
import {PolicyService} from './policy';
import {LdapProfilesService} from './ldapProfiles';
import {SchedulerTasksService} from './schedulerTasks';
import {SenderDomainsService} from './senderDomains';
import {CampaignTargetsService} from './campaignTargets';
import {AttachmentsService} from './attachments';
import {PhishingPagesService} from './phishingPages';
import {FinalPagesService} from './finalPages';
import {ReportsService} from './reports';
import {RisksService} from './risks';
import {UsbService} from './usb';
import {NotificationsService} from './notifications';
import {PatchersService} from './patchers';
import {VariablesService} from './variables';
import {UsersService} from './users';
import {TimeZonesService} from './timeZones';
import {CoursesService} from './courses';
import {RolesService} from './roles';
import {MailServersService} from './mailServers';
import {BossKadrovikProfilesService} from './bossKadroviProfiles';
import {LdapImportsService} from './ldapImports';
import {GroupsService} from './groups';
import {EntityService} from './entity';
import {DirectoriesService} from './directories';

export {
    AuthService,
    CampaignsService,
    DashboardService,
    DepartmentsService,
    StatusService,
    TargetsService,
    MarksService,
    TemplatesService,
    SettingsService,
    LearningService,
    PolicyService,
    LdapProfilesService,
    SchedulerTasksService,
    SenderDomainsService,
    CampaignTargetsService,
    AttachmentsService,
    PhishingPagesService,
    FinalPagesService,
    ReportsService,
    RisksService,
    UsbService,
    NotificationsService,
    PatchersService,
    VariablesService,
    UsersService,
    TimeZonesService,
    CoursesService,
    RolesService,
    MailServersService,
    BossKadrovikProfilesService,
    LdapImportsService,
    GroupsService,
    DirectoriesService,
    EntityService
};
