import modalTemplate from './view.html';
import {CoursesModalController} from './controller';

import {COURSES_MODAL_CONTROLLER} from '../../const';

class CoursesModal {
    constructor($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
        this.modalInstance = null;
    }

    $onInit = () => {
        this.$uibModal;
    };

    open = (learningConfig = null) => {
        this.modalInstance = this.$uibModal.open({
            template: modalTemplate,
            bindToController: true,
            controller: COURSES_MODAL_CONTROLLER,
            controllerAs: '$',
            size: 'md',
            resolve: {
                learningConfig: learningConfig,
            },
            backdrop: 'static',
            keyboard: false,
        });

        return this.modalInstance.result;
    };
}

export {
    CoursesModal,
    CoursesModalController,
};
