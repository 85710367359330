import {Controller} from './controller';
import {fetchTargetHistory} from '../toolkit/targetHistory';

class PracticePlan extends Controller {

    constructor($scope, gettextCatalog, $ngRedux) {
        super();
        this.$scope = $scope;
        this.gettextCatalog = gettextCatalog;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, {fetchTargetHistory})(this);
        this.period = 'month';
        this.load = true;
        this.skills = {
            openAttachment: gettextCatalog.getString('открытие вложения'),
            openAttachmentFromLink: gettextCatalog.getString('открыл файл по ссылке'),
            openLink: gettextCatalog.getString('переход по ссылке'),
            openMail: gettextCatalog.getString('открытие письма'),
            passExam: gettextCatalog.getString('пройдено обучение'),
            reportMail: gettextCatalog.getString('сообщено об атаке'),
            resist: gettextCatalog.getString('атака выдержана'),
            submitForm: gettextCatalog.getString('ввод данных в форму'),
            vulnerabilities: gettextCatalog.getString('наличие уязвимостей'),
        };
    }

    mapStateToThis = (state) => {
        return {
            targetHistory: state.targetHistory.targetHistory.data,
            selectionTotalCount: state.targetHistory.targetHistory.totalTargets,
            load: state.targetHistory.load,
            selectedData: state.selectedData
        };
    };

    $onInit() {
        this.selectedData.all = 1;
        fetch(window.config.SYSTEM_URL + window.config.API_URL + '/targets/rebuild', {
            headers: {
                'X-Csrf-Token': window.csrfToken,
                'Enable-Session': 1,
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify(this.selectedData),
        }).then(selectedData => {
            return selectedData.json();
        }).then(selectedData => {
            selectedData.date_from = '2020-05-12';
            selectedData.date_to = '2020-05-15';
            this.fetchTargetHistory(selectedData);
        });
    }
}

PracticePlan.$inject = [
    '$scope',
    'gettextCatalog',
    '$ngRedux'
];

export {
    PracticePlan
};
