import style from './style.modules.css';
import {EMPTY_SELECTED_DATA} from '../../const';
import angular from 'angular';
import {setBreadcrumbLink, setSelectedData} from '../../toolkit/actions';

class breadcrumbLinksController {
    constructor($localStorage, $ngRedux, $scope) {
        this.style = style;
        this.$localStorage = $localStorage;
        this.scope = $scope;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, {setBreadcrumbLink, setSelectedData})(this);
    }

    mapStateToThis(state) {
        return {
            selectedData: state.selectedData,
            linkNames: state.breadcrumbLinks.link,
        };
    }

    $onInit() {
    }

    onLinkClick = (id = 'clear') => {
        if (id == 'clear') {
            this.$localStorage.openedCourse = null;
            this.$localStorage.openedManager = null;
            if (this.$localStorage.departments.dataMode == 1) {
                let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), {search: ['']});
                this.setSelectedData(selectedData);
            }
        }
        let index = null;
        for (let i = 0; i < this.linkNames.length; i++) {
            if (this.linkNames[i].id === id) {
                index = i;
            }
        }
        if (id === 'clear') {
            this.setBreadcrumbLink([]);
        } else {
            if (index > 0 || index === 0) {
                let links = this.linkNames.filter((item, i) => i <= index);
                this.setBreadcrumbLink([...links]);
            } else {
                this.setBreadcrumbLink([]);
            }
        }
    };

    $onDestroy() {
        this.unsubscribe();
    }
}

breadcrumbLinksController.$inject = [
    '$localStorage',
    '$ngRedux',
    '$scope',
];

export {
    breadcrumbLinksController,
};
