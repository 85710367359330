import campaignViewTemplate from './view.html';

import {CampaignViewController} from './controller';

const CampaignViewComponent = {
    template: campaignViewTemplate,
    controller: CampaignViewController,
    controllerAs: '$',
    scope: true,
    bindings: {
        attackName: '<',
        licenseValid: '<',
        targetsCount: '<',
        fromScheduler: '<',
        attackState: '<',
        isOutdated: '<',
        sendMessage: '<',
        sendTotal: '<',
        isDomainValid: '<',
        sendError: '<',
        attackTasks: '<',
        dateSelected: '<',
        attackStartAccess: '<',
        reportAttackAccess: '<',
        attackSaveAccess: '<',
        attackDeleteAccess: '<',
        fromTime: '<',
        vectorsItems: '<',
        sourceField: '<',
        attackStop: '=',
        attackSchedule: '=',
        attackDelete: '=',
        attackCopy: '=',
        attackPause: '=',
        attackStart:  '=',
        showReport: '=',
        reSendErrors: '=',
        getScheduleDate: '=',
        attackEdit: '=',
        filterByType: '=',
        filterByKey: '='

    },
};

export {
    CampaignViewComponent,
};
