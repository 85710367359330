import React, {useContext, useState, useEffect} from 'react'
import { GettextContext } from 'rootReact/context';
import Input from 'rootReact/components/Input';
import {useSelector, useDispatch} from 'react-redux'
import { useInputStyle, useValidForm } from 'rootReact/hook';
import ReactInputMultiple from 'rootReact/components/ReactInputMultiple';
import { fetchOutlookPost } from '../../../toolkit/think';
import {setLoadOutlook} from '../../../toolkit/actions'
import FaIcon from '../FaIcon';

function OutlookForm({settings, licenseIsValid}) {
    const gettext = useContext(GettextContext)
    const status = useSelector(state => state.outlook.status)
    const dispatch = useDispatch()
    const [prefix, setPrefix] = useState(settings.prefix)
    const [email, setEmail] = useState(settings.email)
    const [saveMailBody, setSaveMailBody] = useState(settings.saveMailBody)
    const [fieldChange, setFieldChange] = useState(false)
    const values = useValidForm({
        'prefix': { value: prefix, setValue: setPrefix },
    }, {prefix: {require: true}})
    const prefixStyle = useInputStyle(
            ['react-input_field'],
            values['prefix'].touched,
            values['prefix'].focused,
            values['prefix'].error)

    useEffect(() => {
        setTimeout(() => {setFieldChange(false)}, 100)
    }, [])

    useEffect(() => {
        setFieldChange(true)
    }, [email, prefix, saveMailBody])

    useEffect(() => {
        if (status === 'success') {
            setTimeout(() => dispatch(setLoadOutlook()), 2000)
        }
    }, [status])

    const saveParams = () => {
        if (!fieldChange || !licenseIsValid) return
        dispatch(fetchOutlookPost({
            prefix,
            email,
            saveMailBody: +saveMailBody,
        }))
    }

    const concatEmail = (newEmail) => {
        setEmail(newEmail.join('$;$'))
    }

    const getFormatEmail = () => {
        let array = email.split('$;$')
        if (!array[0]) return []
        return array
    }

    return (
        <div>
            <div className={'settings_item settings_item__start'}>
                <div className={'settings_path-left'} style={{marginRight: '15px'}}>{gettext.getString('Отправлять сообщения на эти адреса:')}</div>
                <div className={'settings_path-right settings_multiple'}>
                    <ReactInputMultiple
                        placeHolder={gettext.getString('Введите адреса')}
                        inputId={'outlook-emails'}
                        disabled={!licenseIsValid}
                        initialState={getFormatEmail()}
                        setValue={concatEmail}
                        isRequiredBehavior={true}
                    />
                </div>
            </div>
            <div className={'settings_item settings_item__center'}>
                <Input
                        inputId={'outlook-prefix'}
                        disabled={!licenseIsValid}
                        labelStyle={'mr-30'}
                        label={gettext.getString('Задать префикс для сообщений:')}
                        inputAttr={values['prefix'].inputAttr}
                        error={values['prefix'].error}
                        Style={prefixStyle}
                        center
                />
            </div>
            <div className={'settings_item settings_item__center'}>
                <div className={'settings_path-left'}>{gettext.getString('Сохранять сообщения в системе:')}</div>
                <div className={'react-button'}>
                    <button className={`${saveMailBody ? 'react-button_element__active' : ''} react-button_element react-button_element__sm react-button_element__left outlook_button`}
                            disabled={!licenseIsValid}
                            onClick={() => setSaveMailBody(true)}
                    >{gettext.getString('Да')}</button>
                    <button className={`${!saveMailBody ? 'react-button_element__active' : ''} react-button_element react-button_element__sm react-button_element__right outlook_button`}
                            disabled={!licenseIsValid}
                            onClick={() => setSaveMailBody(false)}
                    >{gettext.getString('Нет')}</button>
                </div>
            </div>
            <div className={'settings_item'}>
                <div className={'settings_path-left'}></div>
                <div className={'settings_path-right'}>
                    {!email.length && <span> {gettext.getString('Сохранить настройки')}</span>}
                    {status === 'load' && email.length > 0 && <a className={`${(!fieldChange || !licenseIsValid) ? 'nolink' : ''}`}
                           onClick={saveParams}
                    >{gettext.getString('Сохранить настройки')}</a>}
                    {status === 'pending' && <FaIcon type={'pending'} text={gettext.getString('Сохранение') + '...'} />}
                    {status === 'success' && <FaIcon type={'success'} text={gettext.getString('Настройки сохранены')} />}
                </div>
            </div>
        </div>
    )
}

export default OutlookForm
