import React from 'react';
import Tooltip from '../Tooltip';
import { GettextContext } from '../../context';

const tooltipText = {
    delete: 'удалить',
    edit: 'редактировать',
    logout: 'выход',
    scheduler: 'планировщик',
    pause: 'отключить',
    'play-new': 'запустить',
    'pause-new': 'остановить',
    'edit-new': 'редактировать',
    'copy-new': 'копировать',
    default: 'вернуть до исходного',
    'delete-new': 'удалить',
}

function ReactIcon({icoClass, handleClick, handleParams, disabled, inline, listIcon}) {
    const gettext = React.useContext(GettextContext);

    function getStyle () {
        if (disabled) {
            return `react-icon react-icon_${icoClass} react-icon_${icoClass}__disabled ${listIcon && 'list-icons'}`
        }
        return `react-icon react-icon_${icoClass} ${listIcon && 'list-icons'}`
    }

    return (
            <Tooltip reactTip={gettext.getString(tooltipText[icoClass])} inline={inline}>
                <svg data-testid={icoClass} className={getStyle()} onClick={(e) => handleClick(e, handleParams)}>
                    <use xlinkHref={`/image/icoSprite.svg#${icoClass}`}></use>
                </svg>
            </Tooltip>
    );
}

export default ReactIcon
