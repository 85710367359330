import React from 'react'

function LanguageSelector({languages, onSelect, activeId}) {
    return (
            <div className={'languages-selector'}>
                {languages.map(item => (
                    <div key={item.id}
                         className={`languages-selector_item ${activeId === item.id ? 'languages-selector_item__active' : ''}`}
                         onClick={() => onSelect(item.id)}
                    >{item.name}</div>))}

            </div>
    )
}

export default LanguageSelector
