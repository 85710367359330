import React, { useContext } from "react";
import { GettextContext } from "rootReact/context";
import loader from "../../../../images/loading.svg";
import { useSelector, useDispatch } from "react-redux";
import { updateCoursesChecked, updateCourse } from "../../../toolkit/actions";
import FetchApi from "../../REST";

function CoursesList({ coursesList }) {
    const gettext = useContext(GettextContext);
    const loadCourses = useSelector((state) => state.courses.loading);
    const dispatch = useDispatch();

    if (loadCourses) {
        return (
            <tr className={"react-courses_loader"}>
                <td>
                    <img src={loader} />
                </td>
            </tr>
        );
    } else if (!coursesList.length) {
        return (
            <tr className={"react-courses-list_empty"}>
                <td></td>
                <td></td>
                <td className={"react-courses-list_empty-content"}>{gettext.getString("Нет курсов")}</td>
            </tr>
        );
    }

    const getRolesName = (item) => {
        const roleName = item.user?.roles?.map((role) => role.name)
        return roleName?.join(', ');
    };

    const handleCheckboxSelect = async (id) => {
        dispatch(updateCoursesChecked(id));
    };

    const handleChangeVisible = async (id) => {
        let findItem = coursesList.find((item) => item.id === id);
        if (findItem) {
            let visible = findItem.displayForStat ? false : true
            try {
                const response = await FetchApi(`/courses/set-for-visible`).post({
                    body: {
                        flag: visible,
                        coursesId: [id],
                    },
                });
                if (response && response.isError) {
                    throw response;
                }
                dispatch(updateCourse({ ...findItem, displayForStat: visible }));
            } catch (e) {
                console.log(e);
            }
        }
    }

    return coursesList?.map((item) => {
        return (
            <tr className={`react-courses-list_row ${item?.displayForStat && 'courses-row-display-start'} ${item?.checked && 'courses-row-active'}`} key={item.id}>
                <td className={"react-courses-list_label-check"}>
                    <svg onClick={() => handleChangeVisible(item.id)} className={`react-courses-list-check ${item.displayForStat ? "courses_check-display-start" : ""}`}>
                        <use xlinkHref={`/image/icoSprite.svg#courses-check`}></use>
                    </svg>
                </td>
                <td className={"react-courses-list_label-checkbox"}>
                    <input
                        checked={item.checked}
                        onChange={() => handleCheckboxSelect(item.id)}
                        className="react-checkbox-courses-list"
                        type="checkbox"
                    />
                    <label className="react-checkbox-courses_label-list"></label>
                </td>
                <td className={"react-courses-list_label"}>{gettext.getString(`${item.name}`)}</td>
                <td className={"react-courses-list_label"}>{gettext.getString(`${item.user?.name}`)}</td>
                <td className={"react-courses-list_label"}>{gettext.getString(`${getRolesName(item)}`)}</td>
                <td className={"react-courses-list_label"}>
                    <div className={"react-courses-list_label-status"}>
                        {item.status?.useInTasks && (
                            <span className={"react-courses-list_label-task"}>
                                {gettext.getString(`В планировщике`)}
                            </span>
                        )}
                        {item.status?.useInLearn && (
                            <span className={"react-courses-list_label-learn"}>{gettext.getString(`Назначен`)}</span>
                        )}
                    </div>
                </td>
            </tr>
        );
    });
}

export default CoursesList;
