import setOptions from '../../helpers/setOptions';
import { saveAs } from 'file-saver';

class DaemonSaveFileController {
    constructor (scope) {
        this.scope = scope;
        this.error = false;
        this.loading = false;
    }

    $onInit () {}

    getFilename = (header) => {
        return header.get('Content-Disposition')
            .split(' ')
            .filter(item => item.indexOf('filename') !== -1)[0]
            .replace('filename="', '')
            .replace('"', '');
    }

    downloadFile = async () => {
        // TODO: доделать прогресс бар загрузки (посмотреть возобновление загрузки)
        // const that = this;
        // let xhr = new XMLHttpRequest();
        // xhr.open('POST', `${config.SYSTEM_URL}${config.API_URL}/files/download`);
        // xhr.setRequestHeader('X-Csrf-Token', window.csrfToken);
        // xhr.setRequestHeader('Enable-Session', 1);
        // xhr.setRequestHeader('Content-Type', 'application/json');
        // const body = JSON.stringify({daemon: this.daemonId});
        // xhr.onerror = (err) => {
        //     window.console.log(err);
        //     this.error = err;
        // };
        // xhr.addEventListener('progress', (event) => {
        //     window.console.log(event.loaded, event.total);
        // });
        // xhr.onload = function(e) {
        //     let fileName = that.getFilename(xhr);
        // };
        // xhr.send(body);
        if (this.loading) return;
        this.loading = true;
        const options = setOptions();
        options.method = 'POST';
        options.body = JSON.stringify({daemon: this.daemonId});
        options.headers['Content-Type'] = 'application/json';
        try {
            let response = await fetch(`${window.config.SYSTEM_URL}${window.config.API_URL}/files/download`, options);
            if (!response.ok) throw true;
            let fileName = this.getFilename(response.headers);
            let blobContent = await response.blob();
            saveAs(blobContent, fileName);
            this.loading = false;
            if (this.onClickLink) {
                this.onClickLink(this.type);
            }
        } catch (err) {
            window.console.log(err);
            this.error = err;
            this.loading = false;
        }
    }
}

DaemonSaveFileController.$inject = [
    '$scope'
];

export {
    DaemonSaveFileController
};
