import maturityLevelTemplate from './view.html';

import {MaturityLevelController} from './controller.js';

const MaturityLevelComponent = {
    template: maturityLevelTemplate,
    controller: MaturityLevelController,
    controllerAs: '$',
    scope: true,
    bindings: {
        departmentChoosed: '<',
        courses: '=',
        loadDepartments: '=',
        loading: '<',
        setReloadng: '<',

        isAnyLoading: '<',
        reloading: '<',
        setReloading: '<',
        setDepartmentCount: '<',
        departmentCheckedCache: '=',
        departmentPartialCache: '=',
        setLoading: '<',
        rebuildLoading: '<',
        setSearch: '<',
    },
};

export {
    MaturityLevelComponent,
};
