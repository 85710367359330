import modalFooterTemplate from './view.html';

import {ModalFooterController} from './controller.js';

const ModalFooterComponent = {
    template: modalFooterTemplate,
    controller: ModalFooterController,
    controllerAs: '$',
    scope: true,
    bindings: {
        buttonConf: '<',
        errors: '<',
        loading: '<',
    },
};

export {
    ModalFooterComponent,
};
