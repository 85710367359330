import { Controller } from './controller';
import { EMPTY_SELECTED_DATA, RISKS_SERVICE } from '../const';
import angular from 'angular';
import { hasAccess } from '../reselect';
import style from '../../styles/risks.modules.css';
import go from '../helpers/go';
import {setSearchData, setSelectedData, clearSelectedData, applyFilterDepartment} from '../toolkit/actions';
import { fetchDashboardParams } from '../toolkit/dashboardParams';

class RisksController extends Controller {
    constructor ($localStorage, $scope, RisksService, $ngRedux, $injector, $location) {
        super();
        this.style = style;
        this.$localStorage = $localStorage;
        this.$scope = $scope;
        this.$location = $location;
        this.RisksService = RisksService;
        this.$injector = $injector;
        this.loading = {
            software: null,
            report: null
        };
        this.errors = {
            software: null,
            report: null
        };
        this.fixVulnerabilitiesCallback = () => {
        };
        this.expanded = null;
        this.expandedType = null;
        this.departmentCheckedCache = [];
        this.departmentPartialCache = [];
        this.linkNames = [];
        this.viewMode = 0;
        this.departmentsLoading = false;
        this.targetsLoading = false;
        this.reBuildLoading = false;
        this.ignoreSetLoading = {
            departmentsLoading: false,
            targetsLoading: false,
            reBuildLoading: false
        };
        this.reloading = false;
        this.departmentCount = 0;
        window.risksCtrl = this;
        this.appCtrl = window.appCtrl;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, {applyFilterDepartment, clearSelectedData, setSearchData, setSelectedData, fetchDashboardParams})(this);
        this.setSelectedData(angular.extend({}, angular.copy(EMPTY_SELECTED_DATA)));
    }

    mapStateToThis = (state) => {
        return {
            selectedData: state.selectedData.selectedData,
            reportDepartmentAccess: hasAccess(state, { sectionId: 3, rightName: 'report_risk' }),
            viewAccess: hasAccess(state, { sectionId: 3, rightName: 'view' }),
            fixAccess: hasAccess(state, { sectionId: 3, rightName: 'vulnerability_fix' })
        };
    };

    $onInit () {
        if (!window.risksCtrl) {
            this.clearSelectedData();
        }
        const { $localStorage, $location } = this;
        if (!this.viewAccess) {
            if ($localStorage.userInfo.defaultPage) {
                go($location, $localStorage.userInfo.defaultPage);
            }
            return;
        }
        this.$localStorage.reBuildLoading = false;
        $localStorage.campaignData = null;
        this.dataMode = $localStorage.risks && $localStorage.risks.dataMode || 0;
        this.viewMode = $localStorage.risks && $localStorage.risks.viewMode || 0;
        this.loadSoftwareInfo();
        if (typeof ($localStorage.departments) == 'undefined') {
            $localStorage.departments = {};
        }
        this.$localStorage.openedDepartments = [];
        let search = this.selectedData.search && angular.isArray(this.selectedData.search) ? this.selectedData.search : [''];
        this.setSearchData(search);
        this.$scope.$watch(scope => {
            return scope.risksCtrl.$localStorage.risks.dataMode;
        }, (newValue, oldValue) => {
            if (oldValue === newValue) {
                return;
            }
            this.setDataMode(newValue);
        });
        this.$scope.$watch(scope => {
            return scope.risksCtrl.reloading;
        }, (newValue, oldValue) => {
            if (newValue && newValue !== oldValue && this.dataMode == 0) {
                this.loadSoftwareInfo();
            }
        });
    }

    setDataMode = (dataMode) => {
        this.expanded = null;
        this.expandedType = null;
        const { $localStorage, $location } = this;
        this.$localStorage.openedDepartments = [];
        this.dataMode = dataMode;
        if (typeof ($localStorage.risks) == 'undefined') {
            $localStorage.risks = {};
        }
        $localStorage.risks.dataMode = dataMode;
        if (this.dataMode == 0) {
            go($location, 'risks');
            this.loadSoftwareInfo();
        }
    };

    setViewMode = (viewMode) => {
        const { $localStorage } = this;
        this.viewMode = viewMode;
        if (typeof ($localStorage.risks) == 'undefined') {
            $localStorage.risks = {};
        }
        $localStorage.risks.viewMode = this.viewMode;
    };

    showReport = (department) => {
        this.toggleDepartment(department);
        this.$localStorage.openedDepartments.push(department.id);
    };

    loadSoftwareInfo = () => {
        this.loading.software = true;
        this.errors.software = null;
        this.RisksService.getSoftwareInfo().$promise.then((softwareInfo) => {
            this.softwareTotal = softwareInfo.total;
            delete (softwareInfo.total);
            this.softwareInfo = softwareInfo;
            this.expanded = null;
            this.expandedType = null;
            this.loading.software = null;
        }, (response) => {
            this.loading.software = null;
            this.errors.software = response && response.data ? response.data.error : true;
        }).catch(() => {
            this.loading.software = null;
            this.errors.software = true;
        });
    };

    fixVulnerabilities = () => {
        if (this.fixAccess) {
            if (this.selectedData && this.selectedData.selectedCount && this.selectedData.selectedCount.targets > 0) {
                let vendor;
                let params = {...this.selectedData};
                if (this.expandedType) {
                    if (this.expandedType === 'critical') {
                        vendor = this.softwareInfo.critical.find(sf => sf.id == this.expanded);
                    } else if (this.expandedType === 'major') {
                        vendor = this.softwareInfo.major.find(sf => sf.id == this.expanded);
                    } else {
                        vendor = this.softwareInfo.minor.find(sf => sf.id == this.expanded);
                    }

                    params.vendorId = vendor.vendorId;
                }
                this.fixEmployeesError = false;
                this.fixEmployeesLoading = true;
                params.risk = 1;


                this.RisksService.bulkFix(params, this, (ctrl) => {
                    let search = this.selectedData.search && angular.isArray(this.selectedData.search) ? this.selectedData.search : [''];
                    let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA));
                    selectedData.search = search;
                    this.setSelectedData(selectedData);
                    this.fetchDashboardParams();
                    ctrl.applyFilterDepartment({
                        search: '',
                        callback: null,
                        reload: 'departments'
                    });
                    ctrl.expandedType = null;
                    ctrl.expanded = null;
                    ctrl.fixEmployeesLoading = false;
                    ctrl.setReloading(true);
                }, (ctrl) => {
                    ctrl.fixEmployeesLoading = false;
                    ctrl.fixEmployeesError = true;
                });
            }
        }
    };

    toggleDepartment = (department) => {
        let departmentId = +department.id || null;
        if (departmentId) {
            let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(this.selectedData));
            selectedData = this.__toggleDepartment(selectedData, department);
            this.setSelectedData(selectedData);
        }
    };

    __toggleDepartment = (selectedData, department, forceAction) => {
        if (department && department.id) {
            selectedData.targets[department.id] = [];
            // this.prevSearch = this.buildSearchString();
            let unDepartmentIndex = selectedData.unselectedDepartments.indexOf(department.id);
            let departmentIndex = selectedData.departments.indexOf(department.id);
            let checkedCacheIndex = this.departmentCheckedCache.indexOf(department.id);
            let departmentAction = angular.copy(forceAction);
            if (!departmentAction) {
                if (checkedCacheIndex == -1) {
                    this.departmentCheckedCache.push(department.id);
                    if (!forceAction) {
                        forceAction = 'select';
                    }
                    departmentAction = 'select';
                } else {
                    this.departmentCheckedCache.splice(checkedCacheIndex, 1);
                    if (!forceAction) {
                        forceAction = 'unselect';
                    }
                    departmentAction = 'unselect';
                }
                checkedCacheIndex = this.departmentCheckedCache.indexOf(department.id);
                if (selectedData.all) {
                    if (!forceAction) {
                        forceAction = 'unselect';
                    }
                    departmentAction = 'unselect';
                }
            } else {
                if (!((department.searchTargets > 0 && department.ownTargetsCount > 0) || department.ownTargetsCount == 0)) {
                    departmentAction = 'unselect';
                }
            }
            if (departmentAction) {
                if (departmentAction == 'select') {
                    if (checkedCacheIndex == -1) {
                        this.departmentCheckedCache.push(department.id);
                    }
                    if (unDepartmentIndex > -1) {
                        selectedData.unselectedDepartments.splice(unDepartmentIndex, 1);
                    }
                    if (departmentIndex == -1) {
                        selectedData.departments.push(department.id);
                    }
                }
                if (departmentAction == 'unselect') {
                    if (checkedCacheIndex > -1) {
                        this.departmentCheckedCache.splice(checkedCacheIndex, 1);
                    }
                    if (departmentIndex > -1) {
                        selectedData.departments.splice(departmentIndex, 1);
                    }
                    if ((selectedData.prevData || selectedData.all) && unDepartmentIndex == -1) {
                        selectedData.unselectedDepartments.push(department.id);
                    }
                }
            }
            selectedData.unselectedTargets[department.id] = [];
            selectedData.targets[department.id] = [];
            if (department.childs && department.childs.length > 0) {
                angular.forEach(department.childs, (departmentChild) => {
                    this.__toggleDepartment(selectedData, departmentChild, forceAction);
                });
            }
        }
        return selectedData;
    };

    isAnyLoading = () => {
        return this.departmentsLoading || this.targetsLoading || this.reBuildLoading || this.fixEmployeesLoading;
    };

    setDepartment = (id = 'clear') => {
        let index = null;
        for (let i = 0; i < this.linkNames.length; i++) {
            if (this.linkNames[i].id === id) {
                index = i;
            }
        }
        if (id === 'clear') {
            this.linkNames = [];
            return;
        }
        if (index > 0 || index === 0) {
            this.loadDepartment(this.linkNames[index].id);
            return;
        }
        this.setBreadcrumbLink([...this.linkNames, ...this.departments.filter(item => item.id === id)]);
    };

    $onDestroy = () => {
        this.unsubscribe();
    };

    setLoading = (loadingType = 'departmentsLoading', value = false) => {
        value = !!value;
        if (!this.ignoreSetLoading[loadingType]) {
            if (value != this[loadingType]) {
                this[loadingType] = value;
            }
        } else {
            this.ignoreSetLoading[loadingType] = false;
        }
    };

    setReloading = (reloading) => {
        this.reloading = reloading;
    };

    setDepartmentCount = (count) => {
        this.departmentCount = +count;
    };

    subFunctions = () => {
        const {
            setLoading
        } = this;
        return {
            setLoading
        };
    };

    expand = (riskType, softwareId) => {
        this.clearSelectedData();
        this.expandedType = riskType;
        this.expanded = this.expanded != softwareId ? softwareId : 0;
    };
}

RisksController.$inject = [
    '$localStorage',
    '$scope',
    RISKS_SERVICE,
    '$ngRedux',
    '$injector',
    '$location'
];

export {
    RisksController
};
