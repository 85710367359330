import React, { useEffect, useState, useRef, useContext, useMemo } from "react";
import { GettextContext } from "rootReact/context";
import ReactIcon from "rootReact/components/ReactIcons";

function AttachmentDropdown({
    list,
    attachment,
    disabled,
    setAttachment,
    attachments,
    sendAttachment,
    setAttachmentFakeName,
    attachmentFakeName,
    archiveType,
    setZipAction,
    fileType,
    setFileType,
    setAttachmentNameError,
    setChangeFakeName,
    changeFakeName
}) {
    const [search, setSearch] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [viewAttachments, setViewAttachments] = useState([]);
    const gettext = useContext(GettextContext);
    const inputEl = useRef(null);
    const [searchList, setSearchList] = useState(list);
    const [renameStart, setRenameStart] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const spanRef = useRef(null);
    const inputRef = useRef(null);
    const divRef = useRef(null);
    const [inputWidth, setInputWidth] = useState(0);
    const [checkLength, setCheckLength] = useState(false);
    const [checkLengthArchive, setCheckLengthArchive] = useState(false);
    const [isoWarning, setIsoWarning] = useState(false);
    const [archiveWarning, setArchiveWarning] = useState(false);
    

    const handleFileType = (item) => {
        if (sendAttachment && !archiveType && (item === "lnk" || item === "js")) {
            setZipAction(1);
        }
        setFileType(item);
        setAttachment(null);
    };

    const filterAttachments = () => {
        let prevDeps = attachments.filter((item) => item.type === fileType);
        setViewAttachments(prevDeps);
    };

    const handleOpenClick = ({ target }) => {
        if (!isOpen || disabled) return;
        let opened = isDropdown(target);
        if (!opened) {
            setIsOpen(false);
            setSearch("");
        }
    };

    const handleClickOptions = (item) => {
        if (disabled) return;
        setIsOpen(false);
        setAttachment(item);
        setFileType(item.type);
        if (!changeFakeName) {
            setAttachmentFakeName(item.name)
        }
    };

    const searchAttachment = useMemo(() => {
        return attachments.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()));
    }, [attachments, searchQuery]);

    const getOptions = () => {
        if (disabled) return;
        let resultOptions;
        if (fileType) {
            resultOptions = searchAttachment.filter((item) => item.type === fileType);
        } else {
            resultOptions = searchAttachment;
        }
        return resultOptions.map((item, i) => {
            return (
                <div
                    key={item.name + i}
                    onClick={() => handleClickOptions(item)}
                    className={`react-dropdown_item ${item.name === attachment?.name && 'react-options-selected'}`}>
                    <span
                        data-testid={`list-item`}
                    >
                        {item.ico && <img className={"react-dropdown_ico"} src={item.ico} alt="lnk" />} {item.name}.
                        {item.type}
                    </span>
                    {item.name === attachment?.name &&
                        <svg className={'react-options-selected_icon'}>
                            <use xlinkHref={`/image/icoSprite.svg#option-selected`}></use>
                        </svg>
                    }
                </div>
            );
        });
    };

    const isDropdown = (element) => {
        if (element && element !== document.body && !element?.classList.contains("attachment-input")) {
            return isDropdown(element.parentElement);
        }
        if (element === document.body) return false;
        if (element?.classList.contains("attachment-input")) return true;
    };

    const filterList = (value) => {
        if (!viewAttachments) return;
        if (!value || value?.toLowerCase() === attachment?.name?.toLowerCase()) {
            setSearchList(viewAttachments);
            return;
        }
        let filter = viewAttachments.filter((item) => item?.name?.toLowerCase()?.indexOf(value?.toLowerCase()) !== -1);
        setSearchList(filter);
    };

    useEffect(() => {
        filterList(search);
    }, [search]);

    useEffect(() => {
        if (inputEl.current) {
            inputEl.current.focus();
        }
    }, [renameStart]);

    useEffect(() => {
        document.addEventListener("click", handleOpenClick);
        return () => {
            document.removeEventListener("click", handleOpenClick);
        };
    }, [isOpen]);

    useEffect(() => {
        filterAttachments();
    }, [fileType]);

    const removeAttachment = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setAttachment(null);
        setSearchQuery("");
        if (attachmentFakeName === attachment?.name) {
            setAttachmentFakeName('')
        }
    };

    const removeFakeName = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setAttachmentFakeName('')
        setChangeFakeName(false)
    }

    const setFileName = (fileName) => {
        setAttachmentFakeName(fileName);
        setChangeFakeName(true)
    };

    useEffect(() => {
        let fullLengthFakeName = attachmentFakeName?.length + fileType?.length + 1;
        if (archiveType === 3) {
            //проверка длины iso файлов
            let nameError = fullLengthFakeName > 31;
            setCheckLength(nameError);
            setCheckLengthArchive(false);
            setAttachmentNameError(nameError);
        } else {
            //проверка длины остальных
            let nameError = fullLengthFakeName > 64;
            setCheckLengthArchive(nameError);
            setCheckLength(false);
            setAttachmentNameError(nameError);
        }
    }, [attachmentFakeName, archiveType]);


    useEffect(() => {
        if (spanRef.current) {
            setInputWidth(spanRef.current.offsetWidth);
        }
    }, [attachmentFakeName]);

    useEffect(() => {
        archiveType === 3 ? setIsoWarning(true) : setIsoWarning(false);
    }, [archiveType]);

    const arrowClick = () => {
        setIsOpen(!isOpen);
    };

    const getIcons = () => {
        let mySet = new Set();
        attachments.forEach((item) => {
            mySet.add(item.type);
        });
        let types = Array.from(mySet);
        return types.map((item, i) => (
            <div
                key={i}
                data-testid={`filter-icons`}
                className={`attachment_icon-wrapper ${fileType === item && "attachment_icon-wrapper__active"}`}
                onClick={() => {
                    handleFileType(item);
                }}
            >
                <ReactIcon icoClass={item} handleClick={() => false} />
            </div>
        ));
    };

    const handleCursor = ({ target }) => {
        if (inputRef.current) {
            if (target.tagName.toUpperCase() !== 'INPUT') {
                inputRef.current.selectionStart = attachmentFakeName?.length;
                inputRef.current.selectionEnd = attachmentFakeName?.length;
            }
            inputRef.current.focus();
        }
    };

    return (
        <>
            <div className={"modal-form mb-1"} data-testid={"attachment-icons"}>
                <div style={{ display: "flex" }} data-testid={"attachment-icons"}>
                    {getIcons()}
                </div>
            </div>
            <div
                onClick={() => arrowClick(!isOpen)}
                className={`react-dropdown react-input_field react-attachment_field`}
                style={{ marginBottom: "9px", cursor: "pointer" }}
            >
                {attachment?.ico && (
                    <img
                        style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "5px",
                        }}
                        src={attachment?.ico}
                        alt="lnk"
                    />
                )}
                <input
                    style={{ cursor: 'pointer' }}
                    className={"react-attachment_input"}
                    value={attachment ? attachment.name + '.' + fileType : searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder={gettext.getString("Выберите или найдите по названию")}
                    type="search"
                    data-testid={"attachment-search-field"}
                />
                <div style={{ marginLeft: "auto", display: "flex" }}>
                    {!!attachment && (
                        <div
                            style={{ marginLeft: "auto", height: "22.5px" }}
                            className={"react-cross_black"}
                            data-testid={"attachment-remove"}
                            onClick={removeAttachment}
                        ></div>
                    )}
                    <span style={{ marginLeft: ".5rem" }} onClick={() => arrowClick(!isOpen)}>
                        <svg
                            className="dark-mode-fill"
                            version="1.1"
                            id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            width="10px"
                            height="10px"
                            viewBox="0 0 255 255"
                            fill={"#000"}
                        >
                            <g>
                                <g id="arrow-drop-down">
                                    <polygon points="0,63.75 127.5,191.25 255,63.75"></polygon>
                                </g>
                            </g>
                        </svg>
                    </span>
                </div>
                {isOpen && (
                    <div
                        className={isOpen ? "react-dropdown_options react-attachment_dropdown_open" : "react-dropdown_options"}
                        style={{ marginLeft: "-15px" }}
                        data-testid={"attachment-options-wrapper"}
                    >
                        {getOptions()}
                    </div>
                )}
            </div>
            <div
                ref={divRef}
                onClick={handleCursor}
                className={`react-attachment_fake-block ${checkLength || checkLengthArchive ? "edit-name-error" : ""}`}
            >
                <label className="react-attachment_fake-block_label" htmlFor="attachment-selected-name">{gettext.getString("Название, которое увидят сотрудники")}</label>
                <div className={"react-attachment_fake-block_input"} style={{
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <span style={{ minWidth: '1px' }}
                        className={"react-attachment_span-fake"}
                        ref={spanRef}>
                        {attachmentFakeName}
                    </span>
                    <input
                        ref={inputRef}
                        type="text"
                        data-testid={"attachment-selected-name"}
                        className={`react-attachment_input ${checkLength || checkLengthArchive ? "react-attachment_input-edit" : ""}`}
                        style={{ width: inputWidth, boxSizing: "content-box", padding: "8px 0 8px 12px", marginLeft: '2px', maxWidth: '410px' }}
                        value={attachmentFakeName}
                        onChange={(e) => {
                            setFileName(e.target.value)
                        }}
                        id={'attachment-selected-name'}
                    />
                    {!attachmentFakeName &&
                        <span style={{ opacity: '0.8', lineHeight: '40px' }}>
                            {gettext.getString("Название, которое увидят сотрудники")}
                        </span>}
                    {attachmentFakeName &&
                        <>
                            <span className={"react-attachment_type"}>.</span>
                            <span data-testid={"attachment-selected-type"} className={"react-attachment_type"}>
                                {fileType}
                            </span>
                        </>
                    }
                    { (
                        <div
                            className={"react-cross_black react-attachment_btn-clear"}
                            data-testid={"attachment-remove"}
                            onClick={removeFakeName}
                        ></div>
                    )}

                </div>

            </div>
            {checkLength && (
                <span className="react-attachment_error-message">
                    {gettext.getString("Сократите название до 31 символа. Это ограничение для файла ISO")}
                </span>
            )}
            {isoWarning && !checkLength && (
                <span className="react-attachment_warning">
                    {gettext.getString("В названии не более 31 символа")}
                </span>
            )}
            {checkLengthArchive && (
                <span className="react-attachment_error-message">
                    {gettext.getString("Сократите название до 64 символов")}
                </span>
            )}
            {!isoWarning && !checkLengthArchive && (
                <span className="react-attachment_warning">
                    {gettext.getString("В названии не более 64 символов")}
                </span>
            )}

        </>
    );
}

export default AttachmentDropdown;
