import loginHeaderTemplate from './view.html';

import {LoginHeaderController} from './controller';

const LoginHeaderComponent = {
    template: loginHeaderTemplate,
    controller: LoginHeaderController,
    controllerAs: '$',
    bindings: {
        projectName: '<',
        projectUrl: '<',
        logoMode: '<'
    },
};

export {
    LoginHeaderComponent
};
