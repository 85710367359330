import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FetchApi from "../React/REST";

const initialState = {
    ldapProfiles: [],
    error: false,
    loading: false,
};

export const fetchLdapProfiles = createAsyncThunk("ldapProfiles/status", async () => {
    const api = FetchApi("/ldap-profiles");
    let response = await api.get();
    return response;
});

const ldapProfilesSlice = createSlice({
    name: "ldapProfiles",
    initialState,
    reducers: {
        setLdapProfiles(state, { payload }) {
            state.ldapProfiles = payload;
        },
    },
    extraReducers: {
        [fetchLdapProfiles.pending]: (state) => {
            state.loading = true;
            state = initialState;
        },
        [fetchLdapProfiles.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.error = false;
            (state.errorText = ""), (state.ldapProfiles = payload);
        },
        [fetchLdapProfiles.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = true;
            state.errorText = payload;
        },
    },
});

export const { setLdapProfiles } = ldapProfilesSlice.actions;
export default ldapProfilesSlice.reducer;
