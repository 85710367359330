import React, {useState, useEffect, useContext} from 'react'
import { GettextContext } from '../../context';

function ConfirmPopup({setCancel, name}) {
    const [status, setStatus] = useState('delete')
    const gettext = useContext(GettextContext);

    const getLoader = () => {
        return (<div className={'react-loader_ico'}>
            <svg data-testid={'delete'} className={'react-loader_icon react-icon_delete'}>
                <use xlinkHref={`/image/icoSprite.svg#delete`}></use>
            </svg>
        </div>)
    }

    const getSuccess = () => {
        return (<div className={'react-loader_ico'}>
            <svg data-testid={'success'} className={'react-loader_icon react-icon_success'}>
                <use xlinkHref={`/image/icoSprite.svg#success`}></use>
            </svg>
        </div>)
    }

    const handleCancel = () => {
        setCancel()
    }

    useEffect(() => {
        setTimeout(() => {
            setStatus('success')
        }, 8000);
    }, [])

    return (
            <div className={'confirm-popup'}>
                <div className={'confirm-popup_delete'}>
                    <div className={`react-loader ${status === 'delete' ? 'react-loader_animate' : ''}`}>
                        {status === 'delete' && <div className={'react-loader_indicator'}></div>}
                        <div className={'react-loader_content'}>
                            { status === 'delete' ? getLoader() :  getSuccess() }
                        </div>
                    </div>
                </div>
                <div className={'confirm-popup_text'}>{gettext.getString('Вы удалили новый тип письма {{name}}', {name})}</div>
                {status === 'delete' && (
                    <div className={'confirm-popup_return'}
                         onClick={handleCancel}>
                        <span>{gettext.getString('Вернуть')}</span>
                    </div>
                )}
            </div>
    )
}

export default ConfirmPopup
