import React, {useContext} from 'react'
import { GettextContext, InjectorContext } from 'rootReact/context'
import { useSelector, useDispatch } from 'react-redux'
import { CertsModal } from 'root/assets/webpack/src/js/components/certModal'
import { deleteCerts } from 'root/assets/webpack/src/js/toolkit/think'
import SettingsHeader from 'rootReact/components/SettingsSectionHeader'
import SettingsTable from 'rootReact/components/SettingsTable'
import { certsSchema } from './SettingsCertSchema.jsx'

function SettingsCert({licenseIsValid}) {
    const gettext = useContext(GettextContext)
    const injector = useContext(InjectorContext)
    const certs = useSelector(state => state.certificate.certs)
    const dispatch = useDispatch()

    const openModal = (id = 0) => {
        let modal = injector.instantiate(CertsModal)
        modal.open(id)
    }

    const handleRow = (id) => {
        openModal(id)
    }

    const iconDelete = (e, id) => {
        e.stopPropagation()
        dispatch(deleteCerts(id))
    }

    return (
        <div>
            <SettingsHeader title={gettext.getString('Сертификаты')} right />
            <div className={'settings_item'}>
                <SettingsTable header={[
                    {name: gettext.getString('Название')},
                    {name: ''}
                ]}
                body={certs}
                assets={{empty: gettext.getString('Нет сертификатов')}}
                cellSchema={
                    certsSchema({
                        iconHandle:[iconDelete],
                        handleRow,
                        disabled: !licenseIsValid
                    })}
                ></SettingsTable>
            </div>
        </div>
    )
}

export default SettingsCert
