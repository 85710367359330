import style from './style.modules.css';
import moment from 'moment';
import {fetchMotivationHistory} from '../../toolkit/think';
import {motivationHistoryDefault} from '../../toolkit/actions';

class MotivationComponentController {
    constructor ($ngRedux, gettextCatalog, $cookies) {
        this.style = style;
        this.gettextCatalog = gettextCatalog;
        this.question1 = null;
        this.question2 = null;
        this.question3 = null;
        this.openSecond = false;
        this.openThird = false;
        this.value = 'D';
        this.panelOpen = false;
        this.moment = moment;
        this.moment.updateLocale('ru', {
            longDateFormat : {
                LL: 'DD MMMM YYYY',
            }
        });
        this.langCode = $cookies.get('langCode');
        this.motivationValue = {
            D: gettextCatalog.getString('Низкий'),
            C: gettextCatalog.getString('Средний'),
            B: gettextCatalog.getString('Хороший'),
            A: gettextCatalog.getString('Отличный')
        };
        this.unsubscribe = $ngRedux.connect(null, {fetchMotivationHistory, motivationHistoryDefault})(this);
        this.strings = {
            noTested: gettextCatalog.getString('не определен')
        };
    }

    $onInit () {
        this.fetchMotivationHistory();
    }

    onFirstQuestion = () => {
        if (this.question1 !== null) {
            this.openSecond = true;
        }
        this.setValue();
    }

    onSecondQuestion = () => {
        if (this.question2 !== null) {
            this.openThird = true;
        }
        this.setValue();
    }

    onThirdQuestion = () => {
        if (this.question3 !== null) {
            this.panelOpen = true;
        }
        this.setValue();
    }

    stashQuestion = () => {
        this.question1 = null;
        this.question2 = null;
        this.question3 = null;
        this.openSecond = false;
        this.openThird = false;
        this.panelOpen = false;

        this.fetchMotivationHistory();
    }

    setValue = () => {
        const answers = [this.question1, this.question2, this.question3];
        const answerLength = answers.filter(ans => ans === true).length;
        if (!answerLength) {
            this.value = 'D';
            return;
        }
        if (answerLength === 1) {
            this.value = 'C';
            return;
        }
        if (answerLength === 2) {
            this.value = 'B';
            return;
        }
        if (answerLength === 3) {
            this.value = 'A';
            return;
        }
    }

    onSaveClick = () => {
        this.setAuthMotivation(this.value);
        this.stashQuestion();
        this.fetchMotivationHistory();
    }

    $onDestroy() {
        this.motivationHistoryDefault();
        this.unsubscribe();
    }
}

MotivationComponentController.$inject = [
    '$ngRedux',
    'gettextCatalog',
    '$cookies'
];

export {
    MotivationComponentController
};
