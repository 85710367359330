import {CAMPAIGNS_SERVICE} from '../../const';
import style from './style.modules.css';
import {setCampaignsRestoreData} from '../../toolkit/actions';

class CampaignDeleteModalController {
    constructor(CampaignsService, campaign, $ngRedux) {
        this.CampaignsService = CampaignsService;
        this.campaign = campaign;
        this.style = style;
        this.deleteStatistics = false;
        this.unsubscribe = $ngRedux.connect(null, {setCampaignsRestoreData})(this);
    }

    delete = () => {
        if (this.campaign) {
            this.deleteCampaignError = false;
            this.deleteCampaignLoading = true;
            this.CampaignsService.deleteCampaign(this.campaign.id, {deleteStatistics: this.deleteStatistics ? 1 : 0}, this, (ctrl) => {
                ctrl.$close(ctrl.campaign);
                this.deleteCampaignLoading = false;
                this.setCampaignsRestoreData(this.CampaignsService.restoreInfo());
            }, (ctrl) => {
                this.deleteCampaignLoading = false;
                ctrl.deleteCampaignError = true;
            });
        }
    };
}

CampaignDeleteModalController.$inject = [
    CAMPAIGNS_SERVICE,
    'campaign',
    '$ngRedux'
];

export {
    CampaignDeleteModalController,
};
