import {LEARNING_SERVICE} from '../../const';
import angular from 'angular';
import {hasAccess} from '../../reselect';
import style from './style.modules.css';

class CoursesModalController {
    constructor(LearningService, learningConfig, $ngRedux) {
        this.learningConfig = angular.copy(learningConfig);
        this.learningConfig.notifyNewCourses = 1;
        this.LearningService = LearningService;
        this.style = style;
        if (this.learningConfig && this.learningConfig.courses && this.learningConfig.courses.length > 0) {
            this.currentCourses = this.learningConfig.courses;
            this.loadNewCourses();
        } else {
            this.loadCurrentCourses();
        }
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis)(this);
    }

    mapStateToThis(state) {
        return {
            accessCoursesSave: hasAccess(state, {sectionId: 8, rightName: 'course_save'}),
            accessCoursesDelete: hasAccess(state, {sectionId: 8, rightName: 'course_delete'}),
            accessCoursesReport: hasAccess(state, {sectionId: 8, rightName: 'report_education'}),
        };
    }

    loadCurrentCourses = () => {
        this.loadingCurrentCourses = true;
        this.errorCurrentCourses = false;
        this.LearningService.courses().$promise.then((currentCourses) => {
            this.errorCurrentCourses = false;
            this.loadingCurrentCourses = false;
            this.currentCourses = currentCourses;
            this.loadNewCourses();
        }, () => {
            this.loadingCurrentCourses = false;
            this.errorCurrentCourses = true;
        });
    };

    loadNewCourses = () => {
        this.loadingNewCourses = true;
        this.errorNewCourses = false;
        this.LearningService.courses(this.learningConfig, this,
            (ctrl, response) => {
                ctrl.errorNewCourses = false;
                ctrl.loadingNewCourses = false;
                ctrl.newCourses = response;
                angular.forEach(ctrl.newCourses, (newCourse) => {
                    let course = ctrl.currentCourses.find((currentCourse) => {
                        return currentCourse.name == newCourse.name;
                    });
                    if (course) {
                        course.newId = newCourse.id;
                        course.newName = newCourse.name;
                    }
                });
            }, (ctrl) => {
                ctrl.loadingNewCourses = false;
                ctrl.errorNewCourses = true;
            });
    };

    courseSelected = (course, currentCourseKey) => {
        if (course && this.currentCourses[currentCourseKey]) {
            if (course.id > 0) {
                this.currentCourses[currentCourseKey].lastNewName = course.name ? course.name : course;
            } else {
                this.currentCourses[currentCourseKey].newName = '';
            }
        }
    };

    courseUnfocused = (currentCourseKey) => {
        if (this.currentCourses[currentCourseKey] && !this.currentCourses[currentCourseKey].newName) {
            this.currentCourses[currentCourseKey].newName = this.currentCourses[currentCourseKey].lastNewName ? this.currentCourses[currentCourseKey].lastNewName : null;
        }
    };

    clearCourse = (currentCourseKey) => {
        if (this.currentCourses[currentCourseKey]) {
            this.currentCourses[currentCourseKey].newName = null;
            this.currentCourses[currentCourseKey].lastNewName = null;
        }
    };

    setForm = (form) => {
        this.form = form;
    };

    saveConfig = () => {
        this.errorText = null;
        this.form.$setSubmitted();
        if (this.form.$valid) {
            let courses = [];
            angular.forEach(this.currentCourses, (course) => {
                course.newName = course.newName && course.newName.name ? course.newName.name : course.newName;
                let newCourse = this.newCourses.find((nc) => {
                    return nc.name == course.newName;
                });
                if (newCourse && newCourse.id) {
                    courses.push({
                        id: course.id,
                        name: course.name,
                        newId: newCourse.id,
                        newName: newCourse.name,
                        lastNewName: newCourse.name,
                    });
                } else {
                    courses.push({id: course.id, name: course.name});
                }
            });
            this.learningConfig.coursesUpdated = true;
            this.learningConfig.courses = courses;
            this.$close(this.learningConfig);
        }
    };
}

CoursesModalController.$inject = [
    LEARNING_SERVICE,
    'learningConfig',
    '$ngRedux',
];

export {
    CoursesModalController,
};
