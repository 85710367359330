import React, {useContext} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import { GettextContext, InjectorContext } from 'rootReact/context'
import { MailServerModal } from 'root/assets/webpack/src/js/components/mailServerModal'
import { deleteAccount } from 'root/assets/webpack/src/js/toolkit/accountUsers'
import SettingsHeader from 'rootReact/components/SettingsSectionHeader'
import SettingsTable from 'rootReact/components/SettingsTable'
import { mailTemplateSchema } from './SettingsMailTemplateSchema.jsx'

function SettingsMailTemplate({licenseIsValid}) {
    const gettext = useContext(GettextContext)
    const injector = useContext(InjectorContext)
    const mailServers = useSelector(state => state.settings.settings.mailServers)
    const dispatch = useDispatch()

    const getHeaderLink = () => {
        return (
            <div>
                <a onClick={() => licenseIsValid && openModal()} className={`settings_path-right ${!licenseIsValid ? 'nolink' : ''}`}>{gettext.getString('добавить')}</a>
            </div>
        )
    }

    const openModal = (mailService, id = 0) => {
        let modal = injector.instantiate(MailServerModal)
        modal.open(id)
    }

    const handleRow = (id) => {
        openModal(id)
    }

    const iconDelete = (e, id) => {
        e.stopPropagation()
        dispatch(deleteAccount(id))
    }

    const edit = (e, id) => {
        e.stopPropagation()
        console.log(id)
    }

    return (
            <div>
                <SettingsHeader title={gettext.getString('Шаблоны из электронной почты')} right>{getHeaderLink()}</SettingsHeader>
                <div className={'settings_item'}>
                    <SettingsTable header={[
                        {name: gettext.getString('Название')},
                        {name: gettext.getString('Адрес сервера')},
                        {name: gettext.getString('Пользователь')},
                        {name: ''}
                    ]}
                                   body={mailServers}
                                   assets={{empty: gettext.getString('Нет серверов с шаблонами')}}
                                   cellSchema={
                                       mailTemplateSchema({
                                           iconHandle:[edit, iconDelete],
                                           handleRow,
                                           disabled: !licenseIsValid
                                       })}
                    ></SettingsTable>
                </div>
            </div>
    )
}

export default SettingsMailTemplate;
