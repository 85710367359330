import {createSelector} from 'reselect';

const systemAlert = (state) => {
    return state.auth.auth.systemAlerts;
};

const language = state => {
    return state.languages.languages;
};

const ldapProfiles = state => {
    return state.auth.auth.license.features.manageLdap;
};

const ssoProfiles = state => {
    return state.ssoProfile.ssoProfiles;
};

const getNotExtensibleData = (data) => {
    return data.map((item) => {
        let newObj = {};
        for (let key in item) {
            newObj[key] = item[key];
        }
        return newObj;
    });
};

export const getSystemAlert = createSelector([systemAlert], systemAlert => {
    if (!systemAlert) return [];
    let returnedValue = getNotExtensibleData(systemAlert);
    return returnedValue;
});

export const getLanguage = createSelector([language], (language) => {
    if (!language.length) return [];
    let returnedValue = getNotExtensibleData(language);
    return returnedValue;
});

export const getLdapProfile = createSelector([ldapProfiles], (ldapProfiles) => {
    if (!ldapProfiles.length) return [];
    let returnedValue = getNotExtensibleData(ldapProfiles);
    return returnedValue;
});

export const getSSOProfile = createSelector([ssoProfiles], (ssoProfiles) => {
    if (!ssoProfiles.length) return [];
    let returnedValue = getNotExtensibleData(ssoProfiles);
    return returnedValue;
});
