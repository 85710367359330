import React from 'react';

function SelectLanguage(props) {
    const {onSelect, viewValue, listValues} = props
    const getList = () => {
        let values = Object.entries(listValues)
        return values.map(item => <div key={item[0]} className={'select-language_item'} onClick={() => onSelect(item[0])}>{<span className={`select-language_flag select-language_${item[0]}`}></span>}{item[1]}</div>)
    }

    return (
            <div className={'select-language'}>
                <a className={'select-language_value'}><span className={`select-language_flag select-language_${viewValue}`}></span> {listValues[viewValue]}</a>
                <div className={'select-language_options'}>
                    {getList()}
                </div>
            </div>
    );
}

export default SelectLanguage;
