import campaignModalSenderAddressTemplate from './view.html';

import {CampaignModalSenderAddressController} from './controller';

const CampaignModalSenderAddressComponent = {
    template: campaignModalSenderAddressTemplate,
    controller: CampaignModalSenderAddressController,
    controllerAs: '$',
    bindings: {
        ctrl: '<',
        campaign: '<',
        form: '<',
        senderDomains: '=',
        userInfo: '<',
        withoutState: '<',
        templateErrors: '<',
        disabled: '<',
        fieldChanged: '=',
        isNotification: '<'
    },
};

export {
    CampaignModalSenderAddressComponent,
};
