import employeesImportFromFileTemplate from './view.html';

import {EmployeesImportFromFileController} from './controller';

const EmployeesImportFromFileComponent = {
    template: employeesImportFromFileTemplate,
    controller: EmployeesImportFromFileController,
    controllerAs: '$',
    scope: true,
    bindings: {
        updateParentProcess: '=',
        userInfo: '<',
        modalClose: '=',
        loadDashboard: '='
    },
};

export {
    EmployeesImportFromFileComponent,
};
