import {createSelector} from 'reselect';

const templates = (state) => {
    return state.templates.templates;
};

const courses = (state) => {
    return state.courses.courses;
};

const types = (state) => {
    return state.types;
};

export const notExtensibleTemplate = createSelector([templates], templates => {
    let returnedValue = templates.map((item) => {
        let newObj = {};
        for (let key in item) {
            newObj[key] = item[key];
        }
        return newObj;
    });
    returnedValue.unshift({
        id: 0,
        name: 'Случайный шаблон'
    });
    return returnedValue;
});

export const notExtensibleCourses = createSelector([courses], courses => {
    return courses.map((item) => {
        let newObj = {};
        for (let key in item) {
            newObj[key] = item[key];
        }
        return newObj;
    });
});

export const notExtensibleTrigger = createSelector([types], types => {
    return types.triggerTypes.map((item) => {
        let newObj = {};
        for (let key in item) {
            newObj[key] = item[key];
        }
        return newObj;
    });
});

export const notExtensibleReport = createSelector([types], types => {
    return types.reportTypes.map((item) => {
        let newObj = {};
        for (let key in item) {
            newObj[key] = item[key];
        }
        return newObj;
    });
});

export const notExtensibleAction = createSelector([types], types => {
    return types.actionTypes.map((item) => {
        let newObj = {};
        for (let key in item) {
            newObj[key] = item[key];
        }
        return newObj;
    });
});
