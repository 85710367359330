import { useState, useEffect } from 'react'

export const useAutoWidth = (num, values) => {
    const [value, setValue] = useState(num)

    const setWidth = () => {
        setValue((Math.max(num.toString().length, values.minChars) * 7.8 + 60 - 15.6) + 'px');
    }

    useEffect(() => {
        setWidth()
    }, [num])

    return value;
}
