import attackTemplate from './view.html';

import {AttackGraphController} from './controller';

const AttackGraphComponent = {
    template: attackTemplate,
    controller: AttackGraphController,
    controllerAs: '$',
    scope: true,
    bindings: {
        attackHistory: '<',
        targetsInSystem: '<',
        noCampaign: '<',
        openCampaign: '<',
        linkCampaign: '<',
        attachmentCampaign: '<',
        completeCampaign: '<',
        formCampaign: '<'
    },
};

export {
    AttackGraphComponent,
};
