import { GROUPS_SERVICE } from '../const';


class EntityService {
    constructor($resource, GroupsService) {
        this.service = null;
        return {
            bulkDelete: (data, ctrl, success, error) => {
                error(ctrl, {
                    'data': {'error': 'the service is not delegated'}
                });
            },
            delete: (data, ctrl, success, error) => {
                if (data.type === 'group') {
                    this.service =  GroupsService;
                }
                if (!this.service) {
                    error(ctrl, {
                        'data': {'error': 'the service is not delegated'}
                    });
                    return;
                }
                return this.service.delete(data, ctrl, success, error);
            },
        };
    }
}

EntityService.$inject = [
    '$resource',
    GROUPS_SERVICE
];

export {
    EntityService
};
