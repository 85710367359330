import dashboardAttack from './view.html';

import {DashboardAttack} from './controller';

const DashboardAttackComponent = {
    template: dashboardAttack,
    controller: DashboardAttack,
    controllerAs: '$',
    bindings: {
        targetsOnAttack: '<',
        targetsInSystem: '<',
        month: '<',
        companyTargetCount: '<',
        attackError: '<',
        attackErrorMessage: '<',
        noInAttack: '<',
        addEmployee: '=',
        startCampaign: '='
    },
};

export {
    DashboardAttackComponent
};
