import React, {useState, useEffect, useContext} from 'react'
import { GettextContext } from 'rootReact/context'
import { usePlural } from '../../hook'

const ViewDomains = 5

function SettingLicenseDomain({companyName, initDomains, licenseIsValid}) {
    const gettext = useContext(GettextContext)
    const [domains, setDomains] = useState('')
    const [domainCount, setDomainCount] = useState(ViewDomains)

    const pluralDomains = usePlural(initDomains.length - ViewDomains,
            [gettext.getString(`{{count}} домен`, {count: initDomains.length - ViewDomains}),
                gettext.getString(`{{count}} домена`, {count: initDomains.length - ViewDomains}),
                gettext.getString(`{{count}} доменов`, {count: initDomains.length - ViewDomains})
            ])
    const [link, setLink] = useState(`${gettext.getString('и еще')} ${pluralDomains}`)

    const isShowLink = initDomains.length > ViewDomains

    useEffect(() => {
        if (initDomains.length) {
            let compile = initDomains.reduce((prevValue, currentValue, i)=>{
                if (i===domainCount) return prevValue
                return `${prevValue}${i ? ', ':''} ${currentValue}`
            }, '/ ')
            setDomains(compile)
        }
    }, [domainCount])

    const showDomains = () => {
        if (ViewDomains < domainCount) {
            setLink(`${gettext.getString('и еще')} ${pluralDomains}`)
            setDomainCount(ViewDomains)
            return
        }
        const count = initDomains.length
        setLink('Свернуть')
        setDomainCount(count+1)
    }

    return (
        <div className={'settings_item'}>
            <span>{companyName}&nbsp;{domains} {isShowLink ? <a className={!licenseIsValid ? 'nolink' : ''} onClick={() => showDomains()}>{link}</a> : ''}</span>
        </div>
    )
}

export default SettingLicenseDomain
