import {Controller} from './controller';
import { hasAccess } from '../reselect';
// import angular from 'angular';
import {setAuthProps} from '../toolkit/actions';

class DashboardGraph extends Controller {

    constructor($scope, gettextCatalog, $ngRedux) {
        super();
        this.$scope = $scope;
        this.gettextCatalog = gettextCatalog;
        this.load = true;
        this.loadMotivation = false;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, {setAuthProps})(this);
    }

    mapStateToThis = (state) => {
        return {
            targetHistory: state.targetHistory.targetHistory,
            selectionTotalCount: state.targetHistory.targetHistory.totalTargets,
            load: state.targetHistory.load,
            selectedData: state.selectedData,
            educationHistory: state.educationHistory.educationHistory,
            educationLoad: state.educationHistory.load,
            dashboardState: state.dashboardState.state,
            motivation: state.auth.auth.lastMotivation,
            attackHistory: state.attackHistory.attackHistory,
            attackLoad: state.attackHistory.load,
            attackError: state.attackHistory.isError,
            attackErrorMessage: state.attackHistory.error,
            targetsInSystem: state.auth.auth.targetCount,
            companyCount: state.auth.auth.companyTargetCount,
            licenseCount: state.auth.auth.license.employeeCount,
            dashboard: state.dashboard.info,
            motivationTestAccess: hasAccess(state, { sectionId: 14, rightName: 'statistics_motivation' }),
        };
    };

    $onInit() {}

    setAuthMotivation = (motivation) => {
        this.loadMotivation = true;
        let body = {
            motivation: motivation
        };
        let options = {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Enable-Session': 1,
                'X-Csrf-Token': window.csrfToken

            },
        };
        fetch(window.config.SYSTEM_URL + window.config.API_URL + '/statistics/motivation', options).then(responese => {
            return responese.json();
        }).then(()=>{
            this.setAuthProps(
                {
                    motivation: {
                        value: motivation,
                        updated_at: Date.now()
                    },
                    lastMotivation: {
                        motivation: motivation,
                        updatedAt: Date.now()
                    }
                }
            );
            this.loadMotivation = false;
        });
    }
}

DashboardGraph.$inject = [
    '$scope',
    'gettextCatalog',
    '$ngRedux'
];

export {
    DashboardGraph
};
