import highlightTemplate from './view.html';

import {HighlightController} from './controller';

const HighlightComponent = {
    template: highlightTemplate,
    controller: HighlightController,
    controllerAs: '$',
    scope: true,
    bindings: {
        text: '<',
        search: '<',
        responsiveFullName: '<',
        isLink: '<',
    },
};

export {
    HighlightComponent,
};
