import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { setOneNotification, updateOneNotification, setListNotification } from "../../../toolkit/actions";
import { fetchListNotification } from 'root/assets/webpack/src/js/toolkit/think';
import { GettextContext } from "rootReact/context";
import FetchApi from "../../REST";
import LanguageSelector from "rootReact/components/LanguageSelector/LanguageSelector.jsx";
import ModalHeader from "../ModalHeader";
import ModalFooter from "../ModalFooter";
import img from "../../../../images/notification.svg";
import loader from "../../../../images/loading.svg";
import FaIcon from "rootReact/components/FaIcon";
import { useInput } from "./useValidForm.jsx";
import Locale from "./Locale.jsx";
import { NOTIFICATIONS_TYPE } from "../../../const.js";
import { checkDefaultLanguage } from 'root/assets/webpack/src/js/helpers/content';
import { hasAccess } from 'root/assets/webpack/src/js/reselect';

function NotificationModal({ id, onModalClose, uuid, isCopy }) {
    const gettext = useContext(GettextContext);
    const dispatch = useDispatch();
    const [formError, setFormError] = useState("");
    const [emptyError, setEmptyError] = useState(false);
    const [localeEmptyError, setLocaleEmptyError] = useState(false);
    const [save, setSave] = useState(false);
    const [onClickSave, setOnClickSave] = useState(false);
    const [saveDone, setSaveDone] = useState(false);
    const [loading, setLoading] = useState(false);
    const [domainList, setDomainList] = useState([]);
    const [courseIds, setCourse] = useState([]);
    const [type, setType] = useState(null);
    const [status, setStatus] = useState(0);
    const editAccess = useSelector(state => hasAccess(state, {sectionId: 7, rightName: 'notification_save'}))
    const coursesList = useSelector((state) => state.courses.courses);
    const languages = useSelector((state) => state.languages.languages);
    const [activeLanguage, setActiveLanguage] = useState(languages[0].id);
    const title = useInput("", { isEmpty: true });
    const [locales, setLocales] = useState([]);
    const [isChanged, setIsChanged] = useState(false);
    const [prefix] = useState(` — ${gettext.getString('копия')}`)
    const multi = true;

    useEffect(() => {
        async function fetchDomains () {
            setLoading(true);
            let domains = await FetchApi('/sender-domains').get();
            setDomainList(domains.map(item => ({name: item.name})))
        }
        async function fetchApi() {
            let response;
            if (id) {
                response = await FetchApi("/notifications").get(id);
            } else if (uuid) {
                response = await FetchApi("/custom-notifications").get(uuid);
            }
            if ((response && response.isError) || !response) {
                throw response;
            }
            const langId = response.locales.find((local) => local.isDefault).languageId;
            setActiveLanguage(langId);
            setInitLocales(response.locales);
            title.onChange(isCopy ? response.name + prefix : response.name);
            setStatus(response.status);
            setType(response.type);
            setCourse(response.courses);
        }
        try {
            if (id || uuid) {
                fetchDomains().then(() => {
                    fetchApi().then(() => {
                        setLoading(false);
                    });
                })
            } else {
                fetchDomains().then(() => {
                    setInitLocales([]);
                    setLoading(false);
                })
            }
        } catch (e) {
            setFormError(e.err.message);
        }
    }, [id, uuid]);

    const modalClose = () => {
        if ((isChanged || title.isDirty) && !confirm(gettext.getString('Вы действительно хотите отменить изменения?'))) return;
        onModalClose();
    }

    const setInitLocales = (locales) => {
        let initLocales = [];
        languages.forEach((langItem, i) => {
            let locale = locales.find((item) => item.languageId === langItem.id);
            if (locale) {
                initLocales.push(locale);
            } else {
                initLocales.push({
                    domain: "",
                    from: "",
                    fromName: "",
                    isDefault: locales && locales.length ? false : i ? false : true,
                    subject: "",
                    text: "",
                    languageId: langItem.id,
                });
            }
        });
        setLocales(initLocales);
    };

    const setDefaultLanguage = (id) => {
        setLocales(locales.map(item => {
            if (item.languageId !== id) {
                item.isDefault = 0;
            }
            return item;
        }));
    }

    const setRequestBody = () => {
        let validLocales = locales.filter((item) => isValidLocale(item))
        let defaultLangId = checkDefaultLanguage(validLocales, languages).id;
        let body = {
            name: title.value,
            type,
            courses: courseIds,
            status,
            locales: validLocales.map(item => {
                if (item.languageId === defaultLangId) {
                    item.isDefault = 1;
                } else {
                    item.isDefault = 0;
                }
                return item;
            }),
        };
        return body;
    };

    const isValidLocale = (locale) => {
        let valid = true;
        for (let key in locale) {
            if (key !== "isDefault" && !locale[key]) {
                valid = false;
            }
        }
        return valid;
    };

    const saveSettings = async () => {
        setOnClickSave(true);
        if (!title.value.length || localeEmptyError) {
            setEmptyError(true);
            return;
        }
        try {
            setSave(true)
            setFormError("");
            let body = setRequestBody();
            const defaultApi = FetchApi("/notifications");
            const customApi = FetchApi("/custom-notifications");
            let response;
            if (id && !isCopy) {
                response = await defaultApi.put(id, {
                    body,
                });
                if (response && response.isError) {
                    throw response;
                }
                if (response) {
                    dispatch(fetchListNotification());
                }
            } else if (uuid && !isCopy) {
                response = await customApi.put(uuid, {
                    body,
                });
                if (response && response.isError) {
                    throw response;
                }
                response.custom = true;
                dispatch(updateOneNotification(response));
            } else {
                response = await customApi.post({ body });
                if (response && response.isError) {
                    throw response;
                }
                response.custom = true;
                dispatch(setOneNotification(response));
            }
            setSave(false);
            setSaveDone(true);
            onModalClose(true);
        } catch (e) {
            setSave(false);
            if (e.err && e.err.length) {
                setFormError(e.err[0].message);
            } else {
                setFormError(e.err.error);
            }
        }
    };

    const optionsCourse = coursesList.map((item) => {
        const newItem = {};
        newItem.value = item.id;
        newItem.label = item.name;
        return newItem;
    });

    const optionsType = NOTIFICATIONS_TYPE.map((item) => {
        const newItem = {};
        newItem.value = item.type;
        newItem.label = gettext.getString(item.value);
        return newItem;
    });

    const getValueSelectCourse = () => {
        if (courseIds) {
            return multi
                ? optionsCourse.filter((item) => courseIds.indexOf(item.value) >= 0)
                : optionsCourse.find((item) => item.value === courseIds);
        } else {
            return multi ? [] : "";
        }
    };

    const getValueSelectType = () => {
        type ? optionsType.find((item) => item.value === type) : "";
    };

    const onChangeSelectCourse = (newValue) => {
        setIsChanged(true);
        setCourse(multi ? newValue.map((v) => v.value) : newValue.value);
    };

    const onChangeSelectType = (newValue) => {
        setIsChanged(true);
        setType(!newValue ? null : newValue.value);
    };

    const defaultValueType = type !== null ? optionsType.find((item) => item.value === type) : null;

    const setActiveLocale = (locale) => {
        setIsChanged(true);
        setLocales(
            locales.map((item) => {
                if (item.languageId === activeLanguage) {
                    return locale;
                } else {
                    return item;
                }
            })
        );
    };

    const updateLocaleEmptyError = (value) => {
        setLocaleEmptyError(value);
    };

    const getActiveLocale = () => {
        let locale = locales.find((item) => item.languageId === activeLanguage);
        if (!locale) return;
        return (
            <Locale
                setValue={setActiveLocale}
                key={activeLanguage}
                initValue={locale}
                editAccess={editAccess}
                setDefaultLanguage={setDefaultLanguage}
                activeLanguage={activeLanguage}
                updateEmptyError={updateLocaleEmptyError}
                domainList={domainList}
                save={onClickSave}
            />
        );
    };

    return (
        <>
            <ModalHeader
                style={{ borderBottom: "none" }}
                title={(id || uuid) ? title.value || gettext.getString("Редактирование уведомления") : title.value || gettext.getString("Новое уведомление")}
                closeModal={modalClose}
            />
            {loading ? (
                <img
                    style={{
                        width: "40px",
                        height: "40px",
                        display: "inline",
                        marginLeft: "48%",
                        marginTop: "5%",
                        marginBottom: "5%",
                    }}
                    src={loader}
                    alt=""
                />
            ) : (
                <>
                    <div className="react-notification-modal">
                        <div className="react-notification-modal_top">
                            <label
                                className={
                                    (emptyError && !title.value.length) ||  (title.isDirty && title.isEmpty)
                                        ? "react-notification-modal_label text-error"
                                        : "react-notification-modal_label"
                                }
                                htmlFor="courseIds"
                            >
                                {gettext.getString("Название")}
                            </label>
                            <input
                                onChange={(e) => title.onChange(e.target.value)}
                                onBlur={(e) => title.onBlur(e)}
                                value={title.value}
                                className={
                                    (emptyError && !title.value.length) || (title.isDirty && title.isEmpty)
                                        ? "react-notification-modal_input input-error"
                                        : "react-notification-modal_input"
                                }
                                autoComplete={"off"}
                                type="text"
                                id="courseIds"
                                disabled={!editAccess || (id && !isCopy ? true : false)}
                            />
                        </div>
                        <LanguageSelector
                            languages={languages}
                            activeId={activeLanguage}
                            onSelect={setActiveLanguage}
                        />
                        <hr className="react-notification-modal_hr" />
                        <img className="react-notification-modal_img1" src={img} alt="" />
                        <img className="react-notification-modal_img2" src={img} alt="" />
                        {getActiveLocale()}
                        {!id && (
                            <>
                                <div className="react-notification-modal_block">
                                    <label className="react-notification-modal_label">
                                        {gettext.getString("Действие")}
                                    </label>
                                    <Select
                                            classNamePrefix="react-select"
                                            onChange={onChangeSelectType}
                                            value={getValueSelectType()}
                                            options={optionsType}
                                            defaultValue={id || uuid ? defaultValueType : ""}
                                            isSearchable
                                            isClearable
                                            disabled={!editAccess}
                                            isDisabled={!editAccess}
                                            placeholder={gettext.getString("Не выбрано")}
                                    />
                                </div>
                                {type && type !== 'AuthLink' ? <div className="react-notification-modal_block">
                                    <label className="react-notification-modal_label">
                                        {gettext.getString("Курс")}
                                    </label>
                                    <Select
                                        classNamePrefix="react-select"
                                        isMulti={multi}
                                        onChange={onChangeSelectCourse}
                                        value={getValueSelectCourse()}
                                        disabled={!editAccess}
                                        isDisabled={!editAccess}
                                        options={optionsCourse}
                                        isSearchable={false}
                                        placeholder={gettext.getString("Не выбран")}
                                    />
                                </div> : ''}
                            </>
                        )}
                    </div>
                </>
            )}
            <div className="react-notification-modal_process">
                {formError && formError.length !== 0 ? <div className={'react-error-wrapper'}><FaIcon className="react-icon-error" type={"error"} text={formError} /></div> : ""}
                {save ? <FaIcon className="react-icon-error" type={"pending"} text={"Сохранение..."} /> : ""}
                {saveDone ? <FaIcon className="react-icon-error" type={"success"} text={"Изменения сохранены"} /> : ""}
                <ModalFooter
                    stylePosition={{ justifyContent: "flex-end" }}
                    styleBtnOk={{ backgroundColor: "#37397F" }}
                    titleBtnOk={gettext.getString("Сохранить")}
                    titleBtnClose={gettext.getString("Отмена")}
                    closeModal={() => modalClose()}
                    disabled={!editAccess ? true : save ? true : false}
                    onEnter={saveSettings}
                />
            </div>
        </>
    );
}

export default NotificationModal;
