import ssoProfileModalTemplate from './view.html';

import {SsoProfileModalController} from './controller';

class SsoProfileModal {
    constructor($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
        this.modalInstance = null;
    }

    open = (profile) => {
        this.modalInstance = this.$uibModal.open({
            template: ssoProfileModalTemplate,
            bindToController: true,
            controller: SsoProfileModalController,
            controllerAs: '$',
            size: '885',
            windowClass: 'center-modal',
            resolve: {
                profile
            },
            backdrop: 'static',
            keyboard: false,
        });

        return this.modalInstance.result;
    };
}

export {
    SsoProfileModal
};
