class StatusService {
    constructor(gettextCatalog, $filter) {
        this.gettextCatalog = gettextCatalog;
        this.$filter = $filter;
        this.statuses = {
            0: {
                descr: this.gettextCatalog.getString('не проверялся'),
            },
            1: {
                descr: this.gettextCatalog.getString('выдержал атаку'),
            },
            2: {
                descr: this.gettextCatalog.getString('открыл письмо'),
            },
            3: {
                descr: this.gettextCatalog.getString('перешел по ссылке'),
            },
            4: {
                descr: this.gettextCatalog.getString('на обучении'),
            },
            5: {
                descr: this.gettextCatalog.getString('прошел обучение'),
            },
            7: {
                descr: this.gettextCatalog.getString('не прошел обучение вовремя'),
            },
            8: {
                descr: this.gettextCatalog.getString('обнаружены уязвимости'),
            },
            9: {
                descr: this.gettextCatalog.getString('открыл вложение'),
            },
            10: {
                descr: this.gettextCatalog.getString('перешел по ссылке и открыл вложение'),
            },
            11: {
                descr: this.gettextCatalog.getString('ввел данные в форму'),
            },
            12: {
                descr: this.gettextCatalog.getString('в активной атаке'),
            },
            15: {
                descr: this.gettextCatalog.getString('открыл файл по ссылке'),
            },
            17: {
                descr: this.gettextCatalog.getString('перешел по ссылке в QR'),
            },
            '-4': {
                descr: this.gettextCatalog.getString('не обучался'),
            },
            '-14': {
                descr: this.gettextCatalog.getString('обучение отменено'),
            },
            '-16': {
                descr: this.gettextCatalog.getString('новый сотрудник'),
            },
            '-18': {
                descr: this.gettextCatalog.getString('Скачал вложение по ссылке'),
            },
        };
    }

    getInfo = (id = 0) => {
        return this.statuses[id];
    };

    getEducationInfo = (id = 0) => {
        id = (id === null) ? 0 : id;
        if (id == 0) {
            return this.gettextCatalog.getString('не обучался');
        }
        let status = this.statuses[id].descr;
        return status;
    };

    getEducationAdditionalInfo = (id = 0, till = null, score = 0, scoreTest = 0) => {
        id = (id === null) ? 0 : id;
        if (id > 0) {
            let subStatus = '';
            if (till && id != 5) {
                subStatus = subStatus + (subStatus ? ', ' : '') + this.gettextCatalog.getString('до {{date}}', {date: this.$filter('dateFormat')(till, 'd MMMM')});
            }
            if (id == 5) {
                if (score) {
                    subStatus = subStatus + (subStatus ? ', ' : '') + this.gettextCatalog.getString('{{score}} баллов', {score: score});
                }
                if (scoreTest) {
                    subStatus = subStatus + (subStatus ? ', ' : '') + this.gettextCatalog.getString('{{scoreTest}}% за тест', {scoreTest: scoreTest});
                }
            }
            if (subStatus) {
                subStatus = ', ' + subStatus;
            }
            return subStatus;
        }
    };

    getFullInfo = (id = 0, vuln = false) => {
        let result = this.statuses[id].descr;
        if (vuln) {
            result += ' ' + this.gettextCatalog.getString('и имеет уязвимости');
        }
        return result;
    };

    getFullInfoBySituation = (situation) => {
        let result = '';
        if (situation) {
            if (this.statuses[situation.status]) {
                result += this.statuses[situation.status].descr;
                if (situation.hasVulnerability) {
                    if (situation.reportMail) {
                        result += ', ' + this.gettextCatalog.getString('сообщил об атаке');
                    }
                    result += ' ' + this.gettextCatalog.getString('и имеет уязвимости');
                } else if (situation.reportMail) {
                    result += ' ' + this.gettextCatalog.getString('и сообщил об атаке');
                }
            }
        }
        return result;
    };
}

export {
    StatusService,
};
