import React, { useState, forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, subDays } from 'date-fns';
import FetchApi from '../../REST';

function SaveFilePopup({ visible, fileSave, downloadFile }) {
    const [period, setPeriod] = useState(true);
    const [startDate, setStartDate] = useState(new Date());
    const [startDatePrev, setStartDatePrev] = useState(subDays(new Date(), 7));
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <button className='savefile-popup_date' onClick={onClick} ref={ref}>
            {format(new Date(value), 'yyyy-MM-dd')}
        </button>
    ));

    const saveFilePeriod = () => {
        let fileName = '';
        const reg = /\[.*?]/g;
        let name = (fileName = downloadFile[2]);
        let mask = name.match(reg)[0];
        if (mask === '[data]') {
            let dataPeriod = format(startDatePrev, 'yyyy-MM-dd') + '-' + format(startDate, 'yyyy-MM-dd');
            fileName = name.replace('[data]', '-' + dataPeriod);
        }
        const fetchApi = FetchApi(downloadFile[1]);
        const req = fetchApi.download(downloadFile[1], fileName, JSON.stringify({
            fromDate: format(startDatePrev, 'yyyy-MM-dd'),
            toDate: format(startDate, 'yyyy-MM-dd')
        }));
    };

    const onClickPeriod = () => {
        setPeriod((prev) => !prev);
    };

    return (
        <>
            {period ? (
                <div className={visible ? `savefile-popup` : 'savefile-popup popup-active'}>
                    <div className={`savefile-popup_content `}>
                        <div className={`savefile-popup_content-link`}>
                            <span>
                                журнал действий{' '}
                                <button onClick={onClickPeriod} className={`savefile-popup_period`}>
                                    за период
                                </button>{' '}
                                :
                            </span>
                            <span>
                                с{' '}
                                <div className={`savefile-popup_calendar-start`}>
                                    <DatePicker
                                        positionFixed='true'
                                        popperPlacement='top'
                                        selected={startDatePrev}
                                        onChange={(date) => setStartDatePrev(date)}
                                        customInput={<ExampleCustomInput />}
                                        maxDate={new Date()}
                                        minDate={subDays(new Date(), 31)}
                                    />
                                </div>
                                по{' '}
                                <div className={`savefile-popup_calendar-end`}>
                                    <DatePicker
                                        positionFixed='true'
                                        popperPlacement='top'
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        customInput={<ExampleCustomInput />}
                                        maxDate={new Date()}
                                        minDate={subDays(new Date(), 31)}
                                    />
                                </div>
                                .
                            </span>
                        </div>
                        <a onClick={saveFilePeriod} className={`savefile-popup_link`}>
                            Сохранить
                        </a>
                    </div>
                </div>
            ) : (
                <div className={visible ? `savefile-popup-allTime` : 'savefile-popup-allTime popup-active-allTime'}>
                    <div className={`savefile-popup_content `}>
                        <div className={`savefile-popup_content-link`}>
                            <span>
                                журнал действий{' '}
                                <button onClick={onClickPeriod} className={`savefile-popup_period`}>
                                    за все время
                                </button>{' '}
                                :
                            </span>
                        </div>
                        <a onClick={fileSave} className={`savefile-popup_link`}>
                            Сохранить
                        </a>
                    </div>
                </div>
            )}
        </>
    );
}

export default SaveFilePopup;
