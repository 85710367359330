export const coursesSchema = ({ handleRow, iconHandle, disabled}) => ({
    row: {handleRow, disabled: () => false},
    cell: [
        {
            text: 'name',
        }
    ],
    icon: [
        {
            isIcon: true,
            icon: disabled ? 'noIcon' :'delete',
            handle: [
                ...iconHandle
            ]
        }
    ]
});

