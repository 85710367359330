import setOptions from './setOptions';

export const daemonsCancel = async (id) => {
    let options = setOptions();
    try {
        let response = await fetch(window.config.SYSTEM_URL + window.config.API_URL + `/daemon/cancel?id=${id}`, options);
        if (!response.ok) {
            throw {status: 'error'};
        }
        return {status: 'success'};
    } catch (err) {
        return err;
    }
};
