import notificationErrorListTemplate from './view.html';

import {NotificationErrorListController} from './controller';

const NotificationErrorListComponent = {
    template: notificationErrorListTemplate,
    controller: NotificationErrorListController,
    controllerAs: '$',
    scope: true,
    bindings: {
        errors: '<',
    },
};

export {
    NotificationErrorListComponent,
};
