import searchFilterItemsTemplate from './view.html';

import {SearchFilterItemsController} from './controller';

const SearchFilterItemsComponent = {
    template: searchFilterItemsTemplate,
    controller: SearchFilterItemsController,
    controllerAs: '$',
    scope: true,
    bindings: {
        searchKey: '<',
        search: '=',
    },
};

export {
    SearchFilterItemsComponent,
};
