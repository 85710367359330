import {USB_SERVICE} from '../../const';
import {UsbAttackModal} from '../usbAttackModal';
import {UsbAttackDeleteModal} from '../usbAttackDeleteModal';
import {UsbAttackTokenModal} from '../usbAttackTokenModal';
import angular from 'angular';
import style from './style.modules.css';
import {fetchDashboardParams} from '../../toolkit/think';

class UsbAttackController {
    constructor($injector, $interval, UsbService, $ngRedux) {
        this.$injector = $injector;
        this.$interval = $interval;
        this.UsbService = UsbService;
        this.sender = {};
        this.style = style;
        this.targetsWidth = window.document.getElementById('attacks').offsetWidth;
        this.appCtrl = window.appCtrl;
        this.unsubscribe = $ngRedux.connect(null, {fetchDashboardParams})(this);
    }

    $onInit() {
    }

    getUsbToken = () => {
        this.shown = null;
        this.modal = this.$injector.instantiate(UsbAttackTokenModal);
        this.modal.open(this.attack).then(() => {
            if (this.campaignsCtrl) {
                this.campaignsCtrl.loadUsb(true);
            }
        }, () => {
            if (this.campaignsCtrl) {
                this.campaignsCtrl.loadUsb(true);
            }
        });
    };

    edit = () => {
        if (this.appCtrl && this.appCtrl.hasAccess(2, 'attack_save')) {
            this.modal = this.$injector.instantiate(UsbAttackModal);
            this.modal.open(this.attack.id).then((usbData) => {
                if (usbData) {
                    if (this.campaignsCtrl) {
                        if (this.campaignsCtrl.usbList) {
                            let usb = this.campaignsCtrl.usbList.$data.find((item) => {
                                return item.id == usbData.id;
                            });
                            if (usb) {
                                angular.extend(usb, usbData);
                            }
                        }
                    }
                }
            }, () => {
            });
        }
    };

    delete = () => {
        if (this.appCtrl && this.appCtrl.hasAccess(2, 'attack_delete')) {
            this.modal = this.$injector.instantiate(UsbAttackDeleteModal);
            this.modal.open(this.attack).then((attackData) => {
                if (attackData) {
                    this.attack.deleted = 1;
                    if (this.campaignsCtrl) {
                        this.campaignsCtrl.loadUsb(true);
                    }
                }
                this.fetchDashboardParams();
            }, () => {
            });
        }
    };

    start = () => {
        this.inProcess = true;
        this.errorText = false;
        this.UsbService.sender(this.attack.id, 'start', this, (ctrl, response) => {
            ctrl.inProcess = false;
            if (response && response.status) {
                ctrl.attack.status = response.status;
            }
        }, (ctrl, response) => {
            ctrl.inProcess = false;
            if (response && response.data) {
                ctrl.errorText = response.data.error;
            }
        });
    };

    stop = () => {
        this.inProcess = true;
        this.errorText = false;
        this.UsbService.sender(this.attack.id, 'stop', this, (ctrl, response) => {
            ctrl.inProcess = false;
            if (response && response.status == 0) {
                ctrl.attack.status = response.status;
            }
        }, (ctrl, response) => {
            ctrl.inProcess = false;
            if (response && response.data) {
                ctrl.errorText = response.data.error;
            }
        });
    };

    copy = () => {
        this.modal = this.$injector.instantiate(UsbAttackModal);
        this.modal.open(null, this.attack.id).then((attackData) => {
            if (attackData) {
                if (this.campaignsCtrl) {
                    this.campaignsCtrl.loadUsb();
                }
            }
        }, () => {
        });
    };

}

UsbAttackController.$inject = [
    '$injector',
    '$interval',
    USB_SERVICE,
    '$ngRedux'
];

export {
    UsbAttackController,
};
