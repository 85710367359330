// import angular from 'angular';
import style from './style.modules.css';
import moment from 'moment';
const dashboardChart = {
    scrollColor: '#FFFFFF',
    numVDivLines: '3',
    numDivLines: '2',
    outCnvBaseFont: 'sans-serif',
    outCnvBaseFontSize: '12',
    outCnvBaseFontColor: '#5a5a5a',
    labelDisplay: 'NONE',
    yAxisMaxValue: '100',
    yAxisMinValue: '0',
    showvalues: '0',
    showLegend: '0',
    numvisibleplot: '180',
    lineColor: '#f5a623',
    yAxisValueAlpha: '50',
    theme: 'gammel',
    formatNumber: '0',
    formatNumberScale: 0,
    legendPosition: 'bottom-left',
    legendNumRows: 1,
    scrollToEnd: 1,
    scrollHeight: 0,
    legendBgColor: '#ccc',
    drawAnchors: '0',
    showToolTip: 1,
    seriesNameInToolTip: 1,
    enableChartMouseMoveEvent: '1',
};
class PeopleGraphController {
    constructor(gettextCatalog, $scope, $cookies) {
        this.gettextCatalog = gettextCatalog;
        this.style = style;
        this.moment = moment;
        this.langCode = $cookies.get('langCode');
        this.moment.updateLocale('ru', {
            longDateFormat : {
                LL: 'DD MMMM YYYY года',
            }
        });
        this.scope = $scope;
        this.chart = dashboardChart;
        this.colors = [
            '#5CB75C',
            '#f5a623',
            '#DA5855'
        ];
        this.seriesname = [
            gettextCatalog.getString('Добавлено в систему'),
            gettextCatalog.getString('Разрешено в лицензии'),
            gettextCatalog.getString('Не покрыты лицензией'),
        ];
        this.month = [
            this.gettextCatalog.getString('Январь'),
            this.gettextCatalog.getString('Февраль'),
            this.gettextCatalog.getString('Март'),
            this.gettextCatalog.getString('Апрель'),
            this.gettextCatalog.getString('Май'),
            this.gettextCatalog.getString('Июнь'),
            this.gettextCatalog.getString('Июль'),
            this.gettextCatalog.getString('Август'),
            this.gettextCatalog.getString('Сентябрь'),
            this.gettextCatalog.getString('Октябрь'),
            this.gettextCatalog.getString('Ноябрь'),
            this.gettextCatalog.getString('Декабрь')
        ];
        this.myDataEducation = {};
    }

    $onInit = () => {
        const that = this;
        if (!this.targetHistory.length) {
            this.chart['yAxisMaxValue'] = '5';
            this.myDataEducation.chart = this.chart;
            this.myDataEducation.categories = [{ category: [''] }];
            this.myDataEducation.dataset = [{}];
            return;
        }
        this.chart['yAxisMaxValue'] = this.targetsInSystem > 5 ? this.targetsInSystem : 5;
        let monthCount = this.getMonthCount();
        let startMonth = new Date(this.targetHistory[0].date).getMonth();
        this.myDataEducation.chart = this.chart;
        this.myDataEducation.categories = [
            {category: this.setCategory(startMonth, monthCount)}
        ];
        this.myDataEducation.dataset = this.setDate();
        // eslint-disable-next-line
        FusionCharts.ready((FusionCharts) => {
            FusionCharts.addEventListener('chartRollOver', that.setEvent);
            FusionCharts.addEventListener('chartMouseMove', that.setEvent);
        });
    };

    getMonthCount = () => {
        if (!this.targetHistory || !this.targetHistory.length) return;
        return Math.floor((this.targetHistory[this.targetHistory.length - 1].date - this.targetHistory[0].date) /60/60/24/30);
    }

    getMonth = (n) => {
        let month = new Date(this.targetHistory[n].date).getMonth();
        return month;
    }

    setDate = () => {
        let dataset = [];
        let success = [];
        let licenseCount = [];
        let companyCount = [];
        for (let i = 0; i < this.targetHistory.length; i++) {
            success.push({
                displayValue: `${this.targetHistory[i].count}.${this.targetHistory[i].date}`,
                value: `${this.targetHistory[i].count}.${this.targetHistory[i].date}`
            });
            licenseCount.push({
                value: this.targetHistory[i].licenseCount - this.targetHistory[i].count < 0 ? 0 : this.targetHistory[i].licenseCount - this.targetHistory[i].count,
                displayValue: this.targetHistory[i].licenseCount
            });
            companyCount.push({
                value: this.targetHistory[i].companyCount - this.targetHistory[i].licenseCount,
                displayValue: this.targetHistory[i].companyCount - this.targetHistory[i].licenseCount
            });
        }
        dataset.push({
            seriesname: this.seriesname[0],
            color: this.colors[0],
            data: success
        });
        dataset.push({
            seriesname: this.seriesname[1],
            color: this.colors[1],
            data: licenseCount
        });
        dataset.push({
            seriesname: this.seriesname[2],
            color: this.colors[2],
            data: companyCount
        });
        return dataset;
    }

    setCategory = () => {
        let category = [];
        let choosed = [];
        for (let i = 0; i < this.targetHistory.length; i++) {
            let month = this.month[this.getMonth(i)];
            if (month === choosed[choosed.length - 1]) {
                category.push({
                    label: ''
                });
            } else {
                category.push({
                    label: month
                });
                choosed.push(month);
            }
        }
        return category;
    }

    setEvent = (event) => {
        const chart = event.sender;
        if (chart.args.type !== 'stackedarea2d') {
            return;
        }
        let reg = /[\d.]+/g;
        let children = event.data.container.children[0].children[2] ? event.data.container.children[0].children[2].children : null;
        let data = event.data.container.children[0].children[2] ? event.data.container.children[0].children[2].innerText : null;
        if (!data || children.length === 2) {
            return;
        }
        let arr = data.match(reg);
        const num = +arr[0];
        arr[0] = parseInt(arr[0]).toString();
        let date = num.toFixed(13).match(/\d+/g);
        let title = chart.ref.querySelector('.fc__tooltip');
        let div = document.createElement('section');
        div.classList.add('fc__tooltip_label');
        let ul = document.createElement('ul');
        ul.classList.add('fc__tooltip_list-wrapper');
        let minValue = 0;
        let maxValue = 0;
        if (!arr || !arr.length) return;
        let peopleInCompany = +arr[0]+(+arr[1])+(+arr[2]);
        let cellPersentText = [];
        cellPersentText.push(arr[0]);
        cellPersentText.push(+arr[1] + +arr[0]);
        cellPersentText.push(arr[2]);
        let persent = [];
        persent.push(arr[0] * 100/peopleInCompany ? arr[0] * 100/peopleInCompany : 0);
        persent.push((+arr[0]+ +arr[1]) * 100/peopleInCompany ? (+arr[0]+ +arr[1]) * 100/peopleInCompany : 0);
        persent.push(arr[2] * 100/peopleInCompany ? arr[2] * 100/peopleInCompany : 0);
        minValue = maxValue = persent[0];
        for (let i = 0; i < persent.length; i++) {
            if (minValue > persent[i]) {
                minValue = persent[i];
            }
            if (maxValue < persent[i]) {
                maxValue = persent[i];
            }
        }
        for (let i = 0; i < arr.length; i++) {
            let li = document.createElement('li');
            let cellPersent = document.createElement('span');
            let bollWrapper = document.createElement('span');
            let boll = document.createElement('span');
            let cellNum = document.createElement('span');
            let cellText = document.createElement('span');
            boll.classList.add('fc__tooltip_boll');
            bollWrapper.classList.add('fc__tooltip_bollwrapper');
            cellNum.classList.add('fc__tooltip_num');
            cellText.classList.add('fc__tooltip_text');
            cellPersent.classList.add('fc__tooltip_num');
            boll.style['background-color'] = this.colors[i];
            li.classList.add('fc__tooltip_item');
            if (!i) {
                if (persent[i] > 0 && persent[i] < 1) {
                    persent[i] = '< 1%';
                } else if (persent[i] > 99 && persent[i] < 100) {
                    persent[i] = '> 99%';
                } else if (persent[i] > Math.floor(persent[i])) {
                    persent[i] = `> ${Math.floor(persent[i])}%`;
                } else {
                    persent[i] = Math.round(persent[i]) + '%';
                }
            } else {
                persent[i] = Math.round(persent[i]) + '%';
            }
            cellNum.insertAdjacentText('afterbegin', persent[i]);
            cellText.insertAdjacentText('afterbegin', this.seriesname[i]);
            cellPersent.insertAdjacentText('afterbegin', (+cellPersentText[i]).toLocaleString());
            bollWrapper.appendChild(boll);
            li.appendChild(bollWrapper);
            li.appendChild(cellNum);
            li.appendChild(cellText);
            li.appendChild(cellPersent);
            ul.appendChild(li);
        }
        div.insertAdjacentText('afterbegin', this.moment(+date[1]).locale(this.langCode).format('LL'));
        setTimeout(() => {
            while (title.firstChild) {
                title.removeChild(title.firstChild);
            }
            title.appendChild(div);
            title.appendChild(ul);
        }, 1);
    }

    $onDestroy() {
        const that = this;
        // eslint-disable-next-line
        FusionCharts.removeEventListener('chartMouseMove', that.setEvent);
        // eslint-disable-next-line
        FusionCharts.removeEventListener('chartRollOver', that.setEvent);
    }
}

PeopleGraphController.$inject = [
    'gettextCatalog',
    '$scope',
    '$cookies'
];

export {
    PeopleGraphController,
};
