import modalTemplate from './view.html';

import {UserPolicyModalController} from './controller';

import {USER_POLICY_MODAL_CONTROLLER} from '../../const';

class UserPolicyModal {
    constructor($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
        this.modalInstance = null;
    }

    open = (userId) => {
        this.modalInstance = this.$uibModal.open({
            template: modalTemplate,
            bindToController: true,
            controller: USER_POLICY_MODAL_CONTROLLER,
            controllerAs: '$',
            size: 'md',
            resolve: {
                userId: userId,
            },
            backdrop: 'static',
            keyboard: false,
        });

        return this.modalInstance.result;
    };
}

export {
    UserPolicyModalController,
    UserPolicyModal,
};
