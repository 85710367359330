import angular from 'angular';

import {DEFAULT_PER_PAGE_SIZE, TARGETS_SERVICE} from '../../const';

import {ReportTargetsModal} from '../reportTargetsModal';

class ReportDiagramsController {
    constructor($injector, TargetsService) {
        this.$injector = $injector;
        this.TargetsFailedService = TargetsService.all();
        this.TargetsSuccessService = TargetsService.all();
        this.targets = {
            failed: [],
            success: [],
        };
        this.failedPage = 1;
        this.successPage = 1;
    }

    $onInit() {
        this.firstFailedLoading = this.data && this.data.fail > 0 ? true : false;
        this.firstSuccessLoading = this.data && this.data.success > 0 ? true : false;
    }

    loadSuccessTargets = (all) => {
        this.targetsSuccessLoading = true;
        this.targetsSuccessError = false;
        let targetsIds = '-1';
        let i = 0;
        while (i < DEFAULT_PER_PAGE_SIZE && this.data.success > ((this.successPage - 1) * DEFAULT_PER_PAGE_SIZE) + i) {
            targetsIds += ',' + this.data.targets.success[((this.successPage - 1) * DEFAULT_PER_PAGE_SIZE) + i];
            i++;
        }
        if (i == 0) {
            this.targetsSuccessLoading = false;
            this.firstSuccessLoading = false;
        } else {
            this.TargetsSuccessService.$filters = {
                id: targetsIds,
            };
            this.TargetsSuccessService.$page = 1;
            this.TargetsSuccessService.$load(DEFAULT_PER_PAGE_SIZE).then((data) => {
                if (data.$data && data.$data.length > 0) {
                    angular.forEach(data.$data, (target) => {
                        this.targets.success.push(target);
                    });
                }
                this.successPage++;
                if (all) {
                    this.loadSuccessTargets(all);
                } else {
                    this.targetsSuccessLoading = false;
                    this.firstSuccessLoading = false;
                }

            }, () => {
                this.targetsSuccessLoading = false;
                this.targetsSuccessError = true;
            });
        }
    };

    loadFailedTargets = (all) => {
        this.targetsFailedLoading = true;
        this.targetsFailedError = false;
        let targetsIds = '-1';
        let i = 0;
        while (i < DEFAULT_PER_PAGE_SIZE && this.data.fail > ((this.failedPage - 1) * DEFAULT_PER_PAGE_SIZE) + i) {
            targetsIds += ',' + this.data.targets.failed[((this.failedPage - 1) * DEFAULT_PER_PAGE_SIZE) + i];
            i++;
        }
        if (i == 0) {
            this.targetsFailedLoading = false;
            this.firstFailedLoading = false;
        } else {
            this.TargetsFailedService.$filters = {
                id: targetsIds,
            };
            this.TargetsFailedService.$page = 1;
            this.TargetsFailedService.$load(DEFAULT_PER_PAGE_SIZE).then((data) => {
                if (data.$data && data.$data.length > 0) {
                    angular.forEach(data.$data, (target) => {
                        this.targets.failed.push(target);
                    });
                }
                this.failedPage++;
                if (all) {
                    this.loadFailedTargets(all);
                } else {
                    this.targetsFailedLoading = false;
                    this.firstFailedLoading = false;
                }
            }, () => {
                this.targetsFailedLoading = false;
                this.targetsFailedError = true;
            });
        }
    };

    openTargetList = (skill) => {
        let newValue = !+this.showTargetList[skill];
        angular.forEach(Object.keys(this.showTargetList), (skill) => {
            this.showTargetList[skill] = false;
        });
        this.showTargetList[skill] = newValue;
        if (!newValue) {
            this.targets = {
                failed: [],
                success: [],
            };
            this.failedPage = 1;
            this.successPage = 1;
        } else {
            this.loadFailedTargets();
            this.loadSuccessTargets();
        }
    };

    showHistory = (target) => {
        let selectedData = {
            targets: {},
            departmentsUsed: [],
        };
        selectedData.departmentsUsed.push(target.departmentId);
        selectedData.targets[target.departmentId] = [];
        selectedData.targets[target.departmentId].push(target.id);
        this.modal = this.$injector.instantiate(ReportTargetsModal);
        this.modal.open(selectedData).then(() => {
        }, () => {
        });
    };
}

ReportDiagramsController.$inject = [
    '$injector',
    TARGETS_SERVICE,
];

export {
    ReportDiagramsController,
};
