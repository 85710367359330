import style from './style.modules.css';
import { EMPTY_SELECTED_DATA, SEPARATOR, STATUS_SERVICE } from '../../const';
import { EmployeesModal } from '../employeesModal';
import { hasAccess } from '../../reselect';
import getNextPage from '../../helpers/getNextPage';
import buildSearchString from '../../helpers/buildSearchString';
import angular from 'angular';
import rebuildSelectedData from '../../helpers/rebuildSelectedData';
import buildSelectedDataCacheString from '../../helpers/buildSelectedDataCacheString';
import { ReportTargetsModal } from '../reportTargetsModal';
import {setSelectedData, setLinkings, setSearchData} from '../../toolkit/actions';

class TargetsTableController {
    constructor ($localStorage, $scope, StatusService, $ngRedux, $injector, gettextCatalog, $filter) {
        this.style = style;
        this.$localStorage = $localStorage;
        this.scope = $scope;
        this.StatusService = StatusService;
        this.filter = $filter;
        this.targets = [];
        this.targetCount = 0;
        this.firstLoading = true;
        this.loading = false;
        this.error = false;
        this.nextPage = 0;
        this.sortBy = 'fullName';
        this.sortReverse = false;
        this.gettextCatalog = gettextCatalog;
        this.$injector = $injector;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, {setSelectedData, setLinkings, setSearchData})(this);
        this.hasSituation = false;
        this.hasMarks = false;
        this.langCode = window.appCtrl.langCode || 'ru';
        this.sortableFields = [
            'rating',
            'email',
            'fullName',
            'first_name',
            'departmentName',
            'detectionType',
            'detectionDateTime',
            'score',
            'educationStatus',
            'statusUpdated',
            'activity',
            'groupName'
        ];
        if (!this.departmentId && !this.groupId) {
            let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(this.selectedData));
            this.prevSelectedDataJson = buildSelectedDataCacheString(selectedData);
        }
        this.parentId = null;
        this.strings = {
            linkGroup: gettextCatalog.getString('Группа'),
            linkDepartment: gettextCatalog.getString('Отдел'),
            newEmployee: gettextCatalog.getString('новый сотрудник')
        };
    }

    mapStateToThis (state) {
        return {
            selectedData: state.selectedData.selectedData,
            targetSaveAccess: hasAccess(state, {
                sectionId: 1,
                rightName: 'target_save'
            }),
            linkNames: state.breadcrumbLinks.link,
            hashPath: state.linking.link,
            accessTargetsDefault: hasAccess(state, {
                sectionId: 1,
                rightName: 'view'
            }),
            accessTargetsRisks: hasAccess(state, {
                sectionId: 3,
                rightName: 'view'
            }),
            accessTargetsReports: hasAccess(state, {
                sectionId: 4,
                rightName: 'report_department'
            }),
            accessAttacksReports: hasAccess(state, {
                sectionId: 4,
                rightName: 'report_attack'
            }),
            accessAttackView: hasAccess(state, {
                sectionId: 2,
                rightName: 'view'
            }),
            accessReportView: hasAccess(state, {
                sectionId: 4,
                rightName: 'view'
            })
        };
    }

    $onInit () {
        this.accessTargetsDefault = !this.isVuln && !this.getReportParams && this.accessTargetsDefault;
        this.accessTargetsRisks = this.isVuln && this.accessTargetsRisks;
        this.accessTargetsReports = ((!this.getReportParams && this.isReport) || (this.getReportParams && this.getReportParams().type == 'department')) && this.accessTargetsReports;
        this.accessAttacksReports = ((!this.getReportParams && this.isReport) || (this.getReportParams && this.getReportParams().type == 'attack')) && (this.accessAttacksReports || this.accessAttackView);
        if (!this.accessTargetsDefault && !this.accessTargetsRisks && !this.accessTargetsReports && !this.accessAttacksReports && !this.accessAttackView) {
            return;
        }
        if (this.getFirstLoading) {
            this.getFirstLoading(true);
        }
        let parent = this.linkNames[this.linkNames.length - 1] && this.linkNames[this.linkNames.length - 1] ? this.linkNames[this.linkNames.length - 1] : null;
        this.parentChildren = parent && parent.childIds ? parent.childIds : [];
        this.parentId = parent && !parent.isTitle ? parent.id : null;
        this.watchHashpath();
        this.fetchTargets(false, 1);
        this.scope.$watch(scope => {
            return scope.$.linkNames;
        }, (links, oldLinks) => {
            if (links.length === oldLinks.length) {
                return;
            }
            if (!links.length && !this.courseId) {
                this.parentId = null;
                this.departments = [];
                this.fetchTargets(false, 1);
                return;
            }
            if (links.length === 1 && links[0] &&
                {}.hasOwnProperty.call(links[0], 'isTitle')) {
                this.parentId = null;
                this.targets = [];
                this.fetchTargets(false, 1);
                return;
            }
            this.parentId = links[links.length - 1] ? links[links.length - 1].id : null;
            this.targets = [];
            this.fetchTargets(false, 1);
        }, true);
        this.scope.$watch(scope => {
            return scope.$.reloading;
        }, (newVal, oldVal) => {
            if (newVal === oldVal) {
                return;
            }
            if (newVal) {
                this.targets = [];
                this.fetchTargets(false, 1, () => {
                    if (this.setLoading) {
                        this.setLoading('reloading', false);
                    }
                });
                return;
            }
        });
        this.scope.$watch(scope => {
            return scope.$.selectedData.search;
        }, (search, oldSearch) => {
            let searchString = buildSearchString(search);
            let oldSearchString = buildSearchString(oldSearch);
            if (searchString.length >= 3 && searchString != oldSearchString) {
                this.targets = [];
                this.targetCount = 0;
                this.fetchTargets(0, 1);
            }
        });
        if (!this.departmentId && !this.groupId) {
            this.scope.$watch(scope => {
                return scope.$.hashPath;
            }, (newVal, oldVal) => {
                if (oldVal === newVal) {
                    return;
                }
                if (newVal === 'toolBarAll') {
                    let search = this.selectedData.search;
                    let prevData = null;
                    if (buildSearchString(search)) {
                        prevData = this.selectedData.prevData;
                    }
                    let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy({
                        all: 1,
                        search: search,
                        prevData: prevData,
                        filterData: this.selectedData.filterData
                    }));
                    this.setSelectedData(selectedData);
                    this.setLinkings('');
                    this.checkSelectedData(false, true);
                } else if (newVal === 'toolBarDepartmentSelect') {
                    let search = this.selectedData.search;
                    let prevData = null;
                    if (buildSearchString(search)) {
                        prevData = this.selectedData.prevData;
                    }
                    let departments = [...this.selectedData.departments];
                    departments.push(this.parentId);
                    departments = [...departments, ...this.parentChildren];
                    let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy({
                        all: 0,
                        search,
                        prevData,
                        departments,
                        filterData: this.selectedData.filterData
                    }));
                    this.setSelectedData(selectedData);
                    this.setLinkings('');
                    this.checkSelectedData(false, true);
                } else if (newVal === 'toolBarClear' || newVal === 'toolBarDepartmentUnselect') {
                    let search = this.selectedData.search;
                    let prevData = null;
                    if (buildSearchString(search)) {
                        prevData = this.selectedData.prevData;
                    }
                    let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy({
                        all: 0,
                        search: search,
                        prevData: prevData,
                        filterData: this.selectedData.filterData
                    }));
                    if (prevData) {
                        selectedData.all = 1;
                        selectedData.unselectAll = 1;
                    }
                    this.setSelectedData(selectedData);
                    this.setLinkings('');
                    this.checkSelectedData();
                } else if (newVal === 'toolBarReverse') {
                    let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(this.selectedData.prevData), angular.copy({
                        search: this.selectedData.search,
                        filterData: this.selectedData.filterData
                    }));
                    this.setSelectedData(selectedData);
                    this.setLinkings('');
                    this.checkSelectedData(true);
                } else if (newVal.indexOf('rebuildSelectedData') > -1) {
                    this.setLinkings('');
                    this.checkSelectedData(false, true);
                } else {
                    this.watchHashpath();
                }
                if (!this.departmentId && !this.groupId && ['selectAll', 'sendTraining', 'attacksStart'].indexOf(newVal) > -1) {
                    this.fetchTargets(false, 1);
                }
            });
        }
    }

    fullName = (target) => {
        return target.fullName;
    }

    watchHashpath = () => {
        if (this.location === '/reports' || this.departmentId || this.groupId) {
            return;
        }
        if (this.hashPath.indexOf(SEPARATOR) !== -1) {
            let hashPath = this.hashPath.split(SEPARATOR);
            this.setLinkings(hashPath[0]);
            if (this.setSearch) {
                this.setSearch(hashPath[1]);
            }
        }
    };

    loadSearch = (searchString) => {
        if (this.isAnyLoading()) {
            return;
        }
        const { $localStorage } = this;
        this.setSearchData([searchString]);
        let viewMode = $localStorage.departments && $localStorage.departments.viewMode || 0;
        viewMode = (this.isVuln || this.isReport) ? 0 : (this.viewMode ? this.viewMode : viewMode);
        if (viewMode < 1) {
            if (typeof (this.loadDepartments) == 'function') {
                this.loadDepartments(searchString);
            }
            return;
        }
        this.targets = [];
        this.targetCount = 0;
        this.fetchTargets(0, 1);
    };

    editEmployee = (targetId) => {
        if (this.targetSaveAccess && !this.isAnyLoading()) {
            this.modal = this.$injector.instantiate(EmployeesModal);
            this.modal.open(targetId).then(() => {
                if (this.setLoading) {
                    this.setLoading('reloading', true);
                }
                /*this.targets.length = 0;
                this.fetchTargets(false, 1);*/
            });
        }
    };

    fetchTargets = (fullList = false, page = 1, callback) => {
        if (!this.loading && (!this.isAnyLoading() || this.firstLoading)) {
            this.error = false;
            this.setTargetsLoading(true);
            let bodyParams = null;
            if (this.buildSearchString().indexOf(this.gettextCatalog.getString('выбранные сотрудники')) > -1) {
                bodyParams = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), this.selectedData.prevData);
            }
            fetch(this.getAdress(fullList, page, bodyParams), {
                headers: {
                    'X-Csrf-Token': window.csrfToken,
                    'Enable-Session': 1,
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: bodyParams ? JSON.stringify(bodyParams) : null
            }).then(response => {
                if (response.ok) {
                    // eslint-disable-next-line
                    let nextLink = response.headers.get('link').split(/\,/g);
                    this.targetCount = response.headers.get(
                        'X-Pagination-Total-Count');
                    this.nextPage = getNextPage(nextLink);
                }
                return response.json();
            }).then(json => {
                if (!Array.isArray(this.targets) || !page || page == 1) {
                    this.targets = [];
                }
                if (json.message) {
                    this.setTargetsLoading(false);
                    this.error = json.message;
                    this.scope.$apply();
                    return;
                }
                if (json && json.length > 0) {
                    if (fullList || page == 1) {
                        this.targets = json;
                    } else {
                        this.targets = [...this.targets, ...json];
                    }
                }
                this.hasSituation = this.targets[0] && this.targets[0].situation;
                this.hasMarks = this.targets[0] && this.targets[0].marks;
                if (!this.departmentId && !this.groupId && this.setTargetCount) {
                    this.setTargetCount(this.targets.length);
                }
                this.setTargetsLoading(false);
                this.firstLoading = false;
                if (typeof (callback) == 'function') {
                    callback();
                }
                if (this.getFirstLoading) {
                    this.getFirstLoading(false);
                }
                this.scope.$apply();
            }).then(() => {
                if (this.departmentId || this.groupId) {
                    return;
                }
                if ((this.hashPath === 'selectAll' || this.hashPath === 'sendTraining' || this.hashPath === 'attacksStart')) {
                    let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy({
                        all: 1,
                        search: this.selectedData.search,
                        filterData: this.selectedData.filterData,
                        parentId: this.parentId
                    }));
                    this.setSelectedData(selectedData);
                }
                if (this.hashPath === 'attacksStart') {
                    this.setLinkings('createAttack');
                } else {
                    this.setLinkings('');
                }
                this.checkSelectedData();
                this.scope.$apply();
            }).catch(() => {
                this.setTargetsLoading(false);
                this.error = true;
                this.scope.$apply();
            });
        }
    };

    getSelectedData = () => {
        return angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(this.selectedData));
    };

    isTargetChecked = (target, selectedData) => {
        if (!target || !target.id || !target.department || !target.department.id) {
            return false;
        }

        selectedData = selectedData || this.getSelectedData();
        if (!selectedData || !selectedData.targets || !selectedData.unselectedTargets) {
            return false;
        }

        let selectedTargets = selectedData.targets || [];

        let unselectedTargetIndex = ((selectedData.unselectedTargets || [])[target.department.id] || []).indexOf(target.id);
        let targetUnselectedDepartmentIndex = (selectedData.unselectedDepartments || []).indexOf(target.department.id);
        let targetUnselectedGroupIndex = (selectedData.unselectedGroups || []).indexOf(target.group ? target.group.id : -1);

        if (selectedData.prevData && this.isTargetChecked(target, selectedData.prevData)) {
            if (targetUnselectedDepartmentIndex > -1 || targetUnselectedGroupIndex > -1) {
                return false;
            }
            return unselectedTargetIndex <= -1;
        }

        let targetIndex = (selectedTargets[target.department.id] || []).indexOf(target.id);
        let departmentIndex = (selectedData.departments || []).indexOf(target.department.id);
        let groupIndex = target.group ? (selectedData.groups || []).indexOf(target.group.id) : -1;

        if (unselectedTargetIndex === -1 && (targetUnselectedDepartmentIndex === -1 || targetUnselectedGroupIndex === -1 || targetIndex > -1)) {
            if (selectedData.all) {
                return true;
            } else if (targetIndex > -1 || departmentIndex > -1 || groupIndex > -1) {
                return true;
            }
        }

        return false;
    };

    getAdress = (fullList, page, bodyParams) => {
        let adress = `${window.config.SYSTEM_URL}${window.config.API_URL}/targets/search?fullList=${+fullList}&page=${page}&per-page=10`;
        adress += this.getSearchStr();
        adress += this.getSortStr();
        if (this.courseId) {
            adress += this.getCoursesStr();
        }
        if (this.getReportParams) {
            adress += this.getReportParamsStr();
        }
        if (this.departmentId) {
            adress += this.getDepartmentIdStr();
        }
        if (this.groupId) {
            adress += this.getGroupIdStr();
        }
        if (this.parentId && !this.departmentId && !this.groupId) {
            adress += this.getParentIdStr();
        }
        if (this.isVuln) {
            adress += this.getVulnStr();
        }
        if (this.managerId) {
            adress += this.getManagerStr();
        }
        if (this.software) {
            adress += this.getSoftwareStr();
        }
        if (bodyParams || this.getReportParams || this.isVuln || this.managerId) {
            adress += '&actual=1';
        }
        return adress;
    };

    getReportParamsStr = () => {
        let str = JSON.stringify(this.getReportParams()).replace(/{/g, '%7B');
        str = str.replace(/}/g, '%7D');
        return `&reportParams=${str}`;
    };

    getSortStr = () => {
        return `&sortBy=${this.sortBy}&sortReverse=${this.sortReverse}&actual=0`;
    };

    getSearchStr = () => {
        let search = this.buildSearchString();
        return `&search=${window.encodeURIComponent(search)}`;
    };

    getCoursesStr = () => {
        return `&courses=${this.courseId}`;
    };

    getManagerStr = () => {
        return `&managerId=${this.managerId}`;
    };

    getSoftwareStr = () => {
        return `&softwareId=${this.software.id}&vendorId=${this.software.vendorId}`;
    };

    getDepartmentIdStr = () => {
        return `&departmentId=${this.departmentId}`;
    };

    getGroupIdStr = () => {
        return `&groupId=${this.groupId}`;
    };

    getParentIdStr = () => {
        return `&parentId=${this.parentId}`;
    };

    getVulnStr = () => {
        return '&risk=1';
    };

    $onDestroy () {
        this.unsubscribe();
    }

    setSortBy = (str) => {
        if (!this.isAnyLoading()) {
            if (this.sortBy === str) {
                this.sortReverse = !this.sortReverse;
            } else if (this.sortBy != str) {
                this.sortReverse = false;
            }
            this.sortBy = str;
            if (this.sortableFields.indexOf(this.sortBy) > -1) {
                this.targets = [];
                this.fetchTargets(this.targets.length === +this.targetCount, 1);
            } else {
                this.sortTargets();
            }
        }
    };

    sortTargets = () => {
        let str = this.sortBy;
        if (this.sortableFields.indexOf(str) == -1) {
            let sorting = !this.loading;
            if (sorting) {
                this.setTargetsLoading(true);
            }
            this.targets = this.filter('orderBy')(this.targets, str);
            if (this.sortReverse) {
                this.targets.reverse();
            }
            if (sorting) {
                this.setTargetsLoading(false);
            }
        }
    };

    setTargetsLoading = (value) => {
        value = !!value;
        this.loading = value;
        if (this.setLoading) {
            this.setLoading('targetsLoading', this.loading);
        }
    };

    buildSearchString = () => {
        return buildSearchString(this.selectedData.search);
    };

    isDepartmentChecked = (department) => {
        if (department && department.id && this.departmentCheckedCache && this.departmentCheckedCache.indexOf(department.id) > -1) {
            return true;
        }
        return false;
    };

    isGroupChecked = (group) => {
        if (group && group.id && this.groupCheckedCache && this.groupCheckedCache.indexOf(group.id) > -1) {
            return true;
        }
        return false;
    };

    toggleTarget = (target) => {
        if (!this.isAnyLoading()) {
            let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(this.selectedData));
            let departmentIndex = selectedData.departments.indexOf(target.department.id);
            let targetIndex = selectedData.targets && selectedData.targets[target.department.id] ? selectedData.targets[target.department.id].indexOf(target.id) : -1;
            let usedDepartmentIndex = selectedData.departmentsUsed.indexOf(target.department.id);
            let unTargetsIndex = selectedData.unselectedTargets && selectedData.unselectedTargets[target.department.id] ? selectedData.unselectedTargets[target.department.id].indexOf(target.id) : -1;
            let unDepartmentsIndex = selectedData.unselectedDepartments ? selectedData.unselectedDepartments.indexOf(target.department.id) : -1;
            let prevDepartmentChecked = false;
            let prevTargetChecked = false;
            let prevGroupChecked = false;
            if (selectedData.prevData) {
                prevTargetChecked = this.isTargetChecked(target, selectedData.prevData);
                prevDepartmentChecked = this.isDepartmentChecked(target.department, selectedData.prevData);
                prevGroupChecked = this.isGroupChecked(target.department, selectedData.prevData);
            }
            if (prevTargetChecked || prevDepartmentChecked || departmentIndex > -1 || prevGroupChecked) {
                if (usedDepartmentIndex > -1 && targetIndex == -1) {
                    if (!angular.isArray(selectedData.targets[target.department.id])) {
                        selectedData.targets[target.department.id] = [];
                    }
                    selectedData.targets[target.department.id].push(target.id);
                } else {
                    if (unTargetsIndex > -1) {
                        selectedData.unselectedTargets[target.department.id].splice(unTargetsIndex, 1);
                        unTargetsIndex = -2;
                        if (!selectedData.unselectedTargets[target.department.id].length) {
                            delete selectedData.unselectedTargets[target.department.id];
                        }
                    } else {
                        if (!angular.isArray(selectedData.unselectedTargets[target.department.id])) {
                            selectedData.unselectedTargets[target.department.id] = [];
                        }
                        selectedData.unselectedTargets[target.department.id].push(target.id);
                        targetIndex = selectedData.targets && selectedData.targets[target.department.id] ? selectedData.targets[target.department.id].indexOf(target.id) : -1;
                        if (targetIndex > -1) {
                            selectedData.targets[target.department.id].splice(targetIndex, 1);
                        }
                    }
                }
            } else {
                if (selectedData.all) {
                    if (unDepartmentsIndex > -1) {
                        if (targetIndex == -1) {
                            if (!angular.isArray(selectedData.targets[target.department.id])) {
                                selectedData.targets[target.department.id] = [];
                            }
                            selectedData.targets[target.department.id].push(target.id);
                            if (target.department && selectedData.targets[target.department.id].length == target.department.searchTargets) {
                                selectedData.unselectedDepartments.splice(unDepartmentsIndex, 1);
                                unDepartmentsIndex = -2;
                                if (selectedData.targets[target.department.id]) {
                                    delete selectedData.targets[target.department.id];
                                }
                            }
                        } else {
                            selectedData.targets[target.department.id].splice(targetIndex, 1);
                            targetIndex = -2;
                            if (!selectedData.targets[target.department.id].length) {
                                delete selectedData.targets[target.department.id];
                            }
                        }
                    } else {
                        if (unTargetsIndex > -1) {
                            selectedData.unselectedTargets[target.department.id].splice(unTargetsIndex, 1);
                            unTargetsIndex = -2;
                            if (!selectedData.unselectedTargets[target.department.id].length) {
                                delete selectedData.unselectedTargets[target.department.id];
                            }
                        } else {
                            if (!angular.isArray(selectedData.unselectedTargets[target.department.id])) {
                                selectedData.unselectedTargets[target.department.id] = [];
                            }
                            selectedData.unselectedTargets[target.department.id].push(target.id);
                            targetIndex = selectedData.targets && selectedData.targets[target.department.id] ? selectedData.targets[target.department.id].indexOf(target.id) : -1;
                            if (targetIndex > -1) {
                                selectedData.targets[target.department.id].splice(targetIndex, 1);
                            }
                        }
                    }
                    if (target.department && target.department.ownTargetsCount == selectedData.unselectedTargets[target.department.id].length) {
                        if (unDepartmentsIndex == -1) {
                            selectedData.unselectedDepartments.push(target.department.id);
                        }
                        delete selectedData.unselectedTargets[target.department.id];
                        if (selectedData.targets[target.department.id]) {
                            delete selectedData.targets[target.department.id];
                        }
                    }
                } else {
                    if (target.department) {
                        let departmentIndex = selectedData.departments.indexOf(target.department.id);
                        if (targetIndex > -1) {
                            selectedData.targets[target.department.id].splice(targetIndex, 1);
                            targetIndex = -2;
                            if (selectedData.targets[target.department.id].length == 0 && usedDepartmentIndex > -1) {
                                selectedData.departmentsUsed.splice(usedDepartmentIndex, 1);
                                usedDepartmentIndex = -2;
                            }
                        } else {
                            if (departmentIndex > -1) {
                                if (unTargetsIndex > -1) {
                                    selectedData.unselectedTargets[target.department.id].splice(unTargetsIndex, 1);
                                    unTargetsIndex = -2;
                                    if (!selectedData.unselectedTargets[target.department.id].length) {
                                        delete selectedData.unselectedTargets[target.department.id];
                                    }
                                } else {
                                    if (!angular.isArray(selectedData.unselectedTargets[target.department.id])) {
                                        selectedData.unselectedTargets[target.department.id] = [];
                                    }
                                    selectedData.unselectedTargets[target.department.id].push(target.id);
                                    targetIndex = selectedData.targets && selectedData.targets[target.department.id] ? selectedData.targets[target.department.id].indexOf(target.id) : -1;
                                    if (targetIndex > -1) {
                                        selectedData.targets[target.department.id].splice(targetIndex, 1);
                                    }
                                }
                                if (target.department.ownTargetsCount == selectedData.unselectedTargets[target.department.id].length) {
                                    selectedData.departments.splice(departmentIndex, 1);
                                    departmentIndex = -2;
                                    delete selectedData.unselectedTargets[target.department.id];
                                }
                            } else {
                                if (!angular.isArray(selectedData.targets[target.department.id])) {
                                    selectedData.targets[target.department.id] = [];
                                }
                                selectedData.targets[target.department.id].push(target.id);
                                if (usedDepartmentIndex == -1) {
                                    selectedData.departmentsUsed.push(target.department.id);
                                }
                            }
                        }
                    } else {
                        let targetIndex = selectedData.targets[target.department.id] ? selectedData.targets[target.department.id].indexOf(target.id) : -1;
                        if (targetIndex > -1) {
                            selectedData.targets[target.department.id].splice(targetIndex, 1);
                            targetIndex = -2;
                            if (selectedData.targets[target.department.id].length == 0 && usedDepartmentIndex > -1) {
                                selectedData.departmentsUsed.splice(usedDepartmentIndex, 1);
                                usedDepartmentIndex = -2;
                                delete selectedData.targets[target.department.id];
                            }
                        } else {
                            if (!angular.isArray(selectedData.targets[target.department.id])) {
                                selectedData.targets[target.department.id] = [];
                            }
                            selectedData.targets[target.department.id].push(target.id);
                            if (usedDepartmentIndex == -1) {
                                selectedData.departmentsUsed.push(target.department.id);
                            }
                        }
                    }
                }
            }
            this.setSelectedData(selectedData);
            this.checkSelectedData(false, true);
        }
    };

    checkSelectedData = (reverse = false, ignoreCache = false) => {
        if (this.rebuildLoading) {
            return;
        }
        const { $localStorage, scope } = this;
        let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(this.selectedData));
        if (buildSearchString(this.selectedData.search).indexOf(this.gettextCatalog.getString('выбранные сотрудники')) > -1) {
            selectedData.filterData = this.selectedData.filterData || this.selectedData.prevData || null;
        } else {
            delete selectedData.filterData;
        }
        let selectedDataJson = buildSelectedDataCacheString(selectedData, reverse);
        if (selectedDataJson != $localStorage.selectedDataCacheString || ignoreCache) {
            rebuildSelectedData(angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(selectedData)), {
                risk: this.isVuln ? 1 : 0,
                managerId: $localStorage.openedManager,
                courseId: this.courseId || null,
                scope: scope,
                setLoadingFunction: (loading) => {
                    if (this.setLoading) {
                        this.setLoading('reBuildLoading', loading);
                    }
                },
                setSelectedDataFunction: (newSelectedData) => {
                    newSelectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(newSelectedData));
                    newSelectedData.search = angular.copy(this.selectedData.search);
                    $localStorage.selectedDataCacheString = buildSelectedDataCacheString(angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(newSelectedData)), reverse);
                    if (this.departmentCheckedCache) {
                        this.departmentCheckedCache = angular.copy(newSelectedData.departments);
                    }
                    this.setSelectedData(newSelectedData);
                    scope.$apply();
                },
                setDepartmentCheckedAndPartialFunction: (checked = [], partial = []) => {
                    if (!this.getPartial) {
                        return;
                    }
                    if (angular.isArray(checked)) {
                        checked.sort();
                    }
                    if (angular.isArray(partial)) {
                        partial.sort();
                    }
                    this.getPartial({
                        checked,
                        partial
                    });
                },
                callbackFunction: () => {
                    scope.$apply();
                },
                reverse: reverse
            });
        }
    };

    getFullNameDepartment = (name, fullName) => {
        let arrFullName = fullName.split(' /');
        if (arrFullName[arrFullName.length - 1].trim() === name) {
            arrFullName[arrFullName.length - 1] = ' ';
        }
        return arrFullName.join('&nbsp;/');
    }

    showReport = (target) => {
        let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA));
        selectedData.departmentsUsed.push(target.department.id);
        selectedData.targets[target.department.id] = [];
        selectedData.targets[target.department.id].push(target.id);
        this.modal = this.$injector.instantiate(ReportTargetsModal);
        this.modal.open(selectedData).then(() => {}, () => {});
    };
}

TargetsTableController.$inject = [
    '$localStorage',
    '$scope',
    STATUS_SERVICE,
    '$ngRedux',
    '$injector',
    'gettextCatalog',
    '$filter'
];

export {
    TargetsTableController
};
