import React from 'react';
import ReactIcon from '../ReactIcons';
import Tooltip from '../Tooltip';
import { GettextContext } from '../../context';

function HeaderLink(props) {
    const gettext = React.useContext(GettextContext);

    const {userName, go, logout} = props

    const goTo = (e, {path, hash}) => {
        go(path, hash)
    }

    return (
            <div className={'react_header-link'}>
                <ReactIcon icoClass={'scheduler'} handleClick={goTo} handleParams={{path:'settings', hash: 'scheduler'}}></ReactIcon>
                <Tooltip reactTip={gettext.getString('настройки')}>
                    <a className={'react_header-link_user-name'} onClick={() => go('settings')}>
                        <ReactIcon icoClass={'settings'}></ReactIcon>
                        <span className={'react_header-link_anchor'}>{userName}</span>
                    </a>
                </Tooltip>
                <ReactIcon handleClick={() => {logout()}} icoClass={'logout'} className={'react_header-link_logout'}></ReactIcon>
            </div>
    );
};

export default HeaderLink;
