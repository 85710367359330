import angular from 'angular';
import { GroupsTableComponent } from './components/groupsTable';
import {
    ENTITY_SERVICE,
    TWO_FACTOR_PASS_MODAL
} from './const';
import { EntityService } from './services';
import { SettingsOutlookComponent } from './components/settingsOutlook';
import { ArrayInputComponent } from './components/arrayInput';
import { GroupsGraphItemComponent } from './components/groupsGraphItem';
import { TextDashboardItemComponent } from './components/dashboardItem/textDashboardItem';
import { DashboardOldComponent } from './components/dashboardOld';
import { SettingsSsoProfileComponent } from './components/settingsSsoProfile';
import { ModalHeaderComponent } from './components/modatHeader';
import { ModalFooterComponent } from './components/modalFooter';
import { SettingsRoleComponent } from './components/settingsRole';
import { LdapFieldsMapComponent } from './components/ldapFieldsMap';
import { DaemonMessageViewComponent } from './components/daemonMessageView';
import { DaemonMessageComponent } from './components/daemonMessage';
import { DaemonDownloadComponent } from './components/daemonDownload';
import { DaemonSaveFileComponent } from './components/daemonsSaveFile';
import { RoleSyncComponent } from './components/roleSync';
import { LangSelector } from './components/langSelector';
import { CertsTableComponent } from './components/certsTable';
import { PasswordFieldComponent } from './components/passwordField';
import { InputNumberComponent } from './components/inputNumber';
import { CampaignAttachmentComponent } from './components/campaignAttachment';
import { TwoFactorPassController } from './components/twoFactorAuthModal/twoFactorPass';
import { TwoFactorDisableController } from './components/twoFactorAuthModal/twoFactorDisable';
import { TwoFactorQRController } from './components/twoFactorAuthModal/twoFactorQR';

//выносим мусор из app.js
angular.module('app.core', [])
    .component('groupsTable', GroupsTableComponent)
    .component('arrayInput', ArrayInputComponent)
    .component('settingsOutlook', SettingsOutlookComponent)
    .component('groupsGraphItem', GroupsGraphItemComponent)
    .component('textDashboardItem', TextDashboardItemComponent)
    .component('dashboardOld', DashboardOldComponent)
    .component('modalHeader', ModalHeaderComponent)
    .component('modalFooter', ModalFooterComponent)
    .component('settingsSsoProfile', SettingsSsoProfileComponent)
    .component('settingsRole', SettingsRoleComponent)
    .component('ldapFieldsMap', LdapFieldsMapComponent)
    .component('daemonMessageView', DaemonMessageViewComponent)
    .component('daemonMessage', DaemonMessageComponent)
    .component('daemonDownload', DaemonDownloadComponent)
    .component('daemonSaveFile', DaemonSaveFileComponent)
    .component('roleSync', RoleSyncComponent)
    .component('langSelector', LangSelector)
    .component('certsTable', CertsTableComponent)
    .component('passwordField', PasswordFieldComponent)
    .component('inputNumber', InputNumberComponent)
    .controller(TWO_FACTOR_PASS_MODAL, TwoFactorPassController)
    .controller('twoFactorDisable', TwoFactorDisableController)
    .controller('twoFactorQR', TwoFactorQRController)
    .component('campaignAttachment', CampaignAttachmentComponent)
    .service(ENTITY_SERVICE, ['$resource', 'GroupsService', EntityService]);


