import style from './style.modules.css';
import { getTargetHistoryDashboard, hasAccess } from '../../../reselect';
import moment from 'moment';
import { getDashGraphItem } from '../../../helpers/graphs';
import {fetchTargetHistory} from '../../../toolkit/think';
import {targetHistoryDefault} from '../../../toolkit/actions';

class DashboardPeople {
    constructor(gettextCatalog, $scope, $ngRedux, $cookies) {
        this.gettextCatalog = gettextCatalog;
        this.style = style;
        this.scope = $scope;
        this.supportEmail = window.config.SUPPORT_EMAIL;
        this.supportEmailSubject = window.config.SUPPORT_EMAIL_SUBJECT;
        this.langCode = $cookies.get('langCode');
        this.fields = ['date', 'companyCount', 'licenseCount', 'count'];
        this.moment = moment;
        this.moment.updateLocale('ru', {
            longDateFormat : {
                LL: 'DD MMMM YYYY года',
            }
        });
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, {fetchTargetHistory, targetHistoryDefault})(this);
        this.strings = {
            mailSubject: gettextCatalog.getString('Расширение лицензии для {{companyName}}'),
            mailText: gettextCatalog.getString('Просьба расширить лицензию с {{from}} до {{to}} сотрудников')
        };
    }

    mapStateToThis = (state) => {
        const that = this;
        return {
            myDataSource: getTargetHistoryDashboard(state, {scope: that, gettextCatalog: that.gettextCatalog, apply: 'count', tooltext: (data) => {
                let string = '';
                for (let key in data) {
                    string += ` ${key}:${data[key]}`;
                }
                return string;
            }}),
            targetsLoad: state.targetHistory.load,
            employeesAddAccess: hasAccess(state, {sectionId: 1, rightName: 'target_save'}),
            accessDashboardView: hasAccess(state, {sectionId: 1, rightName: 'view'}),
            accessDashboardEducation: hasAccess(state, {sectionId: 1, rightName: 'target_education'}),
            motivationTestAccess: hasAccess(state, { sectionId: 14, rightName: 'statistics_motivation' }),
        };
    }

    sendMail = () => {
        let mailSubject = this.strings.mailSubject.replace('{{companyName}}', this.companyName);
        let mailText = this.strings.mailText.replace('{{from}}', this.licenceTarget).replace('{{to}}', this.companyTargetCount);
        return `mailto:${this.supportEmail}?subject=${mailSubject}&body=${mailText}`;
    }

    $onInit() {
        const that = this;
        this.fetchTargetHistory();
        this.showMail = (this.companyTargetCount - this.licenceTarget) > 0;
        // eslint-disable-next-line
        FusionCharts.ready((FusionCharts) => {
            // eslint-disable-next-line
            FusionCharts.addEventListener('chartRollOver', that.setEvent);
            // eslint-disable-next-line
            FusionCharts.addEventListener('chartMouseMove', that.setEvent);
        });
    }

    setEvent = (event) => {
        const chart = event.sender;
        if (chart.args.type !== 'scrollline2d') {
            return;
        }
        let children = event.data.container.children[0].children[2] ? event.data.container.children[0].children[2].children : null;
        let data = event.data.container.children[0].children[2] ? event.data.container.children[0].children[2].innerText : null;
        if (!data || children.length === 2) {
            return;
        }
        let item = getDashGraphItem(this.fields, data);
        let title = chart.ref.querySelector('.fc__tooltip');
        let div = document.createElement('section');
        div.classList.add('fc__tooltip_label');
        let ul = document.createElement('ul');
        ul.classList.add('fc__tooltip_list-wrapper');
        let li = document.createElement('li');
        let cellNum = document.createElement('span');
        let cellText = document.createElement('span');
        cellNum.classList.add('fc__tooltip_num');
        cellText.classList.add('fc__tooltip_text');
        li.classList.add('fc__tooltip_item');
        let persent = '0%';
        if (+item.count) {
            persent = +item.count < +item.companyCount ? item.count*100/item.companyCount : 100;
            if (persent < 1 && persent > 0) {
                persent = '< 1%';
            } else if (persent > 99 && persent < 100) {
                persent = '> 99%';
            } else {
                persent = Math.round(persent) + '%';
            }
        }
        cellNum.insertAdjacentText('afterbegin', persent);
        cellText.insertAdjacentText('afterbegin', this.gettextCatalog.getString('{{count}} из {{com}} сотрудников добавлены в систему.',
            {
                count:(+item.count).toLocaleString(),
                com: +item.count < +item.companyCount ? (+item.companyCount).toLocaleString() : (+item.count).toLocaleString()
            }));
        li.appendChild(cellNum);
        li.appendChild(cellText);
        ul.appendChild(li);
        div.insertAdjacentText('afterbegin', this.moment(+item.date).locale(this.langCode).format('LL'));
        setTimeout(() => {
            while (title.firstChild) {
                title.removeChild(title.firstChild);
            }
            title.appendChild(div);
            title.appendChild(ul);
        }, 1);
    }

    $onDestroy() {
        const that = this;
        this.targetHistoryDefault();
        this.unsubscribe();
        // eslint-disable-next-line
        FusionCharts.removeEventListener('chartMouseMove', that.setEvent);
        // eslint-disable-next-line
        FusionCharts.removeEventListener('chartRollOver', that.setEvent);
    }
}

DashboardPeople.$inject = [
    'gettextCatalog',
    '$scope',
    '$ngRedux',
    '$cookies'
];

export {
    DashboardPeople,
};
