import { Controller } from './controller';
import { CampaignModal } from '../components/campaignModal';
import { UsbAttackModal } from '../components/usbAttackModal';
import {setCampaignsRestoreData, clearSelectedData} from '../toolkit/actions';
import { CAMPAIGNS_SERVICE, DEFAULT_PER_PAGE_SIZE, USB_SERVICE } from '../const';
import { hasAccess } from '../reselect';
import go from '../helpers/go';
import WifiAttack from '../React/components/WifiAttack';
import { ReactModal } from 'root/assets/webpack/src/js/components/ReactModal';
import WifiAttackModal from '../React/components/WifiAttackModal';

class CampaignsController extends Controller {
    constructor ($injector, $scope, $localStorage, CampaignsService, UsbService, $location, $ngRedux, gettextCatalog) {
        super();
        this.$injector = $injector;
        this.$scope = $scope;
        this.$location = $location;
        this.$localStorage = $localStorage;
        this.redux = $ngRedux;
        this.CampaignsService = CampaignsService;
        this.campaignsList = CampaignsService.all;
        this.usbList = UsbService.all;
        this.campaignsLoading = false;
        this.campaignsError = false;
        this.campaignsMoreLoading = false;
        this.campaignsMoreError = false;
        this.firstLoading = true;
        this.viewMode = 0;
        this.WifiAttack = WifiAttack;
        this.WifiAttackModal = WifiAttackModal;
        this.allEmail = false;
        this.allUsb = false;
        this.gettextCatalog = gettextCatalog;
        this.isTrash = false;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, {setCampaignsRestoreData, clearSelectedData})(this);
        window.campaignsCtrl = this;
    }

    mapStateToThis = (state) => {
        return {
            hashPath: state.linking.link,
            viewAccess: hasAccess(state, { sectionId: 2, rightName: 'view' }),
            viewReportAccess: hasAccess(state, { sectionId: 4, rightName: 'report_attack' }),
            deleteAccess: hasAccess(state, { sectionId: 2, rightName: 'attack_delete' }),
            saveAccess: hasAccess(state, { sectionId: 2, rightName: 'attack_save' }),
            restoreInfo: state.campaigns.restoreData,
        };
    };

    setViewMode = (viewMode) => {
        this.allEmail = false;
        if (viewMode !== 2) {
            this.isTrash = false;
        }
        this.allUsb = false;
        const { $localStorage } = this;
        this.viewMode = viewMode;
        $localStorage.campaigns.viewMode = viewMode;
        this.load();
    };

    $onInit () {
        const { $localStorage, $location } = this;
        this.viewReportAccess = this.isReport && this.viewReportAccess;
        if (!this.viewAccess && !this.viewReportAccess && $location.path() != '/reports') {
            if ($localStorage.userInfo.defaultPage) {
                go($location, $localStorage.userInfo.defaultPage);
            }
            return;
        }
        this.$localStorage.campaignData = null;
        this.allEmail = false;
        this.allUsb = false;
        if (typeof ($localStorage.campaigns) == 'undefined') {
            $localStorage.campaigns = {};
        }
        this.viewMode = $localStorage.campaigns && $localStorage.campaigns.viewMode || 0;
        this.clearSelectedData();
        this.load();
        this.$scope.$watch(scope => {
            return scope.campaignsCtrl.$localStorage.campaigns;
        }, (newCampaigns, oldCampaings) => {
            if (newCampaigns === oldCampaings) {
                return;
            }
            this.viewMode = newCampaigns;
        });
    }

    load = (reLoad) => {
        if (!this.viewMode) {
            this.loadCampaigns(reLoad);
            if (this.deleteAccess) {
                this.setCampaignsRestoreData(this.CampaignsService.restoreInfo());
            }
        } else {
            this.loadUsb(reLoad);
        }
    };

    loadUsb = (reLoad) => {
        this.usbError = null;
        this.usbLoading = true;
        this.usbList.$page = 1;
        let fullList = this.allUsb ? 1 : 0;
        this.usbList.$filters = {
            fullList
        };
        this.usbList.$load(reLoad ? this.usbList.$data.length : DEFAULT_PER_PAGE_SIZE).then((data) => {
            this.usbList = data;
            this.usbLoading = false;
            this.firstLoading = false;
        }, (response) => {
            this.usbLoading = false;
            this.usbError = response && response.data ? response.data.message : true;
        });
    };

    loadMoreUsb = (all) => {
        if (this.usbList.$existNext() || all) {
            this.usbMoreLoading = true;
            this.usbMoreError = false;
            if (all) {
                this.allUsb = true;
                this.usbList.$page = 1;
                this.usbList.$load(this.usbList.$meta.totalCount).then((data) => {
                    this.usbList = data;
                    this.usbMoreLoading = false;
                }, (response) => {
                    this.usbMoreLoading = false;
                    this.usbMoreError = response && response.data ? response.data.message : true;
                });
            } else {
                const prevData = this.usbList.$data;
                this.usbList.$nextPage().then((data) => {
                    this.usbList.$meta = data.$meta;
                    this.usbList.$data = [...prevData, ...data.$data];
                    this.usbMoreLoading = false;
                }, (response) => {
                    this.usbMoreLoading = false;
                    this.usbMoreError = response && response.data ? response.data.message : true;
                });
            }
        }
    };

    loadCampaigns = (reLoad) => {
        this.campaignsError = null;
        this.setCampaignsLoading(true);
        this.campaignsList.$page = 1;
        let fullList = this.allEmail ? 1 : 0;
        this.campaignsList.$filters = {
            fullList
        };
        const { actual, isReport } = this;
        this.campaignsList.$filters = {};
        if (actual) {
            this.campaignsList.$filters.actual = 1;
        }
        if (isReport) {
            this.campaignsList.$filters.reportParams = {};
        }
        this.campaignsList.$load(reLoad ? this.campaignsList.$data.length : DEFAULT_PER_PAGE_SIZE).then((data) => {
            this.campaignsList = data;
            this.setCampaignsLoading(false);
            this.firstLoading = false;
        }, (response) => {
            this.setCampaignsLoading(false);
            this.campaignsError = response && response.data ? response.data.message : true;
        });
    };

    loadMoreCampaigns = (all) => {
        if (this.campaignsList.$existNext() || all) {
            this.campaignsMoreLoading = true;
            this.campaignsMoreError = false;
            if (all) {
                this.allEmail = true;
                this.campaignsList.$page = 1;
                this.campaignsList.$load(this.campaignsList.$meta.totalCount).then((data) => {
                    this.campaignsList = data;
                    this.campaignsMoreLoading = false;
                }, (response) => {
                    this.campaignsMoreLoading = false;
                    this.campaignsMoreError = response && response.data ? response.data.message : true;
                });
            } else {
                const prevData = this.campaignsList.$data;
                this.campaignsList.$nextPage().then((data) => {
                    this.campaignsList.$meta = data.$meta;
                    this.campaignsList.$data = [...prevData, ...data.$data];
                    this.campaignsMoreLoading = false;
                }, (response) => {
                    this.campaignsMoreLoading = false;
                    this.campaignsMoreError = response && response.data ? response.data.message : true;
                });
            }
        }
    };

    createCampaign = () => {
        const { $localStorage } = this;
        if (this.saveAccess) {
            $localStorage.lastPage = this.$location.path();
            $localStorage.campaignData = null;
            this.clearSelectedData();
            $localStorage.campaignStart = null;
            $localStorage.campaignSchedule = null;
            this.modal = this.$injector.instantiate(CampaignModal);
            this.modal.open().then((campaignData) => {
                if (campaignData) {
                    this.loadCampaigns();
                }
            }, (callback) => {
                if (callback) {
                    callback();
                }
            });
        }
    };

    setTrash = () => {
        this.isTrash = !this.isTrash;
    }

    createUsb = () => {
        if (this.saveAccess) {
            this.modal = this.$injector.instantiate(UsbAttackModal);
            this.modal.open().then((usbData) => {
                if (usbData) {
                    this.loadUsb();
                }
            }, () => {
            });
        }
    };


    createWifi = () => {
        if (this.saveAccess) {
            this.modal = this.$injector.instantiate(ReactModal);
            this.modal.open({
                component: WifiAttackModal,
            }).then((wifiData) => {
                if (wifiData) {
                    this.loadWifi();
                }
            }, () => {
            });
        }
    };

    restoreHiddenCampaigns = () => {
        if (this.deleteAccess) {
            this.restoreCampaignsLoading = true;
            this.restoreCampaignsError = false;
            this.CampaignsService.restoreHiddenCampaigns(this, (ctrl, response) => {
                if (response) {
                    ctrl.restoreInfo = response;
                }
                ctrl.loadCampaigns();
                ctrl.restoreCampaignsLoading = false;
            }, (ctrl) => {
                ctrl.restoreCampaignsError = true;
                ctrl.restoreCampaignsLoading = false;
            });
        }
    };

    deleteHiddenCampaigns = () => {
        if (this.deleteAccess) {
            this.deleteCampaignsLoading = true;
            this.deleteCampaignsError = false;
            this.CampaignsService.deleteHiddenCampaigns(this, (ctrl, response) => {
                if (response) {
                    ctrl.restoreInfo = response;
                }
                this.deleteCampaignsLoading = false;
            }, (ctrl) => {
                ctrl.deleteCampaignsError = true;
                this.deleteCampaignsLoading = false;
            });
        }
    };

    setCampaignsLoading = (value) => {
        this.campaignsLoading = value;
        if (typeof (this.setLoading) == 'function') {
            this.setLoading('campaignsLoading', this.campaignsLoading);
        }
    };
}

CampaignsController.$inject = [
    '$injector',
    '$scope',
    '$localStorage',
    CAMPAIGNS_SERVICE,
    USB_SERVICE,
    '$location',
    '$ngRedux',
    'gettextCatalog'
];

export {
    CampaignsController
};
