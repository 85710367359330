import { NODE } from '../const';

export class Tree {
    constructor(data, schema, selectedData) {
        let node = new Node(data, [], null, schema, ['root'], selectedData);
        this.root = node;
    }

    traverseDF = (callBack, stopCallback = null, node) => {
        if (!node) {
            node = this.root;
        }
        (function recursive (currentNode) {
            for (let i = 0, length = currentNode.children.length; i < length; i++) {
                if (!stopCallback || !stopCallback(currentNode.children[i])) {
                    recursive(currentNode.children[i]);
                }
            }
            callBack(currentNode);
        })(node);
    }

    traverseBF = (callback, node) => {
        let queue = new Queue();
        if (!node) {
            node = this.root;
        }
        queue.addNewTargetsQueue(node);
        let currentTree = queue.deleteTargetQueue();
        while (currentTree) {
            for (let i = 0, length = currentTree.children.length; i < length; i++) {
                queue.addNewTargetsQueue(currentTree.children[i]);
            }
            callback(currentTree);
            currentTree = queue.deleteTargetQueue();
        }
    }

    filterNode = (searchQuery) => {
        // поиск по конечным нодам. Если truе проставляем show у ноды и всех ее родителей
        let nodesShow = new Set([]);
        let searchFields = ['email', 'firstName', 'position', 'secondName', 'thirdName', 'riskGroup', 'externalId'];
        this.traverseBF((data) => {
            data.show = false;
        });
        this.traverseDF(data => {
            if (data.type === 'list') {
                for (let key in data) {
                    if (searchFields.indexOf(key) !== -1 && data[key]) {
                        if (data[key].toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1) {
                            data.show = true;
                            nodesShow.add(data.id);
                            data.parent.forEach(item => {
                                nodesShow.add(item);
                            });
                        }
                    }
                }
                data.parent.forEach(item => {
                    if ((item.toLowerCase()).indexOf(searchQuery.toLowerCase()) !== -1) {
                        data.show = true;
                        nodesShow.add(data.id);
                        data.parent.forEach(item => {
                            nodesShow.add(item);
                        });
                    }
                });
            }
        });
        [...nodesShow].forEach(item => {
            if (item === 'root') return;
            this.traverseDF((data) => {
                if (data.id === item) {
                    data.show = true;
                }
            });
        });
    }

    getNodeByType = (type, node = this.root) => {
        let nodes = [];
        this.traverseDF((data) => {
            if (data.type === type) {
                nodes.push(data);
            }
        }, null, node);
        return nodes;
    }
}

function checkSelected (data, selectedData, nodeType, checkType, parentChoosen) {
    if (parentChoosen) return parentChoosen;
    if (nodeType !== NODE && checkType === 'partial') return false;
    if (nodeType !== NODE) {
        return selectedData.targets.indexOf(data.id) !== -1;
    }
    if (checkType === 'partial') {
        return selectedData.departmentsUsed.indexOf(data.id) !== -1;
    }
    if (checkType === 'checked') {
        return selectedData.departments.indexOf(data.id) !== -1;
    }
}

function createTree (data, parent, parentName, schema, type, selectedData, parentChoosen) {
    if (!parent.length) {
        for (let i = 0; i < data.length; i++) {
            this.children.push(new Node(data[i], ['root'], parentName, schema, NODE, selectedData));
        }
    } else {
        this.show = true;
        this.type = type;
        if (type !== NODE) {
            this.department = parentName;
        }
        this.parent = parent;
        schema[type + 'CurrentParams'].forEach(item => {
            this[item] = data[item];
        });
        if (!this.id) {
            this.id = data['fullName'];
        }
        this.checked = checkSelected(this, selectedData, type, 'checked', parentChoosen);
        this.partial = checkSelected(this, selectedData, type, 'partial');
        for (let key in data) {
            schema.children.forEach(item => {
                if (item.name === key) {
                    data[key].forEach(chield => {
                        let parentArr = [...parent, this[schema.parent]];
                        this.children.push(new Node(chield, parentArr, this.name, schema, item.type, selectedData, this.checked));
                    });
                }
            });
        }
    }
}

class Node {
    constructor(data, parent, parentName, schema, type, selectedData, parentChoosen) {
        this.children = [];
        createTree.call(this, data, parent, parentName, schema, type, selectedData, parentChoosen);
    }
}

class Queue {
    constructor() {
        this.oldestIndex = 1;
        this.lastIndex = 1;
        this.storage = {};
    }

    size = () => {
        return  this.lastIndex - this.oldestIndex;
    }

    addNewTargetsQueue = (data) => {
        this.storage[this.lastIndex] = data;
        this.lastIndex += 1;
    }

    deleteTargetQueue = () => {
        if (this.oldestIndex === this.lastIndex) return;
        let deletedTarget = this.storage[this.oldestIndex];
        delete this.storage[this.oldestIndex];
        this.oldestIndex += 1;
        return deletedTarget;
    }
}
