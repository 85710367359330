import React, { useContext } from "react";
import { GettextContext } from "rootReact/context";
import { format, subDays } from "date-fns";
import { ru } from "date-fns/locale";


function TargetsAttack({ date, ip, deviceTypeName, target, mac, browser }) {
    const gettext = useContext(GettextContext);
    const dayAndMonth = format(new Date(date * 1000), "dd MMMM", { locale: ru });
    const hoursAndMinutes = format(new Date(date * 1000), "HH:mm");

    return (
        <tr className={"wifi-target_list"} data-testid="target-item">
            <td className={"wifi-target_label"} data-testid="target-label">
                <div className={"wifi-target_time"}>
                    <div className={"wifi-target_time-calendar"}>
                        <svg> <use xlinkHref={`/image/icoSprite.svg#wifi-calendar-icon`}></use> </svg>
                        <span style={{width: '96px'}}>{gettext.getString(`${dayAndMonth}`)}</span>
                        
                    </div>
                    <div className={"wifi-target_time-clock"}>
                        <svg> <use xlinkHref={`/image/icoSprite.svg#wifi-clock-icon`}></use> </svg>
                        {`${hoursAndMinutes}`}
                    </div>
                </div>
            </td>
            <td className={"wifi-target_label"} data-testid="target-label">{gettext.getString(`${deviceTypeName}`)}</td>
            <td className={"wifi-target_label"} data-testid="target-label">{browser !== null ? browser : '—'}</td>
            <td className={"wifi-target_label"} data-testid="target-label">{mac !== null ? mac : '—'}</td>
            <td className={"wifi-target_label"} data-testid="target-label">{target !== null ? target.fullName : '—'}</td>
            <td className={"wifi-target_label"} data-testid="target-label">{target !== null ? target.department : '—'}</td>
        </tr>
    );
}

export default TargetsAttack;
