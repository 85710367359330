import {Controller} from './controller';
import angular from 'angular';

class ErrorController extends Controller {

    constructor($scope, $base64) {
        super();
        this.$scope = $scope;
        this.$base64 = $base64;
        this.gettextCatalog = this.$scope.appCtrl.gettextCatalog;
    }

    $onInit() {
        if (!window.errorMessage) {
            this.$scope.appCtrl.go('login');
        }
    }

    getErrorMessage = () => {
        return this.isBase64(window.errorMessage) ? this.$base64.decode(window.errorMessage) : (window.errorMessage ? window.errorMessage : '');
    };

    getErrorDebug = () => {
        return window.errorDebug || this.gettextCatalog.getString('К сожалению, режим отладки был отключен. Пожалуйста, прикрепите к письму архив с отладочной информацией.');
    };

    getErrorUrl = () => {
        return this.isBase64(window.errorUrl) ? this.$base64.decode(window.errorUrl) : (window.errorUrl ? window.errorUrl : '');
    };

    getEmailSubject = () => {
        let body = this.getErrorMessage() + ' @ ' + this.getErrorUrl();
        body = angular.element('<textarea />').html(body).text();
        return window.encodeURIComponent(body);
    };

    getEmailBody = () => {
        let body = this.getErrorDebug();
        body = angular.element('<textarea />').html(body).text();
        return window.encodeURIComponent(body);
    };

    isBase64 = (str) => {
        try {
            return btoa(atob(str)) == str;
        } catch (err) {
            return false;
        }
    };
}

ErrorController.$inject = [
    '$scope',
    '$base64',
];

export {
    ErrorController
};
