import {REPORTS_SERVICE, STATUS_SERVICE} from '../../const';
import moment from 'moment';
import style from './style.modules.css';
import { hasAccess } from '../../reselect';

class ReportModalController {
    constructor($injector, $filter, $scope, gettextCatalog, ReportsService, StatusService, params, $ngRedux, $localStorage) {
        this.style = style;
        this.$injector = $injector;
        this.$filter = $filter;
        this.$scope = $scope;
        this.gettextCatalog = gettextCatalog;
        this.$localStorage = $localStorage;
        this.ReportsService = ReportsService;
        this.StatusService = StatusService;
        this.params = params;
        this.id = params.id;
        this.title = params.title;
        this.type = params.type;
        this.groupingData = params.groupingData;
        this.selectedGroup = params.selectedGroup;
        this.total = params.total;
        this.reportCounter = {
            'total': 0,
            'opened': 0,
            'danger': 0,
            'openLink': 0,
            'openAttachment': 0,
            'openAttachmentFromLink': 0,
            'submitForm': 0,
            'critical': 0,
        };
        this.statuses = {
            opened: {
                cssClass: 'warning',
                text1: this.gettextCatalog.getString('открыл письмо'),
                text2: this.gettextCatalog.getString('открыли письма'),
            },
            danger: {
                cssClass: 'danger',
                text1: this.gettextCatalog.getString('открыл вложение или перешел по ссылке'),
                text2: this.gettextCatalog.getString('открыли вложение или перешли по ссылке'),
            },
            openLink: {
                cssClass: 'open-link',
                text1: this.gettextCatalog.getString('перешел по ссылке'),
                text2: this.gettextCatalog.getString('перешли по ссылке'),
            },
            openQrLink: {
                cssClass: 'open-link',
                text1: this.gettextCatalog.getString('перешел по ссылке в QR'),
                text2: this.gettextCatalog.getString('перешли по ссылке в QR'),
            },
            openAttachment: {
                cssClass: 'open-attachment',
                text1: this.gettextCatalog.getString('открыл вложение'),
                text2: this.gettextCatalog.getString('открыли вложение'),
            },
            openAttachmentFromLink : {
                cssClass: 'open-attachment',
                text1: this.gettextCatalog.getString('открыл файл по ссылке'),
                text2: this.gettextCatalog.getString('открыли файл по ссылке'),
            },
            submitForm: {
                cssClass: 'critical',
                text1: this.gettextCatalog.getString('ввел данные в фишинговую форму'),
                text2: this.gettextCatalog.getString('ввели данные в фишинговую форму'),
            },
            critical: {
                cssClass: 'critical',
                text1: this.gettextCatalog.getString('имеeт критические уязвимости и может быть взломан'),
                text2: this.gettextCatalog.getString('имеют критические уязвимости и могут быть взломаны'),
            },
        };
        this.reportType = 8;
        this.hasAccess = false;
        this.reportLoading = false;
        this.reportError = '';
        this.dataMode = 0;
        this.viewMode = 0;
        this.departmentsLoading = false;
        this.targetsLoading = false;
        this.ignoreSetLoading = {
            departmentsLoading: false,
            targetsLoading: false,
        };
        this.reloading = false;
        this.departmentCount = 0;
        this.appCtrl = window.appCtrl;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, null)(this);
    }

    mapStateToThis = (state) => {
        return {
            hasAttackAccess: hasAccess(state, {sectionId: 4, rightName: 'report_attack_download'}) || hasAccess(state, {sectionId: 1, rightName: 'target_download'}),
            hasDepartmentAccess:  hasAccess(state, {sectionId: 4, rightName: 'report_department_download'}) || hasAccess(state, {sectionId: 1, rightName: 'report_department'})
        };
    }

    $onInit() {
        this.hasAccess = false;
        if (this.type === 'attack') {
            if (this.params.fromReport) {
                this.hasAccess = this.appCtrl.hasAccess(4, 'report_attack_download');
            } else {
                this.hasAccess = this.appCtrl.hasAccess(2, 'attack_download');
            }
        }
        if (this.type === 'department') {
            if (this.params.fromReport) {
                this.hasAccess = this.appCtrl.hasAccess(4, 'report_department_download');
            } else {
                this.hasAccess = this.appCtrl.hasAccess(1, 'target_history');
            }
        }
        this.getCounters();
    }

    getCounters = () => {
        this.countersLoading = true;
        if (this.type === 'department') {
            this.ReportsService.departmentCounters(this.params.id).$promise.then((data) => {
                this.reportCounter = data;
                this.updateHasData();
                this.countersLoading = false;
            }, () => {
                this.countersLoading = false;
            });
        } else if (this.type === 'attack') {
            this.ReportsService.campaignCounters(this.params.id, this.selectedGroup.dateTime).$promise.then((data) => {
                this.reportCounter = data;
                this.updateHasData();
                this.countersLoading = false;
            }, () => {
                this.countersLoading = false;
            });
        }
        this.reportType = this.type === 'department' ? 9 : 8;
    };

    getReportParams = () => {
        const reportParams = {};
        reportParams.id = this.params.id;
        reportParams.type = this.params.type;
        reportParams.dateTime = this.selectedGroup && this.selectedGroup.dateTime || null;
        reportParams.status = this.selectedStatus;
        return reportParams;
    };

    isAnyLoading = () => {
        return this.departmentsLoading ||
            this.targetsLoading;
    };

    updateHasData = () => {
        this.hasData = this.reportCounter && (this.reportCounter.opened + this.reportCounter.critical + this.reportCounter.danger + this.reportCounter.submitForm) > 0;
    };

    selectGroup = (group) => {
        if (this.selectedGroup.dateTime != group.dateTime) {
            this.selectedGroup = group;
            this.selectedStatus = null;
            this.getCounters();
        }
    };

    selectStatus = (status) => {
        if (this.selectedStatus != status) {
            this.selectedStatus = status;
        } else {
            this.selectedStatus = null;
        }
    };

    getCsrfToken = () => {
        return window.csrfToken;
    };

    getNowDate = () => {
        return moment(new Date()).format('YYYY-MM-DD');
    };

    setViewMode = (viewMode) => {
        this.viewMode = viewMode;
    };

    setLoading = (loadingType = 'departmentsLoading', value = false) => {
        value = !!value;
        if (!this.ignoreSetLoading[loadingType]) {
            if (value != this[loadingType]) {
                this[loadingType] = value;
            }
        } else {
            this.ignoreSetLoading[loadingType] = false;
        }
    };

    setReloading = (reloading) => {
        this.reloading = reloading;
    };

    setDepartmentCount = (count) => {
        this.departmentCount = +count;
    };

    subFunctions = () => {
        const {
            setLoading,
        } = this;
        return {
            setLoading,
        };
    };

    dismiss = (dismiss) => {
        this.unsubscribe();
        this.$localStorage.openedDepartments = [];
        dismiss();
    }
}

ReportModalController.$inject = [
    '$injector',
    '$filter',
    '$scope',
    'gettextCatalog',
    REPORTS_SERVICE,
    STATUS_SERVICE,
    'params',
    '$ngRedux',
    '$localStorage'
];

export {
    ReportModalController,
};
