import { useContext, useState, useEffect } from "react";
import { GettextContext, InjectorContext } from "rootReact/context";
import { useSelector, useDispatch } from "react-redux";
import { ReactModal } from "root/assets/webpack/src/js/components/ReactModal";
import WifiListAttack from "./WifiListAttack.jsx";
import WifiAttackModal from "../WifiAttackModal";
import loader from "../../../../images/loading.svg";
import { fetchListWifi } from "root/assets/webpack/src/js/toolkit/think";
import iconCreate from "../../../../images/create-wifi-attack.svg";
import WifiButtonGroup from "./WifiButtonGroup.jsx";
import "react-datepicker/dist/react-datepicker.css";
import WifiPopup from "../WifiPopup";
import GeneralReport from "./GeneralReport.jsx";
import loadReport from "../../../../images/load-wifi-report.svg";
import FetchApi from "../../REST";
import AttacksBasket from "../AttacksBasket";

function WifiAttack({ isTrash }) {
    const injector = useContext(InjectorContext);
    const dispatch = useDispatch();
    const gettext = useContext(GettextContext);
    const wifiAttack = useSelector((state) => state.wifi.wifi);
    const [pagination, setPagination] = useState(10);
    const loadAttackList = useSelector((state) => state.wifi.loading);
    const [reportStart, setReportStart] = useState(false);
    const [errorAttacks, setErrorAttacks] = useState([]);
    const [startTimer, setStartTimer] = useState(false);
    const [popupError, setPopupError] = useState(false);
    const [popupDelete, setPopupDelete] = useState(false);
    const [popupDeleteDone, setPopupDeleteDone] = useState(false);
    const [attackName, setAttackName] = useState('');
    const [ldapProfileCount, setLdapProfileCount] = useState(0);
    const findChecked = wifiAttack.filter((item) => item.checked === true);
    const [deleteIds, setDeleteIds] = useState([]);
    const [timeoutPopup, setTimeoutPopup] = useState();

    useEffect(() => {
        dispatch(fetchListWifi());
    }, []);

    useEffect(() => {
        async function fetchApi() {
            const ldap = FetchApi(`/ldap-profiles`);
            let response = await ldap.get();
            if (response && response.isError) {
                throw response;
            }
            setLdapProfileCount(response.length);
        }
        try {
            fetchApi();
        } catch (e) {
            console.log(e);
        }
    }, []);

    const wifiAttackItem = wifiAttack.slice(0, pagination).map((obj) => <WifiListAttack key={obj.id} {...obj} ldapProfileCount={ldapProfileCount} />);

    const onClickShowMore = () => {
        setPagination((prevValue) => prevValue + 10);
    };

    const onCreateAttack = async () => {
        const modal = injector.instantiate(ReactModal);
        let wifiModale = await modal.open({
            component: WifiAttackModal,
        });
    };

    const handleReturnAttack = async () => {
        setPopupDelete(false)
        setPopupDeleteDone(false)
        setAttackName('')
        try {
            const response = await FetchApi(`/wifi-attacks/restore`).post({
                body: {
                    ids: deleteIds
                },
            });
            if (response && response.isError) {
                throw response;
            }
            dispatch(fetchListWifi());
        } catch (e) {
            console.log(e);
        }
    };

    const closeDeletePopup = () => {
        setPopupDelete(false)
        setPopupDeleteDone(false)
        setAttackName('')
    }

    useEffect(() => {
        if (setPopupDeleteDone) {
            setTimeoutPopup(setTimeout(closeDeletePopup, 3000)) 
        } else {
            setTimeoutPopup(clearTimeout(timeoutPopup))
        }
    }, [popupDeleteDone])

    return (
        <>
            {
                isTrash ?
                    <AttacksBasket deleteIds={deleteIds} />
                    :
                    <>
                        <div className="wifi-attack" data-testid="wifi-attack">

                            <div className="wifi-attack_toolbar">
                                <div className="wifi-attack_toolbar-content">
                                    <WifiButtonGroup
                                        findChecked={findChecked}
                                        setErrorAttacks={setErrorAttacks}
                                        setReportStart={setReportStart}
                                        startTimer={startTimer}
                                        setStartTimer={setStartTimer}
                                        setPopupDelete={setPopupDelete}
                                        setPopupDeleteDone={setPopupDeleteDone}
                                        setAttackName={setAttackName}
                                        setPopupError={setPopupError}
                                        popupDelete={popupDelete}
                                        setDeleteIds={setDeleteIds}
                                    />
                                    <button data-testid="create-attack" onClick={onCreateAttack} className="wifi-attack_create">
                                        <img className="wifi-attack_icon-create" src={iconCreate} />
                                        <span>{gettext.getString("Новая атака")}</span>
                                    </button>
                                </div>
                            </div>
                            <div className="wifi-attack_full-list" data-testid="attack-list">
                                {loadAttackList ? (
                                    <div className={"wifi-attack_loader"}>
                                        <img src={loader} />
                                    </div>
                                ) : !wifiAttack.length ? (
                                    <div className={"wifi-target_empty"}>{gettext.getString("Нет созданных атак")}</div>
                                ) : (
                                    wifiAttackItem
                                )}
                            </div>
                        </div>
                        {pagination < wifiAttack.length && !loadAttackList ? (
                            <div
                                data-testid="wifi-attackPagination"
                                onClick={() => onClickShowMore()}
                                className={"wifi-attack_pagination"}
                            >
                                <button className={"wifi-attack_pagination-btn"}>{gettext.getString("Показать еще")}</button>
                            </div>
                        ) : (
                            ""
                        )}
                        {popupError && (
                            <div className={"wifi-target_block"}>
                                <WifiPopup errorAttacks={errorAttacks} setErrorAttacks={setErrorAttacks} setPopupError={setPopupError} />
                            </div>
                        )}
                        {popupDelete &&
                            <div className={"wifi-attack_delete-popup"}>
                                <div className={"wifi-attack_delete-popup_content"}>
                                    {
                                        popupDeleteDone ?
                                            <div>
                                                <svg className={"wifi-attack_delete-popup_img"}> <use xlinkHref={`/image/icoSprite.svg#popup-delete-icon-done`}></use> </svg>
                                            </div>

                                            :
                                            <div>
                                                <svg className={"wifi-attack_delete-popup_img"}> <use xlinkHref={`/image/icoSprite.svg#popup-delete-icon`}></use> </svg>
                                            </div>
                                    }
                                    <span className={"wifi-attack_delete-popup_text"}>
                                        {gettext.getString(`${attackName.length > 1 ? 'Вы удалили атаки' : 'Вы удалили атаку'}`)}
                                        {gettext.getString(` ${attackName.join(', ')}`)}
                                    </span>
                                    {
                                        !popupDeleteDone && <button data-testid="return-attack" onClick={handleReturnAttack} className={"wifi-attack_delete-popup_btn"}>{gettext.getString("Вернуть")}</button>
                                    }
                                </div>
                            </div>
                        }                     
                    </>
            }
            <GeneralReport />
        </>
    );
}

export default WifiAttack;
