import { useState, useEffect } from "react";

export const useInput = (initialValue, validations) => {
    const [value, setValue] = useState(initialValue);
    const [isDirty, setDirty] = useState(false);
    const valid = useValidateForm(value, validations);

    const onChange = (e) => {
        setValue(e);
    };

    const onBlur = (e) => {
        setDirty(true);
    };
    return {
        value,
        onChange,
        onBlur,
        isDirty,
        ...valid,
    };
};

const useValidateForm = (value, validations) => {
    const [isEmpty, setEmpty] = useState(true);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [ipError, setIpError] = useState(false);
    const [inputValid, setInputValid] = useState(false);

    useEffect(() => {
        for (const validation in validations) {
            switch (validation) {
                case "isEmpty":
                    value ? setEmpty(false) : setEmpty(true);
                    break;
                case "emailError":
                    const re =
                        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                    re.test(String(value).toLowerCase()) ? setEmailError(false) : setEmailError(true);
                    break;
                case "phoneError":
                    const re2 = /^\+?[78][-\(]?\d{3}\)?-?\d{3}-?\d{2}-?\d{2}$/i;
                    re2.test(String(value).toLowerCase()) ? setPhoneError(false) : setPhoneError(true);
                    break;
                case "ipError":
                    const re3 = /^(?:1?[0-9]{1,2}|2[0-4][0-9]|25[0-5])(?:\.(?:1?[0-9]{1,2}|2[0-4][0-9]|25[0-5])){3}(?::(?:[0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5]))?$/;
                    re3.test(String(value).toLowerCase()) ? setIpError(false) : setIpError(true);
                    break;
            }
        }
    }, [value]);

    useEffect(() => {
        if (isEmpty || emailError) {
            setInputValid(false);
        } else {
            setInputValid(true);
        }
    }, [isEmpty, emailError]);

    return {
        isEmpty,
        emailError,
        phoneError,
        ipError,
        inputValid,
    };
};
