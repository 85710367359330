import React, {useContext, useState, useEffect} from 'react'
import { GettextContext } from 'rootReact/context';

function OutlookPreview(props) {
    const {
        buttonText, header,
        logo, optionComments,
        optionReasonShipment, optionTypeAttack,
        optionVectorAttack, text,
    } = props
    const gettext = useContext(GettextContext)

    const [wrapperHeight, setWrapperHeight] = useState()

    const getVectorsItem = () => {
        const vectors = [
            [gettext.getString('Страх'), 'rgb(219, 233, 250)'],
            [gettext.getString('Раздражение'), 'rgb(244, 204, 204)'],
            [gettext.getString('Любопытство'), 'rgb(217, 234, 211)'],
            [gettext.getString('Жадность'), 'rgb(253, 229, 153)'],
            [gettext.getString('Авторитет'), 'rgb(242, 242, 242)'],
            [gettext.getString('Срочность'), 'rgb(242, 242, 242)'],
            [gettext.getString('Невнимательность'), 'rgb(234, 209, 220)'],
            [gettext.getString('Желание помочь'), 'rgb(248, 203, 156)'],
        ]
        return vectors.map( item => (<React.Fragment key={Math.random()}>
            <span className={'preview_list-item'}
                  style={{backgroundColor: item[1]}}>
                <span className={'preview_list-item__text'}>{item[0]}</span>
                <span className={'preview_pointer preview_cross'}></span>
            </span>
        </React.Fragment>))
    }

    const getSuspectItems = () => {
        const items = [
            [gettext.getString('Адрес отправителя'), 'rgb(242, 242, 242)'],
            [gettext.getString('Вложение'), 'rgb(242, 242, 242)'],
            [gettext.getString('Свой вариант'), 'rgb(242, 242, 242)'],
            [gettext.getString('Ссылка в письме'), 'rgb(242, 242, 242)'],
            [gettext.getString('Я не ждал этого письма'), 'rgb(242, 242, 242)'],
        ]

        return items.map( item => (<React.Fragment key={Math.random()}>
            <span className={'preview_list-item'}
                  style={{backgroundColor: item[1]}}>
                <span className={'preview_list-item__text'}>{item[0]}</span>
                <span className={'preview_pointer preview_cross'}></span>
            </span>
        </React.Fragment>))
    }

    useEffect(() => {
        setWrapperHeight(window.innerHeight - 200)

    }, [])

    return (
            <div className={'outlook_path'}>
                <div className={'outlook-main'}>
                    <div className={'outlook_sub-header outlook_sub-header__right pt-0'}>
                        <span>{gettext.getString('Превью плагина')}</span>
                    </div>
                </div>
                <div className={'outlook_main'} style={{ position: 'sticky', top: '160px', backgroundColor: '#fff'}}>
                    <div className={'outlook_preview-wrapper'} style={{height: wrapperHeight}}>
                        <div className={'preview_icon-wrapper'}>
                            <img src={logo} alt="logo"/>
                        </div>
                        <div className={'preview_section-header'}>{header}</div>
                        <div className={'preview_sub-text-wrapper'}><span>{text}</span></div>
                        {optionVectorAttack ? <div>
                            <span className={'preview_title'}>{gettext.getString('Какие эмоции вызвала атака?')}</span>
                            <div>
                                <div className={'preview_parent-wrapper'}>
                                    <span className={'preview_dropdown-item-arrow'}>
                                        <svg className="dark-mode-fill" version="1.1" id="Capa_1"
                                             xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10px" height="10px"
                                             viewBox="0 0 255 255"><g><g id="arrow-drop-down"><polygon
                                                points="0,63.75 127.5,191.25 255,63.75"></polygon></g></g></svg>
                                    </span>
                                    {getVectorsItem()}
                                </div>
                            </div>
                        </div> : ''}
                        {optionTypeAttack ? <div>
                            <span className={'preview_title'}>{gettext.getString('Какая это была атака?')}</span>
                            <div>
                                <div className={'preview_parent-wrapper preview_sub-text-include'}>
                                    <span className={'preview_dropdown-item-arrow'}>
                                        <svg className="dark-mode-fill" version="1.1" id="Capa_1"
                                             xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10px" height="10px"
                                             viewBox="0 0 255 255"><g><g id="arrow-drop-down"><polygon
                                                points="0,63.75 127.5,191.25 255,63.75"></polygon></g></g></svg>
                                    </span>
                                    <span className={'preview_list-item'}>
                                        <span className={'preview_list-item__text'}>{gettext.getString('Корпоративная')}</span>
                                        <span className={'preview_pointer preview_cross'}></span>
                                    </span>
                                </div>
                            </div>
                            <span className={'preview_source-text'}>{gettext.getString('от имени коллег или руководителей')}</span>
                        </div> : ''}
                        {optionReasonShipment ? <div>
                            <span className={'preview_title'}>{gettext.getString('Что показалось подозрительным?')}</span>
                            <div>
                                <div className={'preview_parent-wrapper preview_sub-text-include'}>
                                    <span className={'preview_dropdown-item-arrow'}>
                                        <svg className="dark-mode-fill" version="1.1" id="Capa_1"
                                             xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10px" height="10px"
                                             viewBox="0 0 255 255"><g><g id="arrow-drop-down"><polygon
                                                points="0,63.75 127.5,191.25 255,63.75"></polygon></g></g></svg>
                                    </span>
                                    {getSuspectItems()}
                                </div>
                                <div className={'preview_parent-wrapper'}>
                                    <span className={'preview_list-item'}>
                                        <span className={'preview_list-item__text'} style={{color: '#ccc'}}>{gettext.getString('Мой вариант')}</span>
                                    </span>
                                </div>
                            </div>
                        </div> : ''}
                        {optionComments ? <div>
                            <span className={'preview_title'}>{gettext.getString('Здесь вы можете оставить любой комментарий о подозрительном письме или работе плагина')}</span>
                            <div>
                                <div className={'preview_parent-wrapper preview_sub-text-include'} style={{height: '80px', alignItems: 'baseline'}}>
                                    <span className={'preview_list-item'}>
                                        <span className={'preview_list-item__text'} style={{color: '#ccc'}}>{gettext.getString('Комментарий')}</span>
                                    </span>
                                </div>
                                <span className={'preview_source-text'}>{gettext.getString('Не более {{num}} символов', {num: 1000})}</span>
                            </div>
                        </div> : ''}
                        <div className={'preview_submit-wrapper'}>
                            <div className={'preview_submit-button'}>{buttonText}</div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default OutlookPreview;
