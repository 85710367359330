import React, {useState, useContext} from 'react'
import { GettextContext } from 'rootReact/context'
import UrlInput from 'rootReact/components/UrlInput'
import { useAutoWidth, useInputStyle, usePlural, useValidForm } from 'rootReact/hook'
import { educationRule, getEducationRules } from './EducationSystemRule'
import Input from 'rootReact/components/Input';
import FaIcon from 'rootReact/components/FaIcon';
import {validRules} from '../../../helpers'
import { useSelector } from 'react-redux';

const READY = 'READY'
const LOADING = 'LOADING'
const SUCCESS = 'SUCCESS'

function EducationSystem({learningConfig, licenseIsValid, saveSettings, error, updateCourse}) {
    const gettext = useContext(GettextContext)
    const gpbMode = useSelector(state => state.auth.auth.gpbMode)
    const [type, setType] = useState(learningConfig.type === 2 ? 1 : learningConfig.type)
    const [baseUrl, setBaseUrl] = useState(learningConfig.baseUrl || 'http://')
    const [token, setToken] = useState(learningConfig.token || '')
    const [learningValue, setLearningValue] = useState(learningConfig.timeValue || 1)
    const [learningPeriod, setLearningPeriod] = useState(learningConfig.timePeriod || 'day')
    const [userSyncUrl, setUserSyncUrl] = useState(learningConfig.userSyncUrl ? learningConfig.userSyncUrl : gpbMode ? null : 'http://')
    const [login, setLogin] = useState(learningConfig.login || '')
    const [password, setPassword] = useState('')
    const [isNtlm, setIsNtlm] = useState(learningConfig.isNtlm)
    const [ntlmLogin, setNtlmLogin] = useState(learningConfig.ntlmLogin)
    const [ntlmPassword, setNtlmPassword] = useState('')
    const [learningStatus, setLearningStatus] = useState(READY)

    const values = useValidForm({
        baseUrl: { value: baseUrl, setValue: setBaseUrl },
        token: {value: token, setValue: setToken},
        userSyncUrl: {value: userSyncUrl, setValue: setUserSyncUrl},
        login: {value: login, setValue: setLogin },
        password: {value: password, setValue: setPassword},
        ntlmLogin: {value: ntlmLogin, setValue: setNtlmLogin},
        ntlmPassword: {value: ntlmPassword, setValue: setNtlmPassword}
    }, educationRule)
    const baseUrlStyle = useInputStyle(['react-input_field react-input_field__right'], values['baseUrl'].touched, values['baseUrl'].focused, values['baseUrl'].error)
    const tokenStyle = useInputStyle(['react-input_field'], values['token'].touched, values['token'].focused, values['token'].error)
    const userSyncUrlStyle = useInputStyle(['react-input_field react-input_field__right'], values['userSyncUrl'].touched, values['userSyncUrl'].focused, values['userSyncUrl'].error)
    const loginStyle = useInputStyle(['react-input_field'], values['login'].touched, values['login'].focused, values['login'].error)
    const passwordStyle = useInputStyle(['react-input_field'], values['password'].touched, values['password'].focused, values['password'].error)
    const widthLearningCount = useAutoWidth(learningValue, {minChars: 2})
    const ntlmPasswordStyle = useInputStyle(['react-input_field'], values['ntlmPassword'].touched, values['ntlmPassword'].focused, values['ntlmPassword'].error)
    const ntlmLoginStyle = useInputStyle(['react-input_field'], values['ntlmLogin'].touched, values['ntlmLogin'].focused, values['ntlmLogin'].error)

    const save = () => {
        setLearningStatus(LOADING)
        let validateFields = [];
        if (type) {
            for (let key in values) {
                if (educationRule[key] && key !== 'token') {
                    validateFields.push({name: key, value: values[key].inputAttr.value})
                }
            }
        } else {
            validateFields.push({name: 'token', value: token})
            validateFields.push({name: 'baseUrl', value: baseUrl})
        }
        const viewRules = getEducationRules(type, isNtlm, gpbMode)
        const errors = validRules(viewRules, validateFields)
        if (Object.keys(errors).length) {
            values.setError(errors)
            setLearningStatus(READY)
            return;
        }
        let config = {
            type,
            baseUrl,
            token,
            timePeriod: learningPeriod,
            timeValue: learningValue,
            userSyncUrl,
            login,
            password,
            ntlmPassword,
            isNtlm,
            ntlmLogin
        }
        saveSettings(config)
        setLearningStatus(SUCCESS)
        setTimeout(() => setLearningStatus(READY), 2000);
    }

    const handleLearningValue = (value) => {
        if (value < 1) return setLearningValue(1)
        setLearningValue(value)
    }

    const update = () => {
        let config = {
            ...learningConfig,
            type,
            baseUrl,
            token,
            timePeriod: learningPeriod,
            timeValue: learningValue,
            userSyncUrl,
            login,
            password,
            ntlmPassword,
            isNtlm,
            ntlmLogin
        }
        updateCourse(config)
    }

    const getMoodle = () => {
        return (
            <>
                <div className={'settings_item'}>
                    <Input
                        inputId={'token'}
                        PlaceHolder={gettext.getString('Изменить токен')}
                        label={gettext.getString('Токен')}
                        inputAttr={values['token'].inputAttr}
                        error={values['token'].error}
                        Style={tokenStyle}
                    />
                </div>
            </>
        )
    }

    const getWebTutor = () => {
        return (
            <>
                {!gpbMode && <div className={'settings_item'} style={{alignItems: 'center'}}>
                    <div className={'settings_path-left'}>
                        {gettext.getString('Адрес API для синхронизации пользователей')}
                    </div>
                    <div className={'row-wrapper'}>
                        <div className={'settings_path-right'}>
                            <UrlInput
                                value={values['userSyncUrl'].inputAttr.value}
                                setValue={values['userSyncUrl'].inputAttr.onChange}
                                handleBlur={values['userSyncUrl'].inputAttr.onBlur}
                                handleFocus={values['userSyncUrl'].inputAttr.onFocus}
                                Style={userSyncUrlStyle}
                            />
                        </div>
                    </div>
                </div>}
                <div className={'settings_item'}>
                    <div className={'settings_path-left'}></div>
                    <div className={'settings_path-right'}>
                        <div className={'is-ntlm_wrapper'}
                            onClick={() => {setIsNtlm(!isNtlm)}}
                        >
                            <div className={`react-checkbox is-ntlm_checkbox ${isNtlm ? 'react-checkbox_active' : ''}`}></div>
                            {gettext.getString('NTLM аутентификация')}
                        </div>
                    </div>
                </div>
                {isNtlm ? <>
                    <div className={'settings_item'}>
                        <Input
                                inputId={'ntlm-login'}
                                labelStyle={'settings-auth_label'}
                                label={gettext.getString('NTLM логин')}
                                inputAttr={values['ntlmLogin'].inputAttr}
                                error={values['ntlmLogin'].error}
                                Style={ntlmLoginStyle}
                        />
                    </div>
                    <div className={'settings_item'}>
                        <Input
                                inputId={'ntlm-password'}
                                labelStyle={'settings-auth_label'}
                                type={'password'}
                                label={gettext.getString('NTLM пароль')}
                                inputAttr={values['ntlmPassword'].inputAttr}
                                error={values['ntlmPassword'].error}
                                Style={ntlmPasswordStyle}
                        />
                    </div>
                </> : ''}
                <div className={'settings_item'}>
                    <Input
                        inputId={'login'}
                        label={gettext.getString('Пользователь')}
                        inputAttr={values['login'].inputAttr}
                        error={values['login'].error}
                        Style={loginStyle}
                    />
                </div>
                <div className={'settings_item'}>
                    <Input
                            inputId={'password'}
                            label={gettext.getString('Пароль')}
                            PlaceHolder={gettext.getString('Изменить пароль')}
                            inputAttr={values['password'].inputAttr}
                            error={values['password'].error}
                            type={'password'}
                            Style={passwordStyle}
                    />
                </div>
            </>
        )
    }

    return (
        <div>
            {!!learningConfig.isVisible && <><div className={'settings_item'}>
                <div className={'settings_path-left'}>
                    {gettext.getString('Тип системы')}
                </div>
                <div className={'settings_path-right'}>
                    <div className={'row-wrapper'}>
                        <button className={`${!type ? 'react-button_element__active' : ''} react-button_element react-button_element__sm react-button_element__left`}
                                onClick={() => {setType(0)}}
                        >Moodle</button>
                        <button className={`${type ? 'react-button_element__active' : ''} react-button_element react-button_element__sm react-button_element__right`}
                                onClick={() => {setType(1)}}
                        >WebTutor</button>
                    </div>
                </div>
            </div>
            <div className={'settings_item'}>
                <div className={'settings_path-left'}>
                    {gettext.getString('Адрес')}
                </div>
                <div className={'row-wrapper'}>
                    <div className={'settings_path-right'}>
                        <UrlInput
                                value={values['baseUrl'].inputAttr.value}
                                setValue={values['baseUrl'].inputAttr.onChange}
                                handleBlur={values['baseUrl'].inputAttr.onBlur}
                                handleFocus={values['baseUrl'].inputAttr.onFocus}
                                Style={baseUrlStyle}
                        />
                    </div>
                </div>
            </div>
            {!type ? getMoodle() : getWebTutor()}
            </>}
            <div className={'settings_item'}>
                <div className={'settings_path-left'} style={{whiteSpace: 'nowrap'}}>
                    {gettext.getString('На прохождение курсов')}
                </div>
                <div className={'settings_path-right'}>
                    <div className={'row-wrapper'}>
                        <input type={'number'}
                               className={'react-input_field react-input_field__sm'}
                               style={{width: widthLearningCount, marginRight: 0}}
                               value={learningValue}
                               onChange={(e) => handleLearningValue(e.target.value)}/>
                        <button className={`${learningPeriod === 'day' ? 'react-button_element__active' : ''} react-button_element react-button_element__sm react-button_element__left`}
                                onClick={() => {setLearningPeriod('day')}}
                        >{gettext.getString(usePlural(learningValue, ['день', 'дня', 'дней']))}</button>
                        <button onClick={() => {setLearningPeriod('week')}}
                                className={`${learningPeriod !== 'day' ? 'react-button_element__active' : ''} react-button_element react-button_element__sm react-button_element__right`}>{gettext.getString(usePlural(learningValue, ['неделя', 'недели', 'недель']))}</button>
                    </div>
                </div>
            </div>
            <div className={'settings_item'}>
                <div className={'settings_path-left'}></div>
                <div className={'settings_path-right'}>
                    {error && <div className={'text-danger'}>
                        <span className={'fa fa-exclamation-triangle'}></span>
                        {error}
                    </div>}
                    {learningStatus === LOADING && <div style={{display: 'flex'}}><FaIcon type={'pending'} /> <a className={'nolink'}>{gettext.getString('Сохранить настройки')}</a></div>}
                    {learningStatus === READY && <><a onClick={save} className={!licenseIsValid ? 'nolink' : null}>{gettext.getString('Сохранить настройки')}</a> <span>{gettext.getString('или')}</span> <a onClick={update} className={!licenseIsValid ? 'nolink' : null}>{gettext.getString('Обновить назначения')}</a></>}
                    {learningStatus === SUCCESS && <div style={{display: 'flex'}}><FaIcon type={'success'} /> <a className={'nolink'}>{gettext.getString('Изменения сохранены')}</a></div>}
                </div>
            </div>
        </div>
    )
}

export default EducationSystem
