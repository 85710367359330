import React from 'react'

function StaticNotification({type, text, children}) {
    return (
        <div className={`static-notification static-notification__${type}`}>
            {children ? children : <span>{text}</span>}
        </div>
    )
}

export default StaticNotification
