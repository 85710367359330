import React, { useContext, useRef, useCallback } from "react";
import { GettextContext } from "rootReact/context";
import { useDropzone } from 'react-dropzone';


function DragAndDrop({ setDragFile, accept }) {
    const gettext = useContext(GettextContext);
    const fileRef = useRef(null);


    const onDrop = useCallback((acceptedFiles) => {
        setDragFile(acceptedFiles[0])
    }, []);


    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
    });


    const handleFile = (e) => {
        e.preventDefault();
        setDragFile(e.target.files[0]);
    };


    const handleChooseFile = () => {
        if (fileRef.current) {
            fileRef.current.click();
        }
    };


    return (
        <div
            className={"react-drag"}
            {...getRootProps()}
        >
            <span className={"react-drag_text"}>{gettext.getString("Перетащите файл сюда или")}</span>
            <button onClick={handleChooseFile} className={"react-drag_btn"}>
                {gettext.getString("Выберите файл")}
            </button>
            <input
                onChange={handleFile}
                ref={fileRef}
                accept={accept}
                type="file"
                id="input-file"
                {...getInputProps()}
            />
        </div>
    );
}


export default DragAndDrop;
