import style from './style.modules.css';

class CampaignViewController {
    constructor(cookies, gettextCatalog) {
        this.langCode = cookies.get('langCode');
        this.style = style;
        this.sourceFieldText = {
            2: gettextCatalog.getString('Внешняя атака'),
            3: gettextCatalog.getString('Корпоративная'),
            4: gettextCatalog.getString('Личная'),
        };
        this.instructionFileName = gettextCatalog.getString('инструкция по настройке домена.pdf');
    }

    $onInit() {}

    getInstructionUrl = () => {
        return '/document/domain_setting_up_' + this.langCode + '.pdf';
    }

    cross_download(url, fileName) {
        var req = new XMLHttpRequest();
        req.open('GET', url, true);
        req.responseType = 'blob';
        var __fileName = fileName;
        req.onload = function () {
            var blob = req.response;
            var contentType = req.getResponseHeader('content-type');
            if (window.navigator.msSaveOrOpenBlob) {
                // Internet Explorer
                window.navigator.msSaveOrOpenBlob(new Blob([blob], {type: contentType}), fileName);
            } else {
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.download = __fileName;
                link.href = window.URL.createObjectURL(blob);
                link.click();
                document.body.removeChild(link); //remove the link when done
            }
        };
        req.send();
    }

    $onDestroy = () => {};
}

CampaignViewController.$inject = [
    '$cookies',
    'gettextCatalog'
];

export {
    CampaignViewController,
};
