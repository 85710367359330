import * as React from 'react';
import { Provider } from 'react-redux';
import { GettextContext, ConfigContext, LocationContext, InjectorContext} from '../context';

function ReduxDecorator ({reduxProvider, render, gettextCatalog, location, injector, passThrough}) {
    return (
        <Provider store={reduxProvider}>
            <LocationContext.Provider value={location}>
                <InjectorContext.Provider value={injector}>
                    <ConfigContext.Provider value={window.config}>
                        <GettextContext.Provider value={gettextCatalog}>
                            <App render={render} passThrough={passThrough}/>
                        </GettextContext.Provider>
                    </ConfigContext.Provider>
                </InjectorContext.Provider>
            </LocationContext.Provider>
        </Provider>
    );
}

const App = ({render, passThrough}) => {
    return (<>{render(passThrough)}</>)
}

export default ReduxDecorator;
