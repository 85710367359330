import React, {useState, useEffect} from 'react'
import Tooltip from 'rootReact/components/Tooltip';

function ReactInputMultiple({inputId, initialState, placeHolder, setValue, disabled, isRequiredBehavior}) {
    const [currentValue, setCurrentValue] = useState('')
    const [selectedValues, setSelectedValues] = useState(initialState || [])

    const removeItem = (selected) => {
        if (disabled) return
        setSelectedValues(selectedValues.filter(item => item !== selected))
    }

    const getItems = () => {
        return selectedValues.map(item => {
            return <div key={item}
                        className={'multiple_item'}
                        onClick={(e) => {e.preventDefault()}}
            >
                <span className={'multiple_text'}>{item}</span>
                <span onClick={() => removeItem(item)} className={'close ui-select-match-close'} > ×</span>
            </div>
        })
    }

    const setSelectedValue = () => {
        if (!currentValue) return
        let selected = selectedValues.filter(() => true)
        selected.push(currentValue.trim())
        setSelectedValues(selected)
        setCurrentValue('')
    }

    const pressEnter = (event) => {
        if (event.key !== 'Enter') return
        setSelectedValue()
    }

    useEffect(() => {
        if (initialState) {
            setSelectedValues(initialState)
        }
    }, [initialState])

    useEffect(() => {
        setValue(selectedValues, inputId)
    },[selectedValues])

    return (
        <label className={isRequiredBehavior && !selectedValues.length ? 'multiple multiple__error' : 'multiple'} id={inputId}>
            {getItems()}
            <Tooltip reactTip={placeHolder}>
                {!disabled && <input
                    type="text"
                    className={'multiple_input'}
                    onBlur={setSelectedValue}
                    value={currentValue}
                    onKeyUp={pressEnter}
                    placeholder={selectedValues.length ? '' : placeHolder}
                    onChange={(e) => setCurrentValue(e.target.value)}
                />}
            </Tooltip>
        </label>

    )
}

export default ReactInputMultiple
