import bottomPanelTemplate from './view.html';

import {BottomPanelController} from './controller';

const BottomPanelComponent = {
    template: bottomPanelTemplate,
    controller: BottomPanelController,
    controllerAs: '$',
    scope: true,
    bindings: {
        educationMode: '<',
        groupsMode: '<',
        mode: '<',
        isReBuildLoading: '<',
        trainingOpen: '=',
        licenseIsValid: '<',
        courseList: '<',
        trainingIsOpen: '=',
        loadingSelectedCount: '<',
        trainingSuccsess: '=',
        isAnyLoading: '<',
        setLoading: '<',
        clearAll: '<',
        setReloading: '<',
        isRisk: '<',
        fixVulnerabilities: '<',
        isFixLoading: '<',
    },
};

export {
    BottomPanelComponent,
};
