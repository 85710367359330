import saveFileTemplate from './view.html';

import {SaveFileController} from './controller';

const SaveFileComponent = {
    template: saveFileTemplate,
    controller: SaveFileController,
    controllerAs: '$',
    scope: true,
    bindings: {
        fileType: '@',
        iconClass: '@',
        linkClass: '@',
        errorClass: '@',
        errorPosition: '@',
        accessSection: '@',
        accessRight: '@',
        id: '<',
        disabled: '<',
        linkText: '<',
        loadingText: '<',
        errorText: '<',
        setLoading: '<',
        setError: '<',
        filterDates: '<',
        onClickLink: '<',
        isMinDate: '<',
    },
};

export {
    SaveFileComponent,
};
