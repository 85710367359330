import {USB_SERVICE} from '../../const';

class UsbAttackDeleteModalController {
    constructor(UsbService, attack) {
        this.UsbService = UsbService;
        this.attack = attack;
        this.deleteStatistics = true;
    }

    delete = () => {
        if (this.attack) {
            this.deleteAttackError = false;
            this.deleteAttackLoading = true;
            this.UsbService.deleteAttack(this.attack.id, {deleteStatistics: this.deleteStatistics ? 1 : 0}, this, (ctrl) => {
                ctrl.$close(ctrl.attack);
                this.deleteAttackLoading = false;
            }, (ctrl) => {
                this.deleteAttackLoading = false;
                ctrl.deleteAttackError = true;
            });
        }
    };
}

UsbAttackDeleteModalController.$inject = [
    USB_SERVICE,
    'attack',
];

export {
    UsbAttackDeleteModalController,
};
