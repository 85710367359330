import React, {useContext, useRef, useState} from 'react'
import SettingsHeader from 'rootReact/components/SettingsSectionHeader'
import { GettextContext } from 'rootReact/context'
import FetchApi from '../../REST'
import FaIcon from '../FaIcon';

function SystemUpdate({licenseIsValid}) {
    const gettext = useContext(GettextContext)
    const fetchApi = FetchApi('/settings/update-system')
    const [file, setFile] = useState(null)
    const inputReference = useRef(null)
    const [uploading, setUploading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(null)

    const selectFile = () => {
        if (!licenseIsValid) return
        inputReference.current.click()
    }

    const handleChange = (e) => {
        if (!licenseIsValid) return
        let file = e.target.files[0]
        setFile(file)
    }

    const uploadFile = async () => {
        if (!licenseIsValid) return
        setUploading(true)
        setError(null)
        let response = await fetchApi.upload(file, file.name)
        if (response.isError) {
            setError(response.err.message)
        }
        setSuccess(true);
        setFile(null);
        setUploading(false);
        setTimeout(() => {
            setSuccess(false)
        }, 3000);
    }

    const clearFile = () => {
        setFile(null)
        setUploading(false)
        setError(null)
    }

    return (
            <div>
                <SettingsHeader title={gettext.getString('Обновление системы')}/>
                {!file && !success && <><a className={!licenseIsValid ? 'nolink' : ''} onClick={() => {selectFile()}}>{gettext.getString('Выберите файл')}</a>
                    <input style={{display: 'none'}}
                           type="file"
                           accept="application/zip"
                           disabled={!licenseIsValid}
                           autoComplete="off"
                           onChange={(e) => handleChange(e)}
                           ref={inputReference}/></>
                }
                {success && <FaIcon type={'success'} text={gettext.getString('Система обновлена')} />}
                {file && <div className={'wrapper-column'}>
                    <div className={'wrapper'}><span>{file.name}</span><span onClick={clearFile} className={'close_update'}>&times;</span></div>
                    {uploading ? <FaIcon type={'pending'} text={gettext.getString('Обновление') + '...'} /> : <a onClick={uploadFile}>{gettext.getString('Обновить')}</a>}
                    {error && <FaIcon type={'error'} text={error} />}
                </div>}
            </div>
    )
}

export default SystemUpdate
