import React, {useEffect, useContext} from 'react'
import SettingsAuth from 'rootReact/containers/SettingsAuth'
import SettingsLicense from 'rootReact/components/SettingsLicense'
import Settings2FA from 'rootReact/components/Settings2FA'
import { GettextContext } from 'rootReact/context'
import SystemUpdate from 'rootReact/components/SystemUpdate'
import SettingsAnonym from 'rootReact/components/SettingsAnonym'
import SettingsOutlook from 'rootReact/components/SettingsOutlook'
import { fetchSettings, fetchNotification, fetchAccount, fetchOutlookSettings, fetchRoles, fetchListWifiDevice } from '../../../toolkit/think'
import {settingsDefault, setDefaultSystemNotification} from '../../../toolkit/actions';
import { useSelector, useDispatch } from 'react-redux'
import SettingsSso from 'rootReact/components/SettingsSso';
import { hasAccess } from '../../../reselect';
import SettingsNotifications from 'rootReact/components/SettingsNotifications';
import SettingsEducation from 'rootReact/components/SettingsEducation';
import SettingsCourses from 'rootReact/components/SettingsCourses';
import SettingsCert from 'rootReact/components/SettingsCert';
import SettingsAccount from 'rootReact/components/SettingsAccount';
import SettingsRole from 'rootReact/components/SettingsRole';
import Scheduler from 'rootReact/components/Scheduler';
import SettingsLdap from 'rootReact/components/SettingsLdap';
import SettingsVariables from 'rootReact/components/SettingsVariables';
import SettingsMailTemplate from 'rootReact/components/SettingsMailTemplate';
import SettingsNewEmployee from 'rootReact/components/SettingsNewEmployee';
import SettingsBossKadrovik from 'rootReact/components/SettingsBossKadrovik';
import FaIcon from '../../components/FaIcon';
import OutlookCustomForm from 'rootReact/components/OutlookCustomForm';
import WifiAttack from 'rootReact/components/WifiDevice';
import Notification from 'rootReact/components/NotificationTable/NotificationWrapper.jsx';
import Courses from 'rootReact/components/Courses';

function Settings({debugArchiveEnabled, zipClassExists, languages}) {
    const gettext = useContext(GettextContext);
    const manifestLink = useSelector(state => state.settings.settings.manifestLink)
    const dispatch = useDispatch()
    const notificationStatus = useSelector(state => state.systemNotification.status)
    const settingsStatus = useSelector(state => state.settings.load)
    const learningConfig = useSelector(state => state.settings.settings && state.settings.settings.learning)
    const viewAccess = useSelector((state) => hasAccess(state, {sectionId: 5, rightName: 'view'}))
    const eduView = useSelector(state => hasAccess(state, {sectionId: 6, rightName: 'view'}))
    const courseView = useSelector(state => hasAccess(state, {sectionId: 8, rightName: 'view'}))
    const auth = useSelector(state => state.auth.auth)
    const accountView = useSelector(state => hasAccess(state, {sectionId: 101, rightName: 'view'}))
    const roleView = useSelector(state => hasAccess(state, {sectionId: 102, rightName: 'view'}))
    const schedulerView = useSelector(state => hasAccess(state, {sectionId: 9, rightName: 'view'}))
    const ldapView = useSelector(state => hasAccess(state, {sectionId: 10, rightName: 'view'}))
    const variableView = useSelector(state => hasAccess(state, {sectionId: 11, rightName: 'view'}))
    const mailTemplateView = useSelector(state => hasAccess(state, {sectionId: 12, rightName: 'view'}))
    const bossView = useSelector(state => hasAccess(state, {sectionId: 13, rightName: 'view'}))
    const certShow = useSelector(state => state.auth.auth.license.features.cert)
    const setSettingsNewDay = useSelector(state => hasAccess(state, {sectionId: 5, rightName: 'settings_new_days'}))
    const systemUpdate = useSelector(state => hasAccess(state, {sectionId: 5, rightName: 'settings_system_update'}))
    const systemUpdateEnabled = useSelector(state => state.settings.settings.systemUpdateEnabled)
    const pluginAccess = useSelector(state => hasAccess(state, {sectionId: 5, rightName: 'settings_plugin_email_clients'}))
    const pluginAttackView = useSelector(state => hasAccess(state, {sectionId: 16, rightName: 'view'}))
    const pluginAttackSave = useSelector(state => hasAccess(state, {sectionId: 16, rightName: 'outlook_plugin_attack_save'}))
    const manageSSO = useSelector(state => state.auth.auth.license.features.manageSso)
    const notificationView = useSelector( state => hasAccess(state, {sectionId: 7, rightName: 'view'}))
    const licenseIsValid = useSelector(state => state.auth.auth.license.isValid)
    const firstOutlookLoading = useSelector(state => state.outlook.firstLoading)


    useEffect(() => {
        dispatch(fetchSettings())
        return () => {
            dispatch(setDefaultSystemNotification())
            dispatch(settingsDefault())
        }
    }, [])

    useEffect(() => {
        if (!settingsStatus) {
            dispatch(fetchNotification())
            dispatch(fetchAccount())
            dispatch(fetchOutlookSettings())
            dispatch(fetchRoles())
            dispatch(fetchListWifiDevice())
        }
    }, [settingsStatus])

    return (
        <div className={'settings-wrapper'}>
            <h2 className={'settings_title'}>{gettext.getString('Настройки')}</h2>
            {!viewAccess && <div>
                <FaIcon type={'error'} text={gettext.getString('Вы не можете увидеть настройки')}/>
            </div>}
            {viewAccess && !settingsStatus && <>
                <div className={'settings_row'} id={'adminField'} data-testid={'admin-section'}>
                    <div className={'settings_path'} id={'passwordField'}>
                        <SettingsAuth licenseIsValid={licenseIsValid}/>
                    </div>
                    <div className={'settings_path settings_path__right'}>
                        <SettingsLicense
                            debugArchiveEnabled={debugArchiveEnabled}
                            zipClassExists={zipClassExists}
                            licenseIsValid={licenseIsValid}
                        />
                    </div>
                </div>
                {auth.authType === 1 && <div className={'settings_row'}>
                    <div className={'settings_path'}>
                        <Settings2FA licenseIsValid={licenseIsValid}/>
                    </div>
                    <div className={'settings_path settings_path__right'}></div>
                </div>}
                {!!systemUpdate && !!systemUpdateEnabled && <div className={'settings_row'}>
                    <div className={'settings_path'}>
                        <SystemUpdate licenseIsValid={licenseIsValid}/>
                    </div>
                </div>}
                {!auth.adminId && <div className={'settings_row'}>
                    <SettingsAnonym licenseIsValid={licenseIsValid}/>
                </div>}
                {!!pluginAccess && !!firstOutlookLoading && <div className={'settings_row'} id={'outlook'}>
                    <SettingsOutlook
                            manifestLink={manifestLink}
                            licenseIsValid={licenseIsValid}
                    />
                </div>}
                {!!pluginAttackView && !!firstOutlookLoading && <div className={'settings_row'}>
                    <OutlookCustomForm
                            pluginAttackSave={pluginAttackSave}
                    />
                </div>}
            <WifiAttack/>
                {!!notificationView && <Notification />}
            <Courses/>
                {!!manageSSO && <div className={'settings_row'} data-testid={'sso-section'}>
                    <SettingsSso licenseIsValid={licenseIsValid}/>
                </div>}
                {!settingsStatus &&
                accountView &&
                <div className={'settings_row'} id={'account-field'} data-testid={'accounts-section'}>
                    <SettingsAccount licenseIsValid={licenseIsValid}/>
                </div>}
                <div className={'settings_row'} id={'educationField'}>
                    {eduView && <div className={'settings_path'}>
                        {notificationStatus === 'load' &&
                        !settingsStatus &&
                        <SettingsEducation
                                licenseIsValid={licenseIsValid}
                                languages={languages}
                                learningConfig={learningConfig}
                        />}
                    </div>}
                </div>
                {!!certShow && <div className={'settings_row'}>
                    <div className={'settings_path'}>
                        <SettingsCert licenseIsValid={licenseIsValid}/>
                    </div>
                </div>}
                {/*!settingsStatus &&
                roleView &&
                <div className={'settings_row'} id={'role-field'}>
                    <SettingsRole licenseIsValid={licenseIsValid}/>
                </div>*/}
                {/*{!settingsStatus &&*/}
                {/*schedulerView &&*/}
                {/*<div className={'settings_row'} id={'scheduler'}>*/}
                {/*    <Scheduler licenseIsValid={licenseIsValid}/>*/}
                {/*</div>}*/}
                {/*{!settingsStatus &&*/}
                {/*auth.license.features.manageLdap &&*/}
                {/*ldapView && <div className={'settings_row'} id={'test-ldap-field'}>*/}
                {/*    <SettingsLdap licenseIsValid={licenseIsValid}/>*/}
                {/*</div>}*/}
                {/*{!settingsStatus &&*/}
                {/*variableView &&*/}
                {/*<div className={'settings_row'} id={'setting-variables'}>*/}
                {/*    <SettingsVariables />*/}
                {/*</div>}*/}
                {/*{!settingsStatus &&*/}
                {/*mailTemplateView &&*/}
                {/*auth.license.features.mailServer &&*/}
                {/*<div className={'settings_row'}>*/}
                {/*    <SettingsMailTemplate licenseIsValid={licenseIsValid}/>*/}
                {/*</div>}*/}
                {/*{!settingsStatus &&*/}
                {/*bossView &&*/}
                {/*auth.targetImport.bossKadrovikEnabled &&*/}
                {/*auth.license.features.manageLdap && <div className={'settings_row'}>*/}
                {/*    <SettingsBossKadrovik licenseIsValid={licenseIsValid}/>*/}
                {/*</div>}*/}
                {/*{!settingsStatus &&*/}
                {/*setSettingsNewDay &&*/}
                {/*<div className={'settings_row'}>*/}
                {/*    <SettingsNewEmployee licenseIsValid={licenseIsValid}/>*/}
                {/*</div>}*/}
            </>}
        </div>
    )
}

export default Settings
