import innerCoursesTemplate from './view.html';

import {InnerCoursesController} from './controller.js';

const InnerCoursesComponent = {
    template: innerCoursesTemplate,
    controller: InnerCoursesController,
    controllerAs: '$',
    scope: true,
    bindings: {
        courseId: '<',
        loading: '<',
        setReloadng: '<',

        isAnyLoading: '<',
        reloading: '<',
        setReloading: '<',
        setDepartmentCount: '<',
        setTargetCount: '<',
        departmentCheckedCache: '=',
        departmentPartialCache: '=',
        setLoading: '<',
        rebuildLoading: '<',
        setSearch: '<',
    },
};

export {
    InnerCoursesComponent,
};
