import style from './style.modules.css';
import {getDashboardVersion, hasAccess} from '../../reselect';
import {EmployeesModal} from '../employeesModal';
import {CampaignModal} from '../campaignModal';
import {ReportTargetsModal} from '../reportTargetsModal';
import {UsbAttackModal} from '../usbAttackModal';
import {EMPTY_SELECTED_DATA, SEPARATOR} from '../../const.js';
import {setLinkings, setSelectedData, clearSelectedData} from '../../toolkit/actions';
import angular from 'angular';
import { ReactModal } from 'root/assets/webpack/src/js/components/ReactModal';
import WifiAttackModal from '../../React/components/WifiAttackModal';

/* eslint-disable */

class ClassicMenuController {
    constructor(gettextCatalog, $ngRedux, $scope, $injector, $localStorage, $filter) {
        this.style = style;
        this.gettextCatalog = gettextCatalog;
        this.$scope = $scope;
        this.$injector = $injector;
        this.$localStorage = $localStorage;
        this.$filter = $filter;
        this.csrfToken = window.csrfToken;
        this.searchString = '';
        this.customStyle = window.config.CUSTOM_STYLE;
        this.WifiAttackModal = WifiAttackModal;
        this.renderFields = [
            'knowledge',
            'skills',
            'rating',
            'vulnerabilities',
            'settings',
            'settingsScheduler',
            'settingsService',
            'settingsLogs',
            'settingsLdap',
            'settingsAccounts',
            'settingsRole',
        ];
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, {setLinkings, setSelectedData, clearSelectedData})(this);
    }

    mapStateToThis(state) {
        return {
            selectedData: state.selectedData.selectedData,
            menuHeight: state.menu,
            variablesSystem: state.variables,
            version: getDashboardVersion(state),
            debugArchiveEnabled: state.auth.auth.license.debugArchiveEnabled,
            employeesViewAccess: hasAccess(state, {sectionId: 1, rightName: 'view'}),
            employeesViewSkillsAccess: hasAccess(state, {sectionId: 2, rightName: 'view'}),
            employeesAddAccess: hasAccess(state, {sectionId: 1, rightName: 'target_save'}),
            employeesFileAccess: hasAccess(state, {sectionId: 1, rightName: 'target_file'}),
            employeesLdapAccess: hasAccess(state, {sectionId: 1, rightName: 'target_ldap'}),
            learningAccess: hasAccess(state, {sectionId: 1, rightName: 'target_education'}),
            attackStart: hasAccess(state, {sectionId: 2, rightName: 'attack_save'}),
            downloadReport: hasAccess(state, {sectionId: 4, rightName: 'report_common'}),
            saveReportFeedback: hasAccess(state, {sectionId: 4, rightName: 'report_feedback_outlook'}),
            historyAccess: hasAccess(state, {sectionId: 1, rightName: 'target_history'}),
            variablesAccess: hasAccess(state, {sectionId: 11, rightName: 'variable_save'}),
            userProfileBlock: hasAccess(state, {sectionId: 101, rightName: 'user_block'}),
            educationReportAccess: hasAccess(state, {sectionId: 1, rightName: 'report_education'}),
            educationSystemAccess: hasAccess(state, {sectionId: 6, rightName: 'view'}),
            targetDownloadAccess: hasAccess(state, {sectionId: 1, rightName: 'target_download'}),
            reportRiskAccess: hasAccess(state, {sectionId: 3, rightName: 'report_risk'}),
            notificationsAccess: hasAccess(state, {sectionId: 7, rightName: 'view'}),
            schedulerAccess: hasAccess(state, {sectionId: 9, rightName: 'view'}),
            schedulerReportAccess: hasAccess(state, { sectionId: 9, rightName: 'report_scheduler' }),
            schedulerSave: hasAccess(state, { sectionId: 9, rightName: 'scheduler_save' }),
            schedulerDelete: hasAccess(state, { sectionId: 9, rightName: 'scheduler_delete' }),
            schedulerStatus: hasAccess(state, {sectionId: 9, rightName: 'scheduler_status'}),
            accountsAccess: hasAccess(state, {sectionId: 101, rightName: 'view'}),
            accountCreateAccess: hasAccess(state, { sectionId: 101, rightName: 'user_save' }),
            accountDelete: hasAccess(state, {sectionId: 101, rightName: 'user_delete'}),
            settingsTargetAccess: hasAccess(state, {sectionId: 5, rightName: 'settings_target'}),
            systemUpdateAccess: hasAccess(state, {sectionId: 5, rightName: 'settings_system_update'}),
            roleDeleteAccess: hasAccess(state, {sectionId: 102, rightName: 'role_delete'}),
            roleSaveAccess: hasAccess(state, {sectionId: 102, rightName: 'role_save'}),
            settingsPolicyAccess: hasAccess(state, {sectionId: 5, rightName: 'settings_policy'}),
            settingsDebugAccess: hasAccess(state, {sectionId: 5, rightName: 'settings_debug'}),
            reportAttackAccess: hasAccess(state, {sectionId: 4, rightName: 'report_attack'}),
            reportDepartmentAccess: hasAccess(state, {sectionId: 4, rightName: 'report_department'}),
            reportAnonymStat: hasAccess(state, {sectionId: 4, rightName: 'report_anonym_stat'}),
            accessAttackView: hasAccess(state, {sectionId: 2, rightName: 'view'}),
            ldapAccess: hasAccess(state, { sectionId: 10, rightName: 'view' }),
            ldapSave: hasAccess(state, {sectionId: 10, rightName: 'ldap_save'}),
            ldapReportImport: hasAccess(state, {sectionId: 10, rightName: 'report_import'}),
            accessSettings:  hasAccess(state, { sectionId: 5, rightName: 'view' }),
        };
    }

    $onInit() {
        const ctrl = this;
        this.knowledge = [
            {
                type: 'header',
                get name() {
                    return ctrl.version == 1 ?
                        ctrl.gettextCatalog.getString('Сотрудники') :
                        ctrl.gettextCatalog.getString('Знания');
                },
                access: true,
            },
            {
                type: 'title',
                name: ctrl.gettextCatalog.getString('Добавить сотрудников'),
                access: true,
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('по одному'),
                get access() {
                    return ctrl.employeesAddAccess && ctrl.filterSearch(this.name);
                },
                sref: 'targets',
                onClick() {
                    ctrl.addEmployee(1);
                    ctrl.onMenuOpen();
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : '';
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('из файла'),
                get access() {
                    return ctrl.employeesFileAccess && ctrl.filterSearch(this.name);
                },
                sref: 'targets',
                onClick() {
                    ctrl.addEmployee(2);
                    ctrl.onMenuOpen();
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : '';
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('через LDAP'),
                get access() {
                    return ctrl.employeesLdapAccess && ctrl.filterSearch(this.name);
                },
                sref: 'targets',
                onClick() {
                    ctrl.addEmployee(3);
                    ctrl.onMenuOpen();
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : '';
                },
            },
            {
                type: 'title',
                name: ctrl.gettextCatalog.getString('Посмотреть сотрудников'),
                get access() {
                    return ctrl.employeesViewAccess;
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('по навыкам'),
                get access() {
                    return ctrl.employeesViewSkillsAccess && ctrl.filterSearch(this.name);
                },
                sref: 'targets',
                onClick() {
                    ctrl.setLocalStorageField('departments', 'dataMode', 0);
                    ctrl.onMenuOpen();
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('по знаниям'),
                get access() {
                    return ctrl.employeesViewAccess && ctrl.filterSearch(this.name);
                },
                sref: 'targets',
                onClick() {
                    ctrl.setLocalStorageField('departments', 'dataMode', 1);
                    ctrl.onMenuOpen();
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('Обучить сотрудников'),
                get access() {
                    return ctrl.learningAccess && ctrl.filterSearch(this.name);
                },
                sref: 'targets',
                onClick() {
                    ctrl.clearCampaing();
                    ctrl.setLinkings('sendTraining' + SEPARATOR + ctrl.gettextCatalog.getString('не обучался'));
                    ctrl.setLocalStorageField('departments', 'dataMode', 0);
                    ctrl.onMenuOpen();
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ?
                        `${ctrl.style['noview']} ${ctrl.style['second-section-title']}` :
                        `${ctrl.style['second-section-title']}`;
                },
                ico: `${ctrl.style['ico-learning']}`,
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('Изменить или удалить | сотрудников').replace('|', '\r\n'),
                get access() {
                    return ctrl.employeesAddAccess && ctrl.filterSearch(this.name);
                },
                sref: 'targets',
                onClick() {
                    ctrl.setLocalStorageField('departments', 'dataMode', 0);
                    ctrl.setLinkings('selectAll');
                    ctrl.onMenuOpen();
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ?
                        `${ctrl.style['noview']} ${ctrl.style['item-padding']}` :
                        `${ctrl.style['item-padding']}`;
                },
            },
        ];
        this.skills = [
            {
                type: 'header',
                get name() {
                    return ctrl.version == 1 ?
                        ctrl.gettextCatalog.getString('Атаки') :
                        ctrl.gettextCatalog.getString('Навыки');
                },
                access: true,
            },
            {
                type: 'title',
                name: ctrl.gettextCatalog.getString('Создать атаку'),
                access: true,
                ico: `${ctrl.style['ico-attack']}`,
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('по электронной почте'),
                get access() {
                    return ctrl.attackStart && ctrl.filterSearch(this.name);
                },
                sref: 'attacks',
                onClick() {
                    ctrl.createAttack('mail');
                    ctrl.onMenuOpen();
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('через съемные устройства'),
                get access() {
                    return ctrl.attackStart && ctrl.filterSearch(this.name);
                },
                sref: 'attacks',
                onClick() {
                    ctrl.createAttack('usb');
                    ctrl.onMenuOpen();
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
                get hide() {
                    return +window.config.CUSTOM_STYLE === 1;
                }
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('через беспроводные сети'),
                get access() {
                    return ctrl.attackStart && ctrl.filterSearch(this.name);
                },
                sref: 'attacks',
                onClick() {
                    ctrl.createAttack('wifi');
                    ctrl.onMenuOpen();
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
                get hide() {
                    return +window.config.CUSTOM_STYLE === 1;
                }
            },

            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('Все атаки и статистика по ним'),
                get access() {
                    return ctrl.accessAttackView && ctrl.filterSearch(this.name);
                },
                sref: 'attacks',
                onClick() {
                    ctrl.onMenuOpen();
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ?
                        `${ctrl.style['noview']} ${ctrl.style['first-section-title']}` :
                        `${ctrl.style['first-section-title']}`;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('История действий'),
                get access() {
                    return ctrl.historyAccess && ctrl.filterSearch(this.name);
                },
                sref: 'targets',
                onClick() {
                    let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(ctrl.selectedData), {all: 1});
                    ctrl.setSelectedData(selectedData);
                    ctrl.showHistory();
                    ctrl.onMenuOpen();
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ?
                        `${ctrl.style['noview']} ${ctrl.style['item-padding']}` :
                        `${ctrl.style['item-padding']}`;
                },
                ico: `${ctrl.style['ico-history']}`,
            },
            {
                type: 'title',
                name: ctrl.gettextCatalog.getString('Переменные в атаках'),
                access: true,
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('изменить значения переменных'),
                get access() {
                    return ctrl.variablesAccess && ctrl.variablesSystem.length && ctrl.filterSearch(this.name);
                },
                sref: 'settings',
                onClick() {
                    ctrl.setLinkings('settingVariables');
                    ctrl.onMenuOpen();
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
        ];
        this.rating = [
            {
                type: 'header',
                get name() {
                    return ctrl.version == 1 ?
                        ctrl.gettextCatalog.getString('Отчеты') :
                        ctrl.gettextCatalog.getString('Рейтинг');
                },
                access: true,
            },
            {
                type: 'title',
                name: ctrl.gettextCatalog.getString('Главные отчеты'),
                access: true,
                ico: `${ctrl.style['ico-report']}`,
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('общий отчет'),
                reportType: 4,
                get access() {
                    return ctrl.downloadReport && ctrl.filterSearch(this.name);
                },
                onClick() {},
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('по обучению'),
                reportType: 3,
                newString: {
                    loadLink: ctrl.gettextCatalog.getString('по обучению'),
                },
                get access() {
                    return ctrl.educationReportAccess && ctrl.filterSearch(this.name);
                },
                sref: 'targets',
                onClick() {},
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('по истории действий'),
                reportType: 2,
                newString: {
                    loadLink: ctrl.gettextCatalog.getString('по истории действий'),
                },
                get access() {
                    return ctrl.targetDownloadAccess && ctrl.filterSearch(this.name);
                },
                sref: 'targets',
                onClick() {},
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'title',
                name: ctrl.gettextCatalog.getString('Прочая статистика'),
                access: true,
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('по атакам'),
                get access() {
                    return ctrl.reportAttackAccess && ctrl.filterSearch(this.name);
                },
                sref: 'reports',
                onClick() {
                    ctrl.setLocalStorageField('reports', 'viewMode', 1);
                    ctrl.onMenuOpen();
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('по отделам'),
                get access() {
                    return ctrl.employeesFileAccess && ctrl.filterSearch(this.name);
                },
                sref: 'reports',
                onClick() {
                    ctrl.setLocalStorageField('reports', 'viewMode', 0);
                    ctrl.onMenuOpen();
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('по уязвимостям'),
                reportType: 7,
                newString: {
                    loadLink: ctrl.gettextCatalog.getString('по уязвимостям'),
                    prepareMessage: ctrl.gettextCatalog.getString('готовим отчет (по уязвимостям)...'),
                    downloadLinkText: ctrl.gettextCatalog.getString('скачать отчет (по уязвимостям)'),
                },
                get access() {
                    return ctrl.reportRiskAccess && ctrl.filterSearch(this.name);
                },
                onClick() {},
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('все действия в атаках'),
                get access() {
                    return ctrl.targetDownloadAccess && ctrl.filterSearch(this.name);
                },
                onClick() {
                    ctrl.setLocalStorageField('departments', 'dataMode', 0);
                    let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(ctrl.selectedData), {all: 1});
                    ctrl.setSelectedData(selectedData);
                    ctrl.setLinkings('targetReport');
                    ctrl.showHistory();
                    ctrl.onMenuOpen();
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('анонимная статистика'),
                reportType: 6,
                fileFilterDates: true,
                get access() {
                    return ctrl.reportAnonymStat && ctrl.filterSearch(this.name);
                },
                onClick() {},
                get class() {
                    return !ctrl.filterSearch(this.name) || !ctrl.reportAnonymStat ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('обратная связь от сотрудников'),
                reportType: 10,
                fileFilterDates: false,
                get access() {
                    return ctrl.saveReportFeedback && ctrl.filterSearch(this.name);
                },
                onClick() {},
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
        ];
        this.vulnerabilities = [
            {
                type: 'header',
                get name() {
                    return ctrl.version == 1 ?
                        ctrl.gettextCatalog.getString('Риски') :
                        ctrl.gettextCatalog.getString('Уязвимости');
                },
                access: true,
            },
            {
                type: 'title',
                name: ctrl.gettextCatalog.getString('Посмотреть уязвимости'),
                access: true,
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('по приложениям'),
                get access() {
                    return ctrl.reportRiskAccess && ctrl.filterSearch(this.name);
                },
                sref: 'risks',
                onClick() {
                    ctrl.setLocalStorageField('risks', 'dataMode', 0);
                    ctrl.onMenuOpen();
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('по сотрудникам'),
                get access() {
                    return ctrl.reportRiskAccess && ctrl.filterSearch(this.name);
                },
                sref: 'risks',
                onClick() {
                    ctrl.setLocalStorageField('risks', 'dataMode', 1);
                    ctrl.onMenuOpen();
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('Сохранить отчет'),
                reportType: 7,
                newString: {
                    loadLink: ctrl.gettextCatalog.getString('Подготовить отчет'),
                    prepareMessage: ctrl.gettextCatalog.getString('готовим отчет (по уязвимостям)...'),
                    downloadLinkText: ctrl.gettextCatalog.getString('скачать отчет (по уязвимостям)'),
                },
                get access() {
                    return ctrl.reportRiskAccess && ctrl.filterSearch(this.name);
                },
                onClick() {},
                get class() {
                    return !ctrl.filterSearch(this.name) ?
                        `${ctrl.style['noview']} ${ctrl.style['first-section-title']}` :
                        `${ctrl.style['first-section-title']}`;
                },
            },
        ];
        this.settings = [
            {
                type: 'header',
                name: ctrl.gettextCatalog.getString('Настройки'),
                access: true,
            },
            {
                type: 'title',
                name: ctrl.gettextCatalog.getString('Система обучения'),
                access: true,
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('изменить настройки'),
                get access() {
                    return ctrl.educationSystemAccess && ctrl.accessSettings && ctrl.filterSearch(this.name);
                },
                sref: 'settings',
                onClick() {
                    ctrl.setLinkings('educationField');
                    ctrl.onMenuOpen();
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('изменить уведомления | об обучении').replace('|', '\r\n'),
                get access() {
                    return ctrl.educationSystemAccess && ctrl.accessSettings && ctrl.employeesFileAccess && ctrl.filterSearch(this.name);
                },
                sref: 'settings',
                onClick() {
                    ctrl.setLinkings('educationField');
                    ctrl.onMenuOpen();
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('включить или отключить | уведомления').replace('|', '\r\n'),
                get access() {
                    return ctrl.educationSystemAccess && ctrl.accessSettings && ctrl.employeesLdapAccess && ctrl.filterSearch(this.name);
                },
                sref: 'settings',
                onClick() {
                    ctrl.setLinkings('educationField');
                    ctrl.onMenuOpen();
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
        ];
        this.settingsScheduler = [
            {
                type: 'default',
                name: '',
                access: true,
            },
            {
                type: 'title',
                name: ctrl.gettextCatalog.getString('Планировщик'),
                access: true,
                ico: `${ctrl.style['ico-scheduler']}`,
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('добавить правило'),
                get access() {
                    return  ctrl.schedulerAccess && ctrl.schedulerSave && ctrl.accessSettings && ctrl.filterSearch(this.name);
                },
                sref: 'settings',
                onClick() {
                    ctrl.setLinkings('scheduler');
                    ctrl.onMenuOpen();
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('изменить или удалить правила'),
                get access() {
                    return ctrl.schedulerAccess && ctrl.schedulerSave && ctrl.schedulerDelete && ctrl.accessSettings && ctrl.filterSearch(this.name);
                },
                sref: 'settings',
                onClick() {
                    ctrl.setLinkings('schedulerField');
                    ctrl.onMenuOpen();
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('включить или отключить правила'),
                get access() {
                    return ctrl.schedulerAccess && ctrl.schedulerStatus && ctrl.accessSettings && ctrl.filterSearch(this.name);
                },
                sref: 'settings',
                onClick() {
                    ctrl.setLinkings('schedulerField');
                    ctrl.onMenuOpen();
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('посмотреть журнал'),
                fileType: 'scheduler',
                fileFilterDates: true,
                get access() {
                    return ctrl.schedulerReportAccess && ctrl.accessSettings && ctrl.filterSearch(this.name);
                },
                onClick() {},
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
        ];
        this.settingsService = [
            {
                type: 'default',
                name: '',
                access: true,
            },
            {
                type: 'title',
                name: ctrl.gettextCatalog.getString('Обслуживание'),
                access: true,
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('Сохранить отладочную | информацию').replace('|', '\r\n'),
                fileType: 'debug',
                fileLoadingText: ctrl.gettextCatalog.getString('Подготовка отладочной | информации').replace('|', '\r\n'),
                fileErrorText: ctrl.gettextCatalog.getString('Ошибка при подготовке | отладочной информации').replace('|', '\r\n'),
                get access() {
                    return ctrl.settingsDebugAccess && ctrl.debugArchiveEnabled && ctrl.filterSearch(this.name);
                },
                onClick() {},
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('Обновить систему'),
                get access() {
                    return ctrl.systemUpdateAccess && ctrl.accessSettings && ctrl.filterSearch(this.name);
                },
                sref: 'settings',
                onClick() {
                    ctrl.setLinkings('updateSystemField');
                    ctrl.onMenuOpen();
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
        ];
        this.settingsLogs = [
            {
                type: 'default',
                name: '',
                access: true,
            },
            {
                type: 'title',
                name: ctrl.gettextCatalog.getString('Логирование'),
                access: true,
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('журнал действий'),
                fileType: 'user',
                fileFilterDates: true,
                get access() {
                    return ctrl.accountsAccess && ctrl.filterSearch(this.name);
                },
                onClick() {},
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('журнал администраторов'),
                fileType: 'users',
                get access() {
                    return ctrl.accountsAccess && ctrl.filterSearch(this.name);
                },
                onClick() {},
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('журнал авторизованных | администраторов').replace('|', '\r\n'),
                fileType: 'sessions',
                get access() {
                    return ctrl.accountsAccess && ctrl.filterSearch(this.name);
                },
                onClick() {},
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
        ];
        this.settingsLdap = [
            {
                type: 'title',
                name: ctrl.gettextCatalog.getString('Профили LDAP'),
                access: true,
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('добавить профиль'),
                get access() {
                    return ctrl.ldapSave &&
                        ctrl.accessSettings &&
                        ctrl.ldapAccess &&
                        ctrl.filterSearch(this.name) &&
                        ctrl.$localStorage.userInfo.license.features.manageLdap;
                },
                sref: 'settings',
                onClick() {
                    ctrl.setLinkings('ldapSaveField');
                    ctrl.onMenuOpen();
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('изменить или удалить профили'),
                get access() {
                    return ctrl.employeesLdapAccess && ctrl.accessSettings && ctrl.ldapAccess && ctrl.filterSearch(this.name) && ctrl.$localStorage.userInfo.license.features.manageLdap;
                },
                sref: 'settings',
                onClick() {
                    ctrl.setLinkings('ldapField');
                    ctrl.onMenuOpen();
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('журнал синхронизаций'),
                fileType: 'ldap',
                fileFilterDates: true,
                get access() {
                    return ctrl.ldapReportImport && ctrl.accessSettings && ctrl.filterSearch(this.name);
                },
                onClick() {},
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
        ];
        this.settingsAccounts = [
            {
                type: 'title',
                name: ctrl.gettextCatalog.getString('Учетные записи | администраторов').replace('|', '\r\n'),
                access: true,
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('добавить учетную запись'),
                get access() {
                    return ctrl.accountCreateAccess && ctrl.accessSettings && ctrl.filterSearch(this.name);
                },
                sref: 'settings',
                onClick() {
                    ctrl.setLinkings('accountCreate');
                    ctrl.onMenuOpen();
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('заблокировать вход'),
                get access() {
                    return ctrl.accountsAccess && ctrl.userProfileBlock && ctrl.accessSettings && ctrl.filterSearch(this.name);
                },
                sref: 'settings',
                onClick() {
                    ctrl.setLinkings('accountField');
                    ctrl.onMenuOpen();
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('изменить или удалить | учетные записи').replace('|', '\r\n'),
                get access() {
                    return ctrl.accountsAccess && ctrl.accountDelete && ctrl.accessSettings && ctrl.filterSearch(this.name);
                },
                sref: 'settings',
                onClick() {
                    ctrl.setLinkings('accountField');
                    ctrl.onMenuOpen();
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('Создать цель из администратора'),
                get access() {
                    return ctrl.settingsTargetAccess && ctrl.accessSettings && ctrl.filterSearch(this.name);
                },
                sref: 'settings',
                onClick() {
                    ctrl.setLinkings('adminField');
                    ctrl.onMenuOpen();
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ?
                        `${ctrl.style['noview']} ${ctrl.style['item-padding']}` :
                        `${ctrl.style['item-padding']}`;
                },
            },
        ];
        this.settingsRole = [
            {
                type: 'title',
                name: ctrl.gettextCatalog.getString('Роли администраторов'),
                access: true,
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('добавить роль'),
                get access() {
                    return ctrl.roleSaveAccess && ctrl.accessSettings && ctrl.filterSearch(this.name);
                },
                sref: 'settings',
                onClick() {
                    ctrl.setLinkings('roleSaveField');
                    ctrl.onMenuOpen();
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('изменить или удалить роли'),
                get access() {
                    return ctrl.roleDeleteAccess && ctrl.accessSettings && ctrl.filterSearch(this.name);
                },
                sref: 'settings',
                onClick() {
                    ctrl.setLinkings('roleField');
                    ctrl.onMenuOpen();
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ? `${ctrl.style['noview']}` : ``;
                },
            },
            {
                type: 'link',
                name: ctrl.gettextCatalog.getString('Требования к паролю | и авторизации').replace('|', '\r\n'),
                get access() {
                    return ctrl.settingsPolicyAccess && ctrl.accessSettings && ctrl.filterSearch(this.name);
                },
                sref: 'settings',
                onClick() {
                    ctrl.setLinkings('passwordField');
                    ctrl.onMenuOpen();
                },
                get class() {
                    return !ctrl.filterSearch(this.name) ?
                        `${ctrl.style['noview']} ${ctrl.style['item-padding']}` :
                        `${ctrl.style['item-padding']}`;
                },
            },
        ];
    }

    addEmployee = (viewMode) => {
        if (this.employeesAddAccess) {
            this.modal = this.$injector.instantiate(EmployeesModal, 'ui.bootstrap');
            this.modal.open(0, 0, viewMode);
        }
    };

    showHistory = () => {
        const {$localStorage} = this;
        this.modal = this.$injector.instantiate(ReportTargetsModal);
        let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(this.selectedData));
        selectedData.courses = [$localStorage.openedCourse];
        this.modal.open(selectedData);
    };

    clearCampaing = () => {
        const {$localStorage} = this;
        $localStorage.campaignData = null;
        $localStorage.campaignStart = null;
        $localStorage.campaignSchedule = null;
    }

    createAttack = (campaing) => {
        if (!this.attackStart) {
            return;
        }
        if (campaing === 'mail') {
            const {$localStorage} = this;
            $localStorage.campaignData = null;
            this.clearSelectedData();
            $localStorage.campaignStart = null;
            $localStorage.campaignSchedule = null;
            this.modal = this.$injector.instantiate(CampaignModal);
            this.modal.open().then((campaignData) => {
                if (campaignData) {
                    window.campaignsCtrl.loadCampaigns();
                }
            }, () => {
            });
        }
        if (campaing === 'usb') {
            this.modal = this.$injector.instantiate(UsbAttackModal);
            this.modal.open().then((usbData) => {
                if (usbData) {
                    window.campaignsCtrl.loadUsb();
                }
            }, () => {
            });
        }

        if (campaing === 'wifi') {
            this.modal = this.$injector.instantiate(ReactModal);
            this.modal.open({
                component: WifiAttackModal,
            }).then((wifiData) => {
                if (wifiData) {
                    window.campaignsCtrl.loadWifi();
                }
            }, () => {
            });
        }
    };

    setLocalStorageField = (item, viewMode, value) => {
        if (angular.isArray(this.$localStorage[item][viewMode])) {
            this.$localStorage[item][viewMode].splice(0, this.$localStorage[item][viewMode].length, value);
        } else {
            this.$localStorage[item][viewMode] = value;
        }
    };

    filterSearch = (nameField) => {
        let thisString = this.searchString.toLowerCase();
        let getValue = nameField.toLowerCase();
        if (thisString === '') {
            return true;
        }
        if (getValue.indexOf(thisString, 0) === -1) {
            return false;
        }
        return true;
    };

    clearSearch = (event) => {
        event.stopPropagation();
        this.searchString = '';
    };

    $onDestroy() {
        this.unsubscribe();
    }
}

ClassicMenuController.$inject = [
    'gettextCatalog',
    '$ngRedux',
    '$scope',
    '$injector',
    '$localStorage',
    '$filter',
];

export {
    ClassicMenuController,
};
