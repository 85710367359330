import {BOSS_KADROVIK_SERVICE} from '../../const';

class BossKadrovikProfileModalController {
    constructor(BossKadrovikProfilesService, profileId) {
        this.BossKadrovikProfilesService = BossKadrovikProfilesService;
        this.profileId = profileId;
        this.profile = null;
        this.changePassword = true;
        this.appCtrl = window.appCtrl;
        this.gettextCatalog = this.appCtrl.gettextCatalog;
    }

    setForm = (form) => {
        this.form = form;
    };

    $onInit = () => {
        if (this.profileId > 0) {
            this.BossKadrovikProfilesService.one(this.profileId).then((profile) => {
                this.profile = profile;
                this.changePassword = false;
            });
        } else {
            this.profile = this.BossKadrovikProfilesService.YiiModel.one('boss-kadrovik-profiles');
        }
        this.errorText = null;
        this.editable = this.appCtrl && this.appCtrl.hasAccess(13, 'boss_kadrovik_save');
    };

    save = () => {
        if (this.editable) {
            this.errorText = null;
            this.form.$setSubmitted();
            if (this.form.$valid) {
                this.inProcess = true;
                this.profile.$save().then((profile) => {
                    if (profile) {
                        this.$close(profile);
                    }
                    this.inProcess = false;
                }).catch(() => {
                    this.errorText = this.gettextCatalog.getString('Ошибка при сохранении БОСС-Кадровик профиля');
                });
            }
        }
    };
}

BossKadrovikProfileModalController.$inject = [
    BOSS_KADROVIK_SERVICE,
    'profileId',
];

export {
    BossKadrovikProfileModalController,
};
