import {DEPARTMENTS_SERVICE} from '../../const';
import angular from 'angular';
import {applyFilterDepartment} from '../../toolkit/actions';

class DepartmentModalController {
    constructor(DepartmentsService, departmentId, parentDepartmentId, $ngRedux, gettextCatalog) {
        this.DepartmentsService = DepartmentsService;
        this.departmentId = departmentId;
        this.parentDepartmentId = parentDepartmentId;
        this.inProcess = false;
        this.loading = false;
        this.gettextCatalog = gettextCatalog;
        this.departments = [];
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, {applyFilterDepartment})(this);
    }

    mapStateToThis = (state) => {
        return {
            anonymization: state.auth.auth.license.features.anonymization
        };
    };

    setForm = (form) => {
        this.form = form;
    };

    $onInit = () => {
        if (this.departmentId) {
            this.loading = true;
            this.DepartmentsService.one(this.departmentId).then((department) => {
                this.department = department;
                this.loading = false;
                this.errorText = null;
                this.loadDepartments();
                return;
            });
        } else {
            this.department = this.DepartmentsService.new();
            this.errorText = null;
            this.loadDepartments();
        }
    };

    loadDepartments = () => {
        this.loading = true;
        this.loadingError = null;
        this.DepartmentsService.simple.query({
            layout: 'select',
            ignoreDepartment: this.departmentId,
        }).$promise.then((departments) => {
            this.departments = departments;
            if (!this.department.department) {
                let parentDepartment = this.departments.find((department) => {
                    return department.id == this.parentDepartmentId;
                });
                this.department.department = parentDepartment;
            }
            this.loading = false;
        }, () => {
            this.loading = false;
            this.loadingError = this.gettextCatalog.getString('Ошибка при загрузке отделов');
        });
    };

    save = () => {
        this.errorText = null;
        this.form.$setSubmitted();
        if (this.form.$valid) {
            this.inProcess = true;
            this.department.$save().then((department) => {
                this.inProcess = false;
                if (department) {
                    this.applyFilterDepartment({
                        search: '',
                        callback: null,
                        reload: 'departments',
                    });
                    this.$close(department);
                }
            }).catch(() => {
                this.inProcess = false;
                this.errorText = this.gettextCatalog.getString('Ошибка при сохранении отдела');
            });
        }
    };

    isValidDepartment = () => {
        if (!this.loading && this.department && this.department.department) {
            let departmentName = typeof (this.department.department) == 'string' ? this.department.department : '';
            if (this.department.department && this.department.department.fullName) {
                departmentName = this.department.department.fullName;
            }
            if (departmentName) {
                let department = this.departments.find((department) => {
                    return department.fullName == departmentName;
                });
                if (department && department.id) {
                    if (!this.department.department || !this.department.department.id) {
                        this.department.department = department;
                    }
                    return true;
                }
            } else {
                return true;
            }
        } else {
            return true;
        }
    };

    checkDepartmentMatches = () => {
        this.departmentHasMatches = false;
        if (this.departments && this.departments.length) {
            let departmentName = this.department.department && this.department.department.fullName ? this.department.department.fullName : (typeof (this.department.department) == 'string' ? this.department.department : '');
            angular.forEach(this.departments, (department) => {
                if (!departmentName || !department.fullName || departmentName.indexOf(department.fullName) > -1 || department.fullName.indexOf(departmentName) > -1) {
                    this.departmentHasMatches = true;
                    return true;
                }
            });
        }
    };

    $onDestroy = () => {
        this.unsubscribe();
    };
}

DepartmentModalController.$inject = [
    DEPARTMENTS_SERVICE,
    'departmentId',
    'parentDepartmentId',
    '$ngRedux',
    'gettextCatalog',
];

export {
    DepartmentModalController,
};
