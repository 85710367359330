import style from './style.modules.css';
import angular from 'angular';
import moment from 'moment-timezone';
import {getLerningConfig, hasAccess} from '../../reselect';
import {EMPTY_SELECTED_DATA, LEARNING_SERVICE} from '../../const';
import {fetchDashboardParams, fetchDaemonsMessage} from '../../toolkit/think';
import { ReactModal } from '../ReactModal';
import ConfirmEducation from '../../React/components/ConfirmEducation';

class SendToTrainingController {
    constructor($ngRedux, $scope, $localStorage, LearningService, $cookies, $timeout, growl, gettextCatalog, $injector) {
        this.style = style;
        this.$scope = $scope;
        this.$localStorage = $localStorage;
        this.$timeout = $timeout;
        this.LearningService = LearningService;
        this.langCode = $cookies.get('langCode');
        this.growl = growl;
        this.courses = [];
        this.timeFromValue = new Date().getTime();
        this.timeDateValue = 1;
        this.timeToValue = new Date().getTime();
        this.$injector = $injector;
        this.showCalends = false;
        this.showTimer = false;
        this.allCourse = true;
        this.lastLearningTimeValue = {};
        this.isIe = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
        this.timeError = {
            timeFrom: false,
            timeTo: false,
        };
        this.gettextCatalog = gettextCatalog;
        this.now = new Date();
        this.minDateToDayPicker = new Date(this.now.valueOf() + 1000 * 60 * 60 * 24);
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, {fetchDashboardParams, fetchDaemonsMessage})(this);
    }

    mapStateToThis = (state) => {
        return {
            selectedData: state.selectedData.selectedData,
            targetEducationAccess: hasAccess(state, {sectionId: 1, rightName: 'target_education'}),
            learningConfig: getLerningConfig(state),
            timezone: state.auth.auth.timeZone,
            chosenEntity: state.breadcrumbLinks.link
        };
    };

    $onInit() {
        let courseSelectId = this.checkEntity();
        if (courseSelectId) {
            this.allCourseSelect(false);
            this.setCourse(courseSelectId);
        }
        this.timeDateValue = this.learningConfig.current.timeValue || 1;
        this.timePeriod = this.learningConfig.current.timePeriod || 'day';
        this.timeTo = this.setTimeTo();
    }

    checkEntity = () => {
        if (!this.chosenEntity.length) return false;
        if (!Object.hasOwnProperty.call(this.chosenEntity[0], 'pass_score')) return false;
        return this.chosenEntity[0].id;
    }

    get timeFrom() {
        return this.timeFromValue;
    }

    blurLearningTimeFromTo () {
        if (this.now > this.timeFrom) {
            this.timeFrom = this.now;
        }
        if (this.timeTo < this.timeFrom) {
            this.timeTo = this.setTimeTo();
        }
    }

    set timeFrom(value) {
        this.timeFromValue = new Date(value).getTime();
        if (this.timeFromValue > this.timeToValue) {
            this.timeTo = this.setTimeTo();
        }
    }

    get timeValue() {
        return this.timeDateValue;
    }

    set timeValue(value) {
        this.timeDateValue = value;
        this.timeTo = this.setTimeTo();
    }

    get timeTo() {
        return this.timeToValue;
    }

    set timeTo(value) {
        this.timeToValue = new Date(value).getTime();
    }

    setCourse = (id) => {
        let course = this.courseList.filter(item => item.id === id)[0];
        this.courses.push(course);
    }

    getFormatDate = (value) => {
        return new Date(new Date(value).setHours(0, 0, 0, 0)).toLocaleString(this.langCode, {
            day: 'numeric',
            month: 'long'
        });
    }

    setTimeTo = () => {
        let period = this.timeDateValue * 1000 * 60 * 60 * 24;
        if (this.timePeriod === 'day') {
            return this.timeFromValue + period;
        } else {
            return this.timeFromValue + period * 7;
        }
    }

    allCourseSelect = (allCourse) => {
        this.allCourse = allCourse;
    }

    confirmEducation = async (time, courses, allCourses) => {
        this.modal = this.$injector.instantiate(ReactModal);
        return await this.modal.open({
            component: ConfirmEducation,
            props: {
                selectedData: angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(this.selectedData)),
                time,
                courses: allCourses ? this.courseList: courses,
            }
        });
    }

    trainingSend = async (action = 'ADD') => {
        if (!this.targetEducationAccess || (!this.courses.length && !this.allCourse)) {
            return;
        }
        if (!this.showCalends) {
            this.timeTo = this.setTimeTo();
        }
        const current = {
            timeFrom: this.timeFrom,
            timeTo: this.timeTo,
            timeValue: this.timeValue,
            timePeriod: this.timePeriod
        };
        let isConfirm = action === 'ADD' ? await this.confirmEducation(current, this.courses, this.allCourse) : true;
        if (!isConfirm) return;
        this.trainingLoading = true;
        this.trainingError = null;
        const method = action === 'CANCEL' ? 'cancelTraining' : 'sendToTraining';
        if (this.timeFrom) {
            current.timeFrom = this.setUTCTimestamp(this.timeFrom);
        }
        if (this.timeTo) {
            current.timeTo = this.setUTCTimestamp(this.timeTo);
        }
        this.LearningService[method]({
            courseIds: this.allCourse ? null : this.getCoursesId(this.courses),
            selectedData: angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(this.selectedData)),
            learningConfig: current,
        }, this, function(ctrl) {
            ctrl.trainingLoading = false;
            ctrl.trainingSuccsess();
            ctrl.fetchDashboardParams();
            ctrl.fetchDaemonsMessage();
        }, function(ctrl, response) {
            if (response) {
                if (response.data && response.data.error) {
                    if (response.data.error.debuginfo) {
                        ctrl.growl.error(response.data.error.debuginfo);
                        ctrl.trainingError = response.data.error.debuginfo;
                    } else {
                        ctrl.growl.error(response.data.error);
                        ctrl.trainingError = response.data.error;
                    }
                } else if (response.message) {
                    ctrl.growl.error(response.message);
                    ctrl.trainingError = response.message;
                } else {
                    ctrl.growl.error(action ===
                    'CANCEL' ? ctrl.gettextCatalog.getString('При отмене обучения произошла ошибка')
                        : ctrl.gettextCatalog.getString('При отправке на обучение произошла ошибка'));
                    ctrl.trainingError = (action ===
                    'CANCEL' ? ctrl.gettextCatalog.getString('При отмене обучения произошла ошибка')
                        : ctrl.gettextCatalog.getString('При назначении сотрудников произошла ошибка'));
                }
                ctrl.fetchDaemonsMessage();
                ctrl.trainingLoading = false;
            }
        });
    };

    setUTCTimestamp = (time) => {
        let currentTime = this.formateDate(time, this.timezone);
        let currentZone =  moment.tz(this.timezone)._offset * 60 * 1000;
        let selfZone = -(new Date().getTimezoneOffset() * 60 *1000);
        return new Date(currentTime).valueOf() + (selfZone - currentZone);
    }

    getCoursesId = (courses) => {
        let ids = [];
        courses.forEach(item => {
            ids.push(item.id);
        });
        return ids;
    };

    getIeTime = (reVal, time) => {
        if (typeof time === 'string') {
            reVal = time.replace(/-/g, '/').replace(/ /g, 'T');
        } else {
            reVal = time;
        }
        return reVal;
    }

    openCalendar = (prop) => {
        this.isTimeFromOpen = false;
        this.isTimeToOpen = false;
        this[prop] = true;
        this.$timeout(() => this.getCalendarHeight(document.getElementsByClassName('dropdown-menu-left')), 100);
    };

    setLearningPeriod = value => {
        this.showCalends = false;
        this.timePeriod = value;
    };

    getCalendarHeight = (calends) => {
        if (calends && calends[0]) {
            let cl = calends[0];
            if (cl && typeof (cl.removeChild) == 'function') {
                cl.removeChild(cl.querySelector('[ng-if=\'buttonBar.show\']'));
                cl.style.top = '-' + (cl.clientHeight + 10) + 'px';
            }
        }
    };

    formateDate = (date, timeZone = null) => {
        if (this.isIe) {
            date = this.getIeTime(date, date);
        }
        date = date.valueOf();
        if (timeZone) {
            return moment(date).tz(timeZone).format('YYYY-MM-DD HH:mm:ss');
        }
        date = new Date(date);
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    };

    $onDestroy() {
        this.unsubscribe();
    }
}

SendToTrainingController.$inject = [
    '$ngRedux',
    '$scope',
    '$localStorage',
    LEARNING_SERVICE,
    '$cookies',
    '$timeout',
    'growl',
    'gettextCatalog',
    '$injector',
];

export {
    SendToTrainingController,
};
