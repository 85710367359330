import settingsLicenseTemplate from './view.html';

import {SettingsLisenseController} from './controller';

const SettingsLisenseComponent = {
    template: settingsLicenseTemplate,
    controller: SettingsLisenseController,
    controllerAs: '$',
    scope: true,
    bindings: {
        userInfo: '<',
        domains:'<',
        domainsVisible: '<',
        companyTargetCount: '<',
        debugArchiveEnabled: '<',
        companyTargets: '<',
        supportEmail: '<',
        supportEmailSubject: '<',
        zipClassExists: '<'
    },
};

export {
    SettingsLisenseComponent,
};
