import modalTemplate from './view.html';

import {LdapFieldsMapModalController} from './controller';

class LdapFieldsMapModal {
    constructor($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
        this.modalInstance = null;
    }

    open = (map) => {
        this.modalInstance = this.$uibModal.open({
            template: modalTemplate,
            bindToController: true,
            controller: LdapFieldsMapModalController,
            controllerAs: '$',
            size: 'lg',
            windowClass: 'center-modal',
            resolve: {
                map: map,
            },
            backdrop: 'static',
            keyboard: false,
        });

        return this.modalInstance.result;
    };
}

export {
    LdapFieldsMapModalController,
    LdapFieldsMapModal,
};
