import courseTemplate from './view.html';

import {CourseGraphController} from './controller';

const CourseGraphComponent = {
    template: courseTemplate,
    controller: CourseGraphController,
    controllerAs: '$',
    scope: true,
    bindings: {
        educationHistory: '<',
        targetsInSystem: '<',
        noCourse: '<',
        onCourse: '<',
        completeCourse: '<',
        missCourse: '<'
    },
};

export {
    CourseGraphComponent,
};
