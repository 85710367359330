import logsTemplate from './view.html';

import {LogsListController} from './controller';

const LogsListComponent = {
    template: logsTemplate,
    controller: LogsListController,
    controllerAs: '$',
    scope: true,
    bindings: {
        logs: '<',
        withoutKeys: '<',
    },
};

export {
    LogsListComponent,
};
