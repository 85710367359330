import React from 'react'

function ActionCell({action, actionTypes}) {
    console.log(action, actionTypes)
    return (
            <div>

            </div>
    )
}

export default ActionCell
