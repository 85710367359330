import angular from 'angular';
import {hasAccess} from '../../reselect';
import style from './style.modules.css';
import AnyFor from 'any-foreach';

class SaveFileSelectController {
    constructor($ngRedux, $scope, gettextCatalog) {
        this.gettextCatalog = gettextCatalog;
        this.$scope = $scope;
        this.selectedFile = null;
        this.loading = {
            log: false,
            anonym: false,
        };
        this.error = {
            log: '',
            anonym: '',
        };
        this.style = style;
        this.accessTypes = {
            accessReportFull: 'log',
            accessAnonymReport: 'anonym',
            accessUserLog: 'user',
            accessUsersLog: 'users',
            accessSessionsLog: 'sessions',
        };
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis)(this);
        this.strings = {
            fullStatistics: gettextCatalog.getString('полная статистика'),
            anonymStatistics: gettextCatalog.getString('анонимная статистика'),
            actionLog: gettextCatalog.getString('журнал действий'),
            userLog: gettextCatalog.getString('журнал пользователей'),
            sessionLog: gettextCatalog.getString('журнал авторизованных пользователей')
        };
    }

    mapStateToThis(state) {
        return {
            accessReportFull: hasAccess(state, {sectionId: 4, rightName: 'report_full'}),
            accessAnonymReport: hasAccess(state, {sectionId: 4, rightName: 'report_anonym_stat'}),
            accessUserLog: hasAccess(state, {sectionId: 5, rightName: 'report_user'}),
            accessUsersLog: hasAccess(state, {sectionId: 5, rightName: 'report_users'}),
            accessSessionsLog: hasAccess(state, {sectionId: 5, rightName: 'report_auth'}),
        };
    }

    $onDestroy() {
        this.unsubscribe();
    }

    isAnyLoading = () => {
        let result = false;
        AnyFor(this.loading).for((loading) => {
            if (loading) {
                result = true;
                return true;
            }
        });
        return result;
    };

    getAccessCount = () => {
        let count = 0;
        angular.forEach(this.accessTypes, (reportType, access) => {
            if (this.getAccess(access)) {
                count++;
            }
        });
        return count;
    };

    getAccess = (reportType) => {
        if (this[reportType] && this.accessTypes[reportType] && this.reports.indexOf(this.accessTypes[reportType]) > -1) {
            if (!this.selectedFile) {
                this.selectedFile = reportType;
            }
            return true;
        }
    };

    setLoading = (type, loading) => {
        this.loading[type] = loading;
    };

    setError = (type, error) => {
        this.error[type] = error;
    };
}

SaveFileSelectController.$inject = [
    '$ngRedux',
    '$scope',
    'gettextCatalog',
];

export {
    SaveFileSelectController,
};
