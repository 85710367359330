import React from 'react'
import SettingsHeader from 'rootReact/components/SettingsSectionHeader'
import { GettextContext, LocationContext, InjectorContext } from 'rootReact/context'
import {useContext, useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import Input from 'rootReact/components/Input'
import InputTimeZone from 'rootReact/components/InputTimeZone/'
import FetchApi from 'rootReact/REST/fetchApi'
import FaIcon from 'rootReact/components/FaIcon'
import DepartmentsSelect from 'rootReact/components/DepartmentSelect'
import { useInputStyle, useValidForm } from 'rootReact/hook'
import {passwordRule, userRules} from './SettingsAuthRule'
import {validRules} from '../../helpers'
import {ReactModal} from 'root/assets/webpack/src/js/components/ReactModal'
import UserPolicyModal from '../../components/UserModalPolicy'

function SettingsAuth({licenseIsValid}) {
    const gettext = useContext(GettextContext)
    const injector = useContext(InjectorContext)
    const $location = useContext(LocationContext)
    const auth = useSelector(state => state.auth.auth)
    const [savePassError, setSavePassError] = useState(null)
    const [savePassLoad, setSavePassLoad] = useState('idle')
    const [position, setPosition] = useState(auth.position || '')
    const [timeZone, setTimeZone] = useState({})
    const [department, setDepartment] = useState(auth.department || {})
    const [timeZones, setTimeZones] = useState([])
    const [targetCreate, setTargetCreate] = useState(false)
    const [targetError, setTargetError] = useState('')
    const [fieldChange, setFieldChange] = useState(false)
    const showPasswordForm = useSelector(state => state.settings.settings.ldapProfileId === null)
    const values = useValidForm({
        'department-select': { value: department, setValue: setDepartment },
        'time-zone': { value: timeZone, setValue: setTimeZone },
        position: { value: position, setValue: setPosition }
    }, userRules)
    const [timeZonesLoading, setTimeZonesLoading] = useState(false)
    const timeZoneStyle = useInputStyle(['react-input_field', 'react-input_field__right', 'time-zone_value'], values['time-zone'].touched, values['time-zone'].focused, values['time-zone'].error)
    const departmentStyle = useInputStyle(['react-input_field'], values['department-select'].touched, values['department-select'].focused, values['department-select'].error)
    const positionStyle = useInputStyle(['react-input_field'] , values.position.touched, values.position.focused, values.position.error)
    const [newPassword, setNewPassword] = useState()
    const [oldPassword, setOldPassword] = useState()
    const passwordValues = useValidForm({
        'new-password': {value: newPassword, setValue: setNewPassword},
        'old-password': {value: oldPassword, setValue: setOldPassword}
    }, passwordRule)
    const oldPasswordStyle = useInputStyle(['react-input_field'] ,passwordValues['old-password'].touched, passwordValues['old-password'].focused, passwordValues['old-password'].error)
    const newPasswordStyle = useInputStyle(['react-input_field react-input_field__left'] ,passwordValues['new-password'].touched, passwordValues['new-password'].focused, passwordValues['new-password'].error)

    const logout = async () => {
        const isSSO = auth.authType === 3
        let url = isSSO ? '/sso/logout' : '/logout'
        const fetchApi = FetchApi(url, true)
        let response = await fetchApi.get()
        if (response.ssoRedirectUrl) {
            window.location.href = response.ssoRedirectUrl
        } else {
            window.localStorage.removeItem('ngStorage-userInfo')
            $location.path('login')
        }
    };

    const getTimeZones = async () => {
        let response = await FetchApi('/settings/time-zones').get()
        if (response.error) {
            setError(true)
        } else {
            setTimeZones(response.map(item => {
                        item.displayValue = 'GMT' + item.gmt +' | ' + item.name
                        if(item.name === auth.timeZone) {
                            setTimeZone(item)
                        }
                        return item
                    }
            ));
            setTimeZonesLoading(true)
        }
    }

    useEffect(() => {
        setTargetCreate(false)
        setFieldChange(true)
        setTargetError('')
    }, [position, department])

    useEffect(() => {
        getTimeZones()
        setTimeout(() => {
            setFieldChange(false)
        }, 100)
    }, [])

    const changePassword = async () => {
        if (savePassLoad === 'pending' || !licenseIsValid) return
        setSavePassError(null)
        let validate = validRules(passwordRule, [
            { name: 'new-password', value: newPassword },
            { name: 'old-password', value: oldPassword }
        ])
        if (validate) {
            passwordValues.setError(validate)
            return
        }
        setSavePassLoad('pending')

        const request = await FetchApi('/settings/password').post({body: {password: newPassword, old_password: oldPassword}})
        if (request.isError) {
            setSavePassError(request.err.error)
            setSavePassLoad('reject')
        } else {
            setSavePassLoad('success')
            setNewPassword('')
            setOldPassword('')
            setTimeout(() => {
                setSavePassLoad('idle')
            }, 1000)
        }
    }

    const handleTimeZone = async (timezone) => {
        values['time-zone'].setValue(timezone)
        await saveTarget(false, timezone)
    }

    const passwordGenerate = async () => {
        if (!licenseIsValid) return
        const request = await FetchApi('/settings/password-generate').post({body: {}})
        setNewPassword(request.password)
    }

    const openUserPolicyModal = () => {
        if (!licenseIsValid) return
        let modal = injector.instantiate(ReactModal)
        modal.open({props: {userId: 0}, component: UserPolicyModal }, 'md')
    }

    const saveTarget = async (isTarget = false, load = false) => {
        if (!load && (!fieldChange || !licenseIsValid)) return
        if (isTarget && (!department || !department.id)) {
            setTargetError(gettext.getString('Не выбран отдел!'))
            return
        }
        setFieldChange(false)
        const fetchApi = FetchApi('/settings/profile')
        const body = {
         timeZone: load && load.name || timeZone.name
        }
        if (position) {
            body.position = position
        }
        if (department.id) {
            body.departmentId = department.id
        }
        if (isTarget) {
            body.createTarget = 1
        }
        let request = await fetchApi.post({body})
        if (!request || !request.isError) {
            isTarget && setTargetCreate(true)
            return
        }
        if (request && request.isError) {
            setTargetError(request.err || gettext.getString('Цель не создана!'))
        }
    }

    return (
            <>
                <div className={'settings-auth'}>
                    <SettingsHeader title={gettext.getString('Администратор')}>
                        <div><a onClick={logout} className={'settings_path-right'}>{gettext.getString('выход из системы')}</a></div>
                    </SettingsHeader>
                    <div className={'settings_item'}>
                        <div className={'settings_path-left'}>{gettext.getString('ФИО')}</div>
                        <div className={'settings_path-right'}>{auth.fullName}</div>
                    </div>
                    <div className={'settings_item'}>
                        <div className={'settings_path-left'}>{gettext.getString('Электронная почта')}</div>
                        <div className={'settings_path-right'}>{auth.email}</div>
                    </div>
                    <div className={'settings_item'}>
                        <div className={'settings_path-left'}>{gettext.getString('Телефон')}</div>
                        <div className={'settings_path-right'}>{auth.phone}</div>
                    </div>
                    {timeZonesLoading && <InputTimeZone
                            disabled={!licenseIsValid}
                            inputAttr={values['time-zone'].inputAttr}
                            inputId={'time-zone'}
                            timeZones = {timeZones}
                            Style={timeZoneStyle}
                            placeholder={'UTC'}
                            label={gettext.getString('Часовой пояс')}
                            handleClick={handleTimeZone}
                    />}
                    <div className={'settings_item'}>
                        <div className={'settings_path-left mr-15'}>{gettext.getString('Отдел')}</div>
                        <DepartmentsSelect
                                disabled={!licenseIsValid}
                                inputAttr={values['department-select'].inputAttr}
                                Style={departmentStyle}
                                handleClick={values['department-select'].setValue}
                        />
                    </div>
                    <div className={'settings_item'}>
                        <Input
                                inputId={'position'}
                                disabled={!licenseIsValid}
                                labelStyle={'settings-auth_label'}
                                label={gettext.getString('Должность')}
                                inputAttr={values['position'].inputAttr}
                                error={values['position'].error}
                                Style={positionStyle}
                        />
                    </div>
                    <div className={'settings_item'}>
                        <div className={'settings_path-left'}></div>
                        {!targetCreate &&
                        !targetError &&
                        <div className={`settings_path-right`}>
                            <a className={`${(!fieldChange || !licenseIsValid) ? 'nolink' : ''}`}
                               onClick={() => saveTarget(true)}>{gettext.getString('Создать как цель')}</a>
                        </div>}
                        {targetCreate && !targetError && <div><FaIcon type={'success'} text={gettext.getString('Цель создана')} /></div>}
                        {targetError &&
                        <div className={'settings_path-right'} style={{display: 'flex', flexDirection: 'column'}}>
                            <FaIcon type={'error'} text={targetError} />
                            {targetError !== gettext.getString('Не выбран отдел!') && <span style={{marginTop: '1rem'}}><a onClick={() => saveTarget(true)}>{gettext.getString('Повторить')}</a></span>}
                        </div>}
                    </div>
                </div>
                {auth.authType === 1 && showPasswordForm && <>
                    <div className={'settings-password'}>
                        <div className={'settings_item'}>
                            <Input
                                    inputId={'old-password'}
                                    disabled={!licenseIsValid}
                                    labelStyle={'settings-auth_label'}
                                    label={gettext.getString('Старый пароль')}
                                    inputAttr={passwordValues['old-password'].inputAttr}
                                    error={passwordValues['old-password'].error}
                                    Style={oldPasswordStyle}
                            />
                        </div>

                        <div>
                            <Input
                                    inputId={'new-password'}
                                    disabled={!licenseIsValid}
                                    labelStyle={'settings-auth_label'}
                                    label={gettext.getString('Новый пароль')}
                                    inputAttr={passwordValues['new-password'].inputAttr}
                                    error={passwordValues['new-password'].error}
                                    Style={newPasswordStyle}
                                    control={
                                        {
                                            type: 'button',
                                            value: gettext.getString('Сгенерировать'),
                                            handle: {
                                                className: `react-input_field react-input_field__right ${passwordValues['new-password'].error ? 'react-button__error' : ''}`,
                                                onClick: passwordGenerate,
                                                disabled: !licenseIsValid,
                                                'data-testid': 'generate-password-button',
                                            }
                                        }
                                    }
                            />
                        </div>
                    </div>
                    <div className={'settings_item settings_item__last'}>
                        <div className={'settings_path-left'}></div>
                        <span className={'settings_path-right break-space'}>
                            {!savePassError && savePassLoad !== 'pending' && savePassLoad !== 'success' && '\r\n'}
                            {savePassError && <FaIcon type={'error'} text={savePassError}/> }
                            {savePassLoad === 'pending' && <FaIcon type={'pending'} text={gettext.getString('Изменение пароля')}/>}
                            {savePassLoad === 'success' && <FaIcon testId={'password-change-result'} type={'success'}  text={gettext.getString('Пароль изменен')}/>}
                            <a data-testid={'change-password-link'} className={!licenseIsValid ? 'nolink' : ''} onClick={changePassword}>{gettext.getString('Сменить пароль')}</a>
                            <span> {gettext.getString('или')}</span>{'\r\n'}
                            <a data-testid={'password-policy-link'} className={!licenseIsValid ? 'nolink' : ''} onClick={openUserPolicyModal}>{gettext.getString('Требования к паролю')}</a>
                        </span>
                    </div>
                </>}

            </>
    )
}

export default SettingsAuth;
