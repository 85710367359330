import { getDashboardVersion, hasAccess, getDashboardRound } from '../../reselect';
import style from './style.modules.css';
import { getDashboardNew, getKnowledge, getCampaign, getVulnerability, getRating } from '../../reselect/newDasboard';

class NavBarController {
    constructor ($ngRedux, $state, $anchorScroll, $location, gettextCatalog, scope) {
        this.$state = $state;
        this.scope = scope;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this.style = style;
        this.printValue1 = '';
        this.subVal1 = '';
        this.printValue2 = '';
        this.subVal2 = '';
        this.printValue4 = '';
        this.subVal4 = '';
        this.gettextCatalog = gettextCatalog;
        this.translate = {
            targets: gettextCatalog.getString('Сотрудники'),
            attacks: gettextCatalog.getString('Атаки'),
            risks: gettextCatalog.getString('Риски'),
            reports: gettextCatalog.getString('Отчеты'),
            targets2: gettextCatalog.getString('Знания'),
            attacks2: gettextCatalog.getString('Навыки'),
            risks2: gettextCatalog.getString('Рейтинг'),
            reports2: gettextCatalog.getString('Уязвимости'),
            targets3: gettextCatalog.getString('Люди'),
            education3: gettextCatalog.getString('Обучение'),
            attacks3: gettextCatalog.getString('Тренировки'),
            motivation: gettextCatalog.getString('Мотивация'),
        };
        this.scrolled = 0;
        this.config = window.config;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis)(this);
    }

    mapStateToThis = (state) => {
        const ctrl = this;
        return {
            dashboard: getDashboardRound(state),
            targets: state.dashboard.targets,
            attacks: state.dashboard.attacks,
            risks: state.dashboard.risks,
            targetsInSystem: getDashboardNew(state).targets,
            noCourse: getDashboardNew(state).noCourse,
            noCampaign: getDashboardNew(state).noCampaign,
            companyTargetCount: state.auth.auth.companyTargetCount,
            dasbordV1: [
                {
                    accessDashboardView: hasAccess(state, { sectionId: 1, rightName: 'view' }),
                    location: 'targets',
                    title: ctrl.translate.targets,
                    class: '',
                    get body () {
                        return getDashboardNew(state).targets;
                    }
                },
                {
                    accessDashboardView: hasAccess(state, { sectionId: 2, rightName: 'view' }),
                    location: 'attacks',
                    title: ctrl.translate.attacks,
                    class: 'mark-danger',
                    get body () {
                        return getDashboardNew(state).campaigns;
                    }
                },
                {
                    accessDashboardView: hasAccess(state, { sectionId: 4, rightName: 'view' }),
                    location: 'reports',
                    title: ctrl.translate.reports,
                    class: 'mark-danger',
                    get body () {
                        return !getDashboardNew(state).vulnPercent &&
                        getDashboardNew(state).vulnCount > 0 ? '<1' :
                            `${getDashboardNew(state).vulnPercent}%`;
                    }
                },
                {
                    accessDashboardView: hasAccess(state, { sectionId: 3, rightName: 'view' }),
                    location: 'risks',
                    title: ctrl.translate.risks,
                    class: 'mark-critical',
                    get body () {
                        return getDashboardNew(state).vulnCount;
                    }
                }
            ],
            dasbordV2: [
                {
                    title: ctrl.translate.targets2,
                    accessDashboardView: hasAccess(state, { sectionId: 1, rightName: 'view' }),
                    get body () {
                        const data = getKnowledge(state);
                        const index = getDashboardRound(state).targets;
                        let value = '';
                        for (let key in data) {
                            if (data[key].index && +data[key].index === +index) {
                                value = data[key].text;
                            }
                        }
                        return value;
                    },
                    location: 'targets',
                    get mainClass () {
                        return `v2-percent targets index-${getDashboardRound(state).targets}`;
                    },
                    subClass: 'less',
                    printClass: ''
                }, {
                    title: ctrl.translate.attacks2,
                    accessDashboardView: hasAccess(state, { sectionId: 2, rightName: 'view' }),
                    get body () {
                        const data = getCampaign(state);
                        const index = getDashboardRound(state).attacks;
                        let value = '';
                        for (let key in data) {
                            if (data[key].index && +data[key].index === +index) {
                                value = data[key].text;
                            }
                        }
                        return value;
                    },
                    location: 'attacks',
                    get mainClass () {
                        return `v2-percent attacks index-${getDashboardRound(state).attacks}`;
                    },
                    subClass: 'less',
                    printClass: ''
                }, {
                    title: ctrl.translate.risks2,
                    accessDashboardView: hasAccess(state, { sectionId: 4, rightName: 'view' }),
                    rating: getRating(state),
                    location: 'reports',
                    mainClass: 'rating',
                    subVal: getDashboardNew(state).lastChange,
                    printValue: getDashboardNew(state).rating,
                    get subClass () {
                        return `rating--change ${this.subVal > 0 ? 'text-success' : (this.subVal < 0 ? 'text-danger' : '')}`;
                    },
                    get printClass () {
                        return `rating--indicator ${this.printValue > 0 ? 'text-success' : (this.printValue < 0 ? 'text-danger' : 'zero-rating')}`;
                    }
                }, {
                    title: ctrl.translate.reports2,
                    accessDashboardView: hasAccess(state, { sectionId: 3, rightName: 'view' }),
                    get body () {
                        const data = getVulnerability(state);
                        const index = getDashboardRound(state).risks;
                        let value = '';
                        for (let key in data) {
                            if (data[key].index && +data[key].index === +index) {
                                value = data[key].text;
                            }
                        }
                        return value;
                    },
                    location: 'risks',
                    get mainClass () {
                        return `v2-percent risks index-${getDashboardRound(state).risks}`;
                    },
                    subClass: 'less',
                    printClass: ''
                }
            ],
            accessDashboard: hasAccess(state, { sectionId: 0, rightName: 'view' }),
            knowledge: getKnowledge(state),
            campaign: getCampaign(state),
            rating: getRating(state),
            vulnerability: getVulnerability(state),
            version: getDashboardVersion(state)
        };
    };

    getVersion () {
        return this.version;
    }

    onBurgerClick () {
        if (!this.menuOpen) {
            let t;
            let up = () => {
                var top = Math.max(document.body.scrollTop, document.documentElement.scrollTop);
                if (top > 0) {
                    window.scrollBy(0, ((top + 100) / -10));
                    t = setTimeout(up, 20);
                } else {
                    clearTimeout(t);
                }
                return false;
            };
            up();
        }
        this.onMenuOpen();
    }

    $onInit () {
        // let peoplePercent = this.setValuePersent(this.companyTargetCount, true);
        // let coursePersent = this.setValuePersent(this.noCourse);
        // let attackPersent = this.setValuePersent(this.noCampaign);
        // this.dasbordV3 = [
        //     {
        //         title: this.gettextCatalog.getString('Люди'),
        //         params: peoplePercent.value,
        //         number: peoplePercent.numVal,
        //         state: 'people',
        //         num: 'companyTargetCount',
        //         link: this.onTitleClick,
        //     },
        //     {
        //         title: this.gettextCatalog.getString('Обучение'),
        //         params: coursePersent.value,
        //         number: coursePersent.numVal,
        //         state: 'education',
        //         num: 'noCourse',
        //         link: this.onTitleClick,
        //     },
        //     {
        //         title: this.gettextCatalog.getString('Тренировки'),
        //         params: attackPersent.value,
        //         number: attackPersent.numVal,
        //         state: 'attack',
        //         num: 'noCampaign',
        //         link: this.onTitleClick,
        //     },
        // ];
    }

    setValuePersent = (count, isPeople = false) => {
        let persent = `<span class="${this.style.percent}">%</span>`;
        let value;
        if (isPeople) {
            value = this.targetsInSystem * 100 / count || 0;
        } else {
            value = 100 - (count * 100 / this.targetsInSystem) || 0;
        }
        let numVal = value;
        if (value > 0 && value < 1) {
            value = `<1${persent}`;
        } else if (value < 100 && value > 99) {
            value = `>99${persent}`;
        } else if (value === 0 || value === 100) {
            value += persent;
        } else {
            value = Math.round(value) + persent;
        }
        return {value, numVal};
    }

    go () {
        this.$location.hash('top');
        this.$anchorScroll();
    }
}

NavBarController.$inject = [
    '$ngRedux',
    '$state',
    '$anchorScroll',
    '$location',
    'gettextCatalog',
    '$scope'
];

export {
    NavBarController
};
