import {AUTH_SERVICE, FINAL_PAGES_SERVICE, PHISHING_PAGES_SERVICE, USB_SERVICE} from '../../const';
import angular from 'angular';
import style from './style.modules.css';

class UsbAttackModalController {
    constructor($window, UsbService, PhishingPagesService, FinalPagesService, AuthService, attackId, copyId) {
        this.$window = $window;
        this.UsbService = UsbService;
        this.attackId = attackId;
        this.copyId = copyId;
        this.style = style;
        this.finalPages = [];
        this.gettextCatalog = window.appCtrl.gettextCatalog;
        AuthService.getUserInfo('licenseFeatures,licenseOutInfectedUrl').$promise.then((userInfo) => {
            this.userInfo = userInfo;
            FinalPagesService.getAll().$promise.then((finalPages) => {
                if (userInfo && userInfo.license.outInfectedUrl) {
                    finalPages.unshift({
                        id: -1,
                        title: userInfo.license.outInfectedUrl,
                    });
                }
                this.finalPages = finalPages;
            });
        });
        this.phishingPages = PhishingPagesService.getAll();
        this.inProcess = false;
        this.errorText = '';
    }

    $onInit = () => {
        if (this.attackId || this.copyId) {
            this.attackLoading = true;
            this.attackError = false;
            this.UsbService.one(this.attackId || this.copyId).then((attack) => {
                this.attack = attack;
                this.showRedirectPage = attack.phishingPage ? 1 : 0;
                if (this.copyId) {
                    delete (this.attack.id);
                    this.attack.name += ` - ${this.gettextCatalog.getString('копия')}`;
                }
                this.attackLoading = false;
            }, () => {
                this.attackLoading = false;
                this.attackError = true;
            });
        } else {
            this.attack = this.UsbService.YiiModel.one('usb-attacks');
            this.attack.state = 0;
        }
    };

    setForm = (form) => {
        this.form = form;
    };

    copyClipboard = function(text) {
        let body = angular.element(this.$window.document.body);
        let textarea = angular.element('<textarea/>');
        textarea.css({
            position: 'fixed',
            opacity: '0',
        });
        textarea.val(text);
        body.append(textarea);
        textarea[0].select();
        try {
            document.execCommand('copy');
        } catch (err) {
            if (err) {
                // nothing
            }
        }
        textarea.remove();
    };

    save = () => {
        this.errorText = null;
        this.form.$setSubmitted();
        if (this.form.$valid) {
            this.inProcess = true;
            if (!this.attack.token) {
                let path1 = Math.random().toString(36).substr(2);
                let path2 = Math.random().toString(36).substr(2);
                let path3 = Math.random().toString(36).substr(2);
                this.attack.token = encodeURI(path1 + path2 + path3);
            }
            if (this.copyCl) {
                this.copyClipboard(this.attack.token);
            }
            if (!this.showRedirectPage) {
                this.attack.phishingPage = {};
            }
            this.attack.$save().then((attack) => {
                if (attack) {
                    this.$close(attack);
                }
                this.inProcess = false;
            }).catch(() => {
                this.errorText = this.gettextCatalog.getString('Ошибка при сохранении атаки');
            });
        }
    };

}

UsbAttackModalController.$inject = [
    '$window',
    USB_SERVICE,
    PHISHING_PAGES_SERVICE,
    FINAL_PAGES_SERVICE,
    AUTH_SERVICE,
    'attackId',
    'copyId',
];

export {
    UsbAttackModalController,
};
