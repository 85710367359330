import React from 'react';
import style from './Button.module.less';
import Translate from 'rootReact/Translate.jsx';
const PRIME = 'prime';

const LG = 'lg';

export const Button = ({
                    btnStyle = PRIME,
                    handleClick,
                    disabled,
                    children,
                    active,
                    size = LG
                }) => {

    return (
            <button className={`${style.button} ${style[btnStyle]} ${disabled && style.disabled} ${active && style.active}`}
                    onClick={handleClick}
                    disabled={disabled}
                    data-size={size}
            >
                <Translate>{children}</Translate>
            </button>
    );
};
