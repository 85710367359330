import React from 'react'
import FetchApi from 'rootReact/REST';

function ReactSaveFile({text, iconClass, url, fileType, fileName, disabled}) {

    const handleClick = (e) => {
        if (disabled) return
        e.preventDefault()
        e.stopPropagation()
        const fetchApi = FetchApi(url)
        let response = fetchApi.download(url, fileName)
    }

    const contentTypes = {
        xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        csv: 'text/csv',
        zip: 'application/zip'
    };

    return (
        <>
            {iconClass ? <span className={iconClass}></span>: ''}
            <a className={disabled ? 'nolink' : ''} onClick={handleClick}>
                {text}
            </a>
        </>
    )
}

export default ReactSaveFile
