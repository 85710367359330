import React from 'react'
import { useState, useEffect } from 'react'

function DropdownSelect({
                            inputAttr,
                            idClass,
                            Style,
                            list,
                            children,
                            handleClick,
                            placeholder,
                            optionsName,
                            disabled,
                            testId,
                            testIdList,
                            testIdSearch,
                            labelStyle,
                            parentFilterString,
                            isCreate,
                            setValueInput,
                            visibleArrow
}) {
    const [isOpen, setIsOpen] = useState(false)
    const [value, setValue] = useState(inputAttr.value)
    const [searchList, setSearchList] = useState(list)

    useEffect(() => {
        setValue(inputAttr.value)
    }, [inputAttr.value])

    useEffect(() => {
        filterList(value)
        setValueInput && setValueInput(value);
    }, [value])

    const handleOpenClick = ({ target }) => {
        if (!isOpen || disabled) return
        let opened = isDropdown(target)
        if (!opened) setIsOpen(false)
    }

    const handleClickOptions = (item) => {
        if (disabled) return
        setIsOpen(false)
        handleClick(item)
        setValueInput && setValueInput(item[optionsName]);
    }

    const filterList = (value) => {
        if (!list) return;
        if (!value || value?.toLowerCase() === inputAttr.value?.toLowerCase()) {
            setSearchList(list)
            return;
        }
        let filter = list.filter(item => item[optionsName]?.toLowerCase().indexOf(value?.toLowerCase()) !== -1)
        setSearchList(filter)
    }

    const handleChange = (value) => {
        if (disabled) return
        if (parentFilterString) {
            parentFilterString(value);
        }
        setValue(value)
    }

    const isDropdown = (element) => {
        if (element && element.classList.contains('closes')) return false;
        if (element !== document.body && !element.classList.contains(idClass)) return isDropdown(element.parentElement)
        if (element === document.body) return false
        if (element.classList.contains(idClass)) return true
    }

    useEffect(() => {
        document.addEventListener('click', handleOpenClick)
        return () => {
            document.removeEventListener('click', handleOpenClick)
        }
    }, [isOpen])

    const handleBlur = (e) => {
        setValue(inputAttr.value)
        inputAttr.onBlur && inputAttr.onBlur(e)
    }

    const getOptions = () => {
        if (disabled) return
        if (children) return children
        return searchList.map(item => {
            return <span
                onClick={() => handleClickOptions(item)}
                className={'react-dropdown_item'}
                data-testid={testId}
                key={item[optionsName ? optionsName : 'name']}
            >{item.ico && <img className={'react-dropdown_ico'} src={item.ico} alt="lnk" />} {item[optionsName ? optionsName : 'displayValue']}</span>
        })
    }

    return (
        <div
            className={`react-dropdown ${idClass}`}>
            <input
                onClick={() => { setIsOpen(!isOpen) }}
                onChange={(e) => {
                    handleChange(e.target.value)
                }}
                data-testid={testIdSearch}
                disabled={disabled}
                id={idClass}
                onFocus={inputAttr.onFocus}
                onBlur={handleBlur}
                type="text"
                autoComplete={'off'}
                value={value}
                className={Style}
                placeholder={placeholder}
            />
            <span
                onClick={() => { setIsOpen(!isOpen) }}
                style={{ position: 'absolute', top: '9px', right: '12px', cursor: 'pointer', opacity: visibleArrow }}
            >
                <svg className="dark-mode-fill" version="1.1" id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10px" height="10px"
                    viewBox="0 0 255 255" fill={'#000'}>
                    <g>
                        <g id="arrow-drop-down">
                            <polygon points="0,63.75 127.5,191.25 255,63.75"></polygon>
                        </g>
                    </g>
                </svg>
            </span>
            <div data-testid={testIdList} className={isOpen ? 'react-dropdown_options react-dropdown_open' : 'react-dropdown_options'}>
                {getOptions()}
            </div>
        </div>
    )
}

export default DropdownSelect
