import React from 'react';

function Tooltip({children, reactTip, inline}) {
    return (
            <div className="react-tooltip-wrapper" style={inline && {display: 'inline-flex'}}>
                {reactTip ? (<div className="react-tooltip">{reactTip}</div>) : null}
                {children}
            </div>
    )
}

export default Tooltip;
