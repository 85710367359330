import { Controller } from './controller';
import { getDepartments, hasAccess } from '../reselect';
import buildSearchString from '../helpers/buildSearchString';
import { AUTH_SERVICE, CAMPAIGN_TARGETS_SERVICE, DEPARTMENTS_SERVICE, EMPTY_SELECTED_DATA, LEARNING_SERVICE, STATUS_SERVICE, TARGETS_SERVICE } from '../const';
import angular from 'angular';
import { ReportModal } from '../components/reportModal';
import buildSelectedDataCacheString from '../helpers/buildSelectedDataCacheString';
import go from '../helpers/go';
import {setGraphMode, clearSelectedData, setBreadcrumbLink, setLinkings, setSelectedData, setSearchData} from '../toolkit/actions';
import {fetchLearningConfig} from '../toolkit/think';
import TimeSelect from 'rootReact/components/TimeSelect';

class DepartmentsController extends Controller {
    constructor ($injector, $localStorage, $sessionStorage, $scope, growl, $timeout, $stateParams, gettextCatalog, $ngRedux, DepartmentsService, TargetsService, StatusService, CampaignTargetsService, AuthService, LearningService, $location) {
        super();
        this.$injector = $injector;
        this.$localStorage = $localStorage;
        this.$sessionStorage = $sessionStorage;
        this.scope = $scope;
        this.growl = growl;
        this.$timeout = $timeout;
        this.$stateParams = $stateParams;
        this.$location = $location;
        this.LearningService = LearningService;
        this.redux = $ngRedux;
        this.TimeSelect = TimeSelect;
        this.targetsFirstLoading = false;
        // Services
        this.StatusService = StatusService;
        this.CampaignTargetsService = CampaignTargetsService;
        this.AuthService = AuthService;
        this.DepartmentsService = DepartmentsService;
        this.TargetsService = TargetsService;
        // Progress and errors
        this.departmentsLoading = false;
        this.targetsLoading = false;
        this.coursesLoading = false;
        this.managersLoading = false;
        this.ignoreSetLoading = {
            departmentsLoading: false,
            targetsLoading: false,
            coursesLoading: false,
            managersLoading: false
        };
        this.reBuildLoading = false;
        // Cache
        this.cacheDepartmentsById = {}; // кеш отделов по id
        // Toggle departments if has collapse mode
        this.shown = false;
        // Init variables
        this.userInfo = null;
        // Select all modificators
        this.targetCount = 0;
        this.reloading = false;
        this.departmentCount = 0;
        this.groupCount = 0;
        this.targetCount = 0;
        this.reverse = false;
        this.appCtrl = window.appCtrl;
        this.gettextCatalog = gettextCatalog;
        this.departmentCheckedCache = [];
        this.departmentPartialCache = [];
        this.groupCheckedCache = [];
        this.groupPartialCache = [];
        this.noDepartments = false;
        this.noTargets = false;
        this.breadcrumbTitles = {
            0: this.gettextCatalog.getString('Сотрудники - цели для атаки'),
            1: this.gettextCatalog.getString('Все курсы'),
            2: this.gettextCatalog.getString('Все руководители'),
            3: this.gettextCatalog.getString('Группы риска'),
        };
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, {setSelectedData, setSearchData, setLinkings, setBreadcrumbLink, clearSelectedData, setGraphMode, fetchLearningConfig})(this);
        this.strings = {
            attackNew: gettextCatalog.getString('не проверялся'),
            attackFail: gettextCatalog.getString('не выдержал атаку')
        };
    }

    mapStateToThis = (state) => {
        return {
            selectedData: state.selectedData.selectedData,
            hashPath: state.linking.link,
            applyFilterDepartment: state.departments,
            fetchDepartmentField: getDepartments(state),
            linkNames: state.breadcrumbLinks.link,
            viewAccess: hasAccess(state, { sectionId: 1, rightName: 'view' }),
            saveAccess: hasAccess(state, { sectionId: 1, rightName: 'target_save' }),
            deleteAccess: hasAccess(state, { sectionId: 1, rightName: 'target_delete' }),
            educationAccess: hasAccess(state, { sectionId: 1, rightName: 'target_education' }),
            historyAccess: hasAccess(state, { sectionId: 1, rightName: 'target_history' }),
            attackAccess: hasAccess(state, { sectionId: 1, rightName: 'target_attack' }),
            reportAccess: hasAccess(state, { sectionId: 1, rightName: 'target_download' }),
            reportEducationAccess: hasAccess(state, { sectionId: 1, rightName: 'report_department' }),
            downloadAccess: hasAccess(state, { sectionId: 1, rightName: 'report_education' }),
            accessReportView: hasAccess(state, { sectionId: 4, rightName: 'view'}),
            graphMode: state.graphMode.mode,
        };
    };

    $onInit = () => {
        const { $localStorage, $location } = this;
        if (!this.viewAccess) {
            if ($localStorage.userInfo.defaultPage) {
                go($location, $localStorage.userInfo.defaultPage);
            }
            return;
        }
        if (this.educationAccess) {
            this.fetchLearningConfig();
            this.LearningService.courses().$promise.then(courses => {
                angular.forEach(courses, (course) => {
                    if (course.valid) {
                        course.group = 0;
                    }
                });
                this.courseList = courses;
                this.coursesLoading = false;
            });
        }
        this.scope.$watch($scope => {
            return $scope.departmentsCtrl.$localStorage.departments.dataMode;
        }, (newValue, oldValue) => {
            if (oldValue === newValue) {
                return;
            }
            if (!newValue && !this.accessReportView) {
                this.dataMode === 1;
            }
            this.dataMode = newValue;
        });
        this.scope.$watch($scope => {
            return $scope.departmentsCtrl.$localStorage.departments.viewMode;
        }, (newValue, oldValue) => {
            if (oldValue === newValue) {
                return;
            }
            this.setViewMode(newValue);
        });
        this.reBuildLoading = false;
        // Renew variables
        this.prefix = 'targets';
        if (this.reports) {
            this.prefix = 'reports';
        } else if (this.risk || this.vuln) {
            this.prefix = 'risks';
        }
        $localStorage.selectAllVisible = false;
        $localStorage.unselectAllVisible = false;
        if (!this.course || !this.userInfo) {
            this.userInfo = this.AuthService.getUserInfo('licenseIsValid');
        }
        if (typeof ($localStorage.departments) == 'undefined') {
            $localStorage.departments = {};
        }
        if (typeof ($localStorage.targets) == 'undefined') {
            $localStorage.targets = {};
        }
        $localStorage.openedCourse = null;
        $localStorage.openedManager = null;
        if (typeof ($localStorage.openedDepartments) == 'undefined' || this.risk || this.reportParams) {
            $localStorage.openedDepartments = [];
        }
        this.setViewMode($localStorage.departments ? +$localStorage.departments.viewMode : 2);
        if (!this.course && !this.reports && !this.vuln && !this.risk && !this.notEditable) {
            this.dataMode = $localStorage.departments && $localStorage.departments.dataMode || (this.accessReportView ? 0 : 1);
        } else {
            this.dataMode = this.accessReportView ? 0 : 1;
        }
        this.sortBy = 'targetsCount';
        this.sortReverse = true;
        this.sortReverseNg = false;
        this.educationSortBy = $localStorage.courses && $localStorage.courses.sortBy || 'name';
        this.educationSortReverse = $localStorage.courses && $localStorage.courses.sortReverse || false;
        // Clear selected data
        this.clearSelectedData();
        // $localStorage.selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy({search: this.selectedData.search}));
        // Set selected from attack
        if (this.campaignId) {
            this.CampaignTargetsService.getCurrent(this.campaignId).$promise.then((selectedData) => {
                selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(selectedData));
                this.setSelectedData(selectedData);
            });
        }
        this.setModalSelectedData();
        this.scope.$watch($scope => {
            return $scope.departmentsCtrl.applyFilterDepartment;
        }, (applyFilter, oldValue) => {
            if (applyFilter === oldValue) {
                return;
            }
            if (applyFilter.reload) {
                if (window.risksCtrl && applyFilter.reload === 'risksCtrl') {
                    window.risksCtrl.expand = null;
                    window.risksCtrl.expandedType = null;
                }
                if (applyFilter.reload === 'departments') {
                    this.clearAll();
                    this.reloading = true;
                }
                this.applyFilterDepartment({
                    search: '',
                    callback: null,
                    reload: false
                });
            }
            if (!applyFilter.search && applyFilter.callback) {
                this[applyFilter.callback]();
                this.applyFilterDepartment({
                    search: '',
                    callback: null,
                    reload: false
                });
                return;
            }
            if (!applyFilter.search) {
                return;
            }
            this.findByAndSelectAll(applyFilter.search, applyFilter.callback ? () => {
                this[applyFilter.callback](), this.applyFilterDepartment({ search: '', callback: null, reload: false });
            } : null, 0, true);
        });
        let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(this.selectedData));
        $localStorage.selectedDataJsonCache = buildSelectedDataCacheString(selectedData);
        this.linkNames = [];
        this.setBreadcrumbLink([...this.linkNames]);
        this.scope.$watch(scope => {
            return scope.departmentsCtrl.hashPath;
        }, (newVal, oldVal) => {
            if (oldVal === newVal) {
                return;
            } else if (newVal.indexOf('setModalSelectedData') > -1) {
                this.setModalSelectedData();
            }
        });
    };

    setModalSelectedData = () => {
        const { $localStorage } = this;
        if ((!$localStorage.campaignData ||
            !$localStorage.campaignData.selectedData) &&
            (!$localStorage.campaignData ||
            !$localStorage.campaignData.selectedData) &&
            (!$localStorage.schedulerTaskSelectedData ||
            !$localStorage.schedulerTaskSelectedData.schedulerTask ||
            !$localStorage.schedulerTaskSelectedData.schedulerTask.selectedData)) {
            return;
        }
        this.setLinkings('');
        let selectedSearch = [this.gettextCatalog.getString('выбранные сотрудники')];
        // Extend selected data from attack
        let applySelectedData = null;
        if ($localStorage.campaignData && $localStorage.campaignData.selectedData) {
            applySelectedData = angular.copy($localStorage.campaignData.selectedData);
        }
        if ($localStorage.schedulerTaskData && $localStorage.schedulerTaskData.selectedData) {
            applySelectedData = angular.copy($localStorage.schedulerTaskData.selectedData);
        }
        if ($localStorage.schedulerTaskSelectedData && $localStorage.schedulerTaskSelectedData.schedulerTask && $localStorage.schedulerTaskSelectedData.schedulerTask.selectedData) {
            applySelectedData = angular.copy($localStorage.schedulerTaskSelectedData.schedulerTask.selectedData);
        }
        if (applySelectedData) {
            this.setViewMode(1);
            let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(this.selectedData), angular.copy(applySelectedData));
            if (selectedData.selectedCount && selectedData.selectedCount.targets > 0) {
                selectedData.search = angular.copy(selectedSearch);
                selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), { selectedCount: selectedData.selectedCount, search: selectedSearch, prevData: selectedData });
                selectedData.search = angular.copy(selectedSearch);
            }
            this.setSelectedData(selectedData);
        }
        let timeStamp = new Date().getTime();
        this.setLinkings('rebuildSelectedData' + timeStamp);
    };

    trainingSuccsess = () => {
        this.clearAll();
        this.reloading = true;
    };

    getFirstLoading = (value) => {
        this.targetsFirstLoading = value;
    };

    setDepartment = (id = 'clear') => {
        let index = null;
        for (let i = 0; i < this.linkNames.length; i++) {
            if (this.linkNames[i].id === id) {
                index = i;
            }
        }
        if (id === 'clear') {
            this.linkNames = [];
            return;
        }
        if (index > 0 || index === 0) {
            this.loadDepartment(this.linkNames[index].id);
            return;
        }
        this.setBreadcrumbLink([...this.linkNames, ...this.departments.filter(item => item.id === id)]);
    };

    clearParams = () => {
        // Renew variables
        this.reportParams = null;
        this.campaignId = null;
        this.vuln = null;
        this.risk = null;
        this.software = null;
        this.clearAll();
    };

    __initSelectData = () => {
        // Make empty selected data if not exists
        this.setSelectedData(angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(this.selectedData)));
    };

    __initSelectDataTargets = (selectedData, departmentId) => {
        departmentId = +departmentId;
        // Make empty department space in selected targets if not exists
        this.__initSelectData();
        if (departmentId > 0 && !selectedData.targets[departmentId]) {
            selectedData.targets[departmentId] = [];
            if (selectedData.prevData && (!selectedData.prevData.targets || !selectedData.prevData.targets[departmentId])) {
                selectedData.prevData.targets[departmentId] = [];
            }
        }
        if (departmentId > 0 && !selectedData.unselectedTargets[departmentId]) {
            if (selectedData.unselectedTargets[departmentId]) {
                delete selectedData.unselectedTargets[departmentId];
            }
            if (selectedData.prevData && (!selectedData.prevData.unselectedTargets || !selectedData.prevData.unselectedTargets[departmentId])) {
                delete selectedData.prevData.unselectedTargets[departmentId];
            }
        }
        return selectedData;
    };

    canLoadMore = () => {
        // TODO
        return document.documentElement.scrollHeight <= document.documentElement.clientHeight;
    };

    setViewMode = (viewMode) => {
        const { $localStorage } = this;
        this.viewMode = viewMode;
        if (typeof ($localStorage.departments) == 'undefined') {
            $localStorage.departments = {};
        }
        this.sortBy = $localStorage[viewMode == 1 ? 'targets' : 'departments'] && $localStorage[viewMode == 1 ? 'targets' : 'departments'].sortBy || [viewMode == 1 ? 'first_name' : 'targetsCount'];
        this.sortReverse = $localStorage[viewMode == 1 ? 'targets' : 'departments'] && $localStorage[viewMode == 1 ? 'targets' : 'departments'].sortReverse || ($localStorage[viewMode == 1 ? 'targets' : 'departments'] ? false : true);
        $localStorage[viewMode == 1 ? 'targets' : 'departments'].sortBy = this.sortBy;
        $localStorage[viewMode == 1 ? 'targets' : 'departments'].sortReverse = this.sortReverse;
        $localStorage.departments.viewMode = +this.viewMode;
        if (this.dataMode === 3 && viewMode===2) {
            this.departmentCount = 0;
            this.targetCount = 0;
            this.setBreadcrumbLink([]);
        }
        this.setGraphMode(+(+this.viewMode === 2));
    };

    setDataMode = (dataMode) => {
        this.departmentCount = 0;
        this.targetCount = 0;
        let oldDataMode = this.dataMode;
        this.setBreadcrumbLink([]);
        const { $localStorage } = this;
        this.dataMode = dataMode;
        if (typeof ($localStorage.departments) == 'undefined') {
            $localStorage.departments = {};
        }
        $localStorage.departments.dataMode = this.dataMode;
        $localStorage.openedCourse = null;
        $localStorage.openedManager = null;
        if (oldDataMode == 2 || dataMode == 2 || oldDataMode == 0 || dataMode == 0 ) {
            this.setViewMode(0);
        }
        if (oldDataMode == 2 || dataMode == 2) {
            this.setViewMode(0);
            this.clearSelectedData();
            this.collapseDepartments();
        }
        this.reloading = true;
    };

    selectAll = (callback) => {
        this.__initSelectData();
        let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), {
            all: 1,
            search: this.selectedData.search
        });
        if (this.selectedData.prevData) {
            selectedData.prevData = this.selectedData.prevData;
        }
        this.setSelectedData(selectedData);
        if (typeof (callback) == 'function') {
            callback();
        }
    };

    clearAll = (clearSearch, searchKey) => {
        const { $localStorage } = this;
        this.__initSelectData();
        this.trainingIsOpen = false;
        let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), {
            unselectedDepartments: this.selectedData.departments,
            unselectedTargets: this.selectedData.targets
        });
        let search = angular.copy(this.selectedData.search);
        if (!clearSearch) {
            selectedData.search = search;
        } else {
            if (search && search.length > 1 && +searchKey > -1) {
                search.splice(+searchKey, 1);
                selectedData.search = search;
            } else {
                selectedData.search = [''];
            }
        }
        if (this.selectedData.prevData) {
            selectedData.prevData = this.selectedData.prevData;
        }
        if (selectedData.search.join('') == '') {
            selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA));
            $localStorage.selectedDataCacheString = null;
            this.departmentPartialCache = [];
            this.departmentCheckedCache = [];
        }
        this.setSelectedData(selectedData);
    };

    findBy = (searchQuery, callback, searchKey) => {
        searchQuery = this.appCtrl.gettextCatalog.getString(searchQuery);
        if (!searchQuery || searchQuery.length > 2) {
            let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(this.selectedData));
            let searchData = selectedData.search && angular.isArray(selectedData.search) ? selectedData.search : [''];
            searchData[+searchKey || 0] = searchQuery;
            selectedData.search = searchData;
            this.setSelectedData(selectedData);
            this.reloading = true;
        }
    };

    findByAndSelectAll = (searchQuery, callback, searchKey, clearAll) => {
        searchQuery = this.appCtrl.gettextCatalog.getString(searchQuery);
        if (!searchQuery || searchQuery.length > 2) {
            if (clearAll) {
                this.clearAll(true, searchKey);
            }
            if (searchKey == -1) {
                searchKey = 0;
            }
            let selectedData = angular.extend({}, angular.copy(EMPTY_SELECTED_DATA), angular.copy(this.selectedData));
            let searchData = selectedData.search && angular.isArray(selectedData.search) ? selectedData.search : [''];
            searchData[+searchKey || 0] = searchQuery;
            selectedData.search = searchData;
            this.setSelectedData(selectedData);
            this.reloading = true;
            this.selectAll(callback);
        }
    };

    toggleDepartments = () => {
        this.shown = !+this.shown;
        this.reloading = true;
    };

    showReport = (department, fromReport) => {
        if (this.reportAccess) {
            let params = {
                id: department.id,
                title: department.name,
                type: 'department',
                total: department.searchTargets,
                fromReport: fromReport
            };
            this.modal = this.$injector.instantiate(ReportModal);
            this.modal.open(params).then(() => {
            }, () => {
            });
        }
    };

    collapseDepartments = () => {
        const { $localStorage } = this;
        $localStorage.openedDepartments = [];
        if (typeof ($localStorage.departments) == 'undefined') {
            $localStorage.departments = {};
        }
    };

    findByLastChange = (lastChange) => {
        if (lastChange > 0) {
            this.findBy(this.gettextCatalog.getString('рейтинг улучшился'));
        } else if (lastChange < 0) {
            this.findBy(this.gettextCatalog.getString('рейтинг ухудшился'));
        } else {
            this.findBy(this.gettextCatalog.getString('рейтинг не изменился'));
        }
    };

    trainingOpen = () => {
        if (this.educationAccess && this.courseList && this.courseList.length > 0 && this.userInfo.license.isValid) {
            this.trainingIsOpen = true;
        }
    };

    subFunctions = () => {
        const {
            clearAll,
            setLoading,
            selectAll,
            findBy
        } = this;
        return {
            clearAll,
            setLoading,
            selectAll,
            findBy
        };
    };

    setReloading = (reloading) => {
        this.reloading = reloading;
    };

    isAnyLoading = () => {
        return this.departmentsLoading ||
            this.targetsLoading ||
            this.coursesLoading ||
            this.managersLoading ||
            this.reBuildLoading;
    };

    setLoading = (loadingType = 'departmentsLoading', value = false) => {
        value = !!value;
        if (!this.ignoreSetLoading[loadingType]) {
            if (value != this[loadingType]) {
                this[loadingType] = value;
            }
        } else {
            this.ignoreSetLoading[loadingType] = false;
        }
    };

    setTargetCount = (count) => {
        this.targetCount = +count;
    };

    setDepartmentCount = (count) => {
        this.departmentCount = +count;
    };

    setGroupCount = (count) => {
        this.groupCount = +count;
    };

    setSearch = (str) => {
        let search = this.selectedData.search.filter(() => true);
        if (angular.isArray(str)) {
            search = [...str];
        }
        if (!(~search.indexOf(str)) && !angular.isArray(str)) {
            search[search.length - 1] = str;
        }
        this.setSearchData(search);
    };

    buildSearchString = (selectedData) => {
        return buildSearchString(selectedData ? selectedData.search : this.selectedData.search);
    };
}

DepartmentsController.$inject = [
    '$injector',
    '$localStorage',
    '$sessionStorage',
    '$scope',
    'growl',
    '$timeout',
    '$stateParams',
    'gettextCatalog',
    '$ngRedux',
    DEPARTMENTS_SERVICE,
    TARGETS_SERVICE,
    STATUS_SERVICE,
    CAMPAIGN_TARGETS_SERVICE,
    AUTH_SERVICE,
    LEARNING_SERVICE,
    '$location'
];

export {
    DepartmentsController
};
