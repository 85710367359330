import angular from 'angular';
import { hasAccess } from '../../reselect';
import style from './style.modules.css';
import {CampaignModal} from '../campaignModal';
import {setLinkings, clearSelectedData} from '../../toolkit/actions';

class SideBarController {
    targetsLocation = [
        '/attacks',
        '/targets',
        '/reports',
        '/risks'
    ]

    constructor($ngRedux, $location, $state, $localStorage, $injector) {
        this.style = style;
        this.location = $location;
        this.$state = $state;
        this.$localStorage = $localStorage;
        this.$injector = $injector;
        this.lastLocation = 'targets';
        this.barAccess = false;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, {setLinkings, clearSelectedData})(this);
    }

    mapStateToThis = (state) => {
        return {
            attackStart: hasAccess(state, {sectionId: 2, rightName: 'attack_save'}),
            schedulerAccess: hasAccess(state, {sectionId: 9, rightName: 'view'}),
            learningAccess: hasAccess(state, {sectionId: 1, rightName: 'target_education'}),
            settingsAccess: hasAccess(state, {sectionId: 5, rightName: 'view'}),
            dashboardAccess: hasAccess(state, {sectionId: 14, rightName: 'view'}),
            employeeAccess: hasAccess(state, {sectionId: 1, rightName: 'view'}),
            attackAccess: hasAccess(state, {sectionId: 2, rightName: 'view'}),
            reportAccess: hasAccess(state, {sectionId: 3, rightName: 'view'}),
            vulnerabilitiesAccess: hasAccess(state, {sectionId: 4, rightName: 'view'}),
            licenseIsValid: state.auth.auth.license.isValid
        };
    };

    $onInit() {
        this.barAccess = this.employeeAccess ||
                         this.attackAccess ||
                         this.reportAccess ||
                         this.vulnerabilitiesAccess ||
                         this.dashboardAccess;
    }

    locationAt = (event, location) => {
        if ((event.ctrlKey || event.metaKey)) {
            return;
        }
        if (location === 'main') {
            this.$state.go(`app.index.${this.lastLocation}`);
            return;
        }
        if (this.targetsLocation.indexOf(this.location.path()) !== -1) {
            this.lastLocation = this.location.path().replace('/', '');
        }
        this.$state.go(`app.index.${location}`);
    }

    setLocalStorageField = ($event, item, viewMode, value) => {
        if (angular.isArray(this.$localStorage[item][viewMode])) {
            this.$localStorage[item][viewMode].splice(0, this.$localStorage[item][viewMode].length, value);
        } else {
            this.$localStorage[item][viewMode] = value;
        }
        this.locationAt($event,'targets');
    };

    runScheduler = ($event) => {
        this.setLinkings('scheduler');
        this.locationAt($event,'settings');
    }

    createAttack = () => {
        if (!this.attackStart) {
            return;
        }
        const {$localStorage} = this;
        $localStorage.campaignData = null;
        this.clearSelectedData();
        $localStorage.campaignStart = null;
        $localStorage.campaignSchedule = null;
        this.modal = this.$injector.instantiate(CampaignModal);
        this.modal.open().then((campaignData) => {
            if (campaignData) {
                if (window.campaignsCtrl.loadCampaigns) {
                    window.campaignsCtrl.loadCampaigns();
                }
            }
        }, () => {
        });
    };

    $onDestroy() {
        this.unsubscribe();
    }
}

SideBarController.$inject = [
    '$ngRedux',
    '$location',
    '$state',
    '$localStorage',
    '$injector'
];

export {
    SideBarController,
};
