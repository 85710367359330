import React, {useContext, useState} from 'react'
import SettingsHeader from 'rootReact/components/SettingsSectionHeader'
import {useDispatch, useSelector} from 'react-redux'
import { GettextContext } from 'rootReact/context'
import { useAutoWidth, useInputStyle, usePlural, useValidForm } from 'rootReact/hook'
import FetchApi from '../../REST'
import {setAuthProps} from '../../../toolkit/actions'

function SettingsNewEmployee({licenseIsValid}) {
    const gettext = useContext(GettextContext)
    const initDaysAsNew = useSelector(state => state.auth.auth.daysAsNew)
    const [daysAsNew, setDaysAsNew] = useState(initDaysAsNew)
    const dispatch = useDispatch()
    const labelText = usePlural(daysAsNew, ['дня', 'дней', 'дней'])
    const values = useValidForm({
        'daysAsNew': { value: daysAsNew, setValue: setDaysAsNew },
    }, {daysAsNew: {require: true}})
    const prefixStyle = useInputStyle(
            ['react-input_field'],
            values['daysAsNew'].touched,
            values['daysAsNew'].focused,
            values['daysAsNew'].error)

    const widthNumElement = useAutoWidth(daysAsNew, {minChars: 1})

    const setDays = async (value) => {
        const fetchApi = FetchApi('/settings/days-as-new')
        const response = await fetchApi.post({body: {days_as_new: value}})
        if (response.result === 'OK') {
            dispatch(setAuthProps({daysAsNew: value}))
            values['daysAsNew'].inputAttr.onChange(value)
        }
    }

    return (
            <div>
                <SettingsHeader title={gettext.getString('Новый сотрудник')} right />
                <div>
                    <label>
                        <span>{gettext.getString('в течение')}</span>
                        <input type="number"
                               style={{width: widthNumElement}}
                               id={'days_as_new'}
                               data-test={'days_as_new'}
                               disabled={!licenseIsValid}
                               className={prefixStyle}
                                {...values['daysAsNew'].inputAttr}
                                onChange={(e) => setDays(e.target.value)}
                        />
                        <span>{gettext.getString(labelText)}</span>
                    </label>
                </div>
            </div>
    )
}

export default SettingsNewEmployee
