import {CampaignModal} from '../campaignModal';
import {AUTH_SERVICE, CAMPAIGNS_SERVICE, TIME_ZONES_SERVICE} from '../../const';
import angular from 'angular';
import moment from 'moment-timezone';
import style from './style.modules.css';

class CampaignScheduleModalController {
    constructor($injector, $localStorage, CampaignsService, AuthService, TimeZonesService, campaign, gettextCatalog) {
        this.$injector = $injector;
        this.$localStorage = $localStorage;
        this.CampaignsService = CampaignsService;
        this.campaign = campaign;
        this.isOpen = false;
        this.loading = true;
        this.style = style;
        this.userInfo = {
            timeZone: 'UTC',
            timeZoneGmt: '+0',
        };
        this.timeChooze = '';
        this.lastTimeZone = null;
        this.lastTimeZoneGmt = null;
        this.timeZones = TimeZonesService.getAll();
        this.attackTimeStart = 'atNow';
        this.attackDelay = 'minute';
        this.AuthService = AuthService;
        this.attackDelayValue = 1;
        this.isOpenTo = false;
        this.timeTo = moment.utc().add(2, 'hours').format('YYYY-MM-DD HH:mm:ss');
        this.minDate = new Date();
        this.timeZones.$promise.then((timeZones) => {
            AuthService.getUserInfo('timeZone').$promise.then((userInfo) => {
                this.CampaignsService.getSchedule(this.campaign.id).$promise.then((schedule) => {
                    this.campaign.schedule = schedule;
                    this.userInfo.timeZone = 'UTC';
                    if (userInfo.timeZone) {
                        this.userInfo.timeZone = userInfo.timeZone;
                    }
                    if (this.campaign.schedule && this.campaign.schedule.timeZone) {
                        this.userInfo.timeZone = this.campaign.schedule.timeZone;
                    }
                    if (!this.campaign.schedule.timeStamp) {
                        this.campaign.schedule.time = moment.utc().add(1, 'hours').tz(this.userInfo.timeZone).format('YYYY-MM-DD HH:mm:ss');
                    } else {
                        this.campaign.schedule.time = moment.unix(+this.campaign.schedule.timeStamp).tz('UTC').format('YYYY-MM-DD HH:mm:ss');
                    }
                    moment.tz.setDefault('UTC');
                    if (this.campaign.schedule.timeValue) {
                        this.attackDelay = this.campaign.schedule.timePeriod;
                        this.attackDelayValue = this.campaign.schedule.timeValue;
                        this.attackTimeStart = 'delay';
                    }
                    if (this.campaign.schedule.tillTime || this.campaign.schedule.tillTimeStamp) {
                        this.attackTimeStart = 'period';
                        this.timeTo = moment.unix(+this.campaign.schedule.tillTimeStamp).format('YYYY-MM-DD HH:mm:ss');
                    }
                    this.loading = false;
                    if (this.userInfo.timeZone) {
                        this.lastTimeZone = this.userInfo.timeZone;
                        this.loading = true;
                        let timeZone = timeZones.find((timeZone) => {
                            return this.lastTimeZone == timeZone.name;
                        });
                        if (timeZone && timeZone.name) {
                            this.userInfo.timeZoneGmt = timeZone.gmt;
                            this.lastTimeZoneGmt = timeZone.gmt;
                        }
                        this.loading = false;
                    }
                }, () => {
                    this.loading = false;
                });
            }, () => {
                this.loading = false;
            });
        }, () => {
            this.loading = false;
        });
        this.gettextCatalog = gettextCatalog;
        this.appCtrl = window.appCtrl;
    }

    $onInit = () => {
    };

    validate = () => {
        if (this.campaign && this.campaign.schedule && this.campaign.schedule.time && this.userInfo && this.userInfo.timeZone) {
            if (this.attackTimeStart === 'period' && this.campaign.schedule.time > this.timeTo) {
                this.timeTo = moment.unix(+moment(this.campaign.schedule.time).format('X')).add(1, 'hours').format('YYYY-MM-DD HH:mm:ss');
            }
            let schedule = angular.copy(this.campaign.schedule);
            let scheduleUtcTime = moment.tz(schedule.time, this.userInfo.timeZone);
            let scheduleUtcTimeStamp = scheduleUtcTime.format('X');
            let nowTimeStamp = moment.utc().format('X');
            if (nowTimeStamp >= scheduleUtcTimeStamp) {
                return false;
            }
        }
        return true;
    };

    setDelayPeriod = (period) => {
        this.attackDelay = period;
    };

    setTimeStart = (attackTimeStart) => {
        switch (attackTimeStart) {
            case 'atNow':
                this.timeTo = null;
                this.attackDelay = 'minute';
                this.attackDelayValue = 0;
                break;
            case 'period':
                this.timeTo = moment.unix(+moment(this.campaign.schedule.time).format('X')).add(1, 'hours').format('YYYY-MM-DD HH:mm:ss');
                this.attackDelay = 'minute';
                this.attackDelayValue = 0;
                break;
            case 'delay':
                this.timeTo = null;
                this.attackDelay = 'minute';
                this.attackDelayValue = 1;
                break;
        }
        this.attackTimeStart = attackTimeStart;
    };

    edit = () => {
        this.modal = this.$injector.instantiate(CampaignModal);
        this.$close();
        this.modal.open(this.campaign.id).then((campaignData) => {
            if (campaignData) {
                angular.extend(this.campaign, campaignData);
                if (this.$localStorage.campaignSchedule) {
                    this.$localStorage.campaignSchedule = this.campaign;
                } else {
                    this.$localStorage.campaignSchedule = this.campaign;
                }
            }
        }, () => {
        });
    };

    openCalendar = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.isOpen = true;
        this.isOpenTo = false;
    };

    openCalendarTo = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.isOpen = false;
        this.isOpenTo = true;
    };

    setForm = (form) => {
        this.form = form;
    };

    schedule = () => {
        let schedule = angular.copy(this.campaign.schedule);
        switch (this.attackTimeStart) {
            case 'period':
                delete schedule.timeValue;
                delete schedule.timePeriod;
                schedule.tillTime = moment.utc(this.timeTo, 'YYYY-MM-DD HH:mm:ss').tz(this.userInfo.timeZone);
                schedule.tillTimeStamp = schedule.tillTime.format('X');
                break;
            case 'delay':
                delete schedule.tillTime;
                delete schedule.tillTimeStamp;
                schedule.timeValue = this.attackDelayValue;
                schedule.timePeriod = this.attackDelay;
                break;
            default:
                delete schedule.tillTime;
                delete schedule.tillTimeStamp;
                delete schedule.timeValue;
                delete schedule.timePeriod;
        }
        if (this.campaign.targetsCount > 0) {
            this.errorText = null;
            this.form.$setSubmitted();
            schedule.timeZone = this.userInfo.timeZone || 'UTC';
            let scheduleTime = moment.utc(schedule.time, 'YYYY-MM-DD HH:mm:ss').tz(this.userInfo.timeZone);
            schedule.timeStamp = scheduleTime.format('X');
            delete schedule.time;
            if (!this.validate()) {
                this.errorText = this.gettextCatalog.getString('Некорректное время запуска атаки');
            } else if (this.form.$valid) {
                this.inProcess = true;
                this.CampaignsService.saveSchedule(this.campaign.id, schedule, this, (ctrl, response) => {
                    ctrl.inProcess = false;
                    if (response && response.time) {
                        ctrl.campaign.schedule = {
                            time: response.time,
                            timeStamp: response.timeStamp,
                            timeZone: response.timeZone,
                            tillTime: response.tillTime,
                            tillTimeStamp: response.tillTimeStamp,
                            timeValue: response.timeValue,
                            timePeriod: response.timePeriod,
                        };
                        ctrl.campaign.state = 5;
                    }
                    ctrl.$close(ctrl.campaign);
                }, (ctrl, response) => {
                    ctrl.inProcess = false;
                    if (response.data.error) {
                        ctrl.errorText = response.data.error;
                    }
                });
            }
        }
    };

    cancel = () => {
        this.CampaignsService.deleteSchedule(this.campaign.id, this.campaign.schedule, this, (ctrl, response) => {
            if (!response || !response.time) {
                delete ctrl.campaign.schedule;
            } else {
                ctrl.campaign.schedule = {
                    time: response.time,
                    timeStamp: response.timeStamp,
                    timeZone: response.timeZone,
                };
            }
            ctrl.campaign.state = 3;
            window.campaignsCtrl.load(true);
            ctrl.$close();
        }, () => {
            //
        });
    };

    timeZoneSelected = (timeZone) => {
        angular.forEach(this.timeZones, (tz) => {
            if (tz.name == timeZone.name) {
                this.userInfo.timeZone = timeZone.name;
                this.userInfo.timeZoneGmt = timeZone.gmt;
            }
        });
        this.lastTimeZone = this.userInfo.timeZone;
        this.lastTimeZoneGmt = this.userInfo.timeZoneGmt;
        this.errorText = null;
    };

    timeZoneUnfocused = () => {
        this.timeZones.$promise.then((timeZones) => {
            let timeZone = timeZones.find((tz) => {
                return tz.name == this.userInfo.timeZone;
            });
            if (timeZone && timeZone.name) {
                this.userInfo.timeZone = timeZone.name;
                this.userInfo.timeZoneGmt = timeZone.gmt;
            } else {
                this.userInfo.timeZone = this.lastTimeZone;
                this.userInfo.timeZoneGmt = this.lastTimeZoneGmt;
            }
            this.errorText = null;
        });
    };
}

CampaignScheduleModalController.$inject = [
    '$injector',
    '$localStorage',
    CAMPAIGNS_SERVICE,
    AUTH_SERVICE,
    TIME_ZONES_SERVICE,
    'campaign',
    'gettextCatalog'
];

export {
    CampaignScheduleModalController,
};
