import groupsGraphItemTemplate from './view.html';

import {GroupsGraphItemController} from './controller';

const GroupsGraphItemComponent = {
    template: groupsGraphItemTemplate,
    controller: GroupsGraphItemController,
    controllerAs: '$',
    scope: true,
    bindings: {
        groupsItem: '<',
        ratingSheme: '<',
        view: '<',
        wrapStyle: '<',
        parentStyle: '<',
        lastGroups: '<',
        loadGroups: '=',
    },
};

export {
    GroupsGraphItemComponent,
};
