import {CampaignModal} from '../campaignModal';
import {CampaignDeleteModal} from '../campaignDeleteModal';
import {CampaignScheduleModal} from '../campaignScheduleModal';
import {CampaignStartModal} from '../campaignStartModal';
import {ReportModal} from '../reportModal';
import {CAMPAIGNS_SERVICE} from '../../const';
import {getUserTimeZone, hasAccess} from '../../reselect';
import angular from 'angular';
import style from './style.modules.css';
import moment from 'moment-timezone';
import {clearSelectedData} from '../../toolkit/actions';
import {fetchDashboardParams} from '../../toolkit/think';

class CampaignController {
    constructor($injector, $localStorage, CampaignsService, $cookies, $scope, $ngRedux, $location, $timeout) {
        this.$injector = $injector;
        this.$timeout = $timeout;
        this.scope = $scope;
        this.$localStorage = $localStorage;
        this.$location = $location;
        this.style = style;
        this.CampaignsService = CampaignsService;
        this.sender = {};
        this.fromTime = '';
        this.moment = moment;
        this.isOutdated = false;
        this.locale = $cookies.get('langCode');
        this.appCtrl = window.appCtrl;
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, {clearSelectedData, fetchDashboardParams})(this);
    }

    mapStateToThis = (state) => {
        return {
            selectedData: state.selectedData.selectedData,
            timeZone: getUserTimeZone(state),
            attackStartAccess: hasAccess(state, {sectionId: 2, rightName: 'attack_start'}),
            reportAttackAccess: hasAccess(state, {sectionId: 4, rightName: 'report_attack'}),
            reportAttackAccess2: hasAccess(state, {sectionId: 2, rightName: 'report_attack'}),
            attackSaveAccess: hasAccess(state, {sectionId: 2, rightName: 'attack_save'}),
            attackDeleteAccess: hasAccess(state, {sectionId: 2, rightName: 'attack_delete'})
        };
    };

    $onInit() {
        if ((this.campaign.state == 1 || this.campaign.state == 2) && !this.isReport) {
            this.loadProgress();
        }
        if (this.campaign.schedule && (this.campaign.schedule.timeStamp*1000 < Date.now())) {
            this.isOutdated = true;
        }
        if (this.campaign.tasks) {
            angular.forEach(this.campaign.tasks, (items, date) => {
                this.lastTask = {
                    date: date,
                    dateTime: 0,
                };
                if (items) {
                    angular.forEach(items, (dateTime) => {
                        this.lastTask.dateTime = dateTime;
                    });
                }
            });
        }
        if (this.campaign.schedule && this.campaign.schedule.time && !this.isReport) {
            let ctrl = this;
            ctrl.$timeout.cancel(ctrl.updateTime);
            let time = moment.tz(ctrl.campaign.schedule.time, 'UTC').tz(this.$localStorage.userInfo.timeZone);
            ctrl.updateTime = ctrl.$timeout(ctrl.setTimeInterval, 1000);
            if (time.unix() < moment.utc().tz(ctrl.$localStorage.userInfo.timeZone).unix()) {
                ctrl.loadProgress();
            }
        }
    }

    loadProgress = () => {
        if (!this.isReport) {
            this.CampaignsService.sender(this.campaign.id, 'progress', this, (ctrl, response) => {
                if (response && response.state) {
                    ctrl.campaign.state = response.state;
                    ctrl.sender = response;
                    if (response.state === 3 && response.progress.total === response.progress.done) {
                        ctrl.loadTasks();
                        ctrl.$timeout.cancel(ctrl.update);
                        ctrl.isOutdated = false;
                    }
                    if (response.progress.done > 0 || response.progress.error > 0) {
                        ctrl.$timeout.cancel(ctrl.updateTime);
                    }
                    if (response.state === 1 || response.state === 2 || response.state === 5) {
                        ctrl.$timeout.cancel(ctrl.update);
                        ctrl.update = ctrl.$timeout(ctrl.loadProgress, 5000);
                    }
                }
            }, () => {
            });
        }
    };

    getScheduleDate = () => {
        if (this.campaign.schedule && !this.campaign.schedule.tillTime && !this.campaign.schedule.timeValue) {
            return moment.tz(this.campaign.schedule.time, 'UTC').tz(this.$localStorage.userInfo.timeZone).format('DD MMM YYYY HH:mm');
        }
        if (this.campaign.schedule && this.campaign.schedule.tillTime) {
            if (moment(this.campaign.schedule.time).date() === moment(this.campaign.schedule.tillTime).date() &&
                moment(this.campaign.schedule.time).month() === moment(this.campaign.schedule.tillTime).month() &&
                moment(this.campaign.schedule.time).year() === moment(this.campaign.schedule.tillTime).year()) {
                return `${moment.tz(this.campaign.schedule.time, 'UTC').tz(this.$localStorage.userInfo.timeZone).format('DD MMM YYYY HH:mm')} \u2013 ${moment.tz(this.campaign.schedule.tillTime, 'UTC').tz(this.$localStorage.userInfo.timeZone).format('HH:mm')}`;
            } else {
                return `${moment.tz(this.campaign.schedule.time, 'UTC').tz(this.$localStorage.userInfo.timeZone).format('DD MMM YYYY HH:mm')} \u2013 ${moment.tz(this.campaign.schedule.tillTime, 'UTC').tz(this.$localStorage.userInfo.timeZone).format('DD MMM YYYY HH:mm')}`;
            }
        }
        if (this.campaign.schedule && this.campaign.schedule.timeValue) {
            let timePeriod = 0;
            if (this.campaign.schedule.timePeriod === 'day') {
                timePeriod = this.campaign.schedule.timeValue * 60 * 60 * 24;
            }
            if (this.campaign.schedule.timePeriod === 'hour') {
                timePeriod = this.campaign.schedule.timeValue * 60 * 60;
            }
            if (this.campaign.schedule.timePeriod === 'minute') {
                timePeriod = this.campaign.schedule.timeValue * 60;
            }
            if (moment(this.campaign.schedule.time).date() === moment(this.campaign.schedule.time).add(timePeriod, 'seconds').date() &&
                moment(this.campaign.schedule.time).month() === moment(this.campaign.schedule.time).add(timePeriod, 'seconds').month() &&
                moment(this.campaign.schedule.time).year() === moment(this.campaign.schedule.time).add(timePeriod, 'seconds').year()) {
                return `${moment.tz(this.campaign.schedule.time, 'UTC').tz(this.$localStorage.userInfo.timeZone).format('DD MMM YYYY HH:mm')} \u2013 ${moment.tz(this.campaign.schedule.time, 'UTC').add(timePeriod, 'seconds').tz(this.$localStorage.userInfo.timeZone).format('HH:mm')}`;
            } else {
                return `${moment.tz(this.campaign.schedule.time, 'UTC').tz(this.$localStorage.userInfo.timeZone).format('DD MMM YYYY HH:mm')} \u2013 ${moment.tz(this.campaign.schedule.time, 'UTC').add(timePeriod, 'seconds').tz(this.$localStorage.userInfo.timeZone).format('DD MMM YYYY HH:mm')}`;
            }
        }
    };

    setTimeInterval = () => {
        moment.locale(this.appCtrl.langCode);
        this.$timeout.cancel(this.updateTime);
        let time = moment.tz(this.campaign.schedule.time, 'UTC').tz(this.$localStorage.userInfo.timeZone);
        this.fromTime = time.fromNow();
        if (time.unix() * 1000 - Date.now() <= 0) {
            this.isOutdated = true;
            this.fromTime = '';
            this.loadProgress();
            this.$timeout.cancel(this.updateTime);
            return true;
        } else {
            this.updateTime = this.$timeout(this.setTimeInterval, 1000);
        }
    };

    reSendErrors = () => {
        this.CampaignsService.sender(this.campaign.id, 'errors', this, (ctrl, response) => {
            if (response && response.state) {
                ctrl.campaign.state = response.state;
                ctrl.sender = response;
                if (response.state == 3 && response.progress.total == response.progress.done) {
                    ctrl.loadTasks();
                }
            }
        }, () => {
        });
    };

    loadTasks = () => {
        this.campaign.tasks = this.CampaignsService.tasks(this.campaign.id);
    };

    edit = () => {
        if (this.appCtrl && this.attackSaveAccess) {
            const {$localStorage} = this;
            $localStorage.lastPage = this.$location.path();
            this.clearSelectedData();
            $localStorage.campaignData = null;
            $localStorage.campaignStart = null;
            $localStorage.campaignSchedule = null;
            this.modal = this.$injector.instantiate(CampaignModal);
            this.modal.open(this.campaign.id).then((campaignData) => {
                if (campaignData) {
                    if (this.campaignsCtrl) {
                        if (this.campaignsCtrl.campaignsList) {
                            let campaign = this.campaignsCtrl.campaignsList.$data.find((item) => {
                                return item.id == campaignData.id;
                            });
                            if (campaign) {
                                angular.extend(campaign, campaignData);
                            }
                        }
                    }
                }
            }, () => {
            });
        }
    };

    delete = () => {
        if (this.appCtrl && this.appCtrl.hasAccess(2, 'attack_delete')) {
            this.modal = this.$injector.instantiate(CampaignDeleteModal);
            this.modal.open(this.campaign).then((campaignData) => {
                this.$timeout.cancel(this.update);
                if (campaignData) {
                    this.campaign.status = 4;
                    if (this.campaignsCtrl) {
                        this.campaignsCtrl.loadCampaigns(true);
                    }
                }
                this.fetchDashboardParams();
            }, () => {
            });
        }
    };

    copy = () => {
        if (this.appCtrl && this.attackSaveAccess) {
            const {$localStorage} = this;
            this.clearSelectedData();
            $localStorage.campaignData = null;
            this.modal = this.$injector.instantiate(CampaignModal);
            this.modal.open(null, this.campaign.id).then((campaignData) => {
                if (campaignData) {
                    if (this.campaignsCtrl) {
                        this.campaignsCtrl.loadCampaigns();
                    }
                }
            }, () => {
            });
        }
    };

    schedule = () => {
        if (this.appCtrl && this.attackStartAccess) {
            let campaign = angular.copy(this.campaign);
            if (!campaign.schedule) {
                campaign.schedule = {
                    time: '',
                    timeStamp: 0,
                    timeZone: '',
                };
            }
            this.modal = this.$injector.instantiate(CampaignScheduleModal);
            this.modal.open(campaign).then((campaignData) => {
                if (campaignData) {
                    if (campaignData.schedule) {
                        this.campaign.schedule = angular.copy(campaignData.schedule);
                    } else {
                        delete this.campaign.schedule;
                    }
                    this.campaign.state = campaignData.state;
                    if (this.campaign.state === 5) {
                        this.$timeout.cancel(this.updateTime);
                        this.updateTime = this.$timeout(this.setTimeInterval, 1000);
                    }
                    if (this.campaignsCtrl) {
                        this.campaignsCtrl.loadCampaigns(true);
                    }
                }
            }, (campaignData) => {
                if (campaignData) {
                    if (!campaignData.schedule || !campaignData.schedule.time) {
                        delete this.campaign.schedule;
                    } else {
                        this.campaign.schedule = campaignData.schedule;
                    }
                    this.campaign.state = campaignData.state;
                    if (this.campaignsCtrl) {
                        this.campaignsCtrl.loadCampaigns(true);
                    }
                }
            });
        }
    };

    start = () => {
        if (this.appCtrl && this.attackStartAccess) {
            this.$timeout.cancel(this.update);
            this.sender = {
                state: 1,
                progress: {
                    done: 0,
                    error: 0,
                    total: this.campaign.targetsCount,
                },
            };
            this.modal = this.$injector.instantiate(CampaignStartModal);
            this.modal.open(this.campaign).then((senderData) => {
                if (senderData) {
                    this.campaign.state = senderData.state;
                    this.sender = senderData;
                    this.loadProgress();
                    // this.update = this.$interval(this.loadProgress, 5000);
                    if (this.campaignsCtrl) {
                        this.campaignsCtrl.loadCampaigns(true);
                    }
                }
            }, () => {
            });
        }
    };

    pause = () => {
        if (this.appCtrl && this.attackStartAccess) {
            this.inProcess = true;
            this.errorText = false;
            this.CampaignsService.sender(this.campaign.id, 'pause', this, (ctrl, response) => {
                ctrl.inProcess = false;
                if (response && response.state) {
                    ctrl.campaign.state = response.state;
                    ctrl.sender = response;
                    ctrl.$timeout.cancel(ctrl.update);
                }
            }, (ctrl, response) => {
                ctrl.inProcess = false;
                if (response && response.data) {
                    ctrl.errorText = response.data.error;
                }
            });
        }
    };

    stop = () => {
        if (this.appCtrl && this.attackStartAccess) {
            this.inProcess = true;
            this.errorText = false;
            this.CampaignsService.sender(this.campaign.id, 'stop', this, (ctrl, response) => {
                ctrl.inProcess = false;
                if (response) {
                    ctrl.campaign.state = response.state;
                    ctrl.campaign.schedule = null;
                    ctrl.sender = response;
                    if (response.state) {
                        ctrl.loadTasks();
                    }
                    ctrl.isOutdated = false;
                    ctrl.$timeout.cancel(ctrl.update);
                    ctrl.$timeout.cancel(this.updateTime);
                    if (this.campaignsCtrl) {
                        this.campaignsCtrl.loadCampaigns(true);
                    }
                    //this.campaignsCtrl.loadCampaigns(true);
                }
            }, (ctrl, response) => {
                ctrl.inProcess = false;
                if (response && response.data) {
                    ctrl.errorText = response.data.error;
                }
            });
        }
    };

    showReport = (selected, fromReport) => {
        if ((!fromReport && this.attackStartAccess) || (fromReport && (this.reportAttackAccess || this.reportAttackAccess2))) {
            let params = {
                id: this.campaign.id,
                title: this.campaign.name,
                type: 'attack',
                groupingData: this.campaign.tasks,
                selectedGroup: selected,
                fromReport: fromReport,
            };
            this.modal = this.$injector.instantiate(ReportModal);
            this.modal.open(params).then(() => {
                this.dateSelected = {
                    date: null,
                    dateTime: null,
                };
            }, () => {
                this.dateSelected = {
                    date: null,
                    dateTime: null,
                };
            });
        }
    };

    filterByKey = vector => {
        if (window.campaignsCtrl && window.campaignsCtrl.campaignsList && window.campaignsCtrl.campaignsList.$data && window.campaignsCtrl.campaignsList.$data.length > 0) {
            if (typeof (window.campaignsCtrl.campaignsListOrigin) == 'undefined') {
                window.campaignsCtrl.campaignsListOrigin = angular.copy(window.campaignsCtrl.campaignsList.$data);
            }
            window.campaignsCtrl.campaignsList.$data = window.campaignsCtrl.campaignsList.$data.filter(campaign => {
                let ids = [];
                angular.forEach(campaign.vectors, value => ids.push(value.id));
                return ids.indexOf(vector) != -1;
            });
        }
    };

    filterByType = type => {
        if (window.campaignsCtrl && window.campaignsCtrl.campaignsList && window.campaignsCtrl.campaignsList.$data && window.campaignsCtrl.campaignsList.$data.length > 0) {
            if (typeof (window.campaignsCtrl.campaignsListOrigin) == 'undefined') {
                window.campaignsCtrl.campaignsListOrigin = angular.copy(window.campaignsCtrl.campaignsList.$data);
            }
            window.campaignsCtrl.campaignsList.$data = window.campaignsCtrl.campaignsList.$data.filter(campaign => campaign.sources && campaign.sources[0] && type == campaign.sources[0].id);
        }
    };

    $onDestroy = () => {
        this.$timeout.cancel(this.update);
        this.$timeout.cancel(this.updateTime);
        this.unsubscribe();
    };
}

CampaignController.$inject = [
    '$injector',
    '$localStorage',
    CAMPAIGNS_SERVICE,
    '$cookies',
    '$scope',
    '$ngRedux',
    '$location',
    '$timeout',
];

export {
    CampaignController,
};
