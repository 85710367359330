import ratingGraphTemplate from './view.html';

import {RatingGraphController} from './controller.js';

const RatingGraphComponent = {
    template: ratingGraphTemplate,
    controller: RatingGraphController,
    controllerAs: '$',
    scope: true,
    bindings: {
        item: '<',
        graphList: '<',
        targetCount: '<',
        maxTargetCount: '<',
        onItemClick: '=',
    },
};

export {
    RatingGraphComponent,
};
