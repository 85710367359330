import style from './style.modules.css';
import {hasAccess} from '../../reselect';

class RatingGraphController {
    constructor($ngRedux, $scope, gettextCatalog, $localStorage) {
        this.style = style;
        this.targetCount = 0;
        this.opened = false;
        this.scope = $scope;
        this.field = {};
        this.gettextCatalog = gettextCatalog;
        this.$localStorage = $localStorage;
        this.noTargets = false;
        this.tooltip = {
            negativeRatingDown: gettextCatalog.getString('Рейтинг отрицательный и навыки ухудшаются :-('),
            negativeRatingUp: gettextCatalog.getString('Рейтинг отрицательный, но навыки улучшаются :-\\'),
            positiveRatingDown: gettextCatalog.getString('Рейтинг положительный, но навыки ухудшаются :-/'),
            positiveRatingUp: gettextCatalog.getString('Рейтинг положительный и навыки улучшаются :-)'),
            noRatingChanges: gettextCatalog.getString('Не проверялись')
        };
        this.unsubscribe = $ngRedux.connect(this.mapStateToThis, null)(this);
    }

    mapStateToThis = (state) => {
        return {
            linkNames: state.breadcrumbLinks.link,
            accessCoursesDepartments: hasAccess(state, {sectionId: 1, rightName: 'view'}),
        };
    };

    $onInit() {
        if (!this.targetCount) {
            this.noTargets = true;
        }
        this.getFields();
    }

    chekWidth() {
        if (!this.targetCount) {
            this.noTargets = true;
            return {'width': '30px'};
        }
        if (this.maxTargetCount < 30) {
            return {'width': this.targetCount * 30 + 'px'};
        }
        if (this.maxTargetCount > 30) {
            return {'width': this.targetCount*100/this.maxTargetCount + '%'};
        }
    }

    chekItemWidth = (value) => {
        return {'width': value/this.targetCount*100 + '%'};
    }

    getFields = () => {
        let keys = Object.keys(this.graphList);
        this.itemId = this.graphList[keys[0]];
        this.item.id = this.itemId;
        for (let i = 1; i < keys.length; i++) {
            this.field[keys[i]] = this.item[keys[i]];
        }
    }
}

RatingGraphController.$inject = [
    '$ngRedux',
    '$scope',
    'gettextCatalog',
    '$localStorage',
];

export {
    RatingGraphController,
};
