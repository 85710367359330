import { useState, useEffect, useContext, forwardRef } from "react";
import ModalHeader from "../ModalHeader";
import ModalFooter from "../ModalFooter";
import { GettextContext } from "rootReact/context";
import { useDispatch, useSelector } from "react-redux";
import { useInput } from "../../hook/useValidModal.jsx";
import FetchApi from "../../REST";
import FaIcon from "rootReact/components/FaIcon";
import Select, { components } from "react-select";
import { fetchRoles, fetchSSO, fetchDepartments, fetchLdapProfiles } from "../../../toolkit/think";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, parseISO, subDays } from "date-fns";
import { ru } from "date-fns/locale";
import loader from "../../../../images/loading.svg";
import DepartmentsSelect from "../DepartmentSelect";
import { userRules } from "./UserModalRule";
import { useInputStyle, useValidForm } from "rootReact/hook";
import { addAccount, updateAccount } from "../../../toolkit/actions";

function UserModal({ onModalClose, userId, userInfo }) {
    const gettext = useContext(GettextContext);
    const dispatch = useDispatch();
    const [passwordUpdate, setPasswordUpdate] = useState(true);
    const [checkboxCalendar, setCheckboxCalendar] = useState(true);
    const [formError, setFormError] = useState("");
    const [formValid, setFormValid] = useState(false);
    const [save, setSave] = useState(false);
    const [loading, setLoading] = useState(false);
    const email = useInput("", { isEmpty: true, emailError: false });
    const password = useInput("", { isEmpty: true });
    const phone = useInput("", { isEmpty: true });
    const fullName = useInput("", { isEmpty: true });
    const position = useInput("", { isEmpty: true });
    const ldap = useInput("", { isEmpty: true });
    const ssoProfiles = useSelector((state) => state.ssoProfile.ssoProfiles || []);
    const ldapProfiles = useSelector((state) => state.ldapProfiles.ldapProfiles || []);
    const roleUsers = useSelector((state) => state.roleUsers.roles || []);
    const loadRoles = useSelector((state) => state.roleUsers.loading);
    const departmentsList = useSelector((state) => state.departments.departments);
    const departmentsLoad = useSelector(state => state.departments.loading);
    const [ssoSelect, setSsoSelect] = useState({});
    const [ldapSelect, setLdapSelect] = useState({});
    const [isSuperAdmin, setSuperAdmin] = useState(false);
    const [departmentsSelect, setDepartmentsSelect] = useState({});
    const values = useValidForm(
        {
            "department-select": { value: departmentsSelect, setValue: setDepartmentsSelect},
        },
        userRules
    );
    const departmentStyles = useInputStyle(
        [
            "react-input_field",
            `${formValid && !departmentsSelect.id
                ? "error-input react-user-modal_select-depart"
                : "react-user-modal_select-depart"
            }`,
        ],
        values["department-select"].touched,
        values["department-select"].focused,
        values["department-select"].error
    );
    const [roleUsersSelect, setRoleUsersSelect] = useState([]);
    const [showCalendar, setShowCalendar] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [authType, setAuthType] = useState(1);
    const [cleanLdap, setCleanLdap] = useState(false);
    const [cleanSso, setCleanSso] = useState(false);

    useEffect(() => {
        dispatch(fetchRoles());
        dispatch(fetchSSO());
        dispatch(fetchLdapProfiles());
        dispatch(fetchDepartments())
    }, []);

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <button className="react-user-modal_calendar" onClick={onClick} ref={ref}>
            {format(new Date(value), "dd MMMM hh:mm", { locale: ru })}
        </button>
    ));

    const optionsSso = ssoProfiles.map((item) => {
        const newItem = {};
        newItem.value = item.id;
        newItem.label = item.name;
        return newItem;
    });

    const optionsLdap = ldapProfiles.map((item) => {
        const newItem = {};
        newItem.value = item.id;
        newItem.label = item.title;
        return newItem;
    });

    const optionsRoles = roleUsers.map((item) => {
        const newItem = {};
        newItem.value = item.id;
        newItem.label = item.name;
        return newItem;
    });

    const getValueSelect = (state, options) => {
        state ? options.find((item) => item.value === state) : "";
    };

    const getValueMultiRole = () => {
        roleUsersSelect ? optionsRoles.filter((item) => roleUsersSelect.indexOf(item.value) >= 0) : [];
    };

    const onChangeSelectSso = (newValue) => {
        setSsoSelect({ ...ssoSelect, id: newValue.value, name: newValue.label });
        setCleanSso(false);
    };

    const onChangeSelectLdap = (newValue) => {
        setLdapSelect({ ...ldapSelect, id: newValue.value, name: newValue.label });
        setCleanLdap(false);
    };

    const onChangeSelectRoles = (newValue) => {
        if (isSuperAdmin) return;
        setRoleUsersSelect(
            newValue.map((v) => {
                const newItem = {};
                newItem.id = v.value;
                newItem.name = v.label;
                return newItem;
            })
        );
    };

    const defaultValueRole = roleUsersSelect.map((item) => {
        const newItem = {};
        newItem.value = item.id;
        newItem.label = item.name;
        return newItem;
    });

    const defaultValueLdap = ldapSelect !== null ? optionsLdap.find((item) => item.value === ldapSelect.id) : null;
    const defaultValueSso = ssoSelect !== null ? optionsSso.find((item) => item.value === ssoSelect.id) : null;

    const onClickPassword = () => {
        setAuthType((prev) => prev + 1); //показывается ldap
    };

    const onClickLdap = () => {
        setAuthType((prev) => prev + 1); //показывается sso
    };

    const onClickSso = () => {
        setAuthType((prev) => prev - 2); //показывается password
    };

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props} data-testid="dropdown-indicator">
                <svg width="10" height="5" viewBox="0 0 10 5">
                    <path d="M0 0L5 5L10 0H0Z" fill="#333333" />
                </svg>
            </components.DropdownIndicator>
        );
    };

    const onClickCleanLdap = () => {
        if (ldap.value.length) {
            ldap.onChange("");
        }
        if (ldapSelect !== null) {
            setCleanLdap(true);
            setLdapSelect(null);
        }
    };

    const onClickCleanSso = () => {
        if (ssoSelect !== null) {
            setCleanSso(true);
            setSsoSelect(null);
        }
    };

    const fetchApi = FetchApi("/users");
    const body = {
        authType: authType,
        department: departmentsSelect,
        fullName: fullName.value,
        isUserBlocked: !checkboxCalendar
            ? checkboxCalendar
            : checkboxCalendar && showCalendar
                ? new Date(startDate) / 1000
                : checkboxCalendar,
        login: email.value,
        page: "targets",
        password: !password.value ? null : password.value,
        passwordNeedUpdate: passwordUpdate,
        phone: phone.value,
        position: position.value,
        ldapLogin: authType === 2 ? ldap.value : "",
        ldapProfile: authType === 2 ? ldapSelect : "",
        roles: roleUsersSelect,
        ssoProfile: authType === 3 ? ssoSelect && ssoSelect.id : null,
    };

    const onSaveUser = async () => {
        if (
            !email.value.length ||
            !fullName.value.length ||
            (authType === 1 && !password.value.length && !userId) ||
            !phone.value.length ||
            !position.value.length ||
            (authType === 2 && !ldap.value.length) ||
            (authType === 2 && !ldapSelect.id) ||
            (authType === 3 && !ssoSelect.id) ||
            (!isSuperAdmin && !departmentsSelect)
        ) {
            setFormValid(true);
            return;
        }
        try {
            setFormError("");
            setSave(true);
            if (userId) {
                const response = await fetchApi.put(userId, {
                    body,
                });
                if (response && response.isError) {
                    throw response;
                }
                dispatch(updateAccount(response));
                onModalClose(response);
            } else {
                const response = await fetchApi.post({
                    body,
                });
                if (response && response.isError) {
                    throw response;
                }
                dispatch(addAccount(response));
                onModalClose(response);
            }
        } catch (e) {
            setSave(false);
            const error = e.err.map((item) => {
                const arr = [];
                arr.push(item.message);
                return arr;
            });
            setFormError(error.join(" "));
        }
    };

    const onPasswordGenerate = async () => {
        try {
            const fetchApi = FetchApi("/settings/password-generate");
            let response = await fetchApi.post();
            if (response) {
                password.onChange(Object.values(response.password).join(""));
            }
        } catch (e) {
            setFormError(e.error || gettext.getString("Что-то пошло не так :-("));
        }
    };

    useEffect(() => {
        async function fetchUserData() {
            const res = await fetchApi.get(userId);
            setAuthType(res.authType);
            fullName.onChange(res.fullName);
            email.onChange(res.login);
            phone.onChange(res.phone);
            position.onChange(res.position);
            values["department-select"].setValue(res.department);
            setRoleUsersSelect(res.roles);
            setSsoSelect(res.ssoProfile);
            setLdapSelect(res.ldapProfile);
            ldap.onChange(res.ldapLogin);
            setShowCalendar(res.isUserBlocked !== false && res.isUserBlocked !== true ? true : false);
            setCheckboxCalendar(res.isUserBlocked !== false ? true : false);
            setStartDate(
                res.isUserBlocked !== false && res.isUserBlocked !== true
                    ? new Date(res.isUserBlocked * 1000)
                    : new Date()
            );
            setSuperAdmin(res.superAdmin);
            setPasswordUpdate(res.passwordNeedUpdate);
            setLoading(false);
        }
        try {
            if (userId) {
                setLoading(true);
                fetchUserData();
            }
        } catch (error) {
            setLoading(false);
            setFormError("Что-то пошло не так :-(");
        }
    }, [userId]);

    return (
        <>
            <ModalHeader
                title={
                    userId
                        ? gettext.getString("Редактирование учетной записи")
                        : gettext.getString("Новая учетная запись")
                }
                closeModal={() => onModalClose()}
            />
            {loading ? (
                <div className={"react-user-modal_loader"}>
                    <img src={loader} />
                </div>
            ) : (
                <div className="react-user-modal">
                    <div className="react-user-modal_block">
                        <label
                            className={
                                (formValid && !email.value.length) ||
                                    (email.isDirty && email.isEmpty) ||
                                    (email.isDirty && email.emailError)
                                    ? "react-user-modal_label error-label"
                                    : "react-user-modal_label"
                            }
                            htmlFor="email"
                        >
                            {gettext.getString("Электронная почта")}
                        </label>
                        <input
                            onChange={(e) => email.onChange(e.target.value)}
                            onBlur={(e) => email.onBlur(e)}
                            value={email.value}
                            disabled={isSuperAdmin}
                            className={`${(formValid && !email.value.length) ||
                                (email.isDirty && (email.isEmpty || email.emailError))
                                ? "react-user-modal_input error-input"
                                : "react-user-modal_input"} ${isSuperAdmin && 'react-input_field__untouched'}`

                            }
                            type="email"
                            name="email"
                            id="email"
                            required
                        />
                    </div>
                    <div className={authType === 1 ? "react-user-modal_checkblock" : "react-user-modal_none"}>
                        <div className="react-user-modal_password-block">
                            <label
                                onClick={onClickPassword}
                                className={"react-user-modal_label-link"}
                                htmlFor="password"
                                data-testid="auth-type-switcher-password"
                            >
                                {gettext.getString("Пароль")}
                            </label>
                            <div className="react-user-modal_password">
                                <input
                                    onChange={(e) => password.onChange(e.target.value)}
                                    onBlur={(e) => password.onBlur(e)}
                                    value={password.value}
                                    className={`${(formValid && !password.value.length && authType === 1) ||
                                        (password.isDirty && password.isEmpty)
                                        ? "react-user-modal_input error-input"
                                        : "react-user-modal_input input-pass"}`

                                    }
                                    type="text"
                                    name="password"
                                    id="password"
                                    required
                                />
                                <button onClick={onPasswordGenerate} className={`react-user-modal_btn`}>
                                    {gettext.getString("Сгенерировать")}
                                </button>
                            </div>
                        </div>
                        <div className="react-user-modal_checkbox">
                            <input
                                onChange={() => setPasswordUpdate((prev) => !prev)}
                                checked={passwordUpdate}
                                className="checkox-input"
                                disabled={isSuperAdmin}
                                type="checkbox"
                                id="checkbox"
                            />
                            <label htmlFor="checkbox" className="checkbox-label">
                                {gettext.getString("Обновить пароль при авторизации")}
                            </label>
                        </div>
                    </div>
                    <div className={authType === 2 ? "react-user-modal_block" : "react-user-modal_none"}>
                        <label
                            data-testid="auth-type-switcher-ldap"
                            onClick={onClickLdap}
                            className={"react-user-modal_label-link"}
                            htmlFor="ldap"
                        >
                            {gettext.getString("LDAP")}
                        </label>
                        <div className="react-user-modal_ldap">
                            <div className="react-user-modal_select" data-testid="auth-select-ldap">
                                <Select
                                    classNamePrefix="user-modal_select"
                                    onChange={onChangeSelectLdap}
                                    value={cleanLdap ? null : getValueSelect(ldapSelect, optionsLdap)}
                                    options={optionsLdap}
                                    isSearchable={false}
                                    placeholder={gettext.getString("Выберите профиль")}
                                    data-testid="user-select-ldap"
                                    components={{ DropdownIndicator }}
                                    defaultValue={userId && authType === 2 ? defaultValueLdap : ""}
                                />
                            </div>
                            <span>\</span>
                            <input
                                onChange={(e) => ldap.onChange(e.target.value)}
                                onBlur={(e) => ldap.onBlur(e)}
                                value={ldap.value || ''}
                                className={
                                    (authType === 2 && formValid && !ldap.value.length) ||
                                        (ldap.isDirty && ldap.isEmpty)
                                        ? "react-user-modal_input error-input"
                                        : "react-user-modal_input"
                                }
                                type="text"
                                name="ldap"
                                id="ldap"
                                placeholder={gettext.getString("Введите логин")}
                            />
                            {ldapSelect !== null || ldap?.value?.length ? (
                                <button onClick={onClickCleanLdap} className="react-user-modal_clean-ldap">
                                    ×
                                </button>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                    <div className={
                        authType === 3
                            ? "react-user-modal_block react-user-modal_clean-block"
                            : "react-user-modal_none"
                    }
                    >
                        <label
                            data-testid="auth-type-switcher-sso"
                            onClick={onClickSso}
                            className={"react-user-modal_label-link-sso"}
                            htmlFor="sso"
                        >
                            {gettext.getString("SSO")}
                        </label>
                        <div className="react-user-modal_select-sso" data-testid="auth-select-sso">
                            <Select
                                classNamePrefix="user-modal_select"
                                onChange={onChangeSelectSso}
                                value={cleanSso ? null : getValueSelect(ssoSelect, optionsSso)}
                                options={optionsSso}
                                isSearchable={false}
                                placeholder={gettext.getString("Выберите профиль")}
                                data-testid="user-select-sso"
                                components={{ DropdownIndicator }}
                                defaultValue={userId && authType === 3 ? defaultValueSso : ""}
                            />
                        </div>
                        {ssoSelect !== null && (
                            <button onClick={onClickCleanSso} className="react-user-modal_clean-sso">
                                ×
                            </button>
                        )}
                    </div>
                    <div className="react-user-modal_block">
                        <label
                            className={
                                (formValid && !fullName.value.length) || (fullName.isDirty && fullName.isEmpty)
                                    ? "react-user-modal_label error-label"
                                    : "react-user-modal_label"
                            }
                            htmlFor="fullname"
                        >
                            {gettext.getString("ФИО")}
                        </label>
                        <input
                            onChange={(e) => fullName.onChange(e.target.value)}
                            onBlur={(e) => fullName.onBlur(e)}
                            value={fullName.value}
                            disabled={isSuperAdmin}
                            className={`${(formValid && !fullName.value.length) || (fullName.isDirty && fullName.isEmpty)
                                ? "react-user-modal_input error-input"
                                : "react-user-modal_input"} ${isSuperAdmin && 'react-input_field__untouched'}`

                            }
                            type="text"
                            name="fullname"
                            id="fullname"
                            placeholder={gettext.getString("Фамилия Имя Отчество")}
                        />
                    </div>
                    <div className="react-user-modal_block">
                        <label
                            className={
                                (formValid && !phone.value.length) || (phone.isDirty && phone.isEmpty)
                                    ? "react-user-modal_label error-label"
                                    : "react-user-modal_label"
                            }
                            htmlFor="tel"
                        >
                            {gettext.getString("Телефон")}
                        </label>
                        <input
                            onChange={(e) => phone.onChange(e.target.value)}
                            onBlur={(e) => phone.onBlur(e)}
                            value={phone.value}
                            disabled={isSuperAdmin}
                            className={`${(formValid && !phone.value.length) || (phone.isDirty && phone.isEmpty)
                                ? "react-user-modal_input error-input"
                                : "react-user-modal_input"} ${isSuperAdmin && 'react-input_field__untouched'}`
                            }
                            type="tel"
                            name="tel"
                            id="tel"
                        />
                    </div>
                    <div className="react-user-modal_block">
                        <label
                            className={
                                formValid && !departmentsSelect.id
                                    ? "react-user-modal_label error-label"
                                    : "react-user-modal_label"
                            }
                            htmlFor="department"
                        >
                            {gettext.getString("Отдел")}
                        </label>
                        <div className="react-user-modal_select">
                            {departmentsLoad && <DepartmentsSelect
                                inputAttr={values["department-select"].inputAttr}
                                Style={departmentStyles}
                                disabled={isSuperAdmin}
                                handleClick={values["department-select"].setValue}
                                style={{ width: "100%" }}
                                placeholder={gettext.getString("Отдел")}
                                visibleArrow={'0'}
                                isCreate={true}
                            />}
                        </div>
                    </div>
                    <div className="react-user-modal_block">
                        <label
                            className={
                                (formValid && !position.value.length) || (position.isDirty && position.isEmpty)
                                    ? "react-user-modal_label error-label"
                                    : "react-user-modal_label"
                            }
                            htmlFor="position"
                        >
                            {gettext.getString("Должность")}
                        </label>
                        <input
                            onChange={(e) => position.onChange(e.target.value)}
                            onBlur={(e) => position.onBlur(e)}
                            value={position.value}
                            disabled={isSuperAdmin}
                            className={`${(formValid && !position.value.length) || (position.isDirty && position.isEmpty)
                                ? "react-user-modal_input error-input"
                                : "react-user-modal_input"} ${isSuperAdmin && 'react-input_field__untouched'}`
                            }
                            type="text"
                            name="position"
                            id="position"
                        />
                    </div>
                    <div className="react-user-modal_block">
                        <label className="react-user-modal_label" htmlFor="role">
                            {gettext.getString("Роли")}
                        </label>
                        <div className="react-user-modal_select">
                            <Select
                                classNamePrefix="user-modal_select-role"
                                onChange={onChangeSelectRoles}
                                value={getValueMultiRole()}
                                options={optionsRoles}
                                isDisabled={isSuperAdmin}
                                isSearchable
                                data-testid="user-select-role"
                                placeholder={gettext.getString("Выберите роли")}
                                isMulti
                                defaultValue={userId ? defaultValueRole : ""}
                            />
                        </div>
                    </div>
                    <div className="react-user-modal_checkbox">
                        <input
                            onChange={() => setCheckboxCalendar((prev) => !prev)}
                            checked={checkboxCalendar}
                            disabled={isSuperAdmin}
                            className="checkox-input"
                            type="checkbox"
                        />
                        <label className="checkbox-label">
                            <span>{gettext.getString("Вход заблокирован")}&nbsp;</span>
                            {showCalendar ? (
                                <>
                                    <span onClick={() => !isSuperAdmin && setShowCalendar(false)} className="react-user-modal_link">
                                        {gettext.getString("до")}
                                    </span>
                                    <div className="react-user-modal_calendar">
                                        <DatePicker
                                            popperPlacement="top"
                                            selected={startDate}
                                            onChange={(date) => !isSuperAdmin && setStartDate(date)}
                                            customInput={<ExampleCustomInput />}
                                            minDate={new Date()}
                                            timeInputLabel="Time:"
                                            showTimeInput
                                            data-testid="user-modal-calendar"
                                        />
                                    </div>
                                </>
                            ) : (
                                <span onClick={() => !isSuperAdmin && setShowCalendar(true)} className="react-user-modal_link" href="#">
                                    {gettext.getString("навсегда")}
                                </span>
                            )}
                        </label>
                    </div>
                </div>
            )}

            <div className="react-modal-form_error">
                <ModalFooter
                    titleBtnOk={userId ? gettext.getString("Сохранить") : gettext.getString("Добавить")}
                    titleBtnClose={gettext.getString("Отменить")}
                    closeModal={() => onModalClose()}
                    onEnter={onSaveUser}
                    disabledCancel={loading || save ? true : false}
                    disabled={
                        loading ||
                            save ||
                            (email.isDirty && !email.inputValid) ||
                            (fullName.isDirty && fullName.isEmpty) ||
                            (password.isDirty && password.isEmpty) ||
                            (phone.isDirty && phone.isEmpty) ||
                            (position.isDirty && position.isEmpty) ||
                            (authType === 1 && password.isDirty && password.isEmpty)
                            ? true
                            : false
                    }
                />
                {formError.length !== 0 ? <FaIcon className="react-icon-error" type={"error"} text={formError} /> : ""}
                {save ? <FaIcon type={"pending"} text={"Сохранение..."} /> : ""}
            </div>
        </>
    );
}

export default UserModal;
