import React, {useContext, useEffect, useState} from 'react'
import { GettextContext, InjectorContext } from '../../context'
import SettingsHeader from 'rootReact/components/SettingsSectionHeader'
import {SsoProfileModal} from '../../../components/ssoProfileModal'
import { useSelector, useDispatch } from 'react-redux'
import SettingsTable from 'rootReact/components/SettingsTable'
import { ssoSchema } from 'rootReact/components/SettingsSso/cellSchema'
import { deleteSSO, fetchSSOPost } from '../../../toolkit/think'

function SettingsSso({licenseIsValid}) {
    const gettext = useContext(GettextContext)
    const injector = useContext(InjectorContext)
    const ssoProfile = useSelector(state => state.ssoProfile.ssoProfiles)
    const dispatch = useDispatch()

    const getHeaderLink = () => <div><a onClick={() => licenseIsValid && openModal()} className={`settings_path-right ${!licenseIsValid ? 'nolink' : ''}`}>{gettext.getString('добавить')}</a></div>

    const openModal = (profile = null) => {
        let ssoModal = injector.instantiate(SsoProfileModal)
        ssoModal.open(profile)
    }

    const iconDelete = (e, id) => {
        e.stopPropagation()
        dispatch(deleteSSO(id))
    }

    const handleRow = (id) => {
        let currentProfile = ssoProfile.filter(item => item.id === id)[0]
        openModal(currentProfile)
    }

    return (
        <div className={'settings_path'}>
            <SettingsHeader title={gettext.getString('Профили SSO')} right>{getHeaderLink()}</SettingsHeader>
            <SettingsTable header={[
                {name: gettext.getString('Название')},
                {name: ''}
            ]}
                body={ssoProfile}
                assets={{empty: gettext.getString('Нет профилей')}}
                cellSchema={
                    ssoSchema({
                        iconHandle:[iconDelete],
                        handleRow,
                        disabled: !licenseIsValid
                    })}
            ></SettingsTable>
        </div>
    )
}

export default SettingsSso
