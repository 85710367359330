import React, { useContext, useState, useEffect } from 'react'
import {useDispatch} from 'react-redux'
import { GettextContext } from 'rootReact/context'
import Input from 'rootReact/components/Input'
import { useInputStyle, useValidForm } from 'rootReact/hook'
import { customRules, generalRules } from './OutlookCardRules.jsx'
import { cancelSaved, deleteCard, setActive, setCardDownload, setNewCard } from '../../../toolkit/actions'
import DefaultCards from './DefaultCards.jsx'
import { saveCardSettings } from 'root/assets/webpack/src/js/toolkit/outlookSettings';
import ReactInputMultiple from 'rootReact/components/ReactInputMultiple';
import FaIcon from 'rootReact/components/FaIcon';
import FetchApi from 'rootReact/REST/fetchApi';

const validFormat = ['image/jpeg', 'image/png', 'image/svg+xml']
const validSize = 300000

function OutlookCard (props) {
    const {
        buttonText, header,
        logo, optionComments,
        optionReasonShipment, optionTypeAttack,
        optionVectorAttack, text,
        type, name,
        to, subject,
        copy, token,
        from, setCardParams,
        settingId, cardLoad,
        sendMailToSecurity, cardSaved,
        pluginAttackSave
    } = props

    const dispatch = useDispatch()
    const gettext = useContext(GettextContext)
    const [cardName, setName] = useState(name)
    const [cardLogo, setLogo] = useState(logo)
    const [cardLogoSize, setLogoSize] = useState()
    const [cardButtonText, setButtonText] = useState(buttonText)
    const [cardHeader, setHeader] = useState(header)
    const [cardText, setText] = useState(text)
    const [cardFrom, setFrom] = useState(from || [])
    const [cardTo, setTo] = useState(to)
    const [cardToken, setToken] = useState(token)
    const [cardCopy, setCopy] = useState(copy)
    const [cardSubject, setSubject] = useState(subject)
    const [errorLogo, setErrorLogo] = useState(false)
    const setCardsParams = (currentKey, value) => {
        let key = currentKey.split('-')[1]
        dispatch(setCardParams({id: settingId, params: {[key]: value}}))
    }
    const cardValues = useValidForm({
        'card-header': {value: cardHeader, setValue: setHeader},
        'card-name': { value: cardName, setValue: setName},
        'card-text': {value: cardText, setValue: setText},
        'card-buttonText': {value: cardButtonText, setValue: setButtonText},
        'card-from': {value: cardFrom, setValue: setFrom},
        'card-to': {value: cardTo, setValue: setTo},
        'card-subject': {value: cardSubject, setValue: setSubject},
        'card-copy': {value: cardCopy, setValue: setCopy},
        'card-token': {value: cardToken, setValue: setToken},
    }, type !== 3 ? generalRules : customRules, setCardsParams)

    const headerStyle = useInputStyle(['react-input_field outlook-form_input'] , cardValues['card-header'].touched, cardValues['card-header'].focused, cardValues['card-header'].error)
    const textStyle = useInputStyle(['react-textarea_field'] , cardValues['card-text'].touched, cardValues['card-text'].focused, cardValues['card-text'].error)
    const buttonTextStyle = useInputStyle(['react-input_field outlook-form_input'] , cardValues['card-buttonText'].touched, cardValues['card-buttonText'].focused, cardValues['card-buttonText'].error)
    const nameStyle = useInputStyle(['react-input_field outlook-form_input'] , cardValues['card-name'].touched, cardValues['card-name'].focused, cardValues['card-name'].error)
    const tokenStyle = type !== 3 ? '' : useInputStyle(['react-input_field outlook-form_input'] , cardValues['card-token'].touched, cardValues['card-token'].focused, cardValues['card-token'].error)
    const getSizeFromString = async (base64) => {
        const mime = base64.split(';')[0].replace('data:', '')
        const res = await fetch(base64)
        const buf = await res.arrayBuffer()
        const file = await new File([buf], 'Logo',{type:mime})
        setLogoSize(file.size)
    }

    const removeLogo = (e) => {
        preventDefaults(e)
        setLogo(null)
        setLogoSize(null)
        setCardsParams('card-logo', null)
    }

    const getImage = (file) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function() {
            setLogo(reader.result)
            setLogoSize(file.size)
            setCardsParams('card-logo', reader.result)
        };
    }

    const setChips = (value, id) => {
        cardValues[id].setValue(value)
        setCardsParams(id, value)
    }

    const convertMimeType = (image) => {
        if (image.type) {
            return image.type === 'image/jpeg' ? 'JPEG' : image.type === 'image/png' ? 'PNG' : 'SVG'
        }
        let mime = image.split(';')[0].replace('data:', '')
        return mime === 'image/jpeg' ? 'JPEG' : mime === 'image/png' ? 'PNG' : 'SVG'
    }

    const dropEvent = (e) => {
        setErrorLogo(false)
        let dt = e.dataTransfer
        let files = dt.files
        if (files.length > 1) {
            setErrorLogo(true)
            return
        }
        validImage(files[0])
    }

    const preventDefaults = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const validImage = (file) => {
        setErrorLogo(false)
        if (!~validFormat.indexOf(file.type)) {
            setErrorLogo(true)
            return
        }
        if (file.size > validSize) {
            setErrorLogo(true)
            return
        }
        getImage(file)
    }

    const setProperty = (property) => {
        if (property.logo) {
            setLogo(property.logo)
            getSizeFromString(property.logo)
        }
        property.name ? cardValues['card-name'].setValue(property.name) : null
        property.buttonText ? cardValues['card-buttonText'].setValue(property.buttonText) : null
        property.header ? cardValues['card-header'].setValue(property.header) : null
        property.text ? cardValues['card-text'].setValue(property.text) : null
        property.from && type === 3 ? cardValues['card-from'].setValue(property.from) : null
        property.to && type === 3 ? cardValues['card-to'].setValue(property.to) : null
        property.hasOwnProperty('token') && type === 3 ? cardValues['card-token'].setValue(property.token) : null
        property.copy && type === 3 ? cardValues['card-copy'].setValue(property.copy) : null
        property.subject  && type === 3? cardValues['card-subject'].setValue(property.subject) : null
    }

    const checkGroup = () => {
        if (type !== 3) return true
        return cardFrom.length || cardTo.length || cardToken || cardCopy.length || cardSubject.length
    }

    const setDefaultSettings = async () => {
        if (cardLoad) return
        resetErrors()
        dispatch(setCardDownload(true))
        const fetchApi = FetchApi(`/outlook/card-settings/default?type=${type}`)
        const response = await fetchApi.get()
        if (response.isError) return
        response.type = +response.type
        dispatch(setCardParams({id: settingId, params: {...response}}))
        dispatch(setCardDownload(false))
        setProperty(response)
    }

    const resetErrors = () => {
        for (let key in cardValues.error) {
            cardValues.setError({[key]: false});
        }
    }

    const checkError = () => {
        for (let key in cardValues.error) {
            if (cardValues.error[key]) return true
        }
        return false
    }

    const isFormValid = () => {
        return !cardLogo || cardLoad || checkError() || !checkGroup()
    }

    const saveSettings = async () => {
        if (isFormValid()) return
        dispatch(saveCardSettings(settingId))
    }

    useEffect(() => {
        if (cardSaved) {
            setTimeout(() => {
                dispatch(cancelSaved())
            }, 2000)
        }
    }, [cardSaved])

    useEffect(() => {
        getSizeFromString(logo)
        const dropArea = document.querySelector('.outlook_dragover')
        const events = ['dragenter', 'dragover', 'dragleave', 'drop']
        events.forEach(eventName => {
            dropArea.addEventListener(eventName, preventDefaults, false)
        })
        dropArea.addEventListener('drop', dropEvent)
        return () => {
            dropArea.removeEventListener('drop', dropEvent)
            events.forEach(eventName => {
                dropArea.removeEventListener(eventName, preventDefaults)
            })
        }
    }, [])

    return (
        <div className={'outlook_path'}>
            <div className={'outlook_item'}>
                <Input
                        inputId={'card-name'}
                        disabled={!pluginAttackSave}
                        labelStyle={'outlook_label'}
                        label={gettext.getString('Название')}
                        inputAttr={cardValues['card-name'].inputAttr}
                        error={cardValues['card-name'].error}
                        Style={nameStyle}
                />
            </div>
            {type !== 1 && <div className={'outlook_item'}>
                <div className={'react-input outlook_radio'}>
                    <div className={'react-input_label outlook_label'}>{gettext.getString(`Отправка письма в службу ИБ`)}</div>
                    <div className={''}>
                        <div className={'outlook_radio-wrapper row-center'}>
                            <div className={`react-radiobutton ${!sendMailToSecurity ? 'react-radiobutton_active' : ''} outlook_radiobutton`}
                                 onClick={() => pluginAttackSave && setCardsParams('card-sendMailToSecurity', 0)}
                            />
                            <span className={'react-radiobutton_label'}
                                  onClick={() => pluginAttackSave && setCardsParams('card-sendMailToSecurity', 0)}
                            >{gettext.getString('Сразу')}</span>
                        </div>
                        <div className={'row-center'}>
                            <div className={`${sendMailToSecurity ? 'react-radiobutton_active' : ''} react-radiobutton outlook_radiobutton`}
                                 onClick={() => pluginAttackSave && setCardsParams('card-sendMailToSecurity', 1)}
                            />
                            <span onClick={() => pluginAttackSave && setCardsParams('card-sendMailToSecurity', 1)}
                            >{gettext.getString('После обратной связи')}</span>
                        </div>
                    </div>
                </div>
            </div>}
            {type === 3 && <>
                <div className={'outlook_sub-header'}>
                    <span>{gettext.getString('Правило')}</span>
                    <span className={'outlook_sub-text'}>{gettext.getString('Чтобы сохранить новый тип, заполните хотя бы одно поле.')}</span>
                </div>
                <div className={'outlook_item'}>
                    <div className={'react-input_label outlook_label'}>{gettext.getString('От')}</div>
                    <div className={'react-input_wrapper'}>
                        <ReactInputMultiple
                                inputId={'card-from'}
                                initialState={cardFrom}
                                setValue={setChips}
                                disabled={!pluginAttackSave}
                        />
                    </div>
                </div>
                <div className={'outlook_item'}>
                    <div className={'react-input_label outlook_label'}>{gettext.getString('Кому')}</div>
                    <div className={'react-input_wrapper'}>
                        <ReactInputMultiple
                                inputId={'card-to'}
                                initialState={cardTo}
                                setValue={setChips}
                                disabled={!pluginAttackSave}
                        />
                    </div>
                </div>
                <div className={'outlook_item'}>
                    <div className={'react-input_label outlook_label'}>{gettext.getString('Тема')}</div>
                    <div className={'react-input_wrapper'}>
                        <ReactInputMultiple
                                inputId={'card-subject'}
                                initialState={cardSubject}
                                setValue={setChips}
                                disabled={!pluginAttackSave}
                        />
                    </div>
                </div>
                <div className={'outlook_item'}>
                    <div className={'react-input_label outlook_label'}>{gettext.getString('Копия')}</div>
                    <div className={'react-input_wrapper'}>
                        <ReactInputMultiple
                                inputId={'card-copy'}
                                initialState={cardCopy}
                                setValue={setChips}
                                disabled={!pluginAttackSave}
                        />
                    </div>
                </div>
                <div className={'outlook_item'}>
                    <Input
                            inputId={'card-token'}
                            disabled={!pluginAttackSave}
                            labelStyle={'outlook_label'}
                            label={gettext.getString('Токен')}
                            inputAttr={cardValues['card-token'].inputAttr}
                            error={cardValues['card-token'].error}
                            Style={tokenStyle}
                    />
                </div>
            </>}
            <div className={`outlook_sub-header ${type === 1 ? 'pt-0' : ''}`}>
                <span>{gettext.getString('Форма обратной связи')}</span>
            </div>
            <div className={'outlook_item'}>
                <div className={'react-input outlook_radio'}>
                    <div className={'react-input_label outlook_label'}>{gettext.getString('Логотип')}</div>
                    <div className={'react-input_wrapper'}>
                        <label className={`outlook_dragover ${errorLogo ? 'outlook_dragover__error' : ''} ${cardLogo ? 'outlook_dragover__valid' : ''}`}>
                            {!cardLogo &&
                            <>
                                <span className={'dragover_label dragover_text'}>{gettext.getString('Перетащите или выберите файл')}</span>
                                <span className={'dragover_text'}>{gettext.getString('SVG, PNG, JPEG, не более {{num}} МБ', {num: 1})}</span>
                            </>
                            }
                            {cardLogo &&
                            <>
                                <div className={'outlook_file-wrapper'}>
                                    <span className={'outlook_dragover-label'}>
                                        Logo
                                    </span>
                                    <div className={'react-cross'}
                                         onClick={(e) => {pluginAttackSave && removeLogo(e)}}
                                    />
                                </div>
                                <span className={'outlook_dragover-size'}>
                                    {convertMimeType(cardLogo)}, {Math.round(cardLogoSize/100)/10} {gettext.getString('КБ')}
                                </span>
                            </>
                            }
                            <input className={'outlook_uploader'}
                                   type="file"
                                   disabled={!pluginAttackSave}
                                   accept={'image/jpeg,image/png,image/svg+xml'}
                                   onChange={(e)=>validImage(e.target.files[0])}
                            />
                        </label>
                    </div>
                </div>
            </div>
            <div className={'outlook_item'}>
                <Input
                        inputId={'card-header'}
                        disabled={!pluginAttackSave}
                        labelStyle={'outlook_label'}
                        label={gettext.getString('Заголовок')}
                        inputAttr={cardValues['card-header'].inputAttr}
                        error={cardValues['card-header'].error}
                        Style={headerStyle}
                        prompt={gettext.getString('Не более {{num}} символов', {num: 100})}
                />
            </div>
            <div className={'outlook_item'}>
                <Input
                        inputId={'card-text'}
                        disabled={!pluginAttackSave}
                        labelStyle={'outlook_label'}
                        label={gettext.getString('Текст')}
                        inputAttr={cardValues['card-text'].inputAttr}
                        error={cardValues['card-text'].error}
                        Style={textStyle}
                        center
                        textarea={true}
                        prompt={gettext.getString('Не более {{num}} символов', {num: 150})}
                />
            </div>
            <div className={'outlook_item'}>
                <div className={'react-input'}>
                    <div className={'react-input_label outlook_label'}>{gettext.getString(`Опции`)}</div>
                    <div className={'react-input_wrapper flex-column'}>
                        <div className={'outlook_radio-wrapper row-center'}>
                            <div className={`react-checkbox outlook_checkbox ${optionVectorAttack ? 'react-checkbox_active' : ''}`}
                                onClick={() => pluginAttackSave && setCardsParams('card-optionVectorAttack', +!optionVectorAttack)}
                            />
                            <span onClick={() => pluginAttackSave && setCardsParams('card-optionVectorAttack', +!optionVectorAttack)}>{gettext.getString('Векторы атак')}</span>
                        </div>
                        <div className={'outlook_radio-wrapper row-center'}>
                            <div className={`react-checkbox outlook_checkbox ${optionTypeAttack ? 'react-checkbox_active' : ''}`}
                                 onClick={() => pluginAttackSave && setCardsParams('card-optionTypeAttack', +!optionTypeAttack)}
                            />
                            <span onClick={() => pluginAttackSave && setCardsParams('card-optionTypeAttack', +!optionTypeAttack)}>{gettext.getString('Тип атаки')}</span>
                        </div>
                        <div className={'outlook_radio-wrapper row-center'}>
                            <div className={`react-checkbox outlook_checkbox ${optionReasonShipment ? 'react-checkbox_active' : ''}`}
                                 onClick={() => pluginAttackSave && setCardsParams('card-optionReasonShipment', +!optionReasonShipment)}
                            />
                            <span onClick={() => pluginAttackSave && setCardsParams('card-optionReasonShipment', +!optionReasonShipment)}>{gettext.getString('Причина пересылки')}</span>
                        </div>
                        <div className={'row-center'}>
                            <div className={`react-checkbox outlook_checkbox ${optionComments ? 'react-checkbox_active' : ''}`}
                                 onClick={() => pluginAttackSave && setCardsParams('card-optionComments', +!optionComments)}
                            />
                            <span onClick={() => pluginAttackSave && setCardsParams('card-optionComments', +!optionComments)}>{gettext.getString('Комментарий')}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'outlook_item'}>
                <Input
                        inputId={'card-buttonText'}
                        disabled={!pluginAttackSave}
                        labelStyle={'outlook_label'}
                        label={gettext.getString('Текст на кнопке')}
                        inputAttr={cardValues['card-buttonText'].inputAttr}
                        error={cardValues['card-buttonText'].error}
                        Style={buttonTextStyle}
                        prompt={gettext.getString('Не более {{num}} символов', {num: 30})}
                />
            </div>
            {pluginAttackSave && <div style={{display: 'flex'}}>
                <button className={`col react-button_element react-button_element__sm`}
                        style={{marginRight: 'auto'}}
                        onClick={setDefaultSettings}
                >{gettext.getString('Сбросить до базовых')}</button>
                <button className={`${isFormValid() ? 'react-button_element__disabled' : ''} col react-button_element react-button_element__sm react-button_element__submit`}
                        style={{marginLeft: 'auto', marginRight: '30px'}}
                        onClick={saveSettings}
                >
                    {cardLoad && <FaIcon type={'pending'} />}
                    {cardSaved && <FaIcon type={'success'} />}
                    {gettext.getString('Сохранить')}</button>
            </div>}
        </div>
    )
}

export default OutlookCard
