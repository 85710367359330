import React, { useContext, useState } from "react";
import { GettextContext } from "rootReact/context";
import loader from "../../../../images/loading.svg";
import { useSelector } from "react-redux";
import ButtonsGroup from "./ButtonsGroup.jsx";

function ListWifiDevice({ loadDeviceList, deviceList, deleteDevice, editDevice, setPopup, popup }) {
    const gettext = useContext(GettextContext);
    const loadDeleteDevice = useSelector((state) => state.wifiDevice.deleteLoading);
    const [openMenu, setOpenMenu] = useState({
        id: null,
        close: false,
    });

    if (loadDeviceList) {
        return (
            <tr className={"wifi-device_loader"}>
                <td><img src={loader} /></td>
            </tr>
        );
    } else if (!deviceList.length) {
        return (
            <tr className={"wifi-device_empty"}>
                <td className={"wifi-device_empty-content"}>{gettext.getString("Нет устройств")}</td>
            </tr>
        );
    }

    const setCloseMenu = (newValue) => {
        setOpenMenu({ ...openMenu, close: newValue })
    }

    const handleOpenMenu = (id) => {
        openMenu.close && id === openMenu.id ? setOpenMenu({ ...openMenu, id: id, close: false }) : setOpenMenu({ ...openMenu, id: id, close: true })
    };

    return deviceList.map((item) => {
        return (
            <tr className={"wifi-device_row"} key={item.id} data-testid="device-item">
                <td className={"wifi-device_label"} style={{ width: "24%" }} data-testid="device-label">
                    {item.name}
                </td>
                <td className={"wifi-device_label"} style={{ width: "14%" }} data-testid="device-label">
                    {item.ip}
                </td>
                <td className={"wifi-device_label"} style={{ width: "27%" }} data-testid="device-label">
                    <div className={"wifi-device_status"}>
                        {item.status === 0 ? (
                            <svg className={"status-icon"} width="20" height="20" viewBox="0 0 20 20">
                                <path
                                    d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM8 15L3 10L4.41 8.59L8 12.17L15.59 4.58L17 6L8 15Z"
                                    fill="#4CAF50"
                                />
                            </svg>
                        ) : (
                            <svg className={"status-icon"} width="20" height="20" viewBox="0 0 20 20">
                                <path
                                    d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V13H11V15ZM11 11H9V5H11V11Z"
                                    fill="#EF5350"
                                />
                            </svg>
                        )}
                        {item.status === 0 ? (
                            <span>{gettext.getString("Подключено")}</span>
                        ) : (
                            <span>{gettext.getString("Пароль и/или логин не совпадают с настройками роутера")}</span>
                        )}
                    </div>
                </td>
                <td className={"wifi-device_label"} style={{ width: "35%" }} data-testid="device-label">
                    <div className={"wifi-device_icon-btn"}>
                        {item.attack ? (
                            <a href="/attacks" className={"wifi-device_label-link"}>
                                {item.attack.title}
                            </a>
                        ) : (
                            <span>{gettext.getString("Не используется")}</span>
                        )}
                        <button
                            onClick={() => handleOpenMenu(item.id)}
                            className={`wifi-device_dots-menu ${openMenu.close ? "dots-menu-active" : ""}`}
                        >
                            <svg className={"wifi-device_dots-icon"}>
                                <use xlinkHref={`/image/icoSprite.svg#dots-menu`}></use>
                            </svg>
                        </button>
                        {openMenu.close && item.id === openMenu.id && (
                            <ButtonsGroup
                                deleteDevice={deleteDevice}
                                editDevice={editDevice}
                                id={item.id}
                                name={item.name}
                                attack={item.attack}
                                setCloseMenu={setCloseMenu}
                                setPopup={setPopup}
                                deviceList={deviceList}
                                opened={openMenu.close}
                            />
                        )}
                    </div>
                </td>
            </tr>
        );
    });
}

export default ListWifiDevice;
