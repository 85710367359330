import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FetchApi from '../React/REST';

const initialState = {
    status: 'idle',
    certs: {},
    error: false
};

export const fetchCerts = createAsyncThunk(
    'certificate/status',
    async () => {
        const fetchApi = new FetchApi('/certs');
        const response = await fetchApi.get();
        return response;
    }
);

export const deleteCerts = createAsyncThunk(
    'certificate/delete',
    async (id) => {
        const fetchApi = FetchApi(`certs`);
        const response = await fetchApi.delete(id);
        return response;
    }
)

const certificateSlice = createSlice({
    name: 'certificate',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchCerts.fulfilled]: (state, { payload }) => {
            state.status = 'fulfilled';
            state.error = false;
            state.certs = payload;
        },
        [fetchCerts.pending]: (state) => {
            state.status = 'pending';
        },
        [fetchCerts.rejected]: (state) => {
            state.status = 'rejected';
        },
        [deleteCerts.fulfilled]: (state, { meta }) => {
            const id = meta.arg;
            state.certs = state.certs.filter(item => item.id !== id);
            state.error = false;
        },
        [deleteCerts.pending]: (state) => {
            state.status = 'pending';
        },
        [deleteCerts.rejected]: (state) => {
            state.status = 'rejected';
        },
    }
});

export default certificateSlice.reducer;

