import employeesSingleTemplate from './view.html';

import {EmployeesSingleController} from './controller';

const EmployeesSingleComponent = {
    template: employeesSingleTemplate,
    controller: EmployeesSingleController,
    controllerAs: '$',
    scope: true,
    bindings: {
        employeesModalCtrl: '=',
        updateParentProcess: '=',
        targetId: '<',
        departmentId: '<',
        groupId: '<',
        userInfo: '<',
        modalClose: '=',
    },
};

export {
    EmployeesSingleComponent,
};
