import educationTemplate from './view.html';

import {EducationMessageController} from './controller';

const EducationMessageComponent = {
    template: educationTemplate,
    controller: EducationMessageController,
    controllerAs: '$',
    scope: true,
    bindings: {
        messageCourses: '<',
        messageNotifications: '<',
        hasInProcess: '<'
    },
};

export {
    EducationMessageComponent,
};
