import usbTemplate from './view.html';

import {UsbAttackController} from './controller';

const UsbAttackComponent = {
    template: usbTemplate,
    controller: UsbAttackController,
    controllerAs: '$',
    scope: true,
    bindings: {
        attack: '=',
        campaignsCtrl: '=',
        licenseValid: '=',
    },
};

export {
    UsbAttackComponent,
};
