import {MAIL_SERVERS_SERVICE} from '../../const';

class MailServerModalController {
    constructor(MailServersService, serverId) {
        this.MailServersService = MailServersService;
        this.serverId = serverId;
        this.inProcess = false;
        this.gettextCatalog = window.appCtrl.gettextCatalog;
    }

    $onInit = () => {
        if (this.serverId > 0) {
            this.MailServersService.one(this.serverId).then((response) => {
                this.settings = response;
            });
        } else {
            this.settings = this.MailServersService.new();
            this.settings.protokol = 'imap';
            this.settings.security = 'ssl';
            this.settings.port = 993;
        }
    };

    setForm = (form) => {
        this.form = form;
    };

    saveSettings = () => {
        this.errors = null;
        this.form.$setSubmitted();
        this.errorText = '';
        if (this.form.$valid) {
            this.inProcess = true;
            this.settings.$save().then((data) => {
                this.inProcess = false;
                if (typeof (data) != 'undefined') {
                    this.$close(data);
                }
            }, (response) => {
                if (response && response.error) {
                    this.errorText = response.error;
                } else {
                    this.errorText = this.gettextCatalog.getString('Ошибка при сохранении настроек сервера');
                }
            });
        }
    };

    setSecurity = security => {
        let oldProtokol = this.settings.protokol;
        let oldSecurity = this.settings.security;
        let oldPort = this.settings.port;
        this.settings.security = security;
        this.updatePort(oldProtokol, oldSecurity, oldPort);
    };

    setProtokol = protokol => {
        let oldProtokol = this.settings.protokol;
        let oldSecurity = this.settings.security;
        let oldPort = this.settings.port;
        this.settings.protokol = protokol;
        this.updatePort(oldProtokol, oldSecurity, oldPort);
    };

    updatePort = (oldProtokol, oldSecurity, oldPort) => {
        if (!this.settings.port || (oldSecurity && ((oldProtokol == 'imap' && oldPort == 993) || (oldProtokol == 'pop3' && oldPort == 995))) || (!oldSecurity && ((oldProtokol == 'imap' && oldPort == 143) || (oldProtokol == 'pop3' && oldPort == 110)))) {
            if (this.settings.security) {
                this.settings.port = this.settings.protokol == 'imap' ? 993 : 995;
            } else {
                this.settings.port = this.settings.protokol == 'imap' ? 143 : 110;
            }
        }
    };
}

MailServerModalController.$inject = [
    MAIL_SERVERS_SERVICE,
    'serverId',
];

export {
    MailServerModalController,
};
