import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    actionTypes: [],
    triggerTypes: [],
    reportTypes: []
};

const typesSlice = createSlice({
    name: 'types',
    initialState,
    reducers: {
        setReportTypes (state, {payload}) {
            state.reportTypes = payload;
        },
        setActionTypes (state, {payload}) {
            state.actionTypes = payload;
        },
        setTriggerTypes (state, {payload}) {
            state.triggerTypes = payload
        }
    },
    extraReducers: {}
});

export const {setReportTypes, setActionTypes, setTriggerTypes} = typesSlice.actions;
export default typesSlice.reducer;

