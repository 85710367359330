import peopleGrathTemplate from './view.html';

import {PeopleGraphController} from './controller.js';

const PeopleGraphComponent = {
    template: peopleGrathTemplate,
    controller: PeopleGraphController,
    controllerAs: '$',
    scope: true,
    bindings: {
        targetHistory: '<',
        targetsInSystem: '<',
        companyCount: '<',
        licenseCount: '<',
        targetSuccsess: '='
    },
};

export {
    PeopleGraphComponent,
};
