import modalTemplate from './view.html';
import {ReportTargetsModalController} from './controller';

class ReportTargetsModal {
    constructor($uibModal) {
        'ngInject';
        this.$uibModal = $uibModal;
        this.modalInstance = null;
    }

    $onInit = () => {
        this.$uibModal;
    };

    open = (selectedData = null) => {
        this.modalInstance = this.$uibModal.open({
            template: modalTemplate,
            bindToController: true,
            controller: ReportTargetsModalController,
            controllerAs: '$',
            size: 'lg',
            resolve: {
                selectedData: selectedData,
            },
            backdrop: 'static',
            keyboard: false,
        });

        return this.modalInstance.result;
    };
}

export {
    ReportTargetsModal,
    ReportTargetsModalController
};
