import { useState, useEffect } from 'react'

export const getCurrentLanguage = () => {
    const [value, setValue] = useState('en');

    function getCookie(name) {
        let matches = document.cookie.match(new RegExp(
                "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? matches[1] : undefined;
    }

    useEffect(() => {
        setValue(getCookie('langCode'));
    }, []);

    return value;
}
