import React, { useEffect, useContext, useState } from "react";
import { GettextContext, InjectorContext } from "rootReact/context";
import { useDispatch, useSelector } from "react-redux";
import { fetchListWifiDevice, deleteWifiDevice } from "root/assets/webpack/src/js/toolkit/think";
import { ReactModal } from "root/assets/webpack/src/js/components/ReactModal";
import ListWifiDevice from "./ListWifiDevice.jsx";
import WifiDeviceModal from "../WifiDeviceModal";
import WifiDeleteModal from "../WifiDeviceModal/WifiDeleteModal.jsx";
import PopupComponent from "../PopupComponent/PopupComponent.jsx";

function WifiDevice() {
    const injector = useContext(InjectorContext);
    const gettext = useContext(GettextContext);
    const dispatch = useDispatch();
    const wifiDevice = useSelector((state) => state.wifiDevice.wifiDevice);
    const loadDeviceList = useSelector((state) => state.wifiDevice.loading);
    const [deleteDeviceItem, setDeleteDeviceItem] = useState(null);
    const [popup, setPopup] = useState(false);

    useEffect(() => {
        dispatch(fetchListWifiDevice());
    }, []);

    const editDevice = async (id, attack) => {
        const modal = injector.instantiate(ReactModal);
        let wifiDeviceModal = await modal.open(
            {
                component: WifiDeviceModal,
                props: {
                    id,
                    attack,
                },
            },
            "md"
        );
    };

    const deleteDevice = async (id, nameDevice, attack) => {
        if (attack) {
            const modal = injector.instantiate(ReactModal);
            let wifiDeleteModal = await modal.open(
                {
                    component: WifiDeleteModal,
                    props: {
                        attack,
                        id,
                    },
                },
                "md"
            );
            return;
        }
        const cancel = confirm(`${gettext.getString("Удалить беспроводное устройство")} "${nameDevice}"?`);
        if (!cancel) return;
        if (deleteDeviceItem === id) {
            return setDeleteDeviceItem(null);
        }
        setDeleteDeviceItem(id);
        dispatch(deleteWifiDevice({ id }));
    };

    const getDeviceItemList = () => {
        return (
            <ListWifiDevice
                deviceList={wifiDevice}
                deleteDevice={deleteDevice}
                deleteDeviceItem={deleteDeviceItem}
                editDevice={editDevice}
                loadDeviceList={loadDeviceList}
                setPopup={setPopup}
            />
        );
    };

    const createWifiDevice = async () => {
        const modal = injector.instantiate(ReactModal);
        let wifiDeviceModal = await modal.open(
            {
                component: WifiDeviceModal,
            },
            "md"
        );
    };

    useEffect(() => {
        if (popup) {
            setTimeout(() => setPopup(false), 3000)
        }
    }, [popup]);

    return (
        <div className={"wifi-device"} data-testid="wifi-device">
            <div className={"wifi-device_top"}>
                <div className={"settings-header"}>
                    <span className={"settings-header_title"}>{gettext.getString("Беспроводные сети")}</span>
                </div>
                <div className={"wifi-device_add-link"}>
                    <a data-testid="device-create" onClick={createWifiDevice}>
                        + {gettext.getString("Новое устройство")}
                    </a>
                </div>
            </div>
            <table className={"wifi-device_table"} data-testid="device-table">
                <thead>
                    <tr className={"wifi-device_header-wrapper"}>
                        <th className={"wifi-device-title"} style={{ width: "24%" }}>
                            {gettext.getString("Устройство")}
                        </th>
                        <th className={"wifi-device-title"} style={{ width: "14%" }}>
                            IP-{gettext.getString("адрес")}
                        </th>
                        <th className={"wifi-device-title"} style={{ width: "27%" }}>
                            {gettext.getString("Статус")}
                        </th>
                        <th className={"wifi-device-title"} style={{ width: "35%" }}>
                            {gettext.getString("Атака")}
                        </th>
                    </tr>
                </thead>
                <tbody data-testid="device-list">{getDeviceItemList()}</tbody>
            </table>
            {popup && (
                <PopupComponent className={"wifi-device_popup-position"}>
                    <svg className={"wifi-device_popup-icon"}> <use xlinkHref={`/image/icoSprite.svg#device-copy-token`}></use> </svg>
                    <span className={"wifi-device_popup-text"}>{gettext.getString("Вы скопировали токен")}</span>
                </PopupComponent>
            )}
        </div>
    );
}

export default WifiDevice;
