import dashboardPeople from './view.html';

import {DashboardPeople} from './controller';

const DashboardPeopleComponent = {
    template: dashboardPeople,
    controller: DashboardPeople,
    controllerAs: '$',
    bindings: {
        targetsOnCourse: '<',
        targetsInSystem: '<',
        month: '<',
        companyTargetCount: '<',
        addEmployee: '=',
        companyName: '<',
        licenceTarget: '<',
    },
};

export {
    DashboardPeopleComponent
};
