import React, { useState, useContext, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateWifiChecked } from "../../../toolkit/actions";
import "react-datepicker/dist/react-datepicker.css";
import { GettextContext, InjectorContext, LocationContext } from "rootReact/context";
import { ReactModal } from "root/assets/webpack/src/js/components/ReactModal";
import TargetsAttack from "./TargetsAttack.jsx";
import FetchApi from "../../REST";
import { fetchDaemonsMessage, fetchWifiTargets } from "root/assets/webpack/src/js/toolkit/think";
import WifiAttackModal from "../WifiAttackModal";
import Tooltip from "../Tooltip";
import loader from "../../../../images/loading.svg";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import "overlayscrollbars/overlayscrollbars.css";
import { setLinkings } from 'root/assets/webpack/src/js/toolkit/actions';

function WifiListAttack({ id, status, name, checked, ldapProfileCount }) {
    const injector = useContext(InjectorContext);
    const location = useContext(LocationContext);
    const gettext = useContext(GettextContext);
    const dispatch = useDispatch();
    const [targetsData, setTargetsData] = useState([]);
    const [loadTargets, setLoadTargets] = useState(false);
    const [openTarget, setOpenTarget] = useState(false);
    const [openLdapPopup, setOpenLdapPopup] = useState(false);
    const wifiAttack = useSelector((state) => state.wifi.wifi);

    const settingsLink = (path, hash = 'testLdapField') => {
        location.path(path)
        dispatch(setLinkings(hash))
        if (hash === 'testLdapField') {
            location.hash('testLdapField')
        }
    }

    const handleCheckboxSelect = async (id) => {
        dispatch(updateWifiChecked(id))
    };

    useEffect(() => {
        if (openTarget && !ldapProfileCount && targetsData.length !== 0) {
            setOpenLdapPopup(true)
        } else {
            setOpenLdapPopup(false)
        }
    }, [openTarget, ldapProfileCount, targetsData])

    useEffect(() => {
        async function fetchApi() {
            setLoadTargets(true);
            const wifiTargets = FetchApi(`/wifi-attacks`);
            let response = await wifiTargets.get(id + `/targets`);
            if (response && response.isError) {
                throw response;
            }
            setTargetsData(response);
            setLoadTargets(false);
        }
        try {
            if (id || openTarget) {
                fetchApi();
            }
        } catch (e) {
            setLoadTargets(true);
            console.log(e);
        }
    }, [id, openTarget]);

    const onClickToogleStatistic = () => {
        setOpenTarget((prev) => !prev);
    };

    const handleCloseLdapPopup = () => {
        setOpenLdapPopup(false)
    }

    const editAttack = async (id, statusAttack) => {
        const modal = injector.instantiate(ReactModal);
        if (id) {
            dispatch(fetchWifiTargets(id));
            let wifiModal = await modal.open({
                component: WifiAttackModal,
                props: {
                    id,
                    statusAttack,
                },
            });
        }
    };

    const targetsList = targetsData.length ? (
        targetsData.map((obj) => <TargetsAttack key={obj.id} {...obj} />)
    ) : (
        <tr className={"wifi-target_empty"}>
            <td>{gettext.getString("Нет подключений")}</td>
        </tr>
    );

    return (

        <div
            className={`wifi-attack-item_wrapper ${checked ? 'wifi-attack-item_wrapper-active' : ''}`}
            data-testid="attack-item"
        >
            <div className={`wifi-attack_row`}>
                {/* {openLdapPopup && (
                    <div className={"wifi-attack_ldap-popup"}>
                        <div className={"wifi-attack_ldap-popup-content"}>
                            <span className={"wifi-attack_ldap-popup-text"}>
                                {gettext.getString("Профиль LDAP поможет определить сотрудников и отдел")}
                            </span>
                            <a data-testid="ldap-link" className={"wifi-attack_ldap-popup-link"} onClick={() => settingsLink('settings')}>
                                {gettext.getString("Настроить")}
                            </a>
                        </div>
                        <svg onClick={handleCloseLdapPopup} className="wifi-attack_ldap-popup_svg">
                            <use xlinkHref={`/image/icoSprite.svg#wifi-ldap-close`}></use>
                        </svg>
                    </div>
                )} */}
                <div className={"wifi-attack_row-top"}>
                    <input
                        checked={checked}
                        onChange={() => handleCheckboxSelect(id)}
                        className="wifi-attack-checkbox"
                        type="checkbox"
                        data-testid="attack-checkbox"
                    />
                    <label className="wifi-attack-checkbox_label"></label>
                    <div onClick={() => editAttack(id, status)} className={"wifi-attack_label"} data-testid="attack-edit">
                        {name}
                    </div>
                    {status === 0 && <span className={"wifi-attack_status-new"}>{gettext.getString("Новая")}</span>}
                    {status === 1 && <span className={"wifi-attack_status-play"}>{gettext.getString("Активная")}</span>}
                    {status === 2 && <span className={"wifi-attack_status-pause"}>{gettext.getString("Остановлена")}</span>}
                </div>
                <div style={{marginRight: '8px'}}>
                    <Tooltip reactTip={gettext.getString("посмотреть статистику")}>
                        <button
                            data-testid="wifi-attackToggleStatistic"
                            onClick={onClickToogleStatistic}
                            className="wifi-attack_icon"
                        >
                            <svg
                                className={`wifi-attack_icon-arrow ${openTarget ? "wifi-attack_icon-arrow-open" : ""
                                    }`}
                            >
                                <use xlinkHref={`/image/icoSprite.svg#wifi-arrow-list`}></use>
                            </svg>
                        </button>
                    </Tooltip>
                </div>

            </div>
            {openTarget && (
                <OverlayScrollbarsComponent
                    options={{
                        scrollbars: { autoHide: "move", visibility: "auto" },
                        className: "wifi-attack-scroll",
                    }}
                    defer
                >
                    <table className={`wifi-attack_table`} data-testid="target-table">
                        <thead data-testid="target-head">
                            <tr className={"wifi-attack-statistic"} data-testid="target-item">
                                <th className={"wifi-attack-statistic_title"} data-testid="target-label">{gettext.getString("Когда")}</th>
                                <th className={"wifi-attack-statistic_title"} data-testid="target-label">
                                    {gettext.getString("Тип устройства и имя")}
                                </th>
                                <th className={"wifi-attack-statistic_title"} data-testid="target-label">
                                    {gettext.getString("Браузер")}
                                </th>
                                <th className={"wifi-attack-statistic_title"} data-testid="target-label">
                                    {gettext.getString("MAC адрес")}
                                </th>
                                <th className={"wifi-attack-statistic_title"} data-testid="target-label">
                                    {gettext.getString("Сотрудник")}
                                </th>
                                <th className={"wifi-attack-statistic_title"} data-testid="target-label">{gettext.getString("Отдел")}</th>
                            </tr>
                        </thead>
                        <tbody data-testid="target-list">{openTarget && targetsList}</tbody>
                    </table>
                </OverlayScrollbarsComponent>
            )}
        </div>
    );
}

export default WifiListAttack;
