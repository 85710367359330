import updateTemplate from './view.html';

import {SystemUpdateController} from './controller';

const SystemUpdateComponent = {
    template: updateTemplate,
    controller: SystemUpdateController,
    controllerAs: '$',
    scope: true,
    bindings: {},
};

export {
    SystemUpdateComponent,
};
